import React from 'react';
import { connect } from 'react-redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';

import './SeneMenu.scss';
import { State } from '../../../store';
import { selectUserProfile } from '../../../store/selectors/authSelectors';
import { hasAdminRights, hasCustomerRights } from '../../../utils/auth';
import { AssetDirectImage } from '../../common/Images';
import SeneSiteLink from '../../common/SeneSiteLink';
import SearchProducts from '../../common/SearchProducts';
import CartInfo from '../header/CartInfo';
import { selectCartItemCount } from '../../../store/selectors/cartSelectors';
import { UserManager } from '../../../utils/auth/UserManager';
import { selectAvailableLanguages } from "../../../store/selectors/languageSelectors";
import { CountryLanguage } from "../../../models/language";
import LanguageSelectorMobile from "../../common/LanguageSelectorMobile";
import { selectDistributorCountry } from "../../../store/selectors/distributorSelectors"; 

interface MenuStateProps {
    user: any;
    languages: CountryLanguage[];
    cartItemCount: number;
    distributorCountry: string;
}
type MenuProps = MenuStateProps & WithNamespaces;

interface MenuState {
    isMobileMenuOpen: boolean;
}

class SeneMenu extends React.PureComponent<MenuProps, MenuState> {
    state: MenuState = {
        isMobileMenuOpen: false
    };

    // TODO: get authority URL from config
    authorityUrl = '';
    onLogin = () => UserManager.signinRedirect({ui_locales: this.props.i18n.language.toLowerCase()});
    onLogout = () => UserManager.signoutRedirect({ui_locales: this.props.i18n.language.toLowerCase()});

    mobileToggle = () => { this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen }) }

    renderLoginLogout() {
        const { t } = this.props;
        const label = this.props.user ? t('logout') : t('login');
        const func = this.props.user ? this.onLogout : this.onLogin;

        return (
            <button
                type="button"
                className="btn btn-primary btn-block mt-2"
                onClick={func}
            >
                {label}
            </button>
        );
    }

    render() {
        const { t, distributorCountry } = this.props;
        return (
            <nav className="navbar navbar-expand-lg navbar-light main-menu container-space">
                <button
                    className="navbar-toggler main-menu__icon"
                    type="button"
                    onClick={this.mobileToggle}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <SeneSiteLink className="navbar-brand" to={"/"}>
                    <AssetDirectImage {...{
                        src: 'logo_sene-v2.png',
                        className: 'main-menu__logo',
                        alt: 'SeneGence Logo',
                    }} />
                </SeneSiteLink>

                <CartInfo t={t} isMobile={true} itemCount={this.props.cartItemCount} />

                <Collapse className="main-menu__collapse navbar-collapse" isOpen={this.state.isMobileMenuOpen}>
                    <ul className="navbar-nav mx-auto mt-2 mt-lg-0 main-menu__links w-100 flex-lg-grow-1 bd-highlight">
                        <li className="nav-item">
                            <SeneSiteLink className="nav-link p-lg-0 homeLink" to={"/"}>
                                {t('home')}
                            </SeneSiteLink>
                        </li>
                        <li className="nav-item">
                            <SeneSiteLink className="nav-link p-lg-0 shopNowLink" to={"/shopproducts"}>
                                {t('shopNow')}
                            </SeneSiteLink>
                        </li>
                        <li className="nav-item">
                            <SeneSiteLink className="nav-link p-lg-0 sellSenegenceLink" to={"/sell"}>
                                {t('sellSenegence')}
                            </SeneSiteLink>
                        </li>
                        {distributorCountry.toUpperCase() !== "MX" &&
                        <li className="nav-item">
                            <SeneSiteLink className="nav-link p-lg-0 aboutLink" to={"/aboutme"}>
                            {t('aboutSenegence')}
                            </SeneSiteLink>
                        </li>
                        }
                        <li className="nav-item">
                            <SeneSiteLink className="nav-link p-lg-0 contactLink" to={"/contact"}>
                                {t('contactMe')}
                            </SeneSiteLink>
                        </li>

                        {
                            hasAdminRights(this.props.user) &&
                            <li className="nav-item nav-item--mobile">
                                <NavLink className="nav-link" to="/administration/Dashboard">
                                    {t('dashboard')}
                                </NavLink>
                            </li>
                        }

                        {
                            hasCustomerRights(this.props.user) &&
                            <>
                                <li className="nav-item nav-item--mobile">
                                    <SeneSiteLink className="nav-link" to="/customer-profile">
                                        {t('customerProfile')}
                                    </SeneSiteLink>
                                </li>
                                <li className="nav-item nav-item--mobile">
                                    <a className="nav-link" href={this.authorityUrl}>
                                        {t('customerLoginSecurity')}
                                    </a>
                                </li>
                            </>
                        }

                        <LanguageSelectorMobile renderInAdminHeader={false} onSelectedLanguageChanged={this.mobileToggle}/>

                        <li className="nav-item">
                            <SearchProducts placeHolder={t('searchProduct')} />
                        </li>

                        <li className="nav-item--mobile">
                            {this.renderLoginLogout()}
                        </li>
                    </ul>
                </Collapse>

            </nav>
        )
    }
}

const mapStateToProps = (state: State) => ({
    user: selectUserProfile(state),
    languages: selectAvailableLanguages(state),
    cartItemCount: selectCartItemCount(state),
    distributorCountry: selectDistributorCountry(state)
})

export default withNamespaces('Menu')(connect(mapStateToProps)(SeneMenu));
