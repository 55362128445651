import { produce } from 'immer';

import { Order } from './../models/order';
import { OrderActionTypes } from './../actions/orderActions';

const initialState = [] as Order[];
export type OrderState = typeof initialState;

export const reducer = (state: OrderState = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case OrderActionTypes.getAllOrdersForDistributorError:
            case OrderActionTypes.getAllOrdersForDistributorSuccess:
                return action.orders as Order[]
            case OrderActionTypes.updateDistributorOrderStatusError:
            case OrderActionTypes.updateDistributorOrderStatusSuccess:
                const idx = draft.findIndex(order => order.orderId === action.order.orderId);
                draft[idx] = action.order;
                return draft;
            default:
                return draft;
        }
    });
