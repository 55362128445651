import React from 'react';

import './SeneNumberInput.scss';

export interface SeneNumberInputProps {
    identifier: string;
    value: number;
    step?: number;
    iconSize?: 'fa-xs' | 'fa-sm' | 'fa-lg' | 'fa-2x' | 'fa-3x' | 'fa-4x' | 'fa-5x' | 'fa-6x' | 'fa-7x' | 'fa-8x' | 'fa-9x' | 'fa-10x';
    updateOnBlur?: boolean;
    onChange(value: number, identifier: string): void;
}

interface SeneNumberInputState {
    value: number;
}

export class SeneNumberInput extends React.PureComponent<SeneNumberInputProps, SeneNumberInputState> {

    static defaultProps = {
        step: 1,
        iconSize: 'fa-lg',
        updateOnBlur: false
    }


    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    componentDidUpdate(prevProps: SeneNumberInputProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    add = (e: any) => {
        this.props.step &&
            this.props.onChange((this.props.value + this.props.step), this.props.identifier);
    }

    subtract = (e: any) => {
        if (!this.props.step) {
            return;
        }
        const newVal = this.props.value - this.props.step;
        if (newVal < 0) {
            this.props.onChange(0, this.props.identifier);
        } else {
            this.props.onChange(newVal, this.props.identifier);
        }
    }

    onChange = (e: any) => {
        const val = e && e.target && e.target.value && !isNaN(e.target.value) && parseInt(e.target.value) || 0;
        this.setState({ value: val });
        if (!this.props.updateOnBlur) {
            this.props.onChange(val, this.props.identifier);
        }
    }

    onBlur = (e: any) => {
        const val = e && e.target && e.target.value && !isNaN(e.target.value) && parseInt(e.target.value) || 0;
        if (this.props.updateOnBlur) {
            this.props.onChange(val, this.props.identifier);
        }
    }

    render() {
        return this.state &&
        (
            <div className="container">
                <div className="row">
                    <div className="input-group">
                        <div className="input-group-prepend text-primary">
                            <a
                                {... {
                                    className: "btn",
                                    onClick: this.subtract
                                }}
                            >
                                <i {... {
                                    className: `fas fa-minus-circle ${this.props.iconSize}`
                                }}></i>
                            </a>
                        </div>

                        <input
                            {...{
                                type: 'text',
                                className: 'form-control text-center rounded',
                                value: this.state.value,
                                onChange: this.onChange,
                                onBlur: this.onBlur
                            }}
                        />

                        <div className="input-group-append text-primary">
                            <a
                                {... {
                                    className: "btn",
                                    onClick: this.add
                                }}
                            >
                                <i {... {
                                    className: `fas fa-plus-circle ${this.props.iconSize}`
                                }}></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default SeneNumberInput;
