import { ConfigurationActionTypes } from './../actions/configurationActions';
import { Configuration, ConfigurationDto } from '../models/configuration';
import { APIConstants } from '../utils/constants';

export type ConfigurationState = ConfigurationDto | Configuration | null;

export const reducer = (state: ConfigurationState = null, action) => {
    switch (action.type) {
        case ConfigurationActionTypes.getConfigurationError:
        case ConfigurationActionTypes.getConfigurationSuccess:
            var config = action.payload as Configuration;
            config.apiConstants = new APIConstants(config.imagesHostName);
            return config;
        default:
            return state;
    }
};