import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import './withRetryComponent.scss'

export interface RetryComponentProps {
    initFailed();
}

interface withRetryComponentDispatchProps {}
type withRetryComponentProps = withRetryComponentDispatchProps & WithNamespaces;
interface withRetryComponentState {
    isError: boolean;
}

export default function withRetryComponent() {
    return function <OwnProps>(Component: React.ComponentType<OwnProps>) {
        class WrappedComponent extends React.Component<OwnProps & withRetryComponentProps, withRetryComponentState> {

            state: withRetryComponentState = {
                isError: false
            }

            initFailed = () => {
                this.setState({ isError: true });
            }

            retry = () => {
                this.setState({ isError: false });
            }

            render() {
                const { t } = this.props;
                if (this.state.isError) {
                    return (
                        <>
                            <div className="container display-color mt-3 mb-3">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <i className="fas fa-exclamation-circle fa-6x"></i>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <h1>{t('unexpectedError')}</h1>
                                            </div>
                                        </div>
                                        <div className="row font-weight-bold">
                                            <div className="col-12">
                                                <button type="button" className="btn btn-light display-color font-weight-bold" onClick={this.retry}>
                                                    <i className="fas fa-redo"></i> {t('tapToRetry')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                } else {
                    return (
                        <>
                            <Component {...this.props} initFailed={this.initFailed} />
                        </>
                    )
                }
            }
        }

        return withNamespaces('Common')(WrappedComponent) as any
    }
}
