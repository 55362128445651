import { LocalStorageCart } from './../models/cart';
import { cartLocalStorageKey } from './../utils/constants';
import { Thunk } from "../store";
import { selectCart } from '../store/selectors/cartSelectors';
import { getProductsByIds } from './catalogActions';
import * as toast from './../utils/toastHelper';
import { ActionToastProperties } from '../models/ui';
import { resetCheckoutErrors, CheckoutActionTypes } from './checkoutActions';

export enum CartActionTypes {
    addProductToCart = '[Cart] Add product',
    removeProductFromCart = '[Cart] Remove product',
    changeProductQty = '[Cart] Change quantity',
    loadCart = '[Cart] Load cart',
}

export const addProductToCart = (productId: number, sku: string, qty = 1, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    toast.success(toastProps);
    dispatch({ type: CheckoutActionTypes.resetCheckoutErrors });
    return dispatch({ type: CartActionTypes.addProductToCart, productId, sku, qty });
}

export const removeProductFromCart = (sku: string): Thunk => (dispatch, getState) => {
    dispatch({ type: CheckoutActionTypes.resetCheckoutErrors });
    return dispatch({ type: CartActionTypes.removeProductFromCart, sku });
}

export const changeProductQty = (productId: number, qty: number, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    toast.success(toastProps);
    dispatch({ type: CheckoutActionTypes.resetCheckoutErrors });
    return dispatch({ type: CartActionTypes.changeProductQty, productId, qty });
}

export const loadCart = (seneSiteUrl: string): Thunk => (dispatch, getState) => {
    const storedCarts: LocalStorageCart = JSON.parse(localStorage.getItem(cartLocalStorageKey) || '{}');
    const cart = storedCarts && storedCarts[seneSiteUrl.toLowerCase()];

    if (cart) {
        return dispatch({ type: CartActionTypes.loadCart, cart });
    }
}

export const loadProductsInCart = (): Thunk => (dispatch, getState) => {
    const state = getState();
    const productIds = selectCart(state).map(p => p.productId);

    return dispatch(getProductsByIds(productIds));
}
