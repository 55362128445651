import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { bindActionCreators } from "redux";

import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';
import withAdminPageTitle from '../withAdminPageTitle';
import { DistributorState } from '../../../reducers/distributor';
import * as distributorActions from '../../../actions/distributor';
import Spinner from '../../common/Spinner';
import SeneProfileImage from './SeneProfileImage';
import SeneProfileDetails from './SeneProfileDetails';
import { DistributorProfile } from "../../../models/distributor";
import { selectRegionsForDistributorCountry } from '../../../store/selectors/regionSelectors';
import { getRegions } from '../../../actions/regionActions';
import { CountryState } from '../../../models/region';
import { ActionToastProperties } from '../../../models/ui';

interface SeneProfilePageStateProps {
    distributor: DistributorState;
    regions: CountryState[];
}

interface SeneProfilePageState {
    distributor: DistributorState;
}

interface SeneProfilePageDispatchProps {
    updateDistributorPicture(distId: number, file: any, toastProps?: ActionToastProperties);
    updateDistributorMyProfile(distId: number, distributorProfile: DistributorProfile, toastProps?: ActionToastProperties);
    getRegions();
}

type SeneProfilePageProps = SeneProfilePageStateProps & SeneProfilePageDispatchProps & WithNamespaces;


export class SeneProfilePage extends React.Component<SeneProfilePageProps, SeneProfilePageState> {

    state: SeneProfilePageState = {
        distributor: null
    }

    componentDidMount() {
        this.props.getRegions();
        this.setState({
            distributor: this.props.distributor
        });
    }

    componentDidUpdate(prevProps: SeneProfilePageStateProps) {
        if (prevProps.distributor !== this.props.distributor && this.props.distributor) {
            this.setState({
                distributor: this.props.distributor
            });
        }
        if(this.props.distributor && !prevProps.distributor) {
            this.props.getRegions();
        }
    };

    uploadFileHandler = (fileStream: any) => {
        let data = new FormData();
        data.set('file', fileStream);
        this.props.updateDistributorPicture(this.state.distributor!.dist_ID, data,
            {
                success: this.props.t('profileUpdateSuccess'),
                error: this.props.t('profileUpdateError')
            });
    }

    render() {
        if (!this.state.distributor || !this.props.regions) {
            return <Spinner />;
        }
        else {
            const { t } = this.props;

            return <div>
                <div className='container widget-body'>
                    <div className='row panel-discription'>{t('myProfileDescription')}</div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-12'>
                            <SeneProfileImage t={t} distributor={this.state.distributor}
                                updateDistributorPicture={this.uploadFileHandler} />
                        </div>
                        <div className='col-lg-8 col-md-12'>
                            <SeneProfileDetails t={t} distributor={this.state.distributor}
                                regions={this.props.regions}
                                updateDistributorMyProfile={this.props.updateDistributorMyProfile} />
                        </div>
                    </div>
                </div>
            </div>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        distributor: selectSenesiteDistributor(state),
        regions: selectRegionsForDistributorCountry(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateDistributorPicture: distributorActions.uploadProfileImage,
        updateDistributorMyProfile: distributorActions.updateDistributorMyProfile,
        getRegions
    }
        , dispatch);
}

export default withAdminPageTitle('myprofile')(withNamespaces('DistributorDetails')(connect(mapStateToProps, mapDispatchToProps)(SeneProfilePage)))