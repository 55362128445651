import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import produce from 'immer';
import Reaptcha from 'reaptcha';

import * as distributorActions from '../../../actions/distributor';
import { DistributorState } from '../../../reducers/distributor';
import { ContactMeData } from "../../../models/contact";
import SeneTextInput from "../../common/inputs/SeneTextInput";
import SeneAreaInput from "../../common/inputs/SeneAreaInput";
import SeneButton from "../../common/inputs/SeneButton";
import SeneHomeDistributorProfile from "../home/SeneHomeDistributorProfile";
import { ContactMeValidator } from './SeneContactPageValidators'
import { selectSenesiteDistributor } from "../../../store/selectors/distributorSelectors";
import { selectRecaptchaKey, selectCaptchasDisabled } from "../../../store/selectors/configurationSelectors";
import Spinner from "../../common/Spinner";
import { ActionToastProperties } from "../../../models/ui";
import { selectSelectedLanguageAlpha2Code } from "../../../store/selectors/languageSelectors";
import {selectLoggedInUser} from "../../../store/selectors/userSelectors";
import { shouldSwithToMaintenance } from "../../../utils/helpers";

interface SeneContactPageStateProps {
    distributor: DistributorState;
    recaptchaKey: string;
    selectedLanguageAlpha2Code: String;
    captchasDisabled: boolean;
    isUserLoggedIn: boolean;
}

interface SeneSiteDispatchProps {
    postContactMessage(contactMeData: ContactMeData, toastProps?: ActionToastProperties);
}

interface SeneContactPageState {
    contact: ContactMeData;
    errors: any;
}

type SeneContactPageProps = SeneContactPageStateProps & SeneSiteDispatchProps & WithNamespaces;

export class SeneContactPage extends React.Component<SeneContactPageProps, SeneContactPageState> {

    captcha: any;

    state: SeneContactPageState = {
        contact: {} as ContactMeData,
        errors: {}
    }

    componentDidMount() {
        const locale = this.props.i18n.language;
        this.setState({
            contact: { ...this.state.contact, locale }
        })
    }
    componentDidUpdate(prevProps: SeneContactPageStateProps) {
        const locale = this.props.i18n.language;
        if (locale != this.state.contact.locale) {
            this.setState({
                contact: { ...this.state.contact, locale }
            })
        }
        if(this.props.selectedLanguageAlpha2Code != prevProps.selectedLanguageAlpha2Code) {
            this.onExpire();
        }
    }

    updateContactMe = (e: React.ChangeEvent<any>) => {
        e.preventDefault();

        const value = e.target.value;
        const name = e.target.name;

        this.setState(
            produce(draft => {
                draft.contact[name] = value;
                return draft;
            }));
    }


    submit = () => {

        const contactMeValidator = new ContactMeValidator(this.props.t, this.props.captchasDisabled);
        let validationResult = contactMeValidator.validate(this.state.contact);
        this.setState({
            errors: { ...validationResult.errors }
        });

        if (validationResult.isValid) {
            this.props.postContactMessage(this.state.contact, { success: this.props.t('messageSentSuccessfully'), error: this.props.t('messageSentError') })
                .then(_ => {
                    this.setState({
                        contact: { locale: this.state.contact.locale } as ContactMeData,
                    });
                    this.captcha && this.captcha.reset();
                }).catch((exp) =>
                {
                    shouldSwithToMaintenance(exp);
                });
        }
    }

    getAddress = () => {
        if (this.props.distributor) {
            const distributor = this.props.distributor;

            if (!distributor.profile.addresses) {
                return <span/>
            }

            let address = distributor.profile.addresses.find(s => (s.isSeneSiteAddress));

            if (address && distributor.seneSiteInfo.showAddress) {
                return <div>
                    <i className="row fa fa-map-marker" style={{ fontSize: "28px" }} />
                    <div className="row justify-content-center"> {address.address1} {address.address2} </div>
                    { distributor.countryName === "MX" && address.address3 !== "" &&
                        <div className="row justify-content-center"> {address.address3} </div>
                    }
                    <div className="row justify-content-center"> {address.city}, {address.stateName} {address.zip}</div>
                </div>;
            } else {
                return <span/>;
            }
        } else {
            return <i className="fa fa-spinner spin" />;
        }
    }

    getPhone = () => {

        if (this.props.distributor) {
            const distributor = this.props.distributor;

            if (distributor.seneSiteInfo.showPhone) {
                return <div className="mb-3">
                    <i className="fa fa-phone row" style={{ fontSize: "28px" }} />
                    <div className="row justify-content-center">
                        {distributor.profile.contact.phoneNumber1}
                    </div>
                </div>;
            } else {
                return <span/>;
            }
        } else {
            return <i className="fa fa-spinner spin" />;
        }

    }

    onVerify = (recaptchaToken: string) => {
        this.setState({
            contact: {
                ...this.state.contact,
                recaptchaToken: recaptchaToken
            }
        });
    }

    onExpire = () => {
        this.setState({
            contact: {
                ...this.state.contact,
                recaptchaToken: ''
            }
        });
    }

    renderForm = () => {
        const { t } = this.props;
        const contactMe = this.state.contact;
        return (<div className="mb-1">
            <SeneTextInput
                label={t('yourName')}
                name="name"
                onChange={this.updateContactMe}
                value={contactMe.name}
                error={this.state.errors['name']}
            />
            <SeneTextInput
                type='email'
                label={t('yourEmail')}
                name="sender"
                onChange={this.updateContactMe}
                value={contactMe.sender}
                error={this.state.errors['sender']}
            />
            <SeneTextInput
                label={t('yourPhone')}
                name="phone"
                onChange={this.updateContactMe}
                value={contactMe.phone}
                error={this.state.errors['phone']}
            />
            <SeneAreaInput
                rows={10}
                label={t('yourMessage')}
                name="message"
                onChange={this.updateContactMe}
                value={contactMe.message}
                error={this.state.errors['message']}
            />

            <div key={this.props.selectedLanguageAlpha2Code + "_captcha"}>
                {!this.props.captchasDisabled && <Reaptcha
                    hl={this.props.selectedLanguageAlpha2Code}
                    ref={e => (this.captcha = e)}
                    sitekey={this.props.recaptchaKey}
                    onVerify={this.onVerify}
                    onExpire={this.onExpire}
                    />}
            </div>

            {this.state.errors['recaptchaToken'] && <div className="alert alert-danger">{this.state.errors['recaptchaToken']}</div>}
            <SeneButton
                className="btn btn-secondary mt-3"
                onClick={this.submit}
                icon="fa fa-envelope"
                id="contact_form_submit"
            >
                {t('sendMessage')}
            </SeneButton>
        </div>);
    }

    render() {
        if (!this.props.distributor || !this.props.recaptchaKey) {
            return <Spinner />;
        }
        const { t } = this.props;

        return <div id="contact_me">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-4 mt-4">
                        <SeneHomeDistributorProfile {...{ hideContactMe: true, t: t, distributor: this.props.distributor }} />
                        <div className="mt-3 pt-3 text-center container justify-content-center">
                            {this.getPhone()}
                            {this.getAddress()}
                        </div>
                    </div>
                    <div className="col-md-5 col-10">
                        <h3 className="text-dark mt-4">{t('contactForm')}</h3>
                        {this.renderForm()}
                    </div>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    distributor: selectSenesiteDistributor(state),
    recaptchaKey: selectRecaptchaKey(state),
    selectedLanguageAlpha2Code: selectSelectedLanguageAlpha2Code(state),
    captchasDisabled: selectCaptchasDisabled(state),
    isUserLoggedIn: !!selectLoggedInUser(state),
});

const mapDispatchToProps = (dispatch) : SeneSiteDispatchProps => (
    bindActionCreators({
        postContactMessage: distributorActions.postContactMessage,
    }, dispatch)
);

export default withNamespaces(["DistributorInfo", "ContactForm"])(connect(mapStateToProps, mapDispatchToProps)(SeneContactPage));
