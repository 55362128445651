import React from 'react';

import { CategoryMenuProps } from './CategoryMenu';
import SeneSiteLink from '../../../common/SeneSiteLink';
import { isSubCategory } from '../../../../utils/types';
import SearchProducts from '../../../common/SearchProducts';
import Collapse from 'reactstrap/lib/Collapse';
import { getCategoryName, getSubCategoryName } from '../../../../utils/helpers';

interface CategoryMenuMobileState {
    isSubcategoriesOpen: boolean;
}

class CategoryMenuMobile extends React.PureComponent<CategoryMenuProps, CategoryMenuMobileState> {
    state: CategoryMenuMobileState = {
        isSubcategoriesOpen: false
    }

    getBackNavigationInfo(): { url: string, categoryName: string } {
        let categoryName: string;
        let url: string;

        if (isSubCategory(this.props.currentCategory)) {
            const category = this.props.categories.find(cat => cat.categoryId === this.props.currentCategory!.categoryId)!;
            categoryName = getCategoryName(category, this.props.locale);
            url = `/shopproducts/${category.categoryId}/${encodeURIComponent(categoryName.toLowerCase())}`;
        } else {
            categoryName = 'Shop Now';
            url = '/shopproducts';
        }

        return { url, categoryName };
    }

    toggleCollapse = () => {
        this.setState({isSubcategoriesOpen: !this.state.isSubcategoriesOpen});
    }

    collapseSubcategories = () => {
        this.setState({isSubcategoriesOpen: false});
    }

    render() {
        return <div className="row">
            <div className="col-12">
                <SearchProducts placeHolder={'Search...'} size="large"/>
                <hr />
            </div>
            {
                this.props.currentCategory
                && (() => {
                    const backNavigationInfo = this.getBackNavigationInfo();
                    const currentCategory = isSubCategory(this.props.currentCategory)
                        ? this.props.categories.find(cat => cat.categoryId === this.props.currentCategory!.categoryId)
                        : this.props.currentCategory;

                    return (
                        <div className="col-12" style={{ marginBottom: '10px'}}>
                            <SeneSiteLink to={backNavigationInfo.url}>
                                <h4 className="back-navigation-link">
                                    {`< ${this.props.t('backTo', {name: backNavigationInfo.categoryName})}`}
                                </h4>
                            </SeneSiteLink>
                            {
                                currentCategory &&
                                <>
                                    <button
                                        className="form-control form-control-lg dropdown-button"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="subcategoryList"
                                        onClick={this.toggleCollapse}
                                    >
                                        {getCategoryName(currentCategory, this.props.locale)} Categories
                                </button>
                                    <Collapse isOpen={this.state.isSubcategoriesOpen}>
                                        <ul className="list-group">
                                            {
                                                currentCategory && currentCategory.subCategories &&
                                                currentCategory.subCategories.map(subCat => {
                                                    const subCategoryName = getSubCategoryName(subCat, this.props.locale);
                                                    return (
                                                        <SeneSiteLink
                                                            key={subCat.id}
                                                            to={`/shopproducts/${currentCategory.categoryId}/${subCat.id}/${encodeURIComponent(subCategoryName).toLowerCase()}`}
                                                        >
                                                            <li onClick={this.collapseSubcategories} className="list-group-item">{subCategoryName}</li>
                                                        </SeneSiteLink>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </Collapse>
                                </>
                            }
                        </div>
                    );
                })()
            }
        </div>


    }
}

export default CategoryMenuMobile;
