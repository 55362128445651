import { Category, SubCategory } from "../models/category";
import { Product } from "../models/product";
import i18next from "i18next";

/**
 * Type for removing readonly modifier from properties
 */
export type Writeable<T> = { -readonly [P in keyof T]-?: T[P] };

/**
 * Type for excluding propety K of a type T
 */
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/**
 * If sessionStorage is avaiable, save it into a local refrence, else it will be undefined
 */
export const storage = (function() {
	const uid = new Date().toString();
	let result;
	try {
		sessionStorage.setItem('uid', uid);
		result = sessionStorage.getItem('uid') == uid;
		sessionStorage.removeItem(uid);
		return result && sessionStorage;
	} catch (exception) {}
}());

/**
 * Returns the first country code in the browsers language list
 */
export const getLang = () => (navigator.languages && navigator.languages.length) ? navigator.languages[0] : (navigator.language || 'en');

export const getBrowserCountry = () => {
	const defaultCountry = 'us';

	if (!navigator.languages || ! navigator.languages.length) {
		return defaultCountry;
	}

	const firstCountry = navigator.languages.find(lang => lang.split('-').length > 1);
	if(firstCountry) {
		return firstCountry.split('-')[1];
	}

	return defaultCountry;
}

export const scrollToElement = (elementId: string) => {
    const header = document.getElementById(elementId);
    if (header && header.scrollIntoView) {
        header.scrollIntoView({behavior: 'smooth'});;
    }
}


export const toCompactString = (str: string) => str.toLocaleLowerCase().replace(/ /g, '');

export const filterMatched = (str: string, filter: string):boolean => toCompactString(str).indexOf(toCompactString(filter)) > -1;

export const IsYouTubeVideoURL = (url): boolean => {
	if(url === "" || !url) return false;

	const regExp = /^.*((youtube|youtu\.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
	const match = url.match(regExp);
	let videoId = (match && match[7].length == 11) ? match[7] : '';

    if (!videoId) {
		return false;
    }
    return true;
}

/**
 * Copies the given text to the clipboard
 */
export const copyToClipboard = (str: string) => {
	const el = document.createElement('textarea');
	el.value = str;
	el.setAttribute('readonly', '');
	el.style.position = 'absolute';
	el.style.left = '-9999px';
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
  };

  export const twitterScreenName = (str: string): string => {

	if (!str) return '';
	str = str + ''

	var screenNameRegex = /^@?(\w){1,15}$/

	if (screenNameRegex.test(str)) {
		return str[0] === '@' ? str.slice(1) : str
	}

	var urlRegex = /^https?:\/\/(www\.)?twitter\.com\/(\w{1,15})(\/.*)?$/i
	var matches = urlRegex.exec(str)

	return matches ? matches[2] : ''
}

export const getYouTubeVideoId = (url): string => {
	const regExp = /^.*((youtube|youtu\.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
	const match = url.match(regExp);
	let videoId = (match && match[7].length == 11) ? match[7] : '';

    if (!videoId) {
        if (url.split('/').length === 1 && url.indexOf('youtube') === -1) { // check if the url contains just videoId
            videoId= url;
        }
    }
    return videoId;

}

export const getCategoryName = (category: Category, locale: string) => {
	const localizedCategoryName = category.translations && category.translations[locale];
	return (localizedCategoryName ? localizedCategoryName : category.name);
}

export const getSubCategoryName = (subCategory: SubCategory, locale: string) => {
	const localizedSubCategoryName = subCategory.translations && subCategory.translations[locale];
	return (localizedSubCategoryName ? localizedSubCategoryName : subCategory.subCategoryName);
}

export const getProductName = (product: Product, locale: string) => {
	const localizedProductName = product.productDetails && product.productDetails.productAttributes && product.productDetails.productAttributes[locale] && product.productDetails.productAttributes[locale].name;
	return (localizedProductName ? localizedProductName : product.name);
}

export const createGuid = () => {
	const s4 = () => {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

// copied from https://stackoverflow.com/questions/5968196/check-cookie-if-cookie-exists
export const getCookie = (name) => {
	let end;
	const dc = document.cookie;
	const prefix = name + "=";
	let begin = dc.indexOf("; " + prefix);
	if (begin == -1) {
		begin = dc.indexOf(prefix);
		if (begin != 0) return null;
	}
	else
	{
		begin += 2;
		end = document.cookie.indexOf(";", begin);
		if (end == -1) {
			end = dc.length;
		}
	}
	// because unescape has been deprecated, replaced with decodeURI
	//return unescape(dc.substring(begin + prefix.length, end));
	return decodeURI(dc.substring(begin + prefix.length, end));
}


export const shouldSwithToMaintenance = (exp) => {
	if (exp.response && exp.response.headers['maintenacemode'] == 'true') {
		window.location.href = window.location.href;
	}
}

export class TranslationRemapper {
    dictionary: Object;
    translator: i18next.TranslationFunction;
    constructor(remappedKeysDictionary: Object, translator: i18next.TranslationFunction) {
        this.dictionary = remappedKeysDictionary;
        this.translator = translator;
    }

    translationKeyMapping(countryName: string, translationKey: string) {
        const dic = this.dictionary;
        return dic[countryName] && dic[countryName][translationKey] ? dic[countryName][translationKey] : translationKey;
    }

    getTranslationFor(countryName: string, key: string): string {
        return this.translator(this.translationKeyMapping(countryName, key))
    }

    getTranslatorFor(countryName: string): (key: string) => string {
        return (key) => this.getTranslationFor(countryName, key);
    }

}