import React, { memo } from 'react';
import { LinkProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { selectSeneSiteUrl } from '../../store/selectors/distributorSelectors';

interface SeneSiteLinkStateProps {
    seneSiteUrl: string | null;
}
interface SeneSiteLinkDispatchProps {
    dispatch: Dispatch<any>;
}
type SeneSiteLinkProps = SeneSiteLinkStateProps & SeneSiteLinkDispatchProps & LinkProps;

/**
 * Prepends the current distributors seneSiteUrl to the link
 */
const SeneSiteLink = memo(({children, to, seneSiteUrl, dispatch, ...props}: SeneSiteLinkProps) => {
    let href = to.toString();
    if (!href.startsWith('/')) {
        href = `/${href}`;
    }

    if (seneSiteUrl) {
        href = `/${seneSiteUrl}${href}`;
    }

    return <Link to={href} {...props}>{children}</Link>
})

const mapStateToProps = state => ({
    seneSiteUrl: selectSeneSiteUrl(state)
})

// It might not be the most performant solution to connect each link to redux store, but it's much easier to use that way.
export default connect(mapStateToProps)(SeneSiteLink);
