import React from 'react';

import { Distributor } from '../../../models/distributor';
import { SeneHomeMediaButton } from './SeneHomeMediaButton';

interface ISocialMedia {
    link: string;
    icon: string;
}

export interface SeneSocialMediaGroupStateProps {
    distributor: Distributor;
}

export interface SeneSocialMediaGroupGroupState {
    socialMedias: ISocialMedia[];
}

export class SeneSocialMediaGroup extends React.PureComponent<SeneSocialMediaGroupStateProps, SeneSocialMediaGroupGroupState> {

    state: SeneSocialMediaGroupGroupState = {
        socialMedias: []
    };

    componentDidMount() {
        const socialMedias = this.getSocialMedias(this.props);
        this.setState({ socialMedias });
    }

    private getSocialMedias = (props: SeneSocialMediaGroupStateProps): ISocialMedia[] => {
        if (!props.distributor || !props.distributor.seneSiteInfo || !props.distributor.seneSiteInfo.socialInfo) return [];
        const socialMedia = props.distributor.seneSiteInfo.socialInfo, socialMedias: ISocialMedia[] = [];
        if (socialMedia.facebookAccount) {
            if (socialMedia.facebookAccount.indexOf("facebook") !== -1) {
                socialMedias.push({ link: socialMedia.facebookAccount, icon: 'fa-facebook' });
            }
            else {
                socialMedias.push({
                    link: 'https://www.facebook.com/'+ socialMedia.facebookAccount, icon: 'fa-facebook' });
            }
        }
        if (socialMedia.instagramAccount) socialMedias.push({ link: socialMedia.instagramAccount, icon: 'fa-instagram' });
        if (socialMedia.youTubeAccount) socialMedias.push({ link: socialMedia.youTubeAccount, icon: 'fa-youtube' });
        if (socialMedia.twitterAccount) socialMedias.push({ link: socialMedia.twitterAccount, icon: 'fa-twitter' });
        return socialMedias;
    }

    render() {
        return <div>
                   {this.state.socialMedias.map((socialMedia: ISocialMedia, i: number) => {
                           return <SeneHomeMediaButton key={i} link={socialMedia.link} icon={socialMedia.icon}/>;
                       })
                   }
        </div>;
    }
}
