import React from 'react';
import { connect } from 'react-redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import Spinner from './../../common/Spinner';
import { DistributorState } from './../../../reducers/distributor';
import { selectSenesiteDistributor, selectDistributorEvents } from './../../../store/selectors/distributorSelectors';
import Calendar from './../../common/calendar/Calendar';
import { CalendarEvent } from './../../../models/distributor';


interface EventCalendarPageStateProps {
    distributor: DistributorState;
    events: CalendarEvent[];
}

type EventCalendarPageProps = EventCalendarPageStateProps & WithNamespaces;

class EventCalendarPage extends React.Component<EventCalendarPageProps> {

    private readonly isAdmin = false;

    render() {
        const { distributor, events, t } = this.props;
        const selectedDate = this.props && this.props['match'] && this.props['match'].params && this.props['match'].params.selectedDate;
        const viewMode = this.props && this.props['match'] && this.props['match'].params && this.props['match'].params.viewMode;
        if (!distributor) {
            return <Spinner />;
        } else {
            return (
                <div className="eventCalendar row">
                    <div className="col-12">
                        <Calendar t={t} culture={this.props.i18n.language} isAdmin={this.isAdmin} distributor={distributor} events={events} selectedDate={selectedDate} viewMode={viewMode} />
                    </div>
                </div >
            )
        }
    }

}

const mapStateToProps = state => ({
    distributor: selectSenesiteDistributor(state),
    events: selectDistributorEvents(state)
});

export default (withNamespaces('Calendar')(connect(mapStateToProps)(EventCalendarPage)));
