import produce from "immer";
import { IpAddressActionTypes } from "../actions/ipAddressActions";

export const reducer = (state = '', action) => produce(state, draft => {
    switch (action.type) {
        case IpAddressActionTypes.getIpAddressSuccess:
            return action.data;
        case IpAddressActionTypes.getIpAddress:
        case IpAddressActionTypes.getIpAddressError:
            return draft;
        default:
            return draft;
    }
})