import i18next from 'i18next';

import { HandlingInfo, ShippingInfo } from './../../../models/distributor';
import { Validator } from './../../../utils/validation/validator';
import { SeneSiteApplicationFormModel } from './SeneSiteApplicationForm'

export class SeneSiteApplicationFormValidator extends Validator<SeneSiteApplicationFormModel> {
    constructor(t: i18next.TranslationFunction) {
        super();

        this.ruleFor("agree").isTrue(t("checkBoxAgreeTermsAndConditionsError"));
        this.ruleFor("isValidSeneSiteName").isTrue(t("seneSiteNameInvalidMsg"));
    }
}