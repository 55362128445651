import i18next from 'i18next';
import { CreditCardFormModel } from './CreditCardForm';
import { Validator } from './../../../../utils/validation/validator';
import { CreditCard } from '../../../../models/payment';
import { SquarePaymentFormModel } from './SquareForm';

export class CreditCardValidator extends Validator<CreditCardFormModel> {
    constructor(t: i18next.TranslationFunction, countryAlpha2: string, distributorAllowedCreditCards: CreditCard[], ccNumber: string) {
        super();

        this.ruleFor("firstName").notEmpty(t('msgFirstName'));
        this.ruleFor("lastName").notEmpty(t('msgLastName'));
        this.ruleFor('ccNumber').notEmpty(t('msgCCNumber')).creditCard(distributorAllowedCreditCards, t('msgInvalidCC'));
        this.ruleFor('cvvNumber').notEmpty(t('msgCvvNumber')).cvv(distributorAllowedCreditCards, ccNumber, t('msgInvalidCvv'));
        this.ruleFor('ccExpirationDate').notEmpty(t('msgExpiration')).expirationDate(t('msgInvalidExpiration'));
        this.ruleFor('address').notEmpty(t('msgAddress'));
        this.ruleFor('city').notEmpty(t('msgCity'));
        if (countryAlpha2 !== 'NZ') {
            this.ruleFor('state').notEmpty(t('msgState'));
        }
        this.ruleFor('zipCode').notEmpty(t('msgPostal'));
        this.ruleFor('ccType').notEmpty(t('msgCCType'));
        this.ruleFor('agree').isTrue(t('msgAgree'));
    }
}

export class SquarePaymentValidator extends Validator<SquarePaymentFormModel> {
    constructor(t: i18next.TranslationFunction) {
        super();

        this.ruleFor("sourceId").notEmpty(t('sourceId not set'));
    }
}
