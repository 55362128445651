import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import AdminBreadcrumbs, { BreadcrumbModel } from './AdminBreadcrumbs'
import * as adminActions from '../../actions/administration/adminActions';


interface withAdminPageTitleDispatchProps {
    setTitle(title: string);
}
type withAdminPageTitleProps = withAdminPageTitleDispatchProps & WithNamespaces;

export default function withAdminPageTitle(title: string, additionalBreadcrumbs?: BreadcrumbModel[]) {
    return function<OwnProps> (Component: React.ComponentType<OwnProps>) {
        class WrappedComponent extends React.Component<OwnProps & withAdminPageTitleProps> {
            static displayName = `withAdminPageTitle(${WrappedComponent.name})`;
            static readonly WrappedComponent = WrappedComponent;

            componentDidMount() {
                this.props.setTitle(title);
            }

            render() {
                const { t } = this.props;
                return (
                    <>
                        <AdminBreadcrumbs t={t} currentPage={title} additionalBreadcrumbs={additionalBreadcrumbs}/>
                        <div className="container">
                            <h2 style={{color: '#4675ab'}} className="d-none d-sm-block">{t(title)}</h2>
                            <Component {...this.props}/>
                        </div>
                    </>
                )
            }
        }

        const mapDispatchToProps = dispatch => bindActionCreators({
            setTitle: adminActions.setAdminPageTitle,
        }, dispatch)

        // what the hell typescript ??!!
        return withNamespaces('AdminHeader')(connect(null, mapDispatchToProps)(WrappedComponent as any) as any);
    }
}
