import React from 'react';
import { Tooltip } from 'reactstrap';

import SeneButton from '../inputs/SeneButton';
import { copyToClipboard } from '../../../utils/helpers';
import i18n from '../../../i18n';


interface ShareButtonProps {
    id: string;
    className?: string;
    t: i18n.TranslationFunction
}

interface ShareButtonState {
    showTooltip: boolean;
}

class ShareButton extends React.PureComponent<ShareButtonProps, ShareButtonState> {
    state: ShareButtonState = {
        showTooltip: false
    }

    copyToClipboard = () => {
        copyToClipboard(window.location.href);
        this.setState({ showTooltip: true });
        setTimeout(() => {
            this.setState({ showTooltip: false });
        }, 1000);
    }

    
    render() {
        const {t} = this.props;
        return (
            <>
                <SeneButton
                    icon="fa-share"
                    onClick={this.copyToClipboard}
                    buttonClass="primary"
                    className={this.props.className}
                    id={this.props.id}
                >
                    {t('share')}
                </SeneButton>
                <Tooltip placement="bottom" isOpen={this.state.showTooltip} target={this.props.id}>
                    {t('urlCopied')}
                </Tooltip>
            </>
        )
    }
}

export default ShareButton;
