import React from 'react';

export interface SeneButtonProps {
    loading?: boolean;
    disabled?: boolean;
    onClick();
    icon?: string;
    children: string;
    buttonClass?: 'primary' | 'secondary' | 'info';
    className?: string;
    id?: string;
}

const SeneButton = ({loading, disabled, onClick, icon, children, buttonClass = 'primary', className = '', id}: SeneButtonProps) => {
    const iconToDisplay = loading ? 'fa-spinner fa-spin' : icon;

    return (
            <button
                className={`btn btn-${buttonClass} ${className}`}
                disabled={disabled || loading}
                onClick={onClick}
                id={id}
            >
                {iconToDisplay && <i className={`fas ${iconToDisplay} mr-2`}></i> }
                {children}
            </button>
    );
};

export default SeneButton;
