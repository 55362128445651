import React from 'react';
import { connect } from 'react-redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { bindActionCreators } from 'redux';

import { UserInfo, UserInfoShippingAddress } from '../../../models/userInfo';
import { State } from '../../../store';
import { selectLoggedInUser } from '../../../store/selectors/userSelectors';
import TextInput from '../../common/inputs/SeneTextInput';
import SelectInput from '../../common/inputs/SeneSelectInput';
import { ValidationErrors } from '../../../utils/validation/validator';
import { selectRegionsForCustomerCountry } from '../../../store/selectors/regionSelectors';
import { CountryState } from '../../../models/region';
import { getRegions } from '../../../actions/regionActions';
import SeneButton from '../../common/inputs/SeneButton';
import { selectLoading } from '../../../store/selectors/uiSelectors';
import { CustomerShippingAddressValidator } from './CustomerProfileValidators';
import { updateLoggedInCustomer } from '../../../actions/customerActions';
import { ActionToastProperties } from '../../../models/ui';
import { selectDistributorCountry } from '../../../store/selectors/distributorSelectors';
import { TranslationRemapper } from "../../../../src/utils/helpers";

interface CustomerShippingAddressStateProps {
    customerInfo: UserInfo | null;
    regions: CountryState[];
    distributorCountry: string;
    isLoading: boolean;
}

interface CustomerShippingAddressDispatchProps {
    getRegions(countryCode?: string);
    updateCustomer({shippingAddress}: {shippingAddress: UserInfoShippingAddress}, toastProps?: ActionToastProperties);
}

type CustomerShippingAddressProps = CustomerShippingAddressStateProps & CustomerShippingAddressDispatchProps & WithNamespaces;

type CustomerShippingAddressState = {
    shippingAddressModel: UserInfoShippingAddress;
    errors: ValidationErrors<UserInfoShippingAddress>;
}

class CustomerShippingAddress extends React.PureComponent<CustomerShippingAddressProps, CustomerShippingAddressState> {
    state: CustomerShippingAddressState = {
        shippingAddressModel: {
            address1: '',
            address2: '',
            address3: '',
            city: '',
            state: '',
            zipCode: '',
            name: '',
        },
        errors: {}
    }

    get regions() {
        return this.props.regions.map(country => ({ value: country.abbreviation, text: country.name }));
    }

    setShippingAddressState = (shippingAddress: UserInfoShippingAddress) => {
        const isAvailableRegion = this.regions.some(t => t.value === shippingAddress.state);
        
        this.setState({ 
            shippingAddressModel: {
                ...shippingAddress,
                state: isAvailableRegion ? shippingAddress.state : ''
            } 
            });
    }

    componentDidMount() {
        if (this.props.customerInfo) {
            const { shippingAddress } = this.props.customerInfo;
            this.props.getRegions(this.props.customerInfo.country).then(() => {
                this.setShippingAddressState(shippingAddress);
            });
        } else {
            this.props.getRegions();
        }
    }

    componentDidUpdate(prevProps: CustomerShippingAddressProps) {
        if (this.props.customerInfo && prevProps.customerInfo !== this.props.customerInfo) {
            const { shippingAddress } = this.props.customerInfo;
            if(!prevProps.customerInfo || this.props.customerInfo.country != prevProps.customerInfo.country) {
                this.props.getRegions(this.props.customerInfo.country).then(() => {
                    this.setShippingAddressState(shippingAddress);
                });
            } else {
                this.setShippingAddressState(shippingAddress);
            }
        }
    }

    updateCustomer = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const prop = e.target.name;
        const value = e.target.value;

        this.setState({
            shippingAddressModel: {
                ...this.state.shippingAddressModel,
                [prop]: value
            }
        });
    }

    saveCustomer = () => {
        const countryCode = this.props.customerInfo ? this.props.customerInfo.country : '';
        const validator = new CustomerShippingAddressValidator(this.props.t, countryCode);
        const validationResult = validator.validate(this.state.shippingAddressModel);

        if (validationResult.isValid) {
            this.props.updateCustomer({shippingAddress: this.state.shippingAddressModel}, { success: this.props.t('updateCustomerSuccess'), error: this.props.t('updateCustomerError') });
        } else {
            this.setState({ errors: validationResult.errors });
        }
    }

    isDisplayMXOptions = () => {
        return this.props.customerInfo && this.props.customerInfo.country !== '' ? 
            this.props.customerInfo.country === "MX" : 
            this.props.distributorCountry === "MX";
    }

    isDisplayHKOptions = () => {
        return this.props.customerInfo && this.props.customerInfo.country !== '' ?
            this.props.customerInfo.country === "HK" :
            this.props.distributorCountry === "HK";
    }

    getCountry = () => {
        return this.props.customerInfo && this.props.customerInfo.country !== '' ? 
            this.props.customerInfo.country : 
            this.props.distributorCountry;
    }

    getTranslator() {
        const dic = {
            'NZ': {
                'zipCode': 'postalCode'
            },
            'HK': {
                'address1': 'address1Hk',
                'address2': 'address2Hk',
                'address3': 'address3Hk',
                'city': 'cityHk',
                'stateName': 'stateNameHk'
            }
        };
        return new TranslationRemapper(dic, this.props.t).getTranslatorFor(this.props.distributorCountry);
    }

    render() {
        const t = this.getTranslator();

        return this.props.customerInfo && (
            <>
                <h2 className="customer-profile__title">{t('title')}</h2>
                <div className="row pt-3">
                    <div className="col-sm-4 col-md-5">
                        <TextInput {...{
                            name: 'name',
                            label: `${t('name')} *`,
                            id: 'name',
                            onChange: this.updateCustomer,
                            value: this.state.shippingAddressModel.name,
                            error: this.state.errors.name,
                            autocomplete: 'firstName'
                        }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-md-5">
                        <TextInput {...{
                            name: 'address1',
                            label: `${t('address1')} *`,
                            id: 'address1',
                            onChange: this.updateCustomer,
                            value: this.state.shippingAddressModel.address1,
                            error: this.state.errors.address1,
                            autocomplete: 'address-line1'
                        }} />
                    </div>
                    <div className="col-sm-4 col-md-5">
                        <TextInput {...{
                            name: 'address2',
                            label: t('address2'),
                            id: 'address2',
                            onChange: this.updateCustomer,
                            value: this.state.shippingAddressModel.address2,
                            autocomplete: 'address-line2'
                        }} />
                    </div>
                    { (this.isDisplayMXOptions() || this.isDisplayHKOptions()) &&
                        <div className="col-sm-4 col-md-5">
                            <TextInput {...{
                                name: 'address3',
                                label: t('address3'),
                                id: 'address3',
                                onChange: this.updateCustomer,
                                value: this.state.shippingAddressModel.address3,
                                autocomplete: 'address-line3'
                            }} />
                        </div>
                    }
                </div>
                <div className="row">
                    <div className="col-sm-4 col-md-5">
                        <TextInput {...{
                            name: 'city',
                            label: `${t('city')} *`,
                            id: 'city',
                            onChange: this.updateCustomer,
                            value: this.state.shippingAddressModel.city,
                            error: this.state.errors.city,
                            autocomplete: 'city'
                        }} />
                    </div>
                    <div className="col-sm-4 col-md-5">
                    {this.props.customerInfo.country.toUpperCase() !== "NZ" ?
                        
                            <SelectInput {...{
                                name: 'state',
                                label: `${t('stateName')} *`,
                                id: 'state',
                                onChange: this.updateCustomer,
                                options: this.regions,
                                value: this.state.shippingAddressModel.state,
                                error: this.state.errors.state,
                                autocomplete: 'address-level1'
                            }} />
                            :
                            <TextInput {...{
                                name: 'address3',
                                label: t('suburb'),
                                id: 'address3',
                                onChange: this.updateCustomer,
                                value: this.state.shippingAddressModel.address3,
                                error: this.state.errors.address3,
                                autocomplete: 'address-line3'
                            }} />
                    }
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-md-5">
                        {this.props.customerInfo.country.toUpperCase() !== "HK" &&
                            <TextInput {...{
                                name: 'zipCode',
                                label: `${t('zipCode')} *`,
                                id: 'zipCode',
                                onChange: this.updateCustomer,
                                value: this.state.shippingAddressModel.zipCode,
                                error: this.state.errors.zipCode,
                                autocomplete: 'postal-code'
                            }} />
                        }
                    </div>
                    <div className="col-sm-4 col-md-5">
                        <label className="font-weight-bold" >{t('country')}</label>
                        <div>{this.getCountry()}</div>
                    </div>
                </div>
                <div className="row">
                    <SeneButton
                        className="col-md-2 offset-md-10"
                        onClick={this.saveCustomer}
                        icon="fa-save"
                        loading={this.props.isLoading}
                    >
                        {t('save')}
                    </SeneButton>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: State): CustomerShippingAddressStateProps => ({
    customerInfo: selectLoggedInUser(state),
    regions: selectRegionsForCustomerCountry(state),
    distributorCountry: selectDistributorCountry(state),
    isLoading: selectLoading(state)
});

const mapDistpatchToProps = dispatch => bindActionCreators({
    getRegions,
    updateCustomer: updateLoggedInCustomer
}, dispatch);

export default withNamespaces('ShippingAddress')(connect(mapStateToProps, mapDistpatchToProps)(CustomerShippingAddress));
