import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import SeneTextInput from '../../common/inputs/SeneTextInput';
import { UserInfo } from '../../../models/userInfo';
import { State } from '../../../store';
import { selectLoggedInUser } from '../../../store/selectors/userSelectors';
import { ValidationErrors } from '../../../utils/validation/validator';
import SeneButton from '../../common/inputs/SeneButton';
import { selectLoading } from '../../../store/selectors/uiSelectors';
import { bindActionCreators } from 'redux';
import { updateLoggedInCustomer } from '../../../actions/customerActions';
import { CustomerProfileValidator } from './CustomerProfileValidators';
import { ActionToastProperties } from '../../../models/ui';
import CustomInput from 'reactstrap/lib/CustomInput';

interface CustomerProfileStateProps {
    customerInfo: UserInfo | null;
    isLoading: boolean;
}

interface CustomerProfileDispatchProps {
    updateCustomer(info: CustomerProfileModel, toastProps?: ActionToastProperties);
}
type CustomerProfileProps = CustomerProfileStateProps & CustomerProfileDispatchProps & WithNamespaces;

export interface CustomerProfileModel {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    optInEmail: boolean;
}

type CustomerProfileState = {
    customerModel: CustomerProfileModel;
    errors: ValidationErrors<CustomerProfileModel>
}

class CustomerProfile extends React.PureComponent<CustomerProfileProps, CustomerProfileState> {
    state: CustomerProfileState = {
        customerModel: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            optInEmail: false
        },
        errors: {}
    }

    componentDidMount() {
        if (this.props.customerInfo) {
            const { billingAddress, shippingAddress, ...model } = this.props.customerInfo;
            this.setState({ customerModel: model });
        }
    }

    componentDidUpdate(prevProps: CustomerProfileProps) {
        if (this.props.customerInfo && prevProps.customerInfo !== this.props.customerInfo) {
            const { billingAddress, shippingAddress, ...model } = this.props.customerInfo;
            this.setState({ customerModel: model });
        }
    }

    updateCustomer = (e: React.ChangeEvent<HTMLInputElement>) => {
        const prop = e.target.name;
        let value: any;

        if (e.target && e.target.type == "checkbox") {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }

        this.setState({
            customerModel:{
                ...this.state.customerModel,
                [prop]: value
            }
        });
    }

    saveCustomer = () => {
        const validator = new CustomerProfileValidator(this.props.t);
        const validationResult = validator.validate(this.state.customerModel);

        if (validationResult.isValid) {
            this.props.updateCustomer(this.state.customerModel, { success: this.props.t('updateCustomerSuccess'), error: this.props.t('updateCustomerSuccess')});
        } else {
            this.setState({errors: validationResult.errors});
        }
    }

    render() {
        const { t } = this.props;
        const { customerModel, errors } = this.state;

        return this.props.customerInfo && (
            <>
                <h2 className="customer-profile__title">{t('header')}</h2>
                <div className="row pt-3">
                    <div className="col-sm-4 col-md-5">
                        <SeneTextInput {...{
                            name: 'firstName',
                            label: `${t('firstName')} *`,
                            id: 'firstName',
                            onChange: this.updateCustomer,
                            value: customerModel.firstName,
                            error: errors.firstName,
                            autocomplete: 'firstName'
                        }} />
                    </div>
                    <div className="col-sm-4 col-md-5">
                        <SeneTextInput {...{
                            name: 'lastName',
                            label: `${t('lastName')} *`,
                            id: 'lastName',
                            onChange: this.updateCustomer,
                            value: customerModel.lastName,
                            error: errors.lastName,
                            autocomplete: 'lastName'
                        }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-md-5">
                        <SeneTextInput {...{
                            name: 'phone',
                            label: `${t('phoneNumber1')}`,
                            id: 'phone',
                            onChange: this.updateCustomer,
                            value: customerModel.phone,
                            error: errors.phone,
                            autocomplete: 'phone'
                        }} />
                    </div>
                    <div className="col-sm-4 col-md-5">
                        <label className="font-weight-bold">{t('email')}</label>
                        <div>{customerModel.email}</div>
                    </div>
                </div>
                <CustomInput
                    name="optInEmail"
                    id="optInEmail-id"
                    type="checkbox"
                    checked={customerModel.optInEmail}
                    onChange={this.updateCustomer}
                    label={t('receivedEmails')}
                />
                <div className="row">
                    <SeneButton
                        className="col-md-2 offset-md-10"
                        onClick={this.saveCustomer}
                        icon="fa-save"
                        loading={this.props.isLoading}
                    >
                        {t('save')}
                    </SeneButton>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: State): CustomerProfileStateProps => ({
    customerInfo: selectLoggedInUser(state),
    isLoading: selectLoading(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    updateCustomer: updateLoggedInCustomer
}, dispatch);

export default withNamespaces('CustomerProfile')(connect(mapStateToProps, mapDispatchToProps)(CustomerProfile));
