import React from 'react';
import { connect } from 'react-redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import withAdminPageTitle from '../withAdminPageTitle';
import Spinner from '../../common/Spinner';
import { DistributorState } from '../../../reducers/distributor';
import { selectSenesiteDistributor, selectDistributorEvents } from '../../../store/selectors/distributorSelectors';
import Calendar from './../../common/calendar/Calendar';
import { CalendarEvent } from '../../../models/distributor';


interface CalendarPageStateProps {
    senesiteDistributor: DistributorState;
    events: CalendarEvent[];
}

type CalendarPageProps = CalendarPageStateProps & WithNamespaces;

class CalendarPage extends React.Component<CalendarPageProps> {

    private readonly isAdmin = true;

    render() {

        const { senesiteDistributor, events, t } = this.props;
        const selectedDate = this.props && this.props['match'] && this.props['match'].params && this.props['match'].params.selectedDate;
        const viewMode = this.props && this.props['match'] && this.props['match'].params && this.props['match'].params.viewMode;
        if (!senesiteDistributor) {
            return <Spinner />;
        } else {
            return (
                <div className="eventCalendar row">
                    <div className="col-12">
                        <Calendar t={t} culture={this.props.i18n.language} isAdmin={this.isAdmin} distributor={senesiteDistributor} events={events} selectedDate={selectedDate} viewMode={viewMode} />
                    </div>
                </div >
            )
        }
    }

}

const mapStateToProps = state => ({
    senesiteDistributor: selectSenesiteDistributor(state),
    events: selectDistributorEvents(state)
});

export default withAdminPageTitle('Calendar')(withNamespaces('Calendar')(connect(mapStateToProps)(CalendarPage)));
