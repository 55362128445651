import React, {ChangeEvent} from 'react';

import './MySeneSitesPage.scss';
import SeneTextInput from '../../common/inputs/SeneTextInput';

import SocialFeedOption from './SocialFeedOptions'
import i18n from '../../../i18n';
import { Distributor, DistributorSocialMedia } from '../../../models/distributor';

interface MySeneSitesPageStateProps {
    socialInfo: DistributorSocialMedia;
    hasYoutubeError: boolean;
    setSocialInfo(socialInfo: DistributorSocialMedia);
    t: i18n.TranslationFunction;
}
type MySeneSitesPageProps = MySeneSitesPageStateProps;

interface ISocialInfo {
    YouTubeVideo: string;
    YouTubeVideoError: string;
}

interface MySeneSitesPageState {
    socialInfo: ISocialInfo;
}

class MySeneSitesSocialMedia extends React.Component<MySeneSitesPageProps, MySeneSitesPageState> {
    state: MySeneSitesPageState = {
        socialInfo: {} as ISocialInfo
    }

    onHandleVideoTextChanged = (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        let id = e.target.id;
        let targetName = e.target.name;
        let targetValue = e.target.value;
        if (this.props.socialInfo.hasOwnProperty(targetName)) {
            const socialInfo = {...this.props.socialInfo};
            socialInfo.youTubeVideo = e.target.value
            this.props.setSocialInfo(socialInfo);
        }
    }
    
    handleRadioButton = (e: ChangeEvent<HTMLInputElement>) => {
        const radioButtonId = e.target.id.toLowerCase();
        const socialInfo = {...this.props.socialInfo};
            if (radioButtonId.indexOf("twitter") !== -1) {
                socialInfo.useMyTwitter = radioButtonId.indexOf("mine") > 1;
            }
            if (radioButtonId.indexOf("youtube") !== -1) {
                socialInfo.useMyYouTube = radioButtonId.indexOf("mine") > 1;
            }

            this.props.setSocialInfo(socialInfo);
    }

    render() {
        const { t } = this.props;
        return <>
            <div className='row social-feeds-panel col-md-5 col-lg-5'>
            <div className='admin-personal-msg'>{t('socialFeeds')}</div>

                <SocialFeedOption
                    title={t('twitter')}
                    option1={t('twitterOptionSene')}
                    option2={t('twitterOptionMine')}
                    onHandleChanged={this.handleRadioButton}
                    checked={this.props.socialInfo.useMyTwitter} />

                <SocialFeedOption
                    title={t('youtube')}
                    option1={t('youtubeOptionSene')}
                    option2={t('youtubeOptionMine')}
                    onHandleChanged={this.handleRadioButton}
                    checked={this.props.socialInfo.useMyYouTube} />
                {this.props.socialInfo.useMyYouTube &&
                    <div className='col-12 pl-5'>
                        <SeneTextInput
                            label={t('YouTubeVideo')}
                            name={"youTubeVideo"}
                            onChange={this.onHandleVideoTextChanged}
                            value={this.props.socialInfo.youTubeVideo}
                            error={this.props.hasYoutubeError ? t('YouTubeVideoError') : ''} />
                    </div>}

            </div>
        </>
    }
}

export default MySeneSitesSocialMedia
