import { produce } from 'immer';

import { AvailablePaymentOptionsState  } from '../models/payment';
import {PaymentOptionActionTypes} from "../actions/paymentOptionActions";

export type PaymentOptionsState = AvailablePaymentOptionsState | null;

export const reducer = (state: PaymentOptionsState = null, action) =>
    produce(state, draft => {
        switch (action.type) {
            case PaymentOptionActionTypes.getAvailableAdminPaymentOptionsError:
                draft = null;
                return draft;
            case PaymentOptionActionTypes.getAvailableAdminPaymentOptionsSuccess:
                draft = {
                    country: action.payload.country,
                    paymentOptionList: action.payload.paymentOptionList
                };
                return draft;
            case PaymentOptionActionTypes.updateDistributorPaymentOptionsSuccess:
                draft = {
                    country: state ? state.country : '',
                    paymentOptionList: action.paymentInfo
                };
                return draft;
            default:
                return draft;
        }
    });