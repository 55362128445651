import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

const backendOptions = {
    loadPath: '/i18n/{{lng}}/{{ns}}.json?t=' + (new Date).getTime()
};

i18n
    .use(XHR)
    .use(reactI18nextModule)
    .use(LanguageDetector)
    .init({
        load: 'languageOnly',
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',
        backend: backendOptions,
        ns: ['ApplicationTechnique'],
        defaultNS: 'ApplicationTechnique',
        // fallbackNS: 'common',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            wait: true,
            nsMode: 'fallback'
        },
        detection: ['localStorage', 'navigator']
    });

export default i18n;
