import React from "react";

import './DistributorPicture.scss';
import { Distributor } from "../../models/distributor";
import placeholder from '../../assets/images/image_placeholder-01.png';
import ImageComponent from "./ImageComponent";
import { Configuration } from "../../models/configuration";
import { connect } from "react-redux";
import { selectConfiguration } from "../../store/selectors/configurationSelectors";
import { State } from "../../store";

interface IDistributorPictureProps {
    distributor: Distributor;
    style?: React.CSSProperties;
    config: Configuration | null;
}

const DistributorPicture = ({ distributor, style, config }: IDistributorPictureProps) => {
    const pic = distributor
        && distributor.seneSiteInfo
        && distributor.seneSiteInfo.seneSiteLooks
        && distributor.seneSiteInfo.seneSiteLooks.seneSiteDistrbutorImage;

    const imgSrc = pic && config
        ? `${config.apiConstants.IMAGESCUSTOMERS}${pic}`
            : placeholder;

    return (
        <div className="distributor-picture">
            {
                pic
                    ? <ImageComponent src={imgSrc} alt="distributor image" width={300} height={300} crop="stretch"/>
                    : <img src={imgSrc} style={style} alt="distributor image" />
            }
        </div>
    )
};

const mapStateToProps = (state: State) => ({
    config: selectConfiguration(state)
})

export default connect(mapStateToProps)(DistributorPicture);
