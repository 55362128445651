import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import moment from 'moment';
import * as dompurify from 'dompurify';

import './DashboardPage.scss';
import { oldPayPalDeactivationDate } from './../../../utils/constants';
import { DashboardWidget } from './DashboardWidget';
import { Distributor, SeneSiteInfo } from '../../../models/distributor';
import { Log, LogSeverityLevels } from '../../../models/logging';
import * as distributorActions from '../../../actions/distributor';
import * as adminActions from '../../../actions/administration/adminActions';
import * as loggingActions from '../../../actions/loggingActions';
import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';
import { selectIsSeneCareUser } from '../../../store/selectors/authSelectors';
import { submitSeneSiteUrlForm, verifySeneSiteName } from '../../../actions/administration/applicationFormActions';
import DistributorInfo from './DistributorInfo';
import Spinner from '../../common/Spinner';
import { selectSelectedLanguageCode } from '../../../store/selectors/languageSelectors';
import { SecurityConfiguration } from '../../../models/configuration';
import { selectCdoLink, selectSecurityConfiguration, selectKnTLink, redirectToKissTell, selectPromotionServiceEnabled } from './../../../store/selectors/configurationSelectors';
import * as orderApi from './../../../api/orderApi';
import * as customerApi from './../../../api/customerApi';
import { ActionToastProperties } from '../../../models/ui';
import { SeneSiteApplicationFormModel } from '../application/SeneSiteApplicationForm';
import { selectLoading } from '../../../store/selectors/uiSelectors';
import { PaymentType } from '../../../models/payment';
import Modal from 'reactstrap/lib/Modal';
import { NavLink } from 'react-router-dom';
import { updateDistributorSeneSiteURLState } from '../../../actions/distributor';

interface DashboardStateProps {
    distributor: Distributor | null;
    isSeneCare: boolean | undefined;
    locale: string;
    loading: boolean;
    configuration: SecurityConfiguration | null;
    cdoLink: string;
    knTLink: string;
    redirectToKissTell: boolean;
    isPromotionEnabled: boolean;
}

interface DashboardDispatchProps {
    updateSeneSiteInfo(distId: number, seneSiteInfo: SeneSiteInfo, toastProps?: ActionToastProperties);
    submitSeneSiteUrlForm(model: SeneSiteApplicationFormModel, toastProps?: ActionToastProperties);
    updateDistributorSeneSiteURLState(seneSiteUrl: string);
    setAdminPageTitle(title: string);
    verifySeneSiteName(seneSiteName: string);
}

type DashboardProps = DashboardStateProps & DashboardDispatchProps & WithNamespaces;

interface DashboardState {
    customerCount: number;
    orderCount: number;
    authorityUrl: string;
    showOldPayPalPopup: boolean;
}

class DashboardPage extends React.Component<DashboardProps, DashboardState> {

    state: DashboardState = {
        customerCount: 0,
        orderCount: 0,
        authorityUrl: '',
        showOldPayPalPopup: false
    }

    componentDidMount() {
        this.getNotificationCounts();
        this.setAuthorityUrlState();
        this.showOldPayPalModal();
        this.props.setAdminPageTitle('Dashboard');
    }

    componentDidUpdate(prevProps: DashboardProps) {

        if (
            ((!prevProps || !prevProps.distributor) && this.props.distributor && this.props.distributor.dist_ID) ||
            (prevProps && prevProps.distributor && this.props.distributor && prevProps.distributor.dist_ID !== this.props.distributor.dist_ID)
        ) {
            this.getNotificationCounts();
            this.setAuthorityUrlState();
            this.showOldPayPalModal();
        }
    }

    showOldPayPalModal = () => {
        const { distributor } = this.props;
        if (!distributor) {
            return;
        }

        const paymentMethods = distributor.paymentInfo.paymentMethods;
        const oldPayPal = paymentMethods.filter(t => t.paymentMethodType == PaymentType.PayPal);
        const newPayPal = paymentMethods.filter(t => t.paymentMethodType == PaymentType.PayPalApi);
        if (oldPayPal.length && !newPayPal.length) {
            this.setState({ showOldPayPalPopup: true });
        }
    }

    async getNotificationCounts() {
        const { distributor } = this.props;
        if (!distributor) {
            return;
        }

        const [customerCount, orderCount] = await Promise.all([
            customerApi.getCustomerBadgeCount(distributor.dist_ID),
            orderApi.getOrderBadgeCount(distributor.dist_ID)
        ])

        this.setState({
            customerCount: customerCount,
            orderCount: orderCount
        });
    }

    setAuthorityUrlState = () => {
        const { configuration } = this.props;
        if (!configuration) {
            return;
        }

        const
            authority = configuration.authority,
            clientId = configuration['client_id'],
            redirectUri = window.location.href,
            locale = this.props.locale;
        const query = encodeURIComponent(`clientId=${clientId}&redirectUri=${redirectUri}&ui_locales=${locale}`);
        const authorityUrl = `${authority}Account/Manage?${query}`;
        this.setState({
            authorityUrl: authorityUrl
        })
    }

    closeOldPayPalModel = () => {
        this.setState({
            showOldPayPalPopup: false
        });
    }

    renderOldPayPalPopup = () => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const payPalExpiryDate = oldPayPalDeactivationDate.toLocaleDateString("en-US", options);
        const { t } = this.props;

        return (
            <Modal
                isOpen={this.state.showOldPayPalPopup}
                toggle={this.closeOldPayPalModel}
                onRequestClose={this.closeOldPayPalModel}
                className="modal-dialog modal-lg dashboard-oldpaypal-popup"
            >
                <div className="modal-content overflow-scroll dashboard-oldpaypal-background">
                    <div className="modal-body">
                        <div>{this.props.distributor && this.props.distributor.profile.firstName},</div>
                        <div className="my-2" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(t('payPalSupportExpirationWarning')) }}>
                        </div>
                        <div className="my-2" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(t('payPalSupportExpirationWarning2', { 'payPalExpiryDate': payPalExpiryDate })) }}>
                        </div>
                    </div>
                    <div className="modal-footer row">
                        <div className="col-sm-12 col-lg-5 text-center">
                            <button type="button" className="btn btn-primary">
                                <NavLink style={{ 'color': '#ffffff' }} to="/Administration/PaymentOptions?openPanel=payPalApi">{t('payPalSupportExpirationWarningTakeToSettings')}</NavLink>
                            </button>
                        </div>
                        <div className="col-sm-12 col-lg-5 text-center">
                            <button type="button" className="btn btn-link" style={{ 'color': '#000000' }} onClick={this.closeOldPayPalModel}>{t('payPalSupportExpirationWarningNotNow')}</button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    render() {
        const { t, isPromotionEnabled } = this.props;
        return (
            <>
                {
                    this.renderOldPayPalPopup()
                }
                {
                    this.props.distributor
                        ? <DistributorInfo
                            distributor={this.props.distributor}
                            updateSeneSiteInfo={this.props.updateSeneSiteInfo}
                            submitSeneSiteUrlForm={this.props.submitSeneSiteUrlForm}
                            updateDistributorSeneSiteURLState={this.props.updateDistributorSeneSiteURLState}
                            verifySeneSiteName={this.props.verifySeneSiteName}
                            loading={this.props.loading}
                            locale={this.props.locale}
                            cdoLink={this.props.cdoLink}
                            knTLink={this.props.knTLink}
                            redirectToKissTell={this.props.redirectToKissTell}
                        />
                        : <Spinner style={{ height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
                }
                <div className="dashboard-body">
                    <div className="container">
                        <div className="row">
                            <DashboardWidget
                                url="/administration/SeneSiteCustomers"
                                icon="AdminDashboard/icon_contacts.png"
                                text={t('customers')}
                                badge={{ count: this.state.customerCount }} />
                            <DashboardWidget
                                url="/Administration/SeneSiteOrders"
                                icon="AdminDashboard/icon_orders.png"
                                text={t('orders')}
                                badge={{ count: this.state.orderCount }} />
                            {
                            isPromotionEnabled && <DashboardWidget url="/Administration/Promotion" icon="AdminDashboard/icon_promotion.png" text={t('promotion')} />  
                            }
                            <DashboardWidget url="/Administration/SeneInventory" icon="AdminDashboard/icon_Inventory.png" text={t('inventory')} />
                            <DashboardWidget url="/Administration/MySeneSites" icon="AdminDashboard/icon_home.png" text={t('options')} />
                            <DashboardWidget url="/Administration/PaymentOptions/" icon="AdminDashboard/icon_payment.png" text={t('payments')} />
                            <DashboardWidget url="/Administration/Business/shipping_fees_tab" icon="AdminDashboard/icon_fees.png" text={t('fees')} />
                            <DashboardWidget url="/Administration/DistributorProfile" icon="AdminDashboard/icon_profile.png" text={t('profile')} />
                            {
                                !this.props.isSeneCare &&
                                <DashboardWidget url={this.state.authorityUrl} icon="AdminDashboard/icon_login.png" text={t('security')} isExternalLink={true} />
                            }
                            <DashboardWidget url={`/Administration/Calendar/CalendarPage/${moment(new Date()).format('YYYY-MM-DD')}/month`} icon="AdminDashboard/icon_calendar.png" text={t('eventCalendar')} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    distributor: selectSenesiteDistributor(state),
    isSeneCare: selectIsSeneCareUser(state),
    locale: selectSelectedLanguageCode(state),
    configuration: selectSecurityConfiguration(state),
    cdoLink: selectCdoLink(state),
    loading: selectLoading(state),
    knTLink: selectKnTLink(state),
    redirectToKissTell: redirectToKissTell(state),
    isPromotionEnabled:  selectPromotionServiceEnabled(state)
});

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        updateSeneSiteInfo: distributorActions.updateDistributorSeneSiteInfo,
        submitSeneSiteUrlForm: submitSeneSiteUrlForm,
        updateDistributorSeneSiteURLState: updateDistributorSeneSiteURLState,
        setAdminPageTitle: adminActions.setAdminPageTitle,
        verifySeneSiteName: verifySeneSiteName
    }, dispatch)
);

export default withNamespaces('Dashboard')(connect(mapStateToProps, mapDispatchToProps)(DashboardPage));
