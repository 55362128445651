import React from 'react';
import { NavLink } from 'react-router-dom';
import Modal from 'react-modal';


import './DistributorInfo.scss'
import { Distributor, SeneSiteInfo } from '../../../models/distributor';
import DistributorPicture from '../../common/DistributorPicture';
import { RadioButton } from '../../common/inputs/RadioButton';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { ActionToastProperties } from '../../../models/ui';
import { SeneSiteApplicationFormModel } from '../application/SeneSiteApplicationForm';
import { SeneSiteUrlValidatorInput } from '../application/SeneSiteUrlValidatorInput';
import { DisplayApplicationFormData } from '../../../models/applicationForm';
import * as applicationFormApi from '../../../api/applicationFormApi';

interface DistributorInfoProps {
    distributor: Distributor;
    loading: boolean;
    locale: string;
    cdoLink: string;
    knTLink: string;
    redirectToKissTell: boolean;
    updateSeneSiteInfo(distId: number, seneSiteInfo: SeneSiteInfo, toastProps?: ActionToastProperties);
    submitSeneSiteUrlForm(model: SeneSiteApplicationFormModel, toastProps?: ActionToastProperties);
    updateDistributorSeneSiteURLState(seneSiteUrl: string);
    verifySeneSiteName(seneSiteName: string);
}

interface DistributorInfoState {
    prevDistributor: Distributor | null;
    isPublished: boolean;
    hasShippingFees: boolean;
    hasHandlingFees: boolean;
    hasPayments: boolean;
    isCdoModalOpen: boolean;
    isEditSeneSiteNameModalOpen: boolean;
    cdoOverridesSenesite: boolean;
    seneSiteApplicationFormInfo: SeneSiteApplicationFormModel;
    displayApplicationFormData: DisplayApplicationFormData;
}

class DistributorInfo extends React.PureComponent<DistributorInfoProps & WithNamespaces, DistributorInfoState> {
    // TODO: Get CDO link from config
    _cdoLink = '';
    state = {
        prevDistributor: null,
        isPublished: false,
        hasShippingFees: false,
        hasHandlingFees: false,
        hasPayments: false,
        isCdoModalOpen: false,
        isEditSeneSiteNameModalOpen: false,
        cdoOverridesSenesite: false,
        seneSiteApplicationFormInfo: {
            seneSiteName: '',
            agree: true,
            isValidSeneSiteName: false
        },
        displayApplicationFormData: {} as DisplayApplicationFormData
    };

    componentDidMount() {
        if (this.props.distributor) {
            this.getApplicationformConfiguration();
        }
    }

    componentDidUpdate(prevProps: DistributorInfoProps) {
        if (this.props.distributor && prevProps.distributor && this.props.distributor.dist_ID != prevProps.distributor.dist_ID) {
            this.getApplicationformConfiguration(prevProps.distributor && prevProps.distributor.dist_ID || undefined);
        }
    }

    getApplicationformConfiguration(prevDistId?: number) {
        const { distributor } = this.props;
        if (!distributor || (prevDistId && distributor.dist_ID === prevDistId)) {
            return;
        }
        applicationFormApi.getApplicationformConfiguration(distributor.dist_ID)
            .then(displayApplicationFormData => {
                this.setState({ displayApplicationFormData });
            })
    }

    get distName(): string {
        return (this.props.distributor && this.props.distributor.profile.firstName && this.props.distributor.profile.lastName)
            ? `${this.props.distributor.profile.firstName} ${this.props.distributor.profile.lastName}`
            : '';
    }

    get distSeneSiteUrl(): string {
        try {
            if (this.props.distributor.seneSiteInfo.redirectToCdo) {
                if (this.props.redirectToKissTell) {
                    return `${this.props.knTLink + this.props.i18n.language.toLowerCase() + "/distributor/" + this.props.distributor.dist_ID}`;
                }
                return `${this.props.cdoLink}${this.props.distributor.dist_ID}&culture=${this.props.i18n.language}`;
            } else {
                return window.location.protocol + "//" + window.location.hostname +
                    (window.location.port ? ':' + window.location.port : '') + '/' +
                    this.props.distributor.seneSiteInfo.seneSiteUrl;
            }
        }
        catch (e) { return "" }
    }

    get viewMySites(): string {
        if (!this.props.distributor) return '';

        const { t } = this.props;
        let text = '';

        if (this.props.distributor.seneSiteInfo && this.props.distributor.seneSiteInfo.redirectToCdo) {
            text = 'viewMyCdo';
        } else {
            text = 'viewMySenesite';
        }

        return t(text);
    }

    static getDerivedStateFromProps: React.GetDerivedStateFromProps<DistributorInfoProps, DistributorInfoState> = (props, state) => {
        if (props.distributor !== state.prevDistributor) {
            const cdoOverridesSenesite = props.distributor.seneSiteInfo
                && props.distributor.seneSiteInfo.redirectToCdo !== undefined
                ? props.distributor.seneSiteInfo.redirectToCdo
                : false;
            const isPublished = !!(props.distributor.seneSiteInfo &&
                props.distributor.seneSiteInfo.webStat &&
                props.distributor.seneSiteInfo.webStat === "Published");
            const hasHandlingFees = props.distributor.seneSiteInfo &&
                props.distributor.seneSiteInfo.handlingInfo != null;
            const hasShippingFees = props.distributor.seneSiteInfo &&
                Array.isArray(props.distributor.seneSiteInfo.shippingInfo);
            const hasPayments = props.distributor.paymentInfo && props.distributor.paymentInfo.paymentMethods
                && props.distributor.paymentInfo.paymentMethods.length > 0;
            return {
                cdoOverridesSenesite,
                isPublished,
                hasHandlingFees,
                hasShippingFees,
                hasPayments,
                prevDistributor: props.distributor
            }
        }

        return null;
    }

    handleCdoOptionChange = (value: 'cdo' | 'senesite') => {
        this.setState({
            cdoOverridesSenesite: value === 'cdo'
        });
    }

    onCdoOptionSaved = () => {
        const seneSiteInfo = { ...this.props.distributor.seneSiteInfo };
        seneSiteInfo.redirectToCdo = this.state.cdoOverridesSenesite;

        this.updateSeneSiteInfo(seneSiteInfo);
    }

    updateSeneSiteInfo = (seneSiteInfo: SeneSiteInfo) => {
        this.props.updateSeneSiteInfo(this.props.distributor.dist_ID, seneSiteInfo, { success: this.props.i18n.t('userUpdateSuccess'), error: this.props.i18n.t('userUpdateError') })
            .then((response) => {
                if (response) {
                    this.closeCdoModal();
                }
            });
    }

    renderViewSenesiteButton() {
        return (
            this.props.distributor
            && this.props.distributor.seneSiteInfo
            && this.props.distributor.seneSiteInfo.webStat === "Published"
            && <a href={this.distSeneSiteUrl}
                className="btn admin-dist-info__view-senesite-button"
                target="window.open('', 'senewindow');">
                {this.viewMySites}
            </a>
        )
    }

    onPublish = (e) => {
        e.preventDefault();
        const seneSiteInfo = { ...this.props.distributor.seneSiteInfo };
        seneSiteInfo.webStat = 'Published';

        this.props.updateSeneSiteInfo(this.props.distributor.dist_ID, seneSiteInfo,
            { success: this.props.i18n.t('userUpdateSuccess'), error: this.props.i18n.t('userUpdateError') })
            .then(() => {
                window.location.href = this.distSeneSiteUrl;
            });

    };

    renderSenesiteCdoInfo() {
        const { distributor: dist, t } = this.props;

        if (!dist || !dist.seneSiteInfo ||
            (dist.countryName && dist.countryName.toLocaleLowerCase() !== 'au' && dist.countryName.toLocaleLowerCase() !== 'us' && dist.countryName.toLocaleLowerCase() !== 'ca' && dist.countryName.toLocaleLowerCase() !== 'nz')
            || (dist.cdoInfo && !dist.cdoInfo.isCdoOn)) {
            return null;
        }

        return (
            <div className="col-12 col-sm-9 offset-sm-3 admin-dist-info__cdo-block">
                <span className="admin-dist-info__cdo-text">{this.state.cdoOverridesSenesite ? t('orderCDOOption') : t('orderSenesiteOption')}</span>
                <button className="btn btn-link admin-dist-info__cdo-link" onClick={this.openCdoModal}>{t('changeButtonText')}</button>
                {this.renderCdoModal()}
            </div>
        );
    }

    renderCdoModal = () => {
        const { t } = this.props;

        return (
            //TODO: We could create a modal system that is managed in Redux.
            <Modal
                isOpen={this.state.isCdoModalOpen}
                onRequestClose={this.closeCdoModal}
                className="modal-dialog modal-lg"
            >
                <div className="modal-content overflow-scroll">
                    <div className="modal-header">
                        <h5 className="modal-title" id="uploadContainerLabel">{t('customerOrdersManagement')}</h5>
                        <button type="button" className="close" onClick={this.closeCdoModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.renderSenesiteCdoOptions()}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.closeCdoModal}>{t('close')}</button>
                        <button type="button" className="btn btn-primary" title="btnSave" onClick={this.onCdoOptionSaved}>{t('btnSave')}</button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderSenesiteCdoOptions() {
        const { t } = this.props;

        return (
            <>
                <RadioButton
                    value="senesite"
                    groupName="CDO"
                    label={t('orderSenesiteOption')}
                    onChange={() => this.handleCdoOptionChange('senesite')}
                    identifier={"senesite"}
                    checked={!this.state.cdoOverridesSenesite}
                    className="special-clear"
                />
                <p className="cdo-option-text">
                    {t('orderSeneSiteText')}
                </p>
                <RadioButton
                    value="cdo"
                    groupName="CDO"
                    label={t('orderCDOOption')}
                    onChange={() => this.handleCdoOptionChange('cdo')}
                    identifier={"cdo"}
                    checked={this.state.cdoOverridesSenesite}
                    className="special-clear"
                />
                <p className="cdo-option-text">
                    {t('orderCDOText')}
                </p>
                <p className="cdo-option-text text-danger">
                    {t('orderCDOWarning')}
                </p>
            </>
        );
    }

    openCdoModal = () => {
        this.setState({ isCdoModalOpen: true });
    }

    closeCdoModal = () => {
        const cdo = this.props.distributor.seneSiteInfo && this.props.distributor.seneSiteInfo.redirectToCdo !== undefined
            ? this.props.distributor.seneSiteInfo.redirectToCdo
            : false;
        this.setState({
            isCdoModalOpen: false,
            cdoOverridesSenesite: cdo
        });
    }

    renderEditSenesiteButton() {
        const { distributor: dist, t } = this.props;
        const config = this.state.displayApplicationFormData.applicationFormConfiguration;

        if (!dist
            || !dist.seneSiteInfo
            || !config
            || (config && !config.isSeneSiteURLChangeEnabled)) {
            return null;
        }

        return (
            <div >
                <button className="btn btn-link admin-dist-info__cdo-link" onClick={this.openEditSenesiteModal}>{t('changeButtonText')}</button>
                {this.renderEditSenesite()}
            </div>
        )
    }

    renderEditSenesite = () => {
        const { t } = this.props;

        return (
            <Modal
                isOpen={this.state.isEditSeneSiteNameModalOpen}
                onRequestClose={this.closeEditSenesiteModal}
                className="modal-dialog modal-lg"
            >
                <div className="modal-content overflow-scroll">
                    <div className="modal-header">
                        <h5 className="modal-title" id="uploadContainerLabel">{t('changeSeneSiteUrl')}</h5>
                        <button type="button" className="close" onClick={this.closeEditSenesiteModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.renderEditSenesiteBody()}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.closeEditSenesiteModal}>{t('close')}</button>
                        <button type="button" className="btn btn-primary" title="btnSave" onClick={this.onEditSenesiteSaved}>{t('change')}</button>
                    </div>
                </div>
            </Modal>
        );
    }

    renderEditSenesiteBody() {
        const { t } = this.props;

        return (
            <>
                <div>
                    <div>{t('currentSenesite')} {this.distSeneSiteUrl}</div>
                </div>
                <div>
                    <SeneSiteUrlValidatorInput
                        updateIsValidSeneSiteName={this.updateIsValidSeneSiteName}
                        updateSeneSiteName={this.updateSeneSiteName}
                        seneSiteApplicationFormInfo={this.state.seneSiteApplicationFormInfo}
                        t={this.props.t}
                        loading={this.props.loading}
                        locale={this.props.locale}
                        verifySeneSiteName={this.props.verifySeneSiteName}
                    />
                </div>
            </>
        );
    }

    updateSeneSiteName = (value: string) => {
        this.setState({
            seneSiteApplicationFormInfo: {
                ...this.state.seneSiteApplicationFormInfo,
                seneSiteName: value
            }
        });
    }
    updateIsValidSeneSiteName = (value: boolean) => {
        this.setState({
            seneSiteApplicationFormInfo: {
                ...this.state.seneSiteApplicationFormInfo,
                isValidSeneSiteName: value
            }
        });
    }

    onEditSenesiteSaved = () => {
        const { t } = this.props;
        if (this.state.seneSiteApplicationFormInfo.isValidSeneSiteName && this.state.seneSiteApplicationFormInfo.seneSiteName != "") {
            this.props.submitSeneSiteUrlForm(this.state.seneSiteApplicationFormInfo,
                { success: t('seneSiteChangeUrlFormSent'), error: t('seneSiteChangeUrlFormUnexceptedError') })
                .then((response) => {
                    this.props.updateDistributorSeneSiteURLState(this.state.seneSiteApplicationFormInfo.seneSiteName);
                });
        } else {

        }
    }

    openEditSenesiteModal = () => {
        this.setState({ isEditSeneSiteNameModalOpen: true });
    }

    closeEditSenesiteModal = () => {
        this.setState({ isEditSeneSiteNameModalOpen: false });
    }

    renderSetup = () => {
        if (!this.state.isPublished ||
            !this.state.hasPayments ||
            !this.state.hasShippingFees ||
            !this.state.hasHandlingFees
        ) {
            return <div className="alert alert-info" style={{ width: '100%', marginTop: '1rem' }}>
                <p>{this.props.t('setupAndPublish')}</p>
                <ol>
                    {this.renderStep("/administration/paymentoptions", this.props.t('paymentOptions'), "paymentOptions")}
                    {this.renderStep("/administration/business/shipping_fees_tab", this.props.t('shippingFees'), "shippingFees")}
                    {this.renderStep("/administration/business/handling_fees_tab", this.props.t('handlingFees'), "handlingFees")}
                    {this.renderPublish()}
                </ol>
            </div>;

        } else {
            return '';
        }
    }

    renderPublish = () => {
        const { t } = this.props;
        return (!this.state.isPublished
            && this.state.hasPayments
            && this.state.hasShippingFees
            && this.state.hasHandlingFees) ?
            <li><a href="#" onClick={this.onPublish}>{t('publishSenesite')}</a></li> :
            <li>{t('publishSenesite')}</li>
    }

    renderStep = (link: string, title: string, stepItem) => {
        const { t } = this.props;
        switch (stepItem) {
            case 'paymentOptions':
                return !this.state.hasPayments ? <li><NavLink to={link}>{title}</NavLink></li> : <li>{title} - {t('completed')}</li>;
            case 'shippingFees':
                return !this.state.hasShippingFees ? <li><NavLink to={link}>{title}</NavLink></li> : <li>{title} - {t('completed')}</li>;
            case 'handlingFees':
                return !this.state.hasHandlingFees ? <li><NavLink to={link}>{title}</NavLink></li> : <li>{title} - {t('completed')}</li>;
        }
    }


    render() {
        const { t, distributor } = this.props;

        const address = distributor
            && distributor.profile.addresses
            && distributor.profile.addresses.find(s => s.isSeneSiteAddress);

        return (
            <div className="admin-dist-info">
                <div className="row container admin-dist-info__container">
                    <div className="col-5 col-sm-3 admin-dist-info__picture-container">
                        <DistributorPicture distributor={distributor} />
                    </div>
                    <div className="col-7 col-sm-9">
                        <div className="row admin-dist-info__name">
                            <h2>{this.distName}</h2>
                        </div>
                        <div className="row admin-dist-info__ind-dist-text">{t('independentDistributor')}</div>
                        <div className="row">
                            <span className="admin-dist-info__id">{t('id')}: {distributor.dist_ID}</span>
                            {
                                address &&
                                <span className="admin-dist-info__location"> {address.city}, {address.stateName}</span>
                            }
                        </div>
                        <div className="row admin-dist-info__site-block">
                            <input type="text" readOnly className="col-sm-6 admin-dist-info__link admin-font"
                                value={this.distSeneSiteUrl} />
                            {this.renderViewSenesiteButton()}
                            {this.renderEditSenesiteButton()}
                        </div>
                    </div>
                    <div className="col-12 admin-dist-info__site-block--mobile">
                        {this.renderViewSenesiteButton()}
                        {this.renderEditSenesiteButton()}
                    </div>
                    {this.renderSenesiteCdoInfo()}
                    {this.renderSetup()}
                </div>
            </div>
        )
    }
}

export default withNamespaces(['AdminHeader', 'Common', 'SeneSiteApplicationForm'])(DistributorInfo);
