import React from 'react';
import { Collapse, CardBody, Card, Row, Col } from 'reactstrap';
import Spinner from "../../common/Spinner";
import { AdminPaymentOption as PaymentOption, SquarePaymentOption } from "../../../models/payment";
import i18next from "i18next";

interface PaymentOptionsPageState {
    noLocation: boolean;
    isExpired: boolean;
    error: string;
}

interface SquareCredentialPanelProps {
    distributorPaymentOption: SquarePaymentOption;
    toggle(value: number);
    isOpen: boolean;
    panel: number;
    t: i18next.TranslationFunction;
    toggle(value: number);
    url: string;
    error: string;
    unauthorizeFromSquare();
}

class SquareCredentialPanel extends React.Component<SquareCredentialPanelProps, PaymentOptionsPageState> {

    state: PaymentOptionsPageState = {
        noLocation: true,
        isExpired: false,
        error: ''
    }

    componentDidMount() {
        this.setState({
            error: this.props.error
        })
    }

    render() {
        const { t, isOpen, panel, distributorPaymentOption } = this.props;
        if (!distributorPaymentOption) {
            return (<Spinner />);
        }

        const pdfURL = t(['squarePdfUrl', 'https://seneassets.blob.core.windows.net/images/SeneSitePayments/How%20to%20Accept%20Square%20Payment%20on%20Your%20SeneSite%20-%20%5BUser%20Guide%5D.pdf']);
        const videoURL = t(['squareTutorialVideo', 'https://seneassets.blob.core.windows.net/images/SeneSitePayments/How%20to%20Accept%20Square%20Payment%20On%20Your%20SeneSite.mp4']);
        const { selected } = distributorPaymentOption;
        return (
            <>
                <Card>
                    <Row className="align-items-center">
                        <Col xs="8" sm="8" md="8" className="text-left">
                            <a className="btn text-primary" onClick={() => this.props.toggle(panel)}>
                                {
                                    isOpen ?
                                        <i className="fas fa-chevron-down btn text-primary"></i> :
                                        <i className="fas fa-chevron-right btn text-primary"></i>
                                }
                                {t('squarePayment')}
                            </a>
                        </Col>
                        <Col xs="4" sm="4" md="4" className="text-right">
                            {
                                selected &&
                                <div className="text-primary pr-3" style={{ cursor: "default" }}> {t('activated')} </div>
                            }
                        </Col>
                    </Row>
                </Card>
                <Collapse isOpen={isOpen}>
                    <Card>
                        <CardBody>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-6"><h3>{t('squareDocTitle')}</h3>
                                        <div>{t('squareDocDesc')}</div>
                                        <a
                                            className="mr-1"
                                            href={pdfURL}
                                            target="_blank">
                                            <i className="far fa-file-alt mx-1" aria-hidden="true"></i>
                                            {t('pdf')}
                                        </a>
                                        |
                                        <a
                                            className="ml-1"
                                            href={videoURL}
                                            target="_blank">
                                            <i className="far fa-file-video mx-1" aria-hidden="true"></i>
                                            {t('video')}
                                        </a>
                                    </div>
                                </div>
                                <hr />

                                <div className="row">
                                    <div className="col-sm-6" style={{ marginLeft: "15px" }}>
                                        {this.props.distributorPaymentOption.selected ?
                                            <i className="fa fa-check mr-2"></i> :
                                            <i className="fa fa-times mr-2"></i>
                                        }
                                        <label className="form-check-label" htmlFor="square">{t('squareAllowCheck')}</label></div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        {this.props.distributorPaymentOption.selected &&
                                            <a className="btn btn-primary mt-2 ml-1 text-white"
                                                id="unauth_button"
                                                onClick={() => this.props.unauthorizeFromSquare()}>
                                                <i className="fa fa-times mr-2" aria-hidden="true"></i>{t('squareDeauthorizeBtn')}</a>}
                                        {!this.props.distributorPaymentOption.selected &&
                                            <a className="btn btn-primary mt-2 ml-1"
                                                id="auth_button"
                                                href={this.props.url}>
                                                <i className="fa fa-lock-open mr-2" aria-hidden="true"></i>{t('squareAuthorizeBtn')}</a>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mt-3" style={{ color: 'red' }}>{t(this.props.error)}</div>
                                        {this.props.distributorPaymentOption.location &&
                                            <div>
                                                <label htmlFor="Locations">{t('squareLocationTitle')}:</label>
                                                <p>{this.props.distributorPaymentOption.location.name}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>
            </>
        )
    }

}

export default SquareCredentialPanel;
