import { CheckoutErrors, CheckoutState } from './../../reducers/checkout';
import { State } from '..';
import { selectCart } from './cartSelectors';
import { ShoppingCart } from '../../models/checkout';
import { PaymentRequest, Payment, PaymentType } from '../../models/payment';
import * as loggingApi from '../../api/loggingApi';
import { LogSeverityLevels } from '../../models/logging';
import { sendLog } from '../../actions/loggingActions';
import { selectSelectedLanguageCode } from './languageSelectors';

export const selectCheckoutState = (state: State): CheckoutState => state.checkout;
export const selectHasStoredCheckoutInfo = (state: State) => state.checkout && state.checkout.customerInfo;
export const selectShoppingCartDto = (state: State): ShoppingCart | null => {
    const cartItems = selectCart(state);
    const { shippingAddress, shippingMethod: shippingInfo, paymentInfo } = selectCheckoutState(state);

    if (!shippingAddress || !shippingInfo || !paymentInfo) {
        return null;
    }

    return { cartItems, shippingAddress, shippingInfo };
}
export const selectPaymentDto = (state: State): Payment | null => {
    const { paymentInfo, totalCharges } = selectCheckoutState(state);

    if (!paymentInfo || !totalCharges) {
        return null;
    }

    const transactionId = (paymentInfo.paymentMethod.paymentMethodType === PaymentType.PayPalApi) ?
        paymentInfo.payPalApiPaymentId :
        (paymentInfo.sqInfo && paymentInfo.sqInfo.nonce);
    //TODO: It's always setting it to full amount (same functionality as before)
    const payment = {
        paymentType: paymentInfo.paymentMethod.paymentMethodType,
        amount: totalCharges.cartTotal,
        transactionId: transactionId,
        ...paymentInfo.ccInfo
    };

    return payment;
}
export const selectPaymentRequestDto = (state: State): PaymentRequest | null => {
    const shoppingCart = selectShoppingCartDto(state);
    const payment = selectPaymentDto(state);
    const { customerInfo: customerInformation } = selectCheckoutState(state);
    const signalRConnectionId = state.signalRid;
    const customerNotes = '';
    const locale = selectSelectedLanguageCode(state).toLocaleLowerCase();

    if (!shoppingCart || !payment || !customerInformation || !signalRConnectionId) {
        sendLog({
            'message': `There is an error creating the Payment Request DTO`,
            'severity': LogSeverityLevels.Error,
            'data': JSON.stringify({shoppingCart, payment, customerInformation, signalRConnectionId })
        });
        return null;
    }

    return {
        shoppingCart,
        payments: [payment],
        customerInformation,
        signalRConnectionId,
        customerNotes,
        locale
    };
}
export const selectPreviousOrder = (state: State) => state.previousOrder;
export const selectCheckoutErrors = (state: State): CheckoutErrors | undefined => state.checkout && state.checkout.checkoutErrors;
