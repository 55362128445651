import React from 'react';

import SeneGradientHeader from '../../../common/headers/SeneGradientHeader';
import ProductResults from './ProductResults';
import { MaybeCategoryOrSubcategory, isSubCategory } from '../../../../utils/types';
import { ProductVm } from '../../../../models/product';
import i18n from '../../../../i18n';
import { getSubCategoryName, getCategoryName } from '../../../../utils/helpers';

interface ShopProductsProps {
    selectedCategory: MaybeCategoryOrSubcategory;
    productsInCategory: ProductVm[];
    isLoading: boolean;
    sort: { sortBy: string, direction: string };
    setSort(sortBy: string, direction: string);
    addToCart(productId: number, sku: string);
    t: i18n.TranslationFunction;
    locale: string;
}

const ShopProducts = ({
    selectedCategory,
    productsInCategory,
    isLoading,
    sort,
    setSort,
    addToCart,
    t,
    locale
}: ShopProductsProps) => (
        <>
            {
                selectedCategory &&
                <SeneGradientHeader id="products-page__header">
                    {
                        isSubCategory(selectedCategory)
                            ? getSubCategoryName(selectedCategory, locale)
                            : getCategoryName(selectedCategory, locale)
                    }
                </SeneGradientHeader>
            }
            <ProductResults
                products={productsInCategory}
                addToCart={addToCart}
                isLoading={isLoading}
                currencyForced={false}
                sort={sort}
                setSort={setSort}
                t={t}
            />
        </>
    );

export default ShopProducts;
