import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import StepZilla from 'react-stepzilla';

import './CheckoutPage.scss';
import { State } from '../../../store';
import SectionHeader from '../../common/headers/SectionHeader';
import ShippingForm from './shipping/ShippingForm';
import PaymentForm from './payment/PaymentForm';
import PlaceOrder from './place-order/PlaceOrder';
import { loadProductsInCart } from '../../../actions/cartActions';
import {
    saveOrderInfo,
    savePaymentInfo,
    OrderInfoParams,
    calculateCharges,
    submitPayment,
    createPayPalApiPayment
} from '../../../actions/checkoutActions';
import { selectLoggedInUser } from '../../../store/selectors/userSelectors';
import {ShippingInfo, PublicPaymentMethod as PaymentMethod, Distributor} from '../../../models/distributor';
import { selectShippingMethods, selectPaymentMethods, selectSenesiteDistributor, selectHandlingRate } from '../../../store/selectors/distributorSelectors';
import { selectCheckoutState, selectCheckoutErrors } from '../../../store/selectors/checkoutSelectors';
import { CheckoutErrors, CheckoutState, PaymentInfoState } from '../../../reducers/checkout';
import { selectCartViewModel } from '../../../store/selectors/cartSelectors';
import { CartViewModel } from '../../../models/cart';
import { getRegions } from '../../../actions/regionActions';
import { selectRegionsForDistributorCountry } from '../../../store/selectors/regionSelectors';
import { CountryState } from '../../../models/region';
import { CreditCardTypeConfiguration } from '../../../models/payment';
import { ActionToastProperties } from '../../../models/ui';
import { selectSelectedLanguageAlpha2Code } from '../../../store/selectors/languageSelectors';
import { selectRecaptchaKey, selectCaptchasDisabled } from '../../../store/selectors/configurationSelectors';
import { WithCheckoutJumpState } from '../../common/guards/withCheckoutState';

interface CheckoutPageStateProps {
    currentUser: any;
    shippingMethods: ShippingInfo[];
    paymentMethods: PaymentMethod[];
    checkoutState: CheckoutState;
    cart: CartViewModel;
    distributor: Distributor;
    handlingRate: number;
    regions: CountryState[];
    distributorCreditCards: CreditCardTypeConfiguration[];
    checkoutErrors?: CheckoutErrors;
    recaptchaKey: string;
    selectedLanguageAlpha2Code: string;
    captchasDisabled: boolean;
}

interface CheckoutPageDispatchProps {
    loadProductsInCart();
    saveOrderInfo(info: OrderInfoParams);
    savePaymentInfo(info: PaymentInfoState);
    calculateCharges(toastProps?: ActionToastProperties);
    getRegions();
    submitPayment(customerNotes: string, toastProps?: ActionToastProperties);
    createPayPalApiPayment(customerNotes: string, toastProps?: ActionToastProperties);
}

interface CheckoutPageState {
    customerNotes: string;
}

type CheckoutPageProps = CheckoutPageStateProps & CheckoutPageDispatchProps & WithNamespaces & WithCheckoutJumpState;

class CheckoutPage extends React.Component<CheckoutPageProps, CheckoutPageState> {
    startAtStep = 0;

    state: CheckoutPageState = {
        customerNotes: ""
    }

    constructor(props) {
        super(props);

        if (this.props.checkoutState && this.props.checkoutState.jumpToPlaceOrder) {
            this.startAtStep = 2;
        }
        if(this.props.jumpToPaymentSelection) {
            this.startAtStep = 1;
        }

    }
    getSteps() {
        const { t } = this.props;
        return [
            {
                name: t('tab1'),
                component:
                    <ShippingForm
                        captchasDisabled={this.props.captchasDisabled}
                        recaptchaKey={this.props.recaptchaKey}
                        currentUser={this.props.currentUser}
                        t={this.props.t}
                        saveOrderInfo={this.props.saveOrderInfo}
                        shippingMethods={this.props.shippingMethods}
                        checkoutState={this.props.checkoutState}
                        regions={this.props.regions}
                        distributor={this.props.distributor}
                        selectedLanguageAlpha2Code={this.props.selectedLanguageAlpha2Code}
                    />
            },
            {
                name: t('tab2'),
                component:
                    
                        <PaymentForm
                            t={this.props.t}
                            distributor={this.props.distributor}
                            paymentMethods={this.props.paymentMethods}
                            checkoutState={this.props.checkoutState}
                            savePaymentInfo={this.props.savePaymentInfo}
                            regions={this.props.regions}
                        />
                        
            },
            {
                name: t('tab3'),
                component:
                    <PlaceOrder
                        cart={this.props.cart}
                        calculateCharges={this.props.calculateCharges}
                        checkoutState={this.props.checkoutState}
                        handlingRate={this.props.handlingRate}
                        submitPayment={this.submitPayment}
                        distributor={this.props.distributor}
                        checkoutErrors={this.props.checkoutErrors}
                        customerNotes={this.state.customerNotes}
                        setCustomerNotes={this.setCustomerNotes}
                    />
            },
        ]
    }

    setCustomerNotes = (notes: string) => {
        this.setState({"customerNotes": notes});
    }

    submitPayment = () => {
        const { t } = this.props;

        if (!this.props.checkoutState.paymentInfo) {
            return;
        }

        if (this.props.checkoutState.paymentInfo.paymentMethod.paymentMethodType === 'PayPalApi') {
            this.props.createPayPalApiPayment(this.state.customerNotes, { success: t('payPalApiPaymentCreateSuccess'), error: t('payPalApiPaymentCreateError') });
        } else {
            this.props.submitPayment(this.state.customerNotes, { success: t('submitOrderSuccess'), error: t('submitOrderError') });
        }
    };

    componentDidMount() {
        this.props.loadProductsInCart();
        this.props.getRegions();
    }

    render() {
        const steps = this.getSteps();
        const { t } = this.props;

        return (
            <>
                <SectionHeader>{t('title')}</SectionHeader>
                <div className='step-progress'>
                    <StepZilla
                        steps={steps}
                        stepsNavigation={false}
                        startAtStep={this.startAtStep}
                        nextButtonCls="btn btn-lg btn-primary"
                        backButtonCls="btn btn-lg btn-primary"
                        nextButtonText={t('btnContinue')}
                        backButtonText={t('btnPrevious')}
                        preventEnterSubmission={true}
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: State) => ({
    currentUser: selectLoggedInUser(state),
    shippingMethods: selectShippingMethods(state),
    paymentMethods: selectPaymentMethods(state),
    checkoutState: selectCheckoutState(state),
    cart: selectCartViewModel(state),
    distributor: selectSenesiteDistributor(state),
    handlingRate: selectHandlingRate(state),
    regions: selectRegionsForDistributorCountry(state),
    checkoutErrors: selectCheckoutErrors(state),
    recaptchaKey: selectRecaptchaKey(state),
    selectedLanguageAlpha2Code: selectSelectedLanguageAlpha2Code(state),
    captchasDisabled: selectCaptchasDisabled(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadProductsInCart,
    saveOrderInfo,
    savePaymentInfo,
    calculateCharges,
    getRegions,
    submitPayment,
    createPayPalApiPayment,
}, dispatch);

export default withNamespaces('CheckoutPage')(connect(mapStateToProps, mapDispatchToProps)(CheckoutPage));
