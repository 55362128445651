import React, { memo } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import './SeneFooter.scss';
import { AssetDirectImage } from './Images';
import SeneSiteLink from './SeneSiteLink';
import { connect } from 'react-redux';
import { State } from '../../store';
import { selectSenesiteDistributor } from '../../store/selectors/distributorSelectors';
import { Distributor } from '../../models/distributor';
import { selectConfiguration } from '../../store/selectors/configurationSelectors';
import { Configuration } from '../../models/configuration';

interface SeneFooterProps {
    currentDistributor: any;
    config: Configuration | null;
}

class SeneFooter extends React.Component<SeneFooterProps & WithNamespaces> {
    render() {

        const version = "V2022.3.0";
        const { t, config } = this.props;
        const currentCountry = this.props.currentDistributor != null ? (this.props.currentDistributor as Distributor).countryName : '';
        const facebookUrl = currentCountry !== 'HK' ? 'https://www.facebook.com/SeneGence/' : 'https://www.facebook.com/SeneGenceHK/'

        return (
            <div>
                <hr />
                <footer className="font-color-footer container">
                    <div className="footer-info card-deck">
                        <div className="footer-mobile">
                            <div className="text-title">
                                {t('companyPolicies')}
                            </div>
                            <SeneSiteLink to="/privacy-policy">
                                <div className="company-policy">
                                    {t('privacyPolicy')}
                                </div>
                            </SeneSiteLink>
                            <SeneSiteLink to="/copyright-policy">
                                <div className="company-policy">
                                    {t('copyrightPolicy')}
                                </div>
                            </SeneSiteLink>
                            <SeneSiteLink to="/terms-of-use">
                                <div className="company-policy">
                                    {t('termsOfUse')}
                                </div>
                            </SeneSiteLink>
                        </div>
                        <div className="footer-mobile footer-icons">
                            <div className="text-title">{t('followSenegence')}</div>
                            <a href={facebookUrl} target="_blank">
                                <AssetDirectImage {...{
                                    config,
                                    src: 'icons/ico_sene_facebook.svg',
                                    className: 'icons-footer',
                                    alt: 'facebook'
                                }} />
                            </a>
                            <a href="https://twitter.com/SeneGence_Intl" target="_blank">
                                <AssetDirectImage {...{
                                    config,
                                    src: 'icons/ico_sene_twitter.svg',
                                    className: 'icons-footer',
                                    alt: 'twitter'
                                }} />
                            </a>
                            <a href="https://www.youtube.com/user/SenegenceMedia" target="_blank">
                                <AssetDirectImage {...{
                                    config,
                                    src: 'icons/ico_sene_youtube.svg',
                                    className: 'icons-footer',
                                    alt: 'youtube'
                                }} />
                            </a>
                            <a href="https://www.instagram.com/senegenceinternational/" target="_blank">
                                <AssetDirectImage {...{
                                    config,
                                    src: 'icons/ico_sene_instagram.svg',
                                    className: 'icons-footer',
                                    alt: 'instagram'
                                }} />
                            </a>
                        </div>
                    </div>
                    <div>
                        <p className="copyright">&copy; {new Date().getFullYear()} {t('senegence')} {t('international')}<sup>®</sup>
                            {t('rightsReserved')}. {version}
                        </p>
                    </div>
                </footer>
            </div>
        )
    }
}

const mapStateToProps = (state: State) => ({
    currentDistributor: selectSenesiteDistributor(state),
    config: selectConfiguration(state)
});

export default withNamespaces('Footer')(connect(mapStateToProps, null)(SeneFooter));
