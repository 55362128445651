import {State} from '../index';
import {
    PaymentType,
    CreditCardPaymentOption,
    CashOrCheckPaymentOption,
    PayPalApiPaymentOption
} from '../../models/payment';

const defaultEmptyList = [];
export const selectPaymentOptions = (state: State) => state && state.paymentOptions;
export const selectPaymentOptionList = (state: State) => state && state.paymentOptions && state.paymentOptions.paymentOptionList;
export const selectPaymentOptionsCountry = (state: State) => state && state.paymentOptions && state.paymentOptions.country;
export const selectCashOrCheckPaymentOption = (state: State) => state && state.paymentOptions && state.paymentOptions.paymentOptionList && state.paymentOptions.paymentOptionList.find(pm => pm.paymentMethodType === PaymentType.CashOrCheck) as CashOrCheckPaymentOption;
export const selectPayPalPaymentOption = (state: State) => state && state.paymentOptions && state.paymentOptions.paymentOptionList && state.paymentOptions.paymentOptionList.find(pm => pm.paymentMethodType === PaymentType.PayPalApi) as PayPalApiPaymentOption;
export const selectCreditCardPaymentOption = (state: State) => state && state.paymentOptions && state.paymentOptions.paymentOptionList && state.paymentOptions.paymentOptionList.find(pm => pm.paymentMethodType === PaymentType.CreditCard) as CreditCardPaymentOption;