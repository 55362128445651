import { SubCategory, Category } from '../models/category';
import { ProductVm } from '../models/product';

export type MaybeCategoryOrSubcategory = Category | SubCategory | null;

/**
 * Typescript typeguard for checking if given category is subcategory
 * @param cat Category
 */
export const isSubCategory = (cat: any): cat is SubCategory => cat && cat.subCategoryName !== undefined;

/**
 * Typescript typeguard for checking if given product is product view model
 * @param product Product
 */
export const isProductVm = (product: any): product is ProductVm => product && product.displayPrice !== undefined;

/**
 * Helper function to determine if 2 category/subCategory is the same.
 */
export const isSameCategory = (catA: MaybeCategoryOrSubcategory, catB: MaybeCategoryOrSubcategory) => {
	if (catA && catB) {
		if (catA.categoryId !== catB.categoryId) {
			return false;
		}

		if (isSubCategory(catA)) {
			return isSubCategory(catB) ? catA.id === catB.id : false;
		} else {
			return isSubCategory(catB) ? false : true;
		}
	} else {
		return !catA && !catB;
	}
}
