import axios from 'axios';
import qs from 'query-string';

import { Product } from './../models/product';
import { Category } from './../models/category';

export const getCategories = (distId: number) => {
    return axios.get<Category[]>(`/api/Distributor/${distId}/Catalog/Categories`).then(res => res.data);
}

export const getProductsForCategory = (distId: number, categoryId: number) => {
    return axios.get<Product[]>(`/api/Distributor/${distId}/Catalog/Categories/${categoryId}/Products`).then(res => res.data);
}

export const getProductsForSubcategory = (distId: number, categoryId: number, subCategoryId: number) => {
    return axios.get<Product[]>(`/api/Distributor/${distId}/Catalog/Categories/${categoryId}/SubCategories/${subCategoryId}/Products`).then(res => res.data);
}

export const getProductById = (distId: number, productId: number) => {
    return axios.get<Product>(`/api/Distributor/${distId}/Catalog/ObtainProductById/${productId}`).then(res => res.data);
}

export const getProductsByIds = (distId: number, productIds: number[]) => {
    const query = qs.stringify({productIds});
    return axios.get<Product[]>(`/api/Distributor/${distId}/Catalog/ObtainProductByIds?${query}`).then(res => res.data);
}

export const getAllProducts = (distId: number, availableOnly: boolean) => {
    const query = qs.stringify({availableOnly});
    return axios.get<Product[]>(`/api/Distributor/${distId}/Catalog/ObtainAllProducts?${query}`).then(res => res.data);
}
