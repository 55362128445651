import React, { memo } from 'react';
import { connect } from 'react-redux';

import { getLang } from '../../utils/helpers';
import { State } from '../../store/';
import { selectCurrency } from '../../store/selectors/catalogSelectors';
import { selectSelectedLanguageCode } from '../../store/selectors/languageSelectors';
import { selectDistributorCountry } from '../../store/selectors/distributorSelectors';

interface CurrencyProps {
    children: number;
    currency: string;
    langCode: string;
    distributorCountry: string;
    currencyForced?: boolean;
}

const Currency = memo(({ children, currency, langCode, distributorCountry, currencyForced }: CurrencyProps) => {
    const locale = langCode || getLang();

    return <>{displayFormat(children, currency, locale, distributorCountry, currencyForced)}</>;
});

export function displayFormat(price, currency, locale, distributorCountry, displayCurrencyCodeForced = false): string {
    
    const currencyLocale = `${locale.split('-')[0]}-${distributorCountry}`;
    const numberFormat = new Intl.NumberFormat(currencyLocale, { style: 'currency', currencyDisplay: 'symbol', currency: currency });
    const formatted = numberFormat.format(price);

    if (displayCurrencyCodeForced) {
        if (formatted.indexOf(currency) > -1) {
            return formatted;
        } else {
            return `${currency.replace('HKD', '')} ${formatted}`
        }
    }

    return formatted;
}

const mapStateToProps = (state: State) => ({
    currency: selectCurrency(state),
    langCode: selectSelectedLanguageCode(state),
    distributorCountry: selectDistributorCountry(state)
});

export default connect(mapStateToProps)(Currency);
