import React from 'react';
import { Collapse, CardBody, Card, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import CustomInput from 'reactstrap/lib/CustomInput';
import i18next from "i18next";

import Spinner from "../../common/Spinner";
import SeneButton from '../../common/inputs/SeneButton';
import * as paymentOptionsApi from '../../../api/paymentOptionsApi';
import { AdminPaymentOption as PaymentOption, PayPalApiPaymentOption } from "../../../models/payment";
import { shouldSwithToMaintenance } from "../../../utils/helpers";

interface PayPalApiPanelProps {
    distributorPaymentOption: PayPalApiPaymentOption;
    toggle(value: number);
    isOpen: boolean;
    panel: number;
    t: i18next.TranslationFunction;
    handlePaymentOptionChanged(changedOption: PaymentOption);
    setIsDirty(isDirty: boolean);
}

interface PayPalVerificationResult {
    success: boolean,
    mode?: string
}

interface PayPalApiPanelState {
    isVerificationInProgress: boolean;
    verificationResult: PayPalVerificationResult | null;
    usesPayPalApi: boolean;
    clientIdError?: string;
    clientSecretError?: string;
}

class PayPalApiPanel extends React.PureComponent<PayPalApiPanelProps, PayPalApiPanelState> {

    state: PayPalApiPanelState = {
        isVerificationInProgress: false,
        verificationResult: null,
        usesPayPalApi: false,
        clientIdError: undefined,
        clientSecretError: undefined
    };

    componentDidMount() {
        const { distributorPaymentOption } = this.props;
        const paymentInitiallySetup = distributorPaymentOption && distributorPaymentOption.selected &&
            distributorPaymentOption.clientId && distributorPaymentOption.clientSecret;
        
        const verificationResult = paymentInitiallySetup ? {success: true} : null;
        this.setState({ usesPayPalApi: this.props.distributorPaymentOption.selected, verificationResult });
    }
    
    componentDidUpdate(prevProps: Readonly<PayPalApiPanelProps>) {
        const clientIdChanged = prevProps.distributorPaymentOption.clientId !== this.props.distributorPaymentOption.clientId;
        const secretChanged = prevProps.distributorPaymentOption.clientSecret !== this.props.distributorPaymentOption.clientSecret;

        if (clientIdChanged || secretChanged) {
            this.setState({verificationResult: null});
        }
    }

    handlePayPalApiCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isSelected = e.target.checked && !!this.state.verificationResult && this.state.verificationResult.success;
        const changedOption = { ...this.props.distributorPaymentOption, selected: isSelected };
        this.props.handlePaymentOptionChanged(changedOption);
        this.setState({ usesPayPalApi: e.target.checked });
    };

    handleClientIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const changedOption = { ...this.props.distributorPaymentOption, clientId: e.target.value.trim(), selected: false };
        this.props.handlePaymentOptionChanged(changedOption);
        this.setState({ verificationResult: null });
        if (this.state.usesPayPalApi && e.target.value == '') {
            this.setState({ clientIdError: this.props.t('payPalApiInvalidClientId')});
        }
        else if (this.state.usesPayPalApi && e.target.value != '') {
            this.setState({ clientIdError: ""});
        }
        this.props.setIsDirty(true);
    };

    handleClientSecretChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const changedOption = { ...this.props.distributorPaymentOption, clientSecret: e.target.value.trim(), selected: false };
        this.props.handlePaymentOptionChanged(changedOption);
        this.setState({ verificationResult: null });
        if (this.state.usesPayPalApi && e.target.value == '') {
            this.setState({ clientSecretError: this.props.t('payPalApiInvalidSecret')});
        }
        else if (this.state.usesPayPalApi && e.target.value != '') {
            this.setState({ clientSecretError: ""});
        }
        this.props.setIsDirty(true);
    };

    handleOnClick = () => {
        this.setState({ isVerificationInProgress: true });
        paymentOptionsApi.verifyPayPalApi(this.props.distributorPaymentOption.clientId, this.props.distributorPaymentOption.clientSecret)
            .then(response => {
                this.setState({ 
                    isVerificationInProgress: false,
                    verificationResult: { success: true, mode: response }, 
                    clientIdError: undefined,
                    clientSecretError: undefined
                });
                this.props.setIsDirty(false);
                this.setState({usesPayPalApi: true});
                const changedOption = { ...this.props.distributorPaymentOption, selected: true };
                this.props.handlePaymentOptionChanged(changedOption);
            })
            .catch(err => {
                shouldSwithToMaintenance(err);
                this.setState({ isVerificationInProgress: false, verificationResult: { success: false } });
                this.props.setIsDirty(true);
                const changedOption = { ...this.props.distributorPaymentOption, selected: false };
                this.props.handlePaymentOptionChanged(changedOption);
            })
    };

    getValidationResult = () => {
        const { t } = this.props;
        const verificationResult = this.state.verificationResult;
        let result = {
            icon: 'fas fa-exclamation-triangle text-warning',
            message: t('payPalVerifySaveMsg')
        };
        if (verificationResult && verificationResult.success) {
            if (verificationResult.mode === "live") {
                result = {
                    icon: 'fas fa-check-circle text-success',
                    message: t('payPalApiVerifySuccessLive')
                };
            } else {
                result = {
                    icon: 'fas fa-check-circle text-warning',
                    message: t('payPalApiVerifySuccessSandbox')
                };
            }

        } else if (verificationResult && !verificationResult.success) {
            result = {
                icon: 'fas fa-times-circle text-danger',
                message: t('invalidPayPalApiVerification')
            };
        }
        return result;
    }

    render() {
        const { t, isOpen, panel, distributorPaymentOption } = this.props;
        if (!distributorPaymentOption) {
            return (<Spinner />);
        }

        const { clientId, clientSecret, selected } = distributorPaymentOption;
        const { isVerificationInProgress } = this.state;
        const validationResult = this.getValidationResult();
        return (
            <>
                <Card>
                    <Row className="align-items-center">
                        <Col xs="8" sm="8" md="8" className="text-left">
                            <a className="btn text-primary" onClick={() => this.props.toggle(panel)}>
                                {
                                    isOpen ?
                                        <i className="fas fa-chevron-down btn text-primary"></i> :
                                        <i className="fas fa-chevron-right btn text-primary"></i>
                                }
                                {t('payPayTitle')}
                            </a>
                        </Col>
                        <Col xs="4" sm="4" md="4" className="text-right">
                            {
                                selected &&
                                <div className="text-primary pr-3" style={{ cursor: "default" }}> {t('activated')} </div>
                            }
                        </Col>
                    </Row>
                </Card>
                <Collapse isOpen={isOpen}>
                    <Card>
                        <CardBody>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h3>{t('payPalapiDocTitle')}</h3>
                                        <Form>
                                            <FormGroup>
                                                <label>{t('payPalapiDocDesc1')}</label>
                                                <p>
                                                    <a href={this.props.t('payPalApiDoc1')} target="_blank" className="mr-2"><i className="fas fa-file-alt mr-2" aria-hidden="true"></i>{t('pdf')}</a>
                                                    <span className="mr-2">|</span>
                                                    <a href={this.props.t('payPalApiVideo1')} target="_blank" className="mr-2"><i className="fas fa-file-video mr-2" aria-hidden="true"></i>{t('video')}</a>
                                                </p>
                                            </FormGroup>
                                            <FormGroup>
                                                <label>{t('payPalapiDocDesc2')}</label>
                                                <p>
                                                    <a href={this.props.t('payPalApiDoc2')} target="_blank" className="mr-2"><i className="fas fa-file-alt mr-2" aria-hidden="true"></i>{t('pdf')}</a>
                                                    <span className="mr-2">|</span>
                                                    <a href={this.props.t('payPalApiVideo2')} target="_blank" className="mr-2"><i className="fas fa-file-video mr-2" aria-hidden="true"></i>{t('video')}</a>
                                                </p>
                                            </FormGroup>
                                            <FormGroup>
                                                <label>{t('payPalapiDocDesc3')}</label>
                                                <p>
                                                    <a href={this.props.t('payPalApiDoc3')} target="_blank" className="mr-2"><i className="fas fa-file-alt mr-2" aria-hidden="true"></i>{t('pdf')}</a>
                                                    <span className="mr-2">|</span>
                                                    <a href={this.props.t('payPalApiVideo3')} target="_blank" className="mr-2"><i className="fas fa-file-video mr-2" aria-hidden="true"></i>{t('video')}</a>
                                                </p>
                                            </FormGroup>
                                            <FormGroup>
                                                <CustomInput
                                                    type="checkbox"
                                                    id="payPalApiCheckBox"
                                                    name="payPalApiCheckBox"
                                                    label={t('payPalapiCheckTitle')}
                                                    onChange={this.handlePayPalApiCheckBoxChange}
                                                    checked={this.state.usesPayPalApi}
                                                    disabled={this.state.verificationResult === null || !this.state.verificationResult.success}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="clientId">{t('payPalapiClientId')}</Label>
                                                <Input type="text" name="clientId" id="clientId" value={clientId || ''} onChange={(e) => this.handleClientIdChange(e)} />
                                                {this.state.clientIdError && <div className="alert alert-danger">{this.state.clientIdError}</div>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="clientSecret">{t('payPalapiClientSecret')}</Label>
                                                <Input type="text" name="clientSecret" id="clientSecret" value={clientSecret || ''} onChange={(e) => this.handleClientSecretChange(e)} />
                                                {this.state.clientSecretError && <div className="alert alert-danger">{this.state.clientSecretError}</div>}
                                            </FormGroup>
                                        </Form>
                                        <SeneButton
                                            onClick={this.handleOnClick}
                                            icon={'fab fa-paypal'}
                                            buttonClass={'secondary'}
                                            loading={isVerificationInProgress}
                                            disabled={!distributorPaymentOption.clientId || !distributorPaymentOption.clientSecret}
                                        >
                                            {t('payPalapiVerifyBtn')}
                                        </SeneButton>
                                        <FormGroup className="mt-2">
                                            <Label>
                                                <i className={validationResult.icon}></i>
                                            </Label>
                                            <Label className="ml-2">
                                                {validationResult.message}
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>
            </>
        )
    }

}

export default PayPalApiPanel;
