import { CreditCardTypeConfiguration, CreditCard } from './../models/payment';
import visa from '../assets/images/payments/Visa.png';
import masterCard from '../assets/images/payments/Master_Card.png';
import americanExpress from '../assets/images/payments/American_Express.png';
import discover from '../assets/images/payments/Discover.png';
import dinersClub from '../assets/images/payments/Diners_Club.png';
import jcb from '../assets/images/payments/JCB.png';


//TODO: name should be unique since id is not
export const allowedCreditCards: CreditCardTypeConfiguration[] = [
    {
        id: 0,
        name: 'Visa',
        imageUrl: visa,
        regex: /^4[0-9]{12}(?:[0-9]{3})?$/
    },
    {
        id: 0,
        name: 'MasterCard',
        imageUrl: masterCard,
        regex: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/
    },
    {
        id: 0,
        name: 'AMEX',
        imageUrl: americanExpress,
        regex: /^3[47][0-9]{13}$/
    },
    {
        id: 0,
        name: 'Discover',
        imageUrl: discover,
        regex: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/
    },
    {
        id: 0,
        name: 'Diners Club',
        imageUrl: dinersClub,
        regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/
    }
    ,
    {
        id: 0,
        name: 'JCB',
        imageUrl: jcb,
        regex: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
    }
];

export const findCreditCardData = (distributorsAllowedCreditCards: CreditCard[], ccNumber: string) => {
    const value = ccNumber.replace(/\D/g, '');
    const isValid = checkLuhn(value);
    const acc = getMatchingCreditCardData(value);
    let result;
    if (isValid && acc) {
        const dacc = distributorsAllowedCreditCards.find(dacc => dacc.name === acc.name);
        if (dacc) {
            result = {
                ...acc,
                id: dacc.id
            };
        }
    }
    return result;
}

export const getMatchingCreditCardData = (ccNumber: string) => {
    let result;
    allowedCreditCards.forEach(cc => {
        if ((cc.regex).test(ccNumber)) {
            result = cc;
        }
    });
    return result;
}

export const getCreditCardImageUrlByName = (ccName: string) => {
    const creditCard = allowedCreditCards.find(acc => acc.name === ccName);
    return creditCard ? creditCard.imageUrl : '';
}

export const isCreditCardValid = (distributorsAllowedCreditCards: CreditCard[], ccNumber: string) => {
    const value = ccNumber.replace(/\D/g, '');
    const isValid = checkLuhn(value);
    const acc = getMatchingCreditCardData(value);
    let result = false;
    if (isValid && acc) {
        const dacc = distributorsAllowedCreditCards.find(dacc => dacc.name === acc.name);
        result = dacc ? true : false;
    }
    return result;
}

export const checkLuhn = (value: string) => {
    let sum = 0;
    let shouldDouble = false;
    // loop through values starting at the rightmost side
    for (let i = value.length - 1; i >= 0; i--) {
        let digit = parseInt(value.charAt(i));

        if (shouldDouble) {
            if ((digit *= 2) > 9) digit -= 9;
        }

        sum += digit;
        shouldDouble = !shouldDouble;
    }
    return (sum % 10) == 0;
}

export const isCvvValid = (distributorsAllowedCreditCards: CreditCard[], ccNumber: string, cvvNumber: string) => {
    const ccData = findCreditCardData(distributorsAllowedCreditCards, ccNumber);
    let result = false;
    if (ccData) {
        if (ccData.name === 'AMEX') {
            result = (/^\d{4}$/).test(cvvNumber);
        } else {
            result = (/^\d{3}$/).test(cvvNumber);
        }
    }
    return result;
}

export const checkDigits = (value: string) => {
    const regex = new RegExp('^(0|[0-9][0-9]*)$');
    return regex.test(value);
}