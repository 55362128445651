import React from 'react';
import ReactPaginate from 'react-paginate';
import i18n from '../../i18n';
interface SenePaginatorProps {
    selectedPage: number;
    pageCount: number;
    onPageChange(data: any);
    pageRangeDisplayed?: number;
    t: i18n.TranslationFunction;
}

const SenePaginator = ({
    selectedPage,
    pageCount,
    onPageChange,
    pageRangeDisplayed = 6,
    t
}: SenePaginatorProps) => {
    return (
        <div className="row">
            <div className="col-md-12" style={{ textAlign: 'center' }}>
                <div style={{ margin: '10px auto 0 auto', display: 'inline-flex' }}>
                    <ReactPaginate previousLabel={"Previous"}
                        nextLabel={t('next')}
                        breakLabel={<a href="">...</a>}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={pageRangeDisplayed}
                        onPageChange={onPageChange}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        pageClassName="page-item not-in-mobile"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        initialPage={0}
                        forcePage={selectedPage - 1} />
                </div>
            </div>
        </div>
    )
};

export default SenePaginator;
