import {State} from './../index';
import {CountryLanguage, Languages} from "../../models/language";

export const selectLanguageState = (state: State): Languages => {
    return state.languages;
}

export const selectAvailableLanguages = (state: State) : CountryLanguage[] => {
    const languageState = selectLanguageState(state);

    if (languageState) {
        return languageState.availableLanguages;
    }
    return [];
};

export const selectSelectedLanguage = (state: State) : CountryLanguage | null => {
    const languageState = selectLanguageState(state);

    if (languageState) {
        return languageState.selectedLanguage;
    }
    return null;
};

export const selectSelectedLanguageCode = (state: State) : string => {
    const selectedLanguage = selectSelectedLanguage(state);

    if (selectedLanguage) {
        return selectedLanguage.code;
    }
    return '';
};

export const selectSelectedLanguageAlpha2Code = (state: State) : string => {
    const selectedLanguage = selectSelectedLanguage(state);

    if (selectedLanguage) {
        return selectedLanguage.languageAlpha2Code;
    }
    return '';
};
