import { SeneSiteInfo, DistributorProfile, HandlingInfo } from './../models/distributor';
import { Thunk } from './../store/index';
import * as distributorApi from '../api/distributorApi';
import { setLoading } from './ui';
import { setBlockUi } from './ui';
import { selectSenesiteDistributor } from './../store/selectors/distributorSelectors';
import { AdminPaymentOption as PaymentOption } from "../models/payment";
import { ShippingInfo } from '../models/checkout';
import * as toast from './../utils/toastHelper';
import { ContactMeData } from '../models/contact';
import { ActionToastProperties } from '../models/ui';
import { selectIpAddress } from "../store/selectors/ipAddressSelector";
import { shouldSwithToMaintenance } from "../utils/helpers";

export enum DistributorActionTypes {
    getDistBySeneSiteSuccess = '[Distributor] Get Distributor By SeneSite Success',
    getDistBySeneSiteError = '[Distributor] Get Distributor By SeneSite Error',
    getDistByIdSuccess = '[Distributor] Get Distributor by DistId Success',
    getDistByIdError = '[Distributor] Get Distributor by DistId Error',
    updateDistributorSeneSiteInfo = '[Distributor] Update SenSiteInfo success',
    updateDistributorSeneSiteInfoError = '[Distributor] Update SenSiteInfo error',
    updateDistributorHandlingInfoSuccess = '[Distributor] Update HandlingInfo success',
    updateDistributorShippingInfoSuccess = '[Distributor] Update ShippingInfo success',
    updateDistributorHandlingInfoError = '[Distributor] Update HandlingInfo error',
    updateDistributorShippingInfoError = '[Distributor] Update ShippingInfo error',
    updateDistributorMyProfile = '[Distributor] Update DistributorProfile success',
    updateDistributorMyProfileError = '[Distributor] Update DistributorProfile error',
    uploadProfileImage = '[Distributor] Profile Image Upload success',
    uploadProfileImageError = '[Distributor] Profile Image Upload error',
    postContactMessageSuccess = '[Distributor] Post Contact Message Success',
    postContactMessageError = '[Distributor]  Post Contact Message Error',
    deleteDistributorHandlingInfoSuccess = '[Distributor] Delete HandlingInfo success',
    deleteDistributorHandlingInfoError = '[Distributor] Delete HandlingInfo error',
    getInstagramFeedSuccess = '[Instagram] Get Instagram Feed Success',
    getInstagramFeedError = '[Instagram] Get Instagram Feed Error',
    updateDistributorSeneSiteURLState = '[Distributor] Update Distributor SeneSite URL in Redux'
}

export const updateDistributorSeneSiteURLState = (seneSiteUrl: string): Thunk => (dispatch, getState) => {

    dispatch({ type: DistributorActionTypes.updateDistributorSeneSiteURLState, seneSiteUrl });
};

export const getDistributorFromSeneSiteUrl = (seneSiteUrl: string): Thunk => (dispatch, getState) => {
    const state = getState();

    if (state.distributor
        && state.distributor.seneSiteInfo
        && state.distributor.seneSiteInfo.seneSiteUrl
        && state.distributor.seneSiteInfo.seneSiteUrl === seneSiteUrl) {
        return Promise.resolve(state.distributor);
    }

    dispatch(setLoading(true))
    return distributorApi.getDistributorFromSeneSiteUrl(seneSiteUrl)
        .then(distributor => {
            dispatch(setLoading(false));
            dispatch({ type: DistributorActionTypes.getDistBySeneSiteSuccess, distributor });
            return distributor;
        })
        .catch((exp) => {
            shouldSwithToMaintenance(exp);
            dispatch(setLoading(false));
            dispatch({ type: DistributorActionTypes.getDistBySeneSiteError })
        });
};

export const getDistributorFromDistId = (distId: number): Thunk => (dispatch, getState) => {
    const state = getState();
    if (state.distributor && state.distributor.dist_ID &&
        state.distributor.dist_ID === distId && state.distributor.isPublicData == false) {
        return Promise.resolve(state.distributor);
    }

    return distributorApi.getDistributorFromDistId(distId).then(distributor => {
        dispatch({ type: DistributorActionTypes.getDistByIdSuccess, distributor });
        return distributor;
    }).catch(exp => {
        shouldSwithToMaintenance(exp);
        dispatch({ type: DistributorActionTypes.getDistByIdError })
    });
}

export const updateDistributorSeneSiteInfo = (distId: number, seneSiteInfo: SeneSiteInfo, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    return distributorApi.updateDistributorSeneSiteInfo(distId, seneSiteInfo)
        .then(res => {
            const seneSiteInfo = res.data.seneSiteInfo as SeneSiteInfo;
            toast.success(toastProps);
            dispatch({ type: DistributorActionTypes.updateDistributorSeneSiteInfo, seneSiteInfo });
        }).catch(exp => {
            shouldSwithToMaintenance(exp);
            toast.error(toastProps);
            dispatch({ type: DistributorActionTypes.updateDistributorSeneSiteInfoError })
        });
};

export const updateDistributorHandlingInfo = (distId: number, handlingInfo: HandlingInfo, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    return distributorApi.updateDistributorHandlingInfo(distId, handlingInfo)
        .then(res => {
            toast.success(toastProps);
            dispatch({ type: DistributorActionTypes.updateDistributorHandlingInfoSuccess, handlingInfo });
        }).catch(exp => {
            shouldSwithToMaintenance(exp);
            toast.error(toastProps);
            dispatch({ type: DistributorActionTypes.updateDistributorHandlingInfoError })
        });

};

export const deleteDistributorHandlingInfo = (distId: number, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    return distributorApi.deleteDistributorHandlingInfo(distId)
        .then(res => {
            toast.success(toastProps);
            dispatch({ type: DistributorActionTypes.deleteDistributorHandlingInfoSuccess, res });
        }).catch(exp => {
            shouldSwithToMaintenance(exp);
            toast.error(toastProps);
            dispatch({ type: DistributorActionTypes.deleteDistributorHandlingInfoError })
        });

};

export const updateDistributorShippingInfo = (distId: number, shippingInfo: ShippingInfo[], toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    return distributorApi.updateDistributorShippingInfo(distId, shippingInfo)
        .then(res => {
            toast.success(toastProps);
            dispatch({ type: DistributorActionTypes.updateDistributorShippingInfoSuccess, shippingInfo });
        }).catch(exp => {
            shouldSwithToMaintenance(exp);
            toast.error(toastProps);
            dispatch({ type: DistributorActionTypes.updateDistributorShippingInfoError })
        });
};

export const updateDistributorMyProfile = (distId: number, distributorProfile: DistributorProfile, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    dispatch(setBlockUi(true));
    return distributorApi.updateDistributorMyProfile(distId, distributorProfile)
        .then(res => {
            toast.success(toastProps);
            dispatch({ type: DistributorActionTypes.updateDistributorMyProfile, distributorProfile });
            dispatch(setBlockUi(false));
        }).catch(exp => {
            shouldSwithToMaintenance(exp);
            toast.error(toastProps);
            dispatch({ type: DistributorActionTypes.updateDistributorMyProfileError })
            dispatch(setBlockUi(false));
        });
};

export const uploadProfileImage = (distId: number, file: any, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {

    dispatch(setBlockUi(true));
    return distributorApi.uploadProfileImage(distId, file)
        .then(res => {
            toast.success(toastProps);
            dispatch({ type: DistributorActionTypes.uploadProfileImage, res });
            dispatch(setBlockUi(false));
        }).catch(exp => {
            shouldSwithToMaintenance(exp);
            toast.error(toastProps);
            dispatch({ type: DistributorActionTypes.uploadProfileImageError })
            dispatch(setBlockUi(false));
        });

};

export const postContactMessage = (contactMeData: ContactMeData, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    const state = getState();
    const distributor = selectSenesiteDistributor(state);
    const ipAddress = selectIpAddress(state);
    contactMeData.ipAddress = ipAddress;
    if (!distributor) {
        return;
    }

    dispatch(setLoading(true));
    return distributorApi.postContactMessage(distributor.dist_ID, contactMeData)
        .then(res => {
            toast.success(toastProps);
            dispatch(setLoading(false));
            dispatch({ type: DistributorActionTypes.postContactMessageSuccess, res });
            return res;
        })
        .catch((exp) => {
            shouldSwithToMaintenance(exp);
            toast.error(toastProps);
            dispatch(setLoading(false));
            dispatch({ type: DistributorActionTypes.postContactMessageError });
        });
};

export const getInstagramFeed = (): Thunk => (dispatch, getState) => {
    const state = getState();
    if (!state.distributor) { return; }
    return distributorApi.getInstagramFeed(state.distributor.dist_ID).then(res => {
        dispatch({ type: DistributorActionTypes.getInstagramFeedSuccess, res });
        return res;
    }).catch(exp => {
        shouldSwithToMaintenance(exp);
        dispatch({ type: DistributorActionTypes.getInstagramFeedError })
        return [];
    });
}

