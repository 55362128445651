import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { bindActionCreators } from "redux";

import './SeneFeesPage.scss';
import * as uiActions from '../../../actions/ui';
import * as distributorActions from '../../../actions/distributor';
import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';
import withAdminPageTitle from '../withAdminPageTitle';
import { DistributorState } from '../../../reducers/distributor';
import Spinner from '../../common/Spinner';
import { SeneShippingFeesPage } from './ShippingFeesPage';
import { SeneHandlingFeesPage } from './HandlingFeesPage';
import { HandlingInfo, ShippingInfo } from '../../../models/distributor';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { ActionToastProperties } from '../../../models/ui';
import { shouldSwithToMaintenance } from "../../../utils/helpers";

interface SeneFeesStateProps {
    distributor: DistributorState;
}

interface SeneFeesState {
    activeTab: 'shipping' | 'handling';
}

interface SeneFeesDispatchProps {
    updateHandlingInfo(distId: number, handlingInfo: HandlingInfo, toastProps?: ActionToastProperties);
    updateShippingInfo(distId: number, shippingInfo: ShippingInfo[], toastProps?: ActionToastProperties);
    deleteHandlingInfo(distId: number, toastProps?: ActionToastProperties);
    setBlockUi(blockUi: boolean);
}

type SeneFeesProps = SeneFeesStateProps & SeneFeesDispatchProps & WithNamespaces;


export class SeneFeesPage extends React.Component<SeneFeesProps, SeneFeesState> {

    state: SeneFeesState = {
        activeTab: 'shipping'
    }

    saveHandlingInfo = (handlingInfo: HandlingInfo) => {
        if (this.props.distributor) {
            this.props.setBlockUi(true);
            this.props.updateHandlingInfo(
                this.props.distributor.dist_ID,
                handlingInfo,
                { success: this.props.t('updateDistributorHandlingInfoSuccess'), error: this.props.t('updateDistributorHandlingInfoError') }
            )
                .then(() => this.props.setBlockUi(false))
                .catch((exp) => {
                    shouldSwithToMaintenance(exp);
                    this.props.setBlockUi(false);
                });
        
        }
    }

    saveShippingInfo = (shippingInfo: ShippingInfo[]) => {
        if (this.props.distributor) {
            this.props.setBlockUi(true);
            this.props.updateShippingInfo(
                this.props.distributor.dist_ID,
                shippingInfo,
                { success: this.props.t('updateDistributorShippingInfoSuccess'), error: this.props.t('updateDistributorShippingInfoError') }
            )
                .then(() => this.props.setBlockUi(false))
                .catch((exp) => {
                    shouldSwithToMaintenance(exp);
                    this.props.setBlockUi(false);
                });
        }
    }

    deleteHandlingInfo = () => {
        if (this.props.distributor) {
            this.props.setBlockUi(true);
            this.props.deleteHandlingInfo(
                this.props.distributor.dist_ID,
                { success: this.props.t('updateDistributorHandlingInfoSuccess'), error: this.props.t('updateDistributorHandlingInfoError') }
            )
                .then(() => this.props.setBlockUi(false))
                .catch((exp) => {
                    shouldSwithToMaintenance(exp);
                    this.props.setBlockUi(false);
                });
        }
    }

    render() {
        if (!this.props.distributor) {
            return <Spinner />;
        }
        else {
            const { t } = this.props;

            return <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            style={{ cursor: 'pointer' }}
                            className={`${this.state.activeTab === 'shipping' ? 'active' : ''}`}
                            onClick={() => this.setState({ activeTab: 'shipping' })}>
                            &nbsp;{t('tabShippingFees')}&nbsp;
                        </NavLink>
                    </NavItem>
                    <NavItem >
                        <NavLink
                            style={{ cursor: 'pointer' }}
                            className={`${this.state.activeTab === 'handling' ? 'active' : ''}`}
                            onClick={() => this.setState({ activeTab: 'handling' })}>
                            &nbsp;{t('tabHandlingFees')}&nbsp;
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="shipping">
                        <div className="row">
                            <div className="col-md-12">
                                <SeneShippingFeesPage t={t}
                                    shippingInfo={this.props.distributor.seneSiteInfo.shippingInfo}
                                    saveShippingInfo={this.saveShippingInfo} />
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId="handling">
                        <div className="row">
                            <div className="col-md-12">
                                <SeneHandlingFeesPage t={t}
                                    handlingInfo={this.props.distributor.seneSiteInfo.handlingInfo}
                                    saveHandlingInfo={this.saveHandlingInfo}
                                    deleteHandlingInfo={this.deleteHandlingInfo} />
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        distributor: selectSenesiteDistributor(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateHandlingInfo: distributorActions.updateDistributorHandlingInfo,
        updateShippingInfo: distributorActions.updateDistributorShippingInfo,
        deleteHandlingInfo: distributorActions.deleteDistributorHandlingInfo,
        setBlockUi: uiActions.setBlockUi
    }
        , dispatch);
}

export default withAdminPageTitle('feestaxes')(withNamespaces('BusinessGeneral')(connect(mapStateToProps, mapDispatchToProps)(SeneFeesPage)))
