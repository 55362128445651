export class APIConstants {

    IMAGESHOSTNAME: string;
    IMAGESDIRECT: string;
    IMAGESPRODUCTS: string;
    RESIZEDIMAGESPRODUCTSDIRECT: string;
    IMAGESPRODUCTSDIRECT: string;
    IMAGESCUSTOMERS: string;
    IMAGESCUSTOMERSDIRECT: string;
    IMAGES: string;
    LOCALRESIZERPRODUCTS: string;
    AZURERESIZERRESIZERPRODUCTS: string;

    constructor(imagesHostName: string) {
        this.IMAGESHOSTNAME = imagesHostName;
        this.IMAGESDIRECT = `${this.IMAGESHOSTNAME}images/`;
        this.RESIZEDIMAGESPRODUCTSDIRECT = `${this.IMAGESDIRECT}ResizedImages/`;
        this.IMAGESPRODUCTSDIRECT = `${this.IMAGESDIRECT}products/`;
        this.IMAGESCUSTOMERSDIRECT = `${this.IMAGESDIRECT}members/`;
        this.AZURERESIZERRESIZERPRODUCTS = `${this.IMAGESHOSTNAME}api/ImageProcess`;

        this.IMAGESCUSTOMERS = APIConstants.IMAGESCUSTOMERS;
        this.IMAGESPRODUCTS = APIConstants.IMAGESPRODUCTS;
        this.IMAGES = APIConstants.IMAGES;
        this.LOCALRESIZERPRODUCTS = APIConstants.LOCALRESIZERPRODUCTS;
    }

    public static readonly IMAGESPRODUCTS = "products/";
    public static readonly IMAGESCUSTOMERS = "members/";
    public static readonly IMAGES = "azure/images/";
    public static readonly LOCALRESIZERPRODUCTS = "http://localhost:7071/api/ImageProcess";
}

export const cartLocalStorageKey = 'seneCart';
export const customerNotesLocalStorageKey = 'seneCustomerNotes';
export const oldPayPalDeactivationDate = new Date("2019-05-31");
export const distributorDefaultLanguageLocalStorageKey = 'distributorDefaultLanguageLocalStorageKey';
export const cancelledVerifySeneSiteNameString = 'verifySeneSiteName is cancelled';
