import React from 'react';
import { CallbackComponent } from 'redux-oidc';
import { RouteComponentProps } from 'react-router';
import * as qs from 'query-string';

import { UserManager } from '../../utils/auth/UserManager';
import * as callbackStorage from '../../utils/auth/CallbackStorage';
import Spinner from '../common/Spinner';

class CallbackPage extends React.Component<RouteComponentProps> {
    redirect = async (user) => {
        let callbackUrl = callbackStorage.getCallbackUrl();

        if (!callbackUrl) {
            const query = qs.parse(this.props.location.search);
            callbackUrl = query.url as string || '/';
        }

        this.props.history.push(callbackUrl);
    }

    render() {
        return (
            <CallbackComponent
                userManager={UserManager}
                successCallback={this.redirect}
                errorCallback={this.redirect}
            >
                <Spinner />
            </CallbackComponent>
        )
    }
}

export default CallbackPage;
