import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Button } from 'reactstrap';

import { UserManager } from '../../../utils/auth/UserManager';
import SenePageHeader from '../../common/headers/SeneGradientHeader';
import { setCallbackUrl } from "../../../utils/auth/CallbackStorage";
import { connect } from "react-redux";
import { selectSeneSiteUrl} from "../../../store/selectors/distributorSelectors";

interface AccountCreatedProps {
    seneSiteUrl
}

class AccountCreatedPage extends React.Component<AccountCreatedProps & WithNamespaces> {
    loginRedirect = () => {
        setCallbackUrl(this.props.seneSiteUrl);
        return UserManager.signinRedirect({ui_locales: this.props.i18n.language.toLowerCase()});
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <SenePageHeader>{t('successMsg')}</SenePageHeader>
                <Button
                    style={{display: 'block', margin: '2rem auto'}}
                    color="primary"
                    onClick={this.loginRedirect}
                >
                    {t('login')}
                </Button>
            </>
        )
    }
}

const mapStateToProps = (state): AccountCreatedProps => ({
    seneSiteUrl: selectSeneSiteUrl(state)
});

export default withNamespaces('NewAccount')(connect(mapStateToProps)(AccountCreatedPage));
