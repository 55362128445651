import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import memoize from 'memoize-one';
import ReactExport from "react-data-export";
import Modal from 'react-modal';

import { DistributorState } from '../../../reducers/distributor';
import { InventoryItem } from '../../../models/inventory';
import SeneButton from '../../common/inputs/SeneButton';
import './SeneInventoryDesktop.scss';
import SeneNumberInput from '../../common/inputs/SeneNumberInput';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export interface SeneInventoryDesktopStateProps {
    t: any;
    distributor: DistributorState;
    saleRegardless: boolean;
    inventory: InventoryItem[];
    itemSkus: Set<string>;
    updateQty(value: number, identifier: string);
    handleSaleRegardless(event: React.ChangeEvent<HTMLInputElement>)
    handleFileSelected(event: any);
    handleFileImport();
    handleSave();
}

export interface SeneInventoryDesktopState {
    enableFilters: boolean;
    filters: any[];
    isImportModalOpen: boolean;
}

class SeneInventoryDesktop extends React.PureComponent<SeneInventoryDesktopStateProps, SeneInventoryDesktopState> {

    state: SeneInventoryDesktopState = {
        enableFilters: false,
        filters: [],
        isImportModalOpen: false
    }

    toggleFilters = () => {
        this.setState({ enableFilters: !this.state.enableFilters });
    }

    resetFilter = memoize(
        (filters, enableFilters) => {
            if (!enableFilters) {
                filters.forEach(filter => filter(''));
                filters = [];
            }
            return filters;
        }
    )

    applyFilter = (filterToApply: Function) => {
        if (this.state.enableFilters) {
            return filterToApply({
                getFilter: (filter) => {
                    this.state.filters.push(filter);
                }
            });
        } else {
            return null;
        }
    }

    getRowClass = (row) => {
        return (this.props.itemSkus.has(row.sku) ? 'table-primary' : null);
    }

    handleOpen = () => {
        this.setState({ isImportModalOpen: true });
    }

    handleClose = () => {
        this.setState({ isImportModalOpen: false });
    }

    handleModalSave = () => {
        this.props.handleFileImport();
        this.setState({ isImportModalOpen: false });
    }

    renderModal = () => {
        const { t } = this.props;

        return (
            <Modal
                isOpen={this.state.isImportModalOpen}
                onRequestClose={this.handleClose} className="modal-dialog modal-lg">
                <div className="modal-content overflow-scroll">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('uploadExcel')}</h5>
                        <button type="button" className="close" onClick={this.handleClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input onChange={this.props.handleFileSelected} type="file" multiple accept=".xlsx" />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.handleClose}>{t('close')}</button>
                        <SeneButton
                            onClick={this.handleModalSave}
                        >
                            {t('save')}
                        </SeneButton>
                    </div>
                </div>
            </Modal>
        )
    }

    render() {

        const {
            t,
            saleRegardless,
            inventory
        } = this.props;

        this.resetFilter(this.state.filters, this.state.enableFilters);

        const columns = [
            {
                dataField: 'sku',
                text: t('sku'),
                filter: this.applyFilter(textFilter),
                sort: true,
                style: () => ({ width: '100px' }),
                headerStyle: () => ({ width: '100px' })
            },
            {
                dataField: 'productName',
                text: t('productName'),
                filter: this.applyFilter(textFilter),
                sort: true,
                style: () => ({ flex: 1 }),
                headerStyle: () => ({ flex: 1 })
            },
            {
                dataField: 'qty',
                text: t('qty'),
                style: () => ({ width: '200px' }),
                headerStyle: () => ({ width: '200px' }),
                headerClasses: 'text-center',
                formatter: (cell, row, rowIndex) => (
                    <div className="number-input--compact" key={row.sku}>
                        <SeneNumberInput
                            identifier={row.sku}
                            value={parseInt(cell.toString())}
                            updateOnBlur={true}
                            onChange={this.props.updateQty} />
                    </div>
                )
            }
        ];

        return (
            <div className="container">

                <div className="row mt-4"> {t('manageInvDesc')} </div>

                <div className="row mt-4"> {t('preferToSellDesc')} </div>

                <div className="row mt-4 border border-dark rounded">
                    <div className="col-12">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={saleRegardless} onChange={this.props.handleSaleRegardless} id="saleRegardless" name="saleRegardless"/>
                            <label className="form-check-label font-weight-bold" htmlFor="saleRegardless"> {t('regardlessSale')} </label>
                        </div>
                    </div>
                    <div className="col-12 pl-5 text-danger"> {t('outOfStockDesc')} </div>
                    <div className="col-12 pl-5 text-danger"> {t('discontinuedDesc')} </div>
                </div>

                <div className="row mt-4">
                    <div className="col-xs-5 col-lg-7 pl-0">
                        <h6 className="text-primary mt-0"> <i className="fa fa-exclamation-circle" aria-hidden="true"></i> {t('tipDesc')} </h6>
                    </div>
                    <div className="col-xs-7 col-lg-5">
                        <div className="row justify-content-sm-between justify-content-lg-around">

                            <ExcelFile
                                filename="MyInventory"
                                element={(
                                    <SeneButton
                                        buttonClass={'secondary'}
                                        icon={'fa-file-excel'}
                                        onClick={() => { }}>{t('export')}
                                    </SeneButton>
                                )}>
                                <ExcelSheet data={this.props.inventory} name="Inventory" >
                                    <ExcelColumn label="SKU" value="sku" />
                                    <ExcelColumn label="Product Name" value="productName" />
                                    <ExcelColumn label="Qty" value="qty" />
                                </ExcelSheet>
                            </ExcelFile>

                            <SeneButton
                                buttonClass={'secondary'}
                                onClick={this.handleOpen}>{t('import')}
                            </SeneButton>

                            <SeneButton
                                buttonClass={'primary'}
                                onClick={this.toggleFilters}>{t('showFilters')}
                            </SeneButton>
                        </div>
                    </div>
                </div>

                <div className="row mt-4 responsive-inventory-table">
                    <BootstrapTable
                        keyField='sku'
                        classes="table--scrollable"
                        data={inventory}
                        columns={columns}
                        condensed={true}
                        bootstrap4={true}
                        bordered={false}
                        rowClasses={this.getRowClass}
                        filter={filterFactory()}
                    />
                    <footer className="bottom-bar container">
                        <div className="row">
                            <div className="col-2 offset-10">
                                <SeneButton
                                    buttonClass={'secondary'}
                                    className={'col-12'}
                                    disabled={this.props.itemSkus.size === 0}
                                    onClick={this.props.handleSave}>{t('save')}
                                </SeneButton>
                            </div>
                        </div>
                    </footer>
                </div>

                {this.renderModal()}

            </div>
        )
    }
}

export default SeneInventoryDesktop;
