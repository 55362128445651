import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';

import * as distributor from './distributor';
import * as ui from './ui';
import * as categories from './categories';
import * as products from './products';
import * as administration from './administration';
import * as blog from './blog';
import * as cart from './cart';
import * as checkout from './checkout';
import * as marketing from './marketing';
import * as signalR from './signalR';
import * as regions from './regions';
import * as previousOrder from './previousOrder';
import * as paymentOptions from './paymentOptions';
import * as languages from './languages';
import * as user from './user';
import * as orders from './orders';
import * as configuration from './configuration';
import * as ipAddress from './ipAddress';

const reducers = {
    distributor: distributor.reducer,
    ui: ui.reducer,
    auth: oidcReducer,
    categories: categories.reducer,
    products: products.reducer,
    administration: administration.reducer,
    blog: blog.reducer,
    cart: cart.reducer,
    checkout: checkout.reducer,
    marketing: marketing.reducer,
    signalRid: signalR.reducer,
    regions: regions.reducer,
    previousOrder: previousOrder.reducer,
	paymentOptions: paymentOptions.reducer,
    languages: languages.reducer,
    user: user.reducer,
    orders: orders.reducer,
    configuration: configuration.reducer,
    ipAddress: ipAddress.reducer,
};

export const createRootReducer = (history) => combineReducers({
    ...reducers,
    router: connectRouter(history)
});

/** Default export the reducer with empty object history */
export default createRootReducer({});
