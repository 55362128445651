import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router';
import { connect } from 'react-redux';

import { withNamespaces, WithNamespaces } from 'react-i18next';

import withDistributorGuard from '../common/guards/withDistributorGuard'

import AppHeader from './header/AppHeader';
import SeneFooter from '../common/SeneFooter';
import SeneSite from './SeneSite';
import NotFound from '../common/404';
import IndexPage from './IndexPage';
import { selectConfiguration } from "../../store/selectors/configurationSelectors";
import { Configuration } from "../../models/configuration";

interface AppLayoutProps {
    config: Configuration | null;
}

let cookieBlockerFlag = false;

export class AppLayout extends React.Component<AppLayoutProps & WithNamespaces> {

    componentDidUpdate() {

        let lang = this.props!.lng!;

        if (lang.toUpperCase() === "EN-US" && cookieBlockerFlag == false) {
            cookieBlockerFlag = true;
            const script = document.createElement("script");
            script.src = this.props!.config!.cookieBlockerUrl;
            script.type = "text/javascript"
            document.head.appendChild(script);
        }

    }

    render() {
        return <>
            <AppHeader />
            <div className="app-container">
                <div className="app-content">
                    <Switch>
                        <Route path='/:seneSiteUrl' component={withDistributorGuard(SeneSite)} />
                        <Route path='/' component={IndexPage} />
                        <Route path='**' component={NotFound} />
                    </Switch>
                </div>
                <SeneFooter />
            </div>
        </>
    }
}



const mapStateToProps = (state) => ({
    config: selectConfiguration(state)
});

export default withNamespaces("AppLayout")(connect(mapStateToProps, null)(AppLayout));;

/*    export default withNamespaces("SellSenegencePage")(connect(mapStateToProps, null)(SeneSellPage));*/