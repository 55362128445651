import React from 'react';
import { Collapse, CardBody, Card, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import i18next from "i18next";

import {
    CreditCard,
    CreditCardPaymentOption, AdminPaymentOption as PaymentOption
} from '../../../models/payment';
import Spinner from "../../common/Spinner";

interface CreditCardPanelProps {
    distributorPaymentOption: CreditCardPaymentOption;
    toggle(value: number);
    isOpen: boolean;
    panel: number;
    t: i18next.TranslationFunction,
    handlePaymentOptionChanged(changedOption: PaymentOption);
    creditCardErrors: string;
}

interface CreditCardPanelState {
    // This stores the original values for the distributors payment option. This can be used to prepopulate fields when
    // distributor switches from merchants back and forth
    storedPaymentOption: CreditCardPaymentOption | null;
}

class CreditCardPanel extends React.Component<CreditCardPanelProps, CreditCardPanelState> {

    state: CreditCardPanelState = {
        storedPaymentOption: null
    };

    isChecked = (id: number, name: string) => {
        if (!this.props.distributorPaymentOption) {
            return false;
        }
        const result = this.props.distributorPaymentOption.selectedCreditCards.find(cc => cc.id === id || cc.name === name);
        return !!result;
    };

    handleCreditCardChange = (e: React.ChangeEvent<HTMLInputElement>, creditCard: CreditCard) => {
        let newCreditCards;
        if (e.target.checked) {
            newCreditCards = [...this.props.distributorPaymentOption.selectedCreditCards, creditCard];
        } else {
            newCreditCards = this.props.distributorPaymentOption.selectedCreditCards.filter(sc => sc.name !== creditCard.name);
        }

        const changedOption = {
            ...this.props.distributorPaymentOption,
            selectedCreditCards: newCreditCards,
            selected: newCreditCards.length > 0
        };
        this.props.handlePaymentOptionChanged(changedOption);
    };

    handleMerchantChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const storedPaymentOption = this.state.storedPaymentOption;
        const newlySelectedMerchantId = +(e.target.value);
        const selectedMerchantAccount = this.props.distributorPaymentOption.availableMerchantAccounts.find(m => m.id === newlySelectedMerchantId);

        if (storedPaymentOption && selectedMerchantAccount && storedPaymentOption.selectedMerchant && storedPaymentOption.selectedMerchant.id === newlySelectedMerchantId) {
            //Pre populate the fields if this merchant is currently used by the distributor
            selectedMerchantAccount.fields = storedPaymentOption.selectedMerchant.fields;
        }

        const changedOption = {
            ...this.props.distributorPaymentOption,
            selectedMerchant: selectedMerchantAccount
        };
        this.props.handlePaymentOptionChanged(changedOption);
    };

    handleMerchantFieldChange = (e: React.ChangeEvent<HTMLInputElement>, changedFieldKey: string) => {
        const selectedMerchantAccount = this.props.distributorPaymentOption.selectedMerchant;

        const fields = { ...selectedMerchantAccount.fields };
        fields[changedFieldKey] = { ...selectedMerchantAccount.fields[changedFieldKey], value: e.target.value };
        const newMerchantAccount = { ...selectedMerchantAccount, fields };
        const changedOption = {
            ...this.props.distributorPaymentOption,
            selectedMerchant: newMerchantAccount
        };
        this.props.handlePaymentOptionChanged(changedOption);

    };

    componentDidUpdate(prevProps: CreditCardPanelProps) {
        if (!this.state.storedPaymentOption && this.props.distributorPaymentOption) {
            this.setState({ storedPaymentOption: this.props.distributorPaymentOption });
        }
    }

    render() {
        const { t, isOpen, panel, distributorPaymentOption } = this.props;
        if (!distributorPaymentOption) {
            return (<Spinner />);
        }
        const { selected } = distributorPaymentOption;

        const merchantSelectValue = this.props.distributorPaymentOption && this.props.distributorPaymentOption.selectedMerchant && this.props.distributorPaymentOption.selectedMerchant.id || '';
        const merchantAccount = this.props.distributorPaymentOption && this.props.distributorPaymentOption.selectedMerchant;

        return (
            <>
                <Card>
                    <Row className="align-items-center">
                        <Col xs="8" sm="8" md="8" className="text-left">
                            <a className="btn text-primary" onClick={() => this.props.toggle(panel)}>
                                {
                                    isOpen ?
                                        <i className="fas fa-chevron-down btn text-primary"></i> :
                                        <i className="fas fa-chevron-right btn text-primary"></i>
                                }
                                {t('creditCardTitle')}
                            </a>
                        </Col>
                        <Col xs="4" sm="4" md="4" className="text-right">
                            {
                                selected &&
                                <div className="text-primary pr-3" style={{ cursor: "default" }}> {t('activated')} </div>
                            }
                        </Col>
                    </Row>
                </Card>
                <Collapse isOpen={isOpen}>
                    <Card>
                        <CardBody>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <Form>
                                            <FormGroup check>
                                                <Label> {t('creditCardTypeInfo')} </Label>
                                            </FormGroup>
                                            {
                                                distributorPaymentOption.availableCreditCards.map(cc => {
                                                    return (
                                                        <FormGroup check key={cc.id}>
                                                            <Label check className="font-weight-bold">
                                                                <Input type="checkbox" name={(cc.id).toString()}
                                                                    checked={this.isChecked(cc.id, cc.name)}
                                                                    onChange={(e) => this.handleCreditCardChange(e, cc)} />
                                                                {cc.name}
                                                            </Label>
                                                        </FormGroup>
                                                    )
                                                })
                                            }
                                            <FormGroup>
                                                <Label for="ccMerchantVendorsNote">{t('ccMerchantVendorsNote')}</Label>
                                                <Input type="select" name="merchantAccount" id="merchantAccounts"
                                                    value={merchantSelectValue}
                                                    disabled={!selected}
                                                    onChange={(e) => this.handleMerchantChange(e)}>
                                                    <option value="">{t('selectOne')}</option>
                                                    {
                                                        distributorPaymentOption.availableMerchantAccounts.map(ma => {
                                                            return (
                                                                <option value={ma.id} key={ma.id}>{ma.name}</option>
                                                            )
                                                        })
                                                    }
                                                </Input>
                                            </FormGroup>
                                            {
                                                merchantAccount &&
                                                Object.keys(merchantAccount.fields).map(key => {
                                                    const maf = merchantAccount.fields[key];
                                                    return (
                                                        <FormGroup key={key}>
                                                            <Label for={key}>{t(maf.label)}</Label>
                                                            <Input type="text" name={key} id={key}
                                                                disabled={!selected} value={maf.value || ''}
                                                                onChange={(e) => this.handleMerchantFieldChange(e, key)} />
                                                        </FormGroup>
                                                    );
                                                })
                                            }
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>
            </>
        )
    }

}

export default CreditCardPanel;
