import { Thunk } from '../store';
import * as paymentOptionsApi from '../api/paymentOptionsApi';
import { selectSenesiteDistributor, selectDistributorCountry } from '../store/selectors/distributorSelectors';
import { selectPaymentOptionsCountry } from '../store/selectors/paymentOptionSelectors';
import { setBlockUi } from './ui';

import { AdminPaymentOption } from '../models/payment';
import * as toast from './../utils/toastHelper';
import { ActionToastProperties } from '../models/ui';
import { shouldSwithToMaintenance } from "../utils/helpers";

export enum PaymentOptionActionTypes {
    getAvailableAdminPaymentOptionsSuccess = '[PaymentOption] Get Available Admin Payment Options success',
    getAvailableAdminPaymentOptionsError = '[PaymentOption] Get Available Admin Payment Options error',
    getSquareURLSuccess = '[Square] Get Square URL success',
    getSquareURLError = '[Square] Get Square URL error',
    updateDistributorPaymentOptionsSuccess = '[PaymentOption] Update PaymentOptions success',
    updateDistributorPaymentOptionsError = '[PaymentOption] Update PaymentOptions error',
}

export const getSquareURL = (): Thunk => (dispatch, getState) => {
    const state = getState();
    const distributor = selectSenesiteDistributor(state);

    if (!distributor) {
        return;
    }

    return paymentOptionsApi.getSquareURL(distributor.dist_ID)
        .then(response => {
            dispatch({ type: PaymentOptionActionTypes.getSquareURLSuccess });
            return response;
        })
        .catch(exp => {
            shouldSwithToMaintenance(exp);
            dispatch({ type: PaymentOptionActionTypes.getSquareURLError });
            return "";
        });
}

export const getAvailablePaymentOptions = (): Thunk => (dispatch, getState) => {
    const state = getState();
    const distributor = selectSenesiteDistributor(state),
        distributorCountry = selectDistributorCountry(state),
        paymentMethodCountry = selectPaymentOptionsCountry(state);

    if (!distributor || !distributorCountry || (distributorCountry === paymentMethodCountry)) {
        return;
    }

    return paymentOptionsApi.getAvailableAdminPaymentOptions(distributor.dist_ID)
        .then(response => {
            dispatch({
                type: PaymentOptionActionTypes.getAvailableAdminPaymentOptionsSuccess,
                payload: {
                    country: distributorCountry,
                    paymentOptionList: response
                }
            });
            return response;
        })
        .catch(exp => {
            shouldSwithToMaintenance(exp);
            dispatch({ type: PaymentOptionActionTypes.getAvailableAdminPaymentOptionsError });
            return null;
        });
}

export const updateDistributorPaymentOptions = (paymentOptions: AdminPaymentOption[], toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    const state = getState();
    const distributor = selectSenesiteDistributor(state);
    if (!distributor) {
        return;
    }

    dispatch(setBlockUi(true));
    paymentOptionsApi.updateDistributorPaymentOptions(distributor.dist_ID, paymentOptions)
        .then(response => {
            toast.success(toastProps);
            dispatch({
                type: PaymentOptionActionTypes.updateDistributorPaymentOptionsSuccess,
                paymentInfo: response
            });
            dispatch(setBlockUi(false));
        })
        .catch(exp => {
            shouldSwithToMaintenance(exp);
            toast.error(toastProps);
            dispatch({
                type: PaymentOptionActionTypes.updateDistributorPaymentOptionsError
            });
            dispatch(setBlockUi(false));
        });
}
