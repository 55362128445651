import React from 'react';

import ShippingDetails, { ShippingDetailErrors } from './ShipppingDetails'
import { ShippingInfo } from '../../../models/checkout';
import { RadioButton } from '../../common/inputs/SeneRadioButton';
import Button from '../../common/inputs/SeneButton';
import SeneTextInput from '../../common/inputs/SeneTextInput';
import produce from 'immer';
import { ValidationErrors } from '../../../utils/validation/validator';
import { ShippingFeeValidator } from './SeneFeesValidator';

interface SeneShippingFeesStateProps {
    t: any;
    shippingInfo: ShippingInfo[];
    saveShippingInfo(shippingInfo: ShippingInfo[] | undefined)
}

interface SeneShippingFeesState {
    shippingInfo?: ShippingInfo[];
    chargeShipping: boolean;
    submitted: boolean;
    newShipping: ShippingInfo;
    errors: ValidationErrors<ShippingDetailErrors>
}

const SHIPPING = "shipping";
const NO_SHIPPING = "noShipping";

export class SeneShippingFeesPage extends React.Component<SeneShippingFeesStateProps, SeneShippingFeesState> {

    state: SeneShippingFeesState = {
        shippingInfo: undefined,
        chargeShipping: false,
        submitted: false,
        newShipping: { lowerLimit: 0, upperLimit: 0, rate: 0 } as ShippingInfo,
        errors: {}
    }

    componentDidMount() {
        this.setState({
            shippingInfo: this.props.shippingInfo,
            chargeShipping: this.props.shippingInfo && this.props.shippingInfo.length !== 0
        });
    }

    componentDidUpdate(prevProps: SeneShippingFeesStateProps) {
        if (prevProps.shippingInfo !== this.props.shippingInfo) {
            this.setState({
                shippingInfo: this.props.shippingInfo,
                chargeShipping: this.props.shippingInfo.length !== 0
            });
        }
    };

    isValidated = () => {

        const shippingFeeValidator = new ShippingFeeValidator(this.props.t);
        const shippingFeeResult = shippingFeeValidator.validate(this.state.newShipping);

        const errors = {
            ...shippingFeeResult.errors,
        };

        this.setState({
            errors: errors
        });
        return Object.keys(errors).length === 0;
    }

    changeIsShippingFeeEnabled = (e: any) => {
        const isShipping = e.target.value === SHIPPING;
        this.setState({
            chargeShipping: isShipping
        })
    }

    saveDisabledShippingFee = () => {
        this.props.saveShippingInfo([]);
    }

    deleteShipping = (id: number) => {
        this.setState(
            produce(draft => {
                draft.shippingInfo && draft.shippingInfo.splice(id, 1)
                return draft;
            }), () => {
                this.props.saveShippingInfo(this.state.shippingInfo);
            }
        )
    }

    updateNewShipping = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            newShipping: {
                ...this.state.newShipping,
                [name]: value
            }
        })
    }

    addNewShippingFee = () => {
        if (this.isValidated()) {
            this.setState(
                produce(draft => {
                    draft.shippingInfo = this.state.shippingInfo ? 
                        [...this.state.shippingInfo, this.state.newShipping] : [this.state.newShipping];
                    return draft;
                }), () => {
                    this.setState({
                        newShipping: {
                            lowerLimit: 0,
                            upperLimit: 0,
                            rate: 0
                        } as ShippingInfo
                    })
                    this.props.saveShippingInfo(this.state.shippingInfo);
                }
            )
        }
    }

    saveShippingUpdate = (shippingInfo: ShippingInfo, id: number) => {
        this.setState(
            produce(draft => {
                if(draft.shippingInfo) {
                    draft.shippingInfo[id] = shippingInfo;
                }
                return draft;
            }), () => {
                this.props.saveShippingInfo(this.state.shippingInfo);
            }
        )
    }

    renderAllShippingTypes = () => {
        const shippings = this.state.shippingInfo;
        if (shippings && shippings.length > 0) {
            return shippings.map((shipping, i) => {
                return <ShippingDetails shipping={shipping} t={this.props.t} key={i}
                    id={i} updateShipping={this.saveShippingUpdate}
                    deleteShipping={this.deleteShipping} />
            })
        } else {
            return <i className="fa fa-spinner spin" />;
        }
    }

    buildAddNewShippingComponent = () => {
        const { t } = this.props;
        const rate = this.state.newShipping.rate ? this.state.newShipping.rate : '';
        const carrierName = this.state.newShipping.carrierName ? this.state.newShipping.carrierName : '';
        return <div className='shipping-detail-card'>
            <div className="">
                <div >
                    <div className="col-md-6">
                        <SeneTextInput label={t('shippingFeesCarrier')}
                            name="carrierName"
                            onChange={e => this.updateNewShipping(e)}
                            value={carrierName}
                            type='text'
                            error={this.state.errors.carrierName}
                        />
                    </div>
                    <div className="col-md-6">
                        <SeneTextInput label={t('shippingFeesRate')}
                            name="rate"
                            value={rate.toString()}
                            onChange={e => this.updateNewShipping(e)}
                            type='number'
                            placeholder='0'
                            error={this.state.errors.rate}
                        />
                    </div>
                    <div>
                        <Button className="btn btn-secondary"
                            onClick={this.addNewShippingFee}
                            icon="fa-save">
                            {t('shippingFeesCreateNew')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        const { t } = this.props;
        return <div id="shipping_Info">
            <div className="row admin-dist-name">
                <h4><u>{t('tabShippingFeesTitle')}</u></h4>
            </div>
            <RadioButton
                itemStyle="dummy"
                groupname="shippin_info"
                identifier="no-charge"
                onChange={this.changeIsShippingFeeEnabled}
                value={NO_SHIPPING}
                labelDisplayValue={t('shippingFeeDisable')}
                checked={!this.state.chargeShipping} />

            <RadioButton
                itemStyle="dummy"
                groupname="shippin_info"
                identifier="with-charges"
                onChange={this.changeIsShippingFeeEnabled}
                value={SHIPPING}
                labelDisplayValue={t('shippingFeeEnable')}
                checked={this.state.chargeShipping} />

            {!this.state.chargeShipping &&
                <Button className="btn btn-secondary"
                    onClick={this.saveDisabledShippingFee}
                    icon="fa-save"
                    id="submit-shipping-chard">
                    {t('save')}
                </Button>
            }
            {this.state.chargeShipping && this.state.shippingInfo && this.state.shippingInfo.length > 0 &&
                <div>
                    <div className="row admin-dist-name">
                        <h6>{t('shippingFeesTitle')}</h6>
                    </div>
                    <hr />
                    {this.renderAllShippingTypes()}
                </div>
            }
            {this.state.chargeShipping &&
                <div>
                    <div className="row admin-dist-name">
                        <h6>{t('createNewShippingTitle')}</h6>
                    </div>
                    {this.buildAddNewShippingComponent()}
                </div>
            }
        </div>;
    }
}

export default SeneShippingFeesPage