import React, { Validator } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import { selectSelectedLanguageCode, selectSelectedLanguageAlpha2Code } from '../../../store/selectors/languageSelectors';
import { SeneSiteApplicationFormValidator } from './SeneSiteApplicationFormValidator';
import { ValidationErrors } from '../../../utils/validation/validator';
import { SeneDebounceInput } from '../../common/inputs/SeneDebounceInput';
import { verifySeneSiteName, submitSeneSiteApplicationForm } from '../../../actions/administration/applicationFormActions';
import './SeneSiteApplicationForm.scss';
import { selectLoading } from '../../../store/selectors/uiSelectors';
import i18next from 'i18next';
import { cancelledVerifySeneSiteNameString } from '../../../utils/constants';

interface SeneSiteUrlValidatorInputInfo {
    seneSiteName: string;
}

export type SeneSiteUrlValidatorInputModel = SeneSiteUrlValidatorInputInfo & { agree: boolean; isValidSeneSiteName: boolean; };

interface SeneSiteUrlValidatorInputProps {
    loading: boolean;
    locale: string;
    seneSiteApplicationFormInfo: SeneSiteUrlValidatorInputModel;
    t: i18next.TranslationFunction;
    verifySeneSiteName(seneSiteName: string);
    updateSeneSiteName(value: string);
    updateIsValidSeneSiteName(value: boolean);
}

interface SeneSiteUrlValidatorInputState {
    validationMessages: ValidationErrors<SeneSiteUrlValidatorInputModel>;
}

export class SeneSiteUrlValidatorInput extends React.Component<SeneSiteUrlValidatorInputProps, SeneSiteUrlValidatorInputState> {

    state: SeneSiteUrlValidatorInputState = {
        validationMessages: {
        }
    };

    componentDidMount() {
        this.handleOnDebounce("");
    }

    handleOnDebounce = (value: string) => {
        this.props.updateSeneSiteName(value)

        this.props.verifySeneSiteName(value)
            .then(response => {
                this.props.updateIsValidSeneSiteName(response.isValid)
                this.setState({
                    validationMessages: {
                        ...this.state.validationMessages,
                        seneSiteName: `${this.props.seneSiteApplicationFormInfo.seneSiteName} ${this.props.t(response.responseKey)}`
                    }
                });
            })
            .catch(err => {
                if(err.message !== cancelledVerifySeneSiteNameString) {
                    this.props.updateIsValidSeneSiteName(false)
                    this.setState({
                        validationMessages: {
                            ...this.state.validationMessages,
                            seneSiteName: this.props.t('unexpectedError')
                        }
                    });
                }
            });
    }

    render() {
        const { t } = this.props;
        const { isValidSeneSiteName } = this.props.seneSiteApplicationFormInfo;
        return (
            <div className="form-group">
                <h3 className="paragraph-title">{t('seneSiteName')}</h3>
                <SeneDebounceInput {...{
                    id: 'seneSiteName',
                    label: t('selectSeneSiteNameLabel'),
                    value: this.props.seneSiteApplicationFormInfo.seneSiteName,
                    wait: 1000,
                    onDebounce: this.handleOnDebounce,
                    forceDebounceOnEnter: true,
                    forceDebounceOnBlur: true,
                    loading: this.props.loading,
                    success: (isValidSeneSiteName && this.state.validationMessages.seneSiteName) || undefined,
                    error: (!isValidSeneSiteName && this.state.validationMessages.seneSiteName) || undefined
                }} />
                <div className="form-group">
                    <div><small>{t('tradeMarkMessage')}</small></div>
                    <div><small>{t('suggestions')}</small></div>
                    <div><small>{t('noSpecialCharacterMessage')}</small></div>
                </div>
            </div>
        )
    }
}

export default SeneSiteUrlValidatorInput;
