import React, { ChangeEvent } from 'react';
import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import produce from 'immer';

import Spinner from '../../common/Spinner';
import './MySeneSitesPage.scss';
import { Languages } from "../../../models/language";
import { Distributor } from '../../../models/distributor';
import CustomEditor from '../../common/customcontent/CustomEditor';
import i18n from '../../../i18n';
import memoizeOne from 'memoize-one';

interface MySeneSitesPageStateProps {
    distributor: Distributor;
    languages: Languages;
    setCustomContent(content: CustomContentHtml[]);
    t: i18n.TranslationFunction;
}
type MySeneSitesPageProps = MySeneSitesPageStateProps;

interface MySeneSitesPageState {
    contents: Array<CustomContentHtml>,
    activeLang: string,
}

export interface CustomContentHtml {
    content: ContentState;
    locale: string;
}

class MySeneSitesCustomContent extends React.Component<MySeneSitesPageProps, MySeneSitesPageState> {

    state: MySeneSitesPageState = {
        contents: [] as Array<CustomContentHtml>,
        activeLang: '',
    }

    convertContent = (content: string): ContentState => {
        const contentBlock = htmlToDraft(content);
        if (contentBlock) {
            return ContentState.createFromBlockArray(contentBlock.contentBlocks);
        }
    }
        
    componentDidMount() {
        const customContents = this.props.distributor.seneSiteInfo.seneSiteLooks.customContents;
        const convertedCustomContents = customContents.map(t => ({locale: t.locale, content: this.convertContent(t.content)}) ); 
        this.setState({
            contents:  convertedCustomContents,
            activeLang: this.props.languages.selectedLanguage ? this.props.languages.selectedLanguage.code : 'en-us'
        });
        this.props.setCustomContent(convertedCustomContents);
    }

    componentDidUpdate(prevProps: MySeneSitesPageStateProps) {
        const customContents = this.props.distributor.seneSiteInfo.seneSiteLooks.customContents;
        const prevCustomContents = prevProps.distributor.seneSiteInfo.seneSiteLooks.customContents;
        if(customContents != prevCustomContents) {
            const convertedCustomContents = customContents.map(t => ({locale: t.locale, content: this.convertContent(t.content)}) );
            this.setState({
                contents:  convertedCustomContents,
                activeLang: this.props.distributor.seneSiteInfo.locale || 'en-us'
            });
            this.props.setCustomContent(convertedCustomContents);
        }
    }

    onChangeLang = (e: React.ChangeEvent<any>) => {
        let key = e.target.id;
        if(!this.props.distributor) return;
        
        this.setState({
            activeLang: key
        });
    }

    updateContentOfLang = (newContent: ContentState) => {
        let contentLocale = this.state.activeLang;
        if (this.state.activeLang === 'en-ca' || this.state.activeLang === 'en-au') {
            contentLocale = 'en-us';
        }
        let index;
        this.setState(produce(draft => {
            const customContents = draft.contents;
            const num = customContents.filter(s => this.getLanguage(s.locale) == this.getLanguage(this.state.activeLang)).length;
            if (num > 1) {
                index = customContents.findIndex(s => s.locale == this.state.activeLang);
            }
            else {
                index = customContents.findIndex(s => this.getLanguage(s.locale) == this.getLanguage(this.state.activeLang));
            }
            if (index === -1) {
                customContents.push({ content: newContent, locale: contentLocale });
            }
            else {
                customContents[index].content = newContent;
                if(!customContents[index].locale.split('-')[1]){
                    customContents[index].locale = contentLocale;
                }
            }

            return draft;
        }), () => {this.props.setCustomContent(this.state.contents);} );

    }

    updateContent = (currentContent: ContentState) => {
        if(!this.props.distributor) return;
        this.updateContentOfLang(currentContent);
    }

    getLanguage = (locale: string): string => {
        if (!locale) return '';
        const aLocales = locale.split('-');
        return aLocales[0];
    }

    getContentForEditor = memoizeOne((lang: string) => {
        const contents = this.state.contents;
        const editorLanguage = this.getLanguage(lang);
        if(contents.filter(c => this.getLanguage(c.locale) === editorLanguage).length > 1) {
            return contents.find(c => c.locale == lang)!.content;
        }
        else {
            return (contents.findIndex(c => this.getLanguage(c.locale) === editorLanguage) > -1) ? 
            contents.find(c => this.getLanguage(c.locale) === editorLanguage)!.content : this.convertContent('')
        }
    });

    render() {
        const { t } = this.props;
        if (this.props.distributor && this.props.distributor.seneSiteInfo) {
            return (
                <div className="row">
                    <div className='admin-personal-msg'>{t('personalMsgTitle')}</div>
                    <div className='personal-msg-text'>{t('personalMsgDetails')} </div>
                    <div >
                        <ul className="nav nav-tabs" role="tablist">
                            {this.props.languages.availableLanguages.map((lang, i) => (
                                <li className="nav-item" key={`${lang.code}-${i}-tab`}>
                                    <a className={`nav-link my-senesite-custom-content-tab ${this.getLanguage(lang.code) === this.getLanguage(this.state.activeLang) ? 'active show' : ''}`}
                                        data-toggle="tab"
                                        id={lang.code}
                                        onClick={this.onChangeLang}>
                                        &nbsp;{lang.languageDisplayName}&nbsp;</a>

                                </li>
                            ))}
                        </ul>
                        <div className="tab-content">
                            <div className={`tab-pane active show`}
                                id={`${this.state.activeLang}-edit`} key={`${this.state.activeLang}-edit`}>
                                <CustomEditor
                                    locale={this.state.activeLang}
                                    updateContent={this.updateContent}
                                    content={this.getContentForEditor(this.state.activeLang)}
                                />
                            </div>
                        </div>
                    <p className='max-character-len'>{t('personalMsgMax')}</p>
                    </div>
                </div>
            );
        }
        else {
            return <Spinner />;
        }
    }
}

export default MySeneSitesCustomContent

