import React from "react";
import i18next from 'i18next';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import InstagramEmbed from 'react-instagram-embed';
import YouTube from 'react-youtube-embed';

import './SeneHomeSocialFeed.scss';
import SectionHeader from '../../../common/headers/SectionHeader';
import * as helpers from "../../../../utils/helpers";
import { DistributorSocialMedia } from "../../../../models/distributor";
import { Marketing } from '../../../../models/marketing';
import { connect } from "react-redux";
import * as distributorActions from '../../../../actions/distributor';
import { bindActionCreators } from "redux";
import SeneInstagramFeed from "./SeneInstagramFeed";
import { Distributor } from '../../../../models/distributor';

interface ISocialFeedPageStateProps {
    socialInfo: DistributorSocialMedia;
    senesiteMarketing: Marketing;
    t: i18next.TranslationFunction;
    distributor: Distributor;
}

interface ISocialFeedPageDispatchProps {
    getInstagramFeed();
}

interface ISocialFeedPageState {
    twitterScreenName: string;
    instagramData: any[];
}

type ISocialFeedPageProps = ISocialFeedPageDispatchProps & ISocialFeedPageStateProps;

class SeneHomeSocialFeed extends React.Component<ISocialFeedPageProps, ISocialFeedPageState> {

    state: ISocialFeedPageState = {
        twitterScreenName: '',
        instagramData: []
    }

    componentDidMount() {
        const twitterId = this.getTwitterScreenName();
        this.props.getInstagramFeed().then(res => {
            this.setState({
                instagramData: res
            });

        });
        this.setState({
            twitterScreenName: twitterId
        });

    }

    getVideoId(): string {
        if (this.props.socialInfo.useMyYouTube && this.props.socialInfo.youTubeVideo) {
            return helpers.getYouTubeVideoId(this.props.socialInfo.youTubeVideo);
        }
        else {
            if (this.props.distributor && this.props.distributor.countryID == 6) {
                return (this.props.senesiteMarketing.accounts && this.props.senesiteMarketing.accounts.youTubeVideoIdZH ? 
                        helpers.getYouTubeVideoId(this.props.senesiteMarketing.accounts.youTubeVideoIdZH) : '');
            }
            else
                return (this.props.senesiteMarketing.accounts && this.props.senesiteMarketing.accounts.youTubeVideoId ? 
                        helpers.getYouTubeVideoId(this.props.senesiteMarketing.accounts.youTubeVideoId) : '');
        }
    }

    getTwitterScreenName(): string {

        let twitterScreenName = this.state.twitterScreenName;

        if (this.props.socialInfo.useMyTwitter && this.props.socialInfo.twitterAccount) {
            twitterScreenName = helpers.twitterScreenName(this.props.socialInfo.twitterAccount);
            if (!twitterScreenName) {
                return this.props.senesiteMarketing.accounts.twitterId;
            }
            return twitterScreenName;

        }
        else {
            return (this.props.senesiteMarketing.accounts && this.props.senesiteMarketing.accounts.twitterId ? 
                helpers.twitterScreenName(this.props.senesiteMarketing.accounts.twitterId) : '');
        }
    }

    render() {
        const { t } = this.props;
        return <section>

            <SectionHeader>{t('socialFeeds')}</SectionHeader>
            <div className="container">
                <div className="card-deck flex-center">
                    <div className="card card-border col-lg-12 col-xl-6">
                        <div className="card-icon">
                            <i className={"fab fa-instagram"} /> Instagram
                            </div>
                            <SeneInstagramFeed instagramData={this.state.instagramData} />
                    </div>
                    <div className="card card-border col-lg-12 col-xl-6">
                        <div className="card-icon">
                            <i className={"fab fa-twitter"} /> Twitter
                            </div>
                            { (this.state.twitterScreenName !== '') && 
                                <TwitterTimelineEmbed sourceType="profile" screenName={this.state.twitterScreenName} options={{ height: 400 }} />
                            }
                    </div>
                </div>
            </div>
            <div className="card-deck container col-lg-12">
                <div className="card card-border">
                    <div className="card-icon">
                        <i className={"fab fa-youtube"} /> YouTube
                        </div>
                        { (this.getVideoId() !== '') &&
                            <YouTube id={this.getVideoId()} />
                        }
                </div>
            </div>
        </section>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getInstagramFeed: distributorActions.getInstagramFeed
    },
        dispatch);
}

export default (connect(null, mapDispatchToProps)(SeneHomeSocialFeed));

