import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import i18next from 'i18next';
import 'moment/min/locales';

import { DistributorState } from '../../../reducers/distributor';
import { CalendarEvent, SeneSiteInfo } from '../../../models/distributor';
import SeneButton from '../inputs/SeneButton';
import EventModal from './EventModal';
import EventDetailsModal from './EventDetailsModal';
import * as distributorActions from './../../../actions/distributor';
import * as uiActions from '../../../actions/ui';
import './Calendar.scss';
import { ActionToastProperties } from '../../../models/ui';
import { shouldSwithToMaintenance } from "../../../utils/helpers";

export interface IBigCalendarEvent {
    title: string;
    location: string;
    description: string;
    start: Date;
    end: Date;
    allDay: boolean;
}

export interface CalendarStateProps {
    t: i18next.TranslationFunction;
    culture: string;
    isAdmin: boolean;
    distributor: DistributorState;
    events: CalendarEvent[];
    viewMode: 'month' | 'week' | 'day';
    selectedDate: Date;
}

export interface CalendarDispatchProps {
    updateDistributorSeneSiteInfo(distId: number, seneSiteInfo: SeneSiteInfo, toastProps?: ActionToastProperties);
    setBlockUi(blockUi: boolean);
}

type CalendarProps = CalendarStateProps & CalendarDispatchProps;

export interface CalendarState {
    events: IBigCalendarEvent[];
    selectedEvent: IBigCalendarEvent | null;
    selectedEventIdx: number;
    isEventModalOpen: boolean;
    isEventDetailsModalOpen: boolean;
}

class Calendar extends React.PureComponent<CalendarProps, CalendarState> {

    private readonly step = 60;
    private readonly views = Object.keys(BigCalendar.Views).filter(k => BigCalendar.Views[k] !== 'work_week').filter(m => BigCalendar.Views[m] !== 'agenda').map(k => BigCalendar.Views[k]);

    static defaultProps = {
        isAdmin: false
    }

    state: CalendarState = {
        events: [],
        selectedEvent: null,
        selectedEventIdx: -1,
        isEventModalOpen: false,
        isEventDetailsModalOpen: false
    }

    componentDidMount() {
        const distId = this.props && this.props.distributor && this.props.distributor.dist_ID;
        if (distId) {
            this.loadEvents();
        }
    }

    componentDidUpdate(prevProps: CalendarProps) {
        const
            distId = this.props && this.props.distributor && this.props.distributor.dist_ID,
            prevDistId = prevProps && prevProps.distributor && prevProps.distributor.dist_ID,
            prevCalendarEvents = prevProps && prevProps.distributor && prevProps.distributor.seneSiteInfo && prevProps.distributor.seneSiteInfo.calendarEvents,
            calendarEvents = this.props && this.props.distributor && this.props.distributor.seneSiteInfo && this.props.distributor.seneSiteInfo.calendarEvents;
        if ((distId !== prevDistId) || (prevCalendarEvents != calendarEvents)) {
            this.loadEvents();
        }
    }

    loadEvents = () => {
        const convertedEvents = this.convertToIBigCalendarEvents(this.props.events);
        this.setState({ events: convertedEvents });
    }

    convertToIBigCalendarEvents = (origEvents: CalendarEvent[]): IBigCalendarEvent[] => {
        let calendarEvents;
        if (origEvents) {
            calendarEvents = origEvents.map(e => {
                return this.convertToIBigCalendarEvent(e);
            });
        }
        return calendarEvents;
    }

    convertToIBigCalendarEvent = (origEvent: CalendarEvent): IBigCalendarEvent => {
        const
            startDate = moment(origEvent.startDate),
            endDate = moment(origEvent.endDate),
            startDateStr = moment(startDate).format('YYYY-MM-DD'),
            startOfStartDate = moment(startDateStr).startOf('day').toDate(),
            endDateStr = moment(endDate).format('YYYY-MM-DD'),
            endOfEndDate = moment(endDateStr).endOf('day').toDate(),
            allDay = (startDateStr === endDateStr) && (startDate.isSame(startOfStartDate)) && (endDate.isSame(endOfEndDate));
        return {
            title: origEvent.eventName,
            location: origEvent.location,
            description: origEvent.description,
            start: new Date(origEvent.startDate),
            end: new Date(origEvent.endDate),
            allDay: allDay
        } as IBigCalendarEvent;
    }

    convertFromIBigCalendarEvent = (origEvent: IBigCalendarEvent): CalendarEvent => {
        return {
            eventName: origEvent.title,
            description: origEvent.description,
            location: origEvent.location,
            startDate: origEvent.start,
            endDate: origEvent.end
        } as CalendarEvent;
    }

    handleChange = (changes: any) => {
        const changedEvent = {
            ...this.state.selectedEvent,
            ...changes
        } as IBigCalendarEvent;
        this.setState({
            selectedEvent: changedEvent
        });
    }

    handleAddEvent = () => {
        this.setState({
            selectedEvent: {
                title: '',
                location: '',
                description: '',
                allDay: false
            } as IBigCalendarEvent,
            selectedEventIdx: -1,
            isEventModalOpen: true
        });
    }

    handleSelectEvent = (event: IBigCalendarEvent) => {
        this.setState({
            selectedEvent: event,
            selectedEventIdx: this.state.events.findIndex(e => e.title === event.title),
            isEventDetailsModalOpen: true
        })
    }

    handleSaveEvent = () => {
        const { distributor } = this.props;
        const { selectedEventIdx } = this.state;
        if (!distributor || !distributor.seneSiteInfo || !this.state.selectedEvent) {
            return;
        }

        const eventToSave = this.convertFromIBigCalendarEvent(this.state.selectedEvent);
        let calendarEvents = [...distributor.seneSiteInfo.calendarEvents];
        if (selectedEventIdx === -1) {
            calendarEvents.push(eventToSave);
        } else {
            calendarEvents[selectedEventIdx] = eventToSave;
        }
        const seneSiteInfo = {
            ...distributor.seneSiteInfo,
            calendarEvents: calendarEvents
        };
        this.updateSeneSiteInfo(distributor.dist_ID, seneSiteInfo);
    }

    handleDeleteEvent = () => {
        const { distributor } = this.props;
        const { selectedEventIdx } = this.state;
        if (!distributor || !distributor.seneSiteInfo || !this.state.selectedEvent) {
            return;
        }

        const calendarEvents = distributor.seneSiteInfo.calendarEvents.filter((event, idx) => idx !== selectedEventIdx);
        const seneSiteInfo = {
            ...distributor.seneSiteInfo,
            calendarEvents: calendarEvents
        };
        this.updateSeneSiteInfo(distributor.dist_ID, seneSiteInfo, { success: this.props.t('calendarUpdateSuccess') });

    }

    updateSeneSiteInfo = (distId: number, seneSiteInfo: SeneSiteInfo, toastProps?: ActionToastProperties) => {
        this.props.setBlockUi(true);
        this.props.updateDistributorSeneSiteInfo(distId, seneSiteInfo, toastProps)
            .then(() => {
                this.setState({
                    isEventModalOpen: false,
                    isEventDetailsModalOpen: false
                });
                this.props.setBlockUi(false);
            })
            .catch((exp) => {
                shouldSwithToMaintenance(exp);
                this.props.setBlockUi(false);
            });
    }

    handleEditEvent = () => {
        this.setState({
            isEventDetailsModalOpen: false,
            isEventModalOpen: true
        })
    }

    handleCloseEventModal = () => {
        this.setState({ isEventModalOpen: false });
    }

    handleCloseEventDetailsModal = () => {
        this.setState({ isEventDetailsModalOpen: false });
    }

    render() {
        const { t, culture, isAdmin, viewMode, selectedDate } = this.props;
        const { events } = this.state;
        const messages = { allDay: t('allday'), previous: t('previous'), next: t('next'), today: t('today'), month: t('month'), week: t('week'), day: t('day'), agenda: t('agenda'), date: t('date'), time: t('time'), event: t('event') };
        moment.locale(culture);
        const localizer = BigCalendar.momentLocalizer(moment);
        return (
            <>
                <div className="row mb-2">
                    <div className="col-12">
                        {
                            isAdmin &&
                            <div className="row mt-4">
                                <div className="col-12 text-right">
                                    <SeneButton
                                        buttonClass="secondary"
                                        onClick={this.handleAddEvent}
                                    >
                                        {t('addNewEvent')}
                                    </SeneButton>
                                </div>
                            </div>
                        }
                        <div className="row mt-4">
                            <div className="col-12 bigCalendarContainer">
                                <BigCalendar
                                    localizer={localizer}
                                    events={events}
                                    startAccessor="start"
                                    endAccessor="end"
                                    step={this.step}
                                    views={this.views}
                                    showMultiDayTimes
                                    defaultDate={new Date(selectedDate)}
                                    defaultView={viewMode}
                                    messages={messages}
                                    culture={culture}
                                    selectable
                                    onSelectEvent={(event) => this.handleSelectEvent(event)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.selectedEvent &&
                    <>
                        <EventModal
                            t={t}
                            isOpen={this.state.isEventModalOpen}
                            event={this.state.selectedEvent}
                            onChange={this.handleChange}
                            save={this.handleSaveEvent}
                            close={this.handleCloseEventModal}
                        />
                        <EventDetailsModal
                            t={t}
                            isOpen={this.state.isEventDetailsModalOpen}
                            event={this.state.selectedEvent}
                            isAdmin={isAdmin}
                            edit={this.handleEditEvent}
                            delete={this.handleDeleteEvent}
                            close={this.handleCloseEventDetailsModal}
                        />
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        updateDistributorSeneSiteInfo: distributorActions.updateDistributorSeneSiteInfo,
        setBlockUi: uiActions.setBlockUi
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
