import { PaymentType } from "./payment";

export enum OrderStatus {
    Unknown = 'Unknown',
    PendingPayment = 'PendingPayment',
    DeclinedPayment = 'DeclinedPayment',
    PayedFullPayment = 'PayedFullPayment',
    BeingProcessPayment = 'BeingProcessPayment',
    CanceledPayment = 'CanceledPayment',
    CompletePayment = 'CompletePayment',
    OnHoldPayment = 'OnHoldPayment'
}

export interface Order {
    orderId: number;
    orderDate: Date;
    customerId: number;
    distributorId: number;
    orderStatus: OrderStatus;
    shippingName: string;
    grandTotal: number;
    totalPaid: number;
    paymentMethod: PaymentType;
    customerEmail: string;
    customerPhone: string;
    shippingAddress1: string;
    shippingAddress2: string;
    shippingCity: string;
    shippingState: string;
    shippingZip: string;
    country: string;
    itemTotal: number;
    shippingFee: number;
    handlingFee: number;
    taxTotal: number;
    customerNotes: string;
    lineItems: LineItem[];
}

export interface LineItem {
    sku: string;
    productName: string;
    price: number;
    quantity: number;
    totalPrice: number;
    imageUrl: string;
}