import React from "react";

export interface NotificationBadgeProps {
    count: number;
    color?: string;
}

const styles: React.CSSProperties = {
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '3rem',
    position: 'absolute',
    right: '0px',
    top: '0px',
    width: '3rem',
}

const NotificationBadge = ({ count, color = '#4675ab' }: NotificationBadgeProps) => (
    <div className="notif-badge" style={{ ...styles, backgroundColor: color }}>{count}</div>
);

export default NotificationBadge;
