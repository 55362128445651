import React from 'react';
import ReactExport from "react-data-export";
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import i18next from 'i18next';
import memoize from "memoize-one";
import Button from 'reactstrap/lib/Button';

import { DistributorState } from '../../../reducers/distributor';
import { Order } from '../../../models/order';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import SeneButton from '../../common/inputs/SeneButton';
import senePaginationFactory from '../../common/SeneTablePagination';
import Currency from '../../common/Currency';
import './SeneSiteOrdersDesktop.scss';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export interface SeneSiteOrdersDesktopProps {
    t: i18next.TranslationFunction;
    locale: string;
    distributor: DistributorState;
    activeTab: string;
    changeTab(tab: 'currentorders' | 'inProcessOrders' | 'completedOrders');
    newOrders: Order[];
    inProcessOrders: Order[];
    completedOrders: Order[];
    orderStatuses: any[];
    handleStatusChange(updatedOrder: Order);
    handleOrderDetailsToggle(isModalOpen: boolean, order?: Order);
}


export interface SeneSiteOrdersDesktopState {
    enableFilters: boolean
}


class SeneSiteOrdersDesktop extends React.Component<SeneSiteOrdersDesktopProps, SeneSiteOrdersDesktopState> {

    filters: Array<any> = [];

    state: SeneSiteOrdersDesktopState = {
        enableFilters: false
    }

    resetFilter = memoize(
        (filters, enableFilters) => {
            if (!enableFilters) {
                filters.forEach(filter => filter(''));
                filters = [];
            }
            return filters;
        }
    )

    applyFilter = (filterToApply: Function) => {
        if (this.state.enableFilters) {
            return filterToApply({
                getFilter: (filter) => {
                    this.filters.push(filter);
                }
            });
        } else {
            return null;
        }
    }

    handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>, order: any) => {
        this.props.handleStatusChange({ ...order, orderStatus: e.target.value })
    }

    handleOrderDetailsOpen = (order: Order) => {
        this.props.handleOrderDetailsToggle(true, order);
    }

    renderTabContent = (orders: Order[], label: string) => {
        return (
            <div className="container">
                <div className="row justify-content-end mt-2">
                    <div>
                        {this.renderEnableFiltersButton()}
                    </div>
                    <div className="ml-2">
                        {this.renderExportButton(orders, label)}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        {this.renderTable(orders)}
                    </div>
                </div>
            </div>
        )
    }

    renderEnableFiltersButton = () => {
        const { t } = this.props;
        return (
            <SeneButton onClick={() => this.setState({ enableFilters: !this.state.enableFilters })}>
                {
                    this.state.enableFilters ?
                        t('hideFilters')
                        :
                        t('showFilters')
                }
            </SeneButton>
        )
    }

    renderExportButton = (orders: Order[], label: string) => {
        const { t, locale } = this.props;
        return (
            <ExcelFile
                filename={`MyOrders-${label}`}
                element={(
                    <SeneButton
                        buttonClass={'secondary'}
                        icon={'fa-file-excel'}
                        onClick={() => undefined}>{t('export')}
                    </SeneButton>
                )}>
                <ExcelSheet data={orders} name="My Orders" >
                    <ExcelColumn label="Date" value={(col) => moment(col.orderDate).locale(locale).format("LL")} />
                    <ExcelColumn label="Name" value="shippingName" />
                    <ExcelColumn label="Order Id" value="orderId" />                    
                    <ExcelColumn label="Order Total" value="grandTotal" />
                    <ExcelColumn label="Method" value="paymentMethod" />
                    <ExcelColumn label="Status" value="orderStatus" />
                    <ExcelColumn label="Payment" value="totalPaid" />
                </ExcelSheet>
            </ExcelFile>
        )
    }

    renderTable = (orders: Order[]) => {
        const { t, locale, orderStatuses } = this.props;
        return (
            <BootstrapTable
                bootstrap4={true} bordered={false} condensed={true}
                keyField='orderId' data={orders} columns={[
                    {
                        dataField: 'orderDate',
                        text: t('colDate'),
                        formatter: (cell, row) => {
                            return (<span>{moment(row.orderDate).locale(locale).format("LL")}</span>);
                        },
                        sort: true,
                        filter: this.applyFilter(textFilter),
                        headerStyle: () => ({ width: '15%' })
                    },
                    {
                        dataField: 'shippingName',
                        text: t('colName'),
                        sort: true,
                        filter: this.applyFilter(textFilter),
                        headerStyle: () => ({ width: '15%' })
                    },
                    {
                        dataField: 'orderId',
                        text: t('colOrderId'),
                        sort: true,
                        filter: this.applyFilter(textFilter),
                        headerStyle: () => ({ width: '15%' })
                    },
                    {
                        dataField: 'grandTotal',
                        text: t('colGrandTotal'),
                        sort: true,
                        filter: this.applyFilter(textFilter),
                        formatter: (cell, row) => {
                            return (
                                <Currency>{row.grandTotal}</Currency>
                            )
                        },
                        headerStyle: () => ({ width: '10%' })
                    },
                    {
                        dataField: 'paymentMethod',
                        text: t('colMethod'),
                        sort: true,
                        filter: this.applyFilter(textFilter),
                        headerStyle: () => ({ width: '10%' })
                    },
                    {
                        dataField: 'orderStatus',
                        text: t('colStatus'),
                        formatter: (cell, row) => {
                            return (
                                <select
                                    className="custom-select"
                                    value={row.orderStatus}
                                    onChange={(e) => this.handleStatusChange(e, row)}
                                    key={row.orderId}
                                >
                                    {orderStatuses.map(os => (
                                        <option value={os.value} key={os.key}> {t(os.key)} </option>
                                    ))}
                                </select>
                            );

                        },
                        sort: true,
                        headerStyle: () => ({ width: '20%' })
                    },
                    {
                        dataField: 'totalPaid',
                        text: t('colPaid'),
                        sort: true,
                        filter: this.applyFilter(textFilter),
                        formatter: (cell, row) => {
                            return (
                                <Currency>{row.totalPaid}</Currency>
                            );
                        },
                        headerStyle: () => ({ width: '10%' })
                    },
                    {
                        dataField: 'placeholder',
                        text: '',
                        events: {
                            onClick: (e, column, columnIndex, row, rowIndex) => {
                                this.handleOrderDetailsOpen(row);
                            }
                        },
                        formatter: (cell, row) => {
                            return <Button style={{ padding: 0 }} color="link">{t('details')}</Button>;
                        },
                        headerStyle: () => ({ width: '10%' })
                    }
                ]}
                pagination={senePaginationFactory(this.props.t)}
                filter={filterFactory()}
            />
        )
    }

    render() {
        const { t, activeTab, newOrders, inProcessOrders, completedOrders } = this.props;
        return (
            <>
                <Nav tabs className="mt-4">
                    <NavItem>
                        <NavLink
                            style={{ cursor: 'pointer' }}
                            className={`${activeTab === 'currentorders' ? 'active' : ''}`}
                            onClick={() => this.props.changeTab('currentorders')}>
                            {t('tabNew')}
                        </NavLink>
                    </NavItem>
                    <NavItem >
                        <NavLink
                            style={{ cursor: 'pointer' }}
                            className={`${activeTab === 'inProcessOrders' ? 'active' : ''}`}
                            onClick={() => this.props.changeTab('inProcessOrders')}>
                            {t('tabInprocess')}
                        </NavLink>
                    </NavItem>
                    <NavItem >
                        <NavLink
                            style={{ cursor: 'pointer' }}
                            className={`${activeTab === 'completedOrders' ? 'active' : ''}`}
                            onClick={() => this.props.changeTab('completedOrders')}>
                            {t('tabCompleted')}
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="currentorders">
                        {this.renderTabContent(newOrders, 'Current')}
                    </TabPane>
                    <TabPane tabId="inProcessOrders">
                        {this.renderTabContent(inProcessOrders, 'InProcess')}
                    </TabPane>
                    <TabPane tabId="completedOrders">
                        {this.renderTabContent(completedOrders, 'Processed')}
                    </TabPane>
                </TabContent>
            </>
        )
    }
}

export default SeneSiteOrdersDesktop;
