import React from 'react';
import memoize from 'memoize-one';

import { InventoryItem } from '../../../models/inventory';
import SeneButton from '../../common/inputs/SeneButton';
import './SeneInventoryMobile.scss';
import SeneNumberInput from '../../common/inputs/SeneNumberInput';
import { filterMatched } from './../../../utils/helpers';
import { SeneInventoryDesktopStateProps, SeneInventoryDesktopState } from './SeneInventoryDesktop';

interface SeneInventoryMobileStateProps extends SeneInventoryDesktopStateProps {
    searchString: string;
    handleSearch(event: React.ChangeEvent<HTMLInputElement>);
}

export interface SeneInventoryMobileState extends SeneInventoryDesktopState {
}
class SeneInventoryMobile extends React.Component<SeneInventoryMobileStateProps, SeneInventoryMobileState> {

    state: SeneInventoryMobileState = {
        enableFilters: false,
        filters: [],
        isImportModalOpen: false
    }

    filterData = memoize(
        (data: InventoryItem[], searchString) => {
            return searchString
                ? data.filter(item => {

                    return filterMatched(`${item.sku}${item.productName}`, searchString);

                })
                : data;
        }
    )

    render() {

        const {
            t,
            saleRegardless,
            inventory
        } = this.props;

        const filteredInventory = this.filterData(inventory, this.props.searchString);

        return (
            <div className="col-12 content-container">

                <div className="section-container">

                    <div className="top-container mb-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={saleRegardless} onChange={this.props.handleSaleRegardless} id="saleRegardless" name="saleRegardless"/>
                                        <label className="form-check-label" htmlFor="saleRegardless"> {t('regardlessSale')} </label>
                                    </div>
                                </div>
                                <div className="col-12 pl-5 text-danger"> {t('outOfStockDesc')} </div>
                                <div className="col-12 pl-5 text-danger"> {t('discontinuedDesc')} </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <div className="input-group ">
                                        <input
                                            type="search"
                                            className="form-control"
                                            aria-label="Search"
                                            placeholder={t('search')}
                                            value={this.props.searchString}
                                            onChange={this.props.handleSearch} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="fa fa-search"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2 border-bottom">
                                <div className="col-12">
                                    <div className="table-header">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-2 font-weight-bold"> {t('sku')} </div>
                                                <div className="col-3 font-weight-bold"> {t('productName')} </div>
                                                <div className="col-7 font-weight-bold text-center"> {t('qty')} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="center-container border-top border-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {
                                        filteredInventory.map(item => {
                                            const rowHighlight = this.props.itemSkus.has(item.sku) ? 'table-primary' : '';
                                            return (
                                                <div className={`table-row border-bottom ${rowHighlight}`} key={item.sku}>
                                                    <div className="container">
                                                        <div className="row align-items-center">
                                                            <div className="col-2"> {item.sku} </div>
                                                            <div className="col-3"> {item.productName} </div>
                                                            <div className="col-7">
                                                                <SeneNumberInput
                                                                    identifier={item.sku}
                                                                    value={item.qty}
                                                                    onChange={this.props.updateQty} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bottom-container mt-2 mb-2">
                        <div className="container">
                            <div className="col-12">
                                <SeneButton
                                    buttonClass={'primary'}
                                    className={'btn-block'}
                                    disabled={this.props.itemSkus.size === 0}
                                    onClick={this.props.handleSave}>{t('save')}
                                </SeneButton>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default SeneInventoryMobile;
