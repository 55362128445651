import React from "react";
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import './SeneSellPage.scss'
import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';
import { CreateBackgroundImageUrl } from '../../common/Images';
import { DistributorState } from '../../../reducers/distributor';
import { DesktopOrTablet } from "../../common/responsive/ResponsiveHelpers";
import ImageComponent from "../../common/ImageComponent";
import { selectImageResizerUrl, selectDistributorSignupUrl, selectConfiguration, selectDistributorSignupUsAndCa } from "../../../store/selectors/configurationSelectors";
import SeneSiteLink from "../../common/SeneSiteLink";
import { Configuration } from "../../../models/configuration";

interface SeneSellPageProps {
    distributor: DistributorState;
    imageResizerUrl: string;
    distributorSignupUrl: string;
    distributorSignupUsAndCa: string;
    config: Configuration | null;
}

export class SeneSellPage extends React.Component<SeneSellPageProps & WithNamespaces> {
    
    GetLanguageString(): string {
		let localeString: string = 'en-US';
		let languageString: string = 'en'

		if (this.props.i18n.language)
			localeString = this.props.i18n.language;

		languageString = localeString.split('-')[0];

		return languageString
    }

    renderIntro = () => {
        const HeaderImage = this.props.distributor && this.props.distributor.countryID == 6 ? 'products/HeaderImages/HeaderImage_HK.png' : 'products/HeaderImages/HeaderImage.jpg'
        return (<div className="container mb-2">
            <div className="sell-header-image" style={{ backgroundImage: CreateBackgroundImageUrl({ "imageResizerUrl": this.props.imageResizerUrl, "config": this.props.config, "src": HeaderImage,"width": 1024,"height": 0,"crop": "max"})}}>
            </div>
            <div className="row justify-content-center text-center">
                <h3 className="text-dark">{this.props.t('header')}</h3>
                <p className="sell-senegence-p">{this.props.t('sellContent')}</p>
            </div>
        </div>)
    }

    renderDemoOrDistributor = () => {
        const HostADemo = this.props.distributor && this.props.distributor.countryID == 6 ? 'products/SellSenegence/HostADemo_HK.png' : 'products/SellSenegence/HostADemo.jpg'
        const { t } = this.props;

        var isUsOrCa = false;

        if (this.props.i18n.language.toUpperCase() == 'EN-US' || this.props.i18n.language.toUpperCase() == 'ES-US' || this.props.i18n.language.toUpperCase() == 'EN-CA' || this.props.i18n.language.toUpperCase() == 'FR-CA') {
            isUsOrCa = true;
        }

        return this.props.distributor && (<div className="container mb-5">
            <div className="row text-center">
                <div className="col mt-3">
                    <ImageComponent 
                        src={HostADemo}
                        height={200}
                        className="placed-image"
                        alt={`Card image cap`} />
                    <h5 className="text-dark">{t('demoHeader')}</h5>
                    <p className="sell-senegence-p">{t('demoContent')}</p>
                    <SeneSiteLink to="/contact">
                        <div className="btn btn-light">
                            {t('contactMe')}
                        </div>
                    </SeneSiteLink>
                </div>
                <div className="col-md-1 align-self-center px-1">
                    <DesktopOrTablet>
                        <div className="or-box ">{t('or')}</div>     
                    </DesktopOrTablet>
                </div>
                <div className="col mt-3">
                    <ImageComponent 
                        src={`products/SellSenegence/BecomeDistributor.jpg`}
                        height={200}
                        className="placed-image"
                        alt={`Card image cap`} />
                    <h5 className="text-dark">{t('distributorHeader')}</h5>
                    <p className="sell-senegence-p">{this.props.t('distributorContent')}</p>
                    <a href={isUsOrCa ? this.props.distributorSignupUsAndCa + this.props.i18n.language.replace('-', '_') + "/nds/find?distributor_id=" + this.props.distributor.dist_ID : this.props.distributorSignupUrl + "?c=" + this.props.distributor.countryID + "&s=" + this.props.distributor.dist_ID + "&l=" + this.GetLanguageString()}
                        target="_blank"
                        className="btn btn-secondary">
                        {t('distributoLink')}
                    </a>
                </div>
            </div>
        </div>)
    }

    renderOpportunities = () => {
        const { t } = this.props;

        var isUsOrCa = false;

        if (this.props.i18n.language.toUpperCase() == 'EN-US' || this.props.i18n.language.toUpperCase() == 'ES-US' || this.props.i18n.language.toUpperCase() == 'EN-CA' || this.props.i18n.language.toUpperCase() == 'FR-CA') {
            isUsOrCa = true;
        }

        return this.props.distributor && (<div className="container mb-2">
            <div className="row clearfix">
                <div className="col-md-12 column">
                    <h2>{t('buildYourCareerHeader')}</h2>
                    <p><span className="eighteen">{t('buildYourCareerText')}</span><br /><br /></p>
                </div>
            </div>
            <div className="row clearfix">
                <div className="col-lg-6 column">
                    <table className="carrier-table">
                        <tbody>
                            <tr className="odd info-box">
                                <td className="info-box-icon">
                                    <ImageComponent src={`products/SellSenegence/clock.png`} height={75} className="placed-image" />
                                </td>
                                <td className="info-box-text-container">
                                    <h3>{t('yourCareerYourTermsHeader')}</h3>
                                    <p style={{ fontSize: "16px" }}>{t('yourCareerYourTermsText')}</p>
                                </td>
                            </tr>
                            <tr className="even">
                                <td className="icon-spacer">&nbsp;</td>
                                <td className="text-spacer">&nbsp;</td>
                            </tr>
                            <tr className="odd info-box">
                                <td className="info-box-icon">
                                    <ImageComponent src={`products/SellSenegence/money.png`} height={75} className="placed-image" />
                                </td>
                                <td className="info-box-text-container">
                                    <h3>{t('additionalIncomeHeader')}</h3>
                                    <p style={{ fontSize: "16px" }}>{t('additionalIncomeText')}</p>
                                </td>
                            </tr>
                            <tr className="even">
                                <td className="icon-spacer">&nbsp;</td>
                                <td className="text-spacer">&nbsp;</td>
                            </tr>
                            <tr className="odd info-box">
                                <td className="info-box-icon">
                                    <ImageComponent src={`products/SellSenegence/mascara.png`} height={75} className="placed-image" />
                                </td>
                                <td className="info-box-text-container">
                                    <h3>{t('innovativeProductsHeader')}</h3>
                                    <p style={{ fontSize: "16px" }}>{t('innovativeProductsText')}</p>
                                </td>
                            </tr>
                            <tr className="even">
                                <td className="icon-spacer">&nbsp;</td>
                                <td className="text-spacer">&nbsp;</td>
                            </tr>
                            <tr className="odd info-box">
                                <td className="info-box-icon">
                                    <ImageComponent src={`products/SellSenegence/checklist.png`} height={75} className="placed-image" />
                                </td>
                                <td className="info-box-text-container">
                                    <h3>{t('noInventoryRequiredHeader')}</h3>
                                    <p style={{ fontSize: "16px" }}>{t('noInventoryRequiredText')}</p>
                                </td>
                            </tr>
                            <tr className="even">
                                <td className="icon-spacer">&nbsp;</td>
                                <td className="text-spacer">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-lg-6 column">
                    <table className="carrier-table">
                        <tbody>
                            <tr className="odd info-box">
                                <td className="info-box-icon">
                                    <ImageComponent src={`products/SellSenegence/training.png`} height={75} className="placed-image" />
                                </td>
                                <td className="info-box-text-container">
                                    <h3>{t('toolsForSuccessHeader')}</h3>
                                    <p style={{ fontSize: "14px" }}>{t('toolsForSuccessText')}</p>
                                </td>
                            </tr>
                            <tr className="even">
                                <td className="icon-spacer">&nbsp;</td>
                                <td className="text-spacer">&nbsp;</td>
                            </tr>
                            <tr className="odd info-box">
                                <td className="info-box-icon">
                                    <ImageComponent src={`products/SellSenegence/balance.png`} height={75} className="placed-image" />
                                </td>
                                <td className="info-box-text-container">
                                    <h3>{t('workLifeBalanceHeader')}</h3>
                                    <p style={{ fontSize: "16px" }}>{t('workLifeBalanceText')}</p>
                                </td>
                            </tr>
                            <tr className="even">
                                <td className="icon-spacer">&nbsp;</td>
                                <td className="text-spacer">&nbsp;</td>
                            </tr>
                            <tr className="odd info-box">
                                <td className="info-box-icon">
                                    <ImageComponent src={`products/SellSenegence/trips.png`} height={75} className="placed-image" />
                                </td>
                                <td className="info-box-text-container">
                                    <h3>{t('exclusiveOpportunitiesHeader')}</h3>
                                    <p style={{ fontSize: "16px" }}>{t('exclusiveOpportunitiesText')}</p>
                                </td>
                            </tr>
                            <tr className="even">
                                <td className="icon-spacer">&nbsp;</td>
                                <td className="text-spacer">&nbsp;</td>
                            </tr>
                            <tr className="odd info-box">
                                <td className="info-box-icon">
                                    <ImageComponent src={`products/SellSenegence/globe-icon.png`} height={75} className="placed-image" />
                                </td>
                                <td className="info-box-text-container">
                                    <h3>{t('sisterhoodHeader')}</h3>
                                    <p style={{ fontSize: "16px" }}>{t('sisterhoodText')}</p>
                                </td>
                            </tr>
                            <tr className="even">
                                <td className="icon-spacer">&nbsp;</td>
                                <td className="text-spacer">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>  
            <div className="row justify-content-center p4-4 mt-4">
                <a href={isUsOrCa ? this.props.distributorSignupUsAndCa + this.props.i18n.language.replace('-', '_') + "/nds/find?distributor_id=" + this.props.distributor.dist_ID : this.props.distributorSignupUrl + "?c=" + this.props.distributor.countryID + "&s=" + this.props.distributor.dist_ID + "&l=" + this.GetLanguageString()}
                    target="_blank"
                    className="btn btn-secondary px-3 py-2 today">
                    {t('becomeDistributor')}
                </a>
            </div>
        </div>)
    }

	display = () => {
		if (this.props.distributor) {
			return <div>
                {this.renderIntro()}
                {this.renderDemoOrDistributor()}
                {this.renderOpportunities()}
			</div>;
		} else {
			return <h2 style={{ textAlign: 'center' }}><i className="fa fa-spinner spin" /></h2>;
		}
	}

	render() {
		return <div className="sell_senegence">
			<div>
				{this.display()}
			</div>
		</div>;
	}
}

const mapStateToProps = (state) => ({
    distributor: selectSenesiteDistributor(state),
    imageResizerUrl: selectImageResizerUrl(state),
    distributorSignupUrl: selectDistributorSignupUrl(state),
    config: selectConfiguration(state),
    distributorSignupUsAndCa: selectDistributorSignupUsAndCa(state)
});

export default withNamespaces("SellSenegencePage")(connect(mapStateToProps, null)(SeneSellPage));