import i18next from 'i18next';

import { CustomerInformation, ShippingAddress, ShippingInfo, ShippingCaptchaState } from './../../../../models/checkout';
import { Validator } from './../../../../utils/validation/validator';


export class CustomerInfoValidator extends Validator<CustomerInformation> {
    constructor(t: i18next.TranslationFunction) {
        super();

        this.ruleFor("firstName").notEmpty(t('msgFirstName'));
        this.ruleFor("lastName").notEmpty(t('msgLastName'));
        this.ruleFor("email").notEmpty(t('msgEmail')).email(t('msgInvalidEmail'));
        this.ruleFor("phone").notEmpty(t('msgPhone')).phone(t('msgInvalidPhone'));
    }
}

export class ShippingAddressValidator extends Validator<ShippingAddress> {
    constructor(t: i18next.TranslationFunction, countryName: string) {
        super();

        const cityKey = countryName !== 'HK' ? 'msgCity' : 'msgCityHk';
        const stateKey = countryName !== 'HK' ? 'msgState' : 'msgStateHk';

        this.ruleFor('address').notEmpty(t('msgAddress'));
        this.ruleFor('city').notEmpty(t(cityKey));
        if (countryName !== 'NZ') {
            this.ruleFor('state').notEmpty(t(stateKey));
        }
        if (countryName !== 'HK') {
            this.ruleFor('zipCode').notEmpty(t('msgPostal'));
        }
    }
}

export class ShippingCaptchaValidator extends Validator<ShippingCaptchaState>{
    constructor(t: i18next.TranslationFunction, isLoggedIn: boolean, captchasDisabled: boolean) {
        super();
        if (!captchasDisabled && !isLoggedIn) {
            this.ruleFor('isCaptchaVerified').isTrue('captcha requires validation')
            this.ruleFor('captchaToken').notEmpty(t('captcha Token Required'));
        }
    }
}

export class ShippingMethodValidator extends Validator<ShippingInfo> {
    constructor(t: i18next.TranslationFunction, availableShippingMethods: ShippingInfo[]) {
        super();

        if (availableShippingMethods.length > 0) {
            this.ruleFor('rate').isFloat(t('msgShippingMethod'));
        }
    }
}
