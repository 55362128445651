import React from 'react';

import './BlockUi.scss'
import { connect } from 'react-redux';

import { selectBlockUi } from '../../store/selectors/uiSelectors';
import { State } from '../../store';

interface BlockUiProps {
    blockUi: boolean;
}

const BlockUi = ({ blockUi = false }: BlockUiProps) => (
    blockUi &&
    (
        <div className="ui-blocker">
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        </div>
    ) || null
);

const mapStateToProps = (state: State) => ({
    blockUi: selectBlockUi(state)
});

export default connect(mapStateToProps)(BlockUi);
