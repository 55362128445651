import React, { ReactNode } from 'react';

import './SectionHeader.scss';

const SectionHeader = ({children}: {children: ReactNode}) => (
    <h3 className="section-header">
        <span>
        {children}
        </span>
    </h3>
);

export default SectionHeader;
