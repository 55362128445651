import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { selectSenesiteDistributor, selectBanners, selectUpcomingEvents } from '../../../store/selectors/distributorSelectors';
import { DistributorState } from '../../../reducers/distributor';
import BlogContainer from './blog/BlogContainer';
import { selectBlogFeed } from '../../../store/selectors/blogSelectors';
import { BlogFeed } from '../../../models/blog';
import * as blogActions from './../../../actions/blogActions';
import SeneCarousel from '../../common/SeneCarousel';
import { Banner, CalendarEvent } from '../../../models/distributor';
import SeneHomeDistributorInfo from './SeneHomeDistributorInfo';
import SeneHomeEvent from './event/SeneHomeEvent';
import SeneHomeGallery from './gallery/SeneHomeGallery';
import SeneHomeSocialFeed from './social/SeneHomeSocialFeed';
import { selectMarketing } from './../../../store/selectors/marketingSelectors';
import SeneHomeShopNowBanner from './SeneHomeShopNowBanner';
import { Mobile, DesktopOrTablet } from '../../common/responsive/ResponsiveHelpers';
import { MarketingState } from '../../../reducers/marketing';
import { selectImageResizerUrl } from '../../../store/selectors/configurationSelectors';

interface HomeStateProps {
    distributor: DistributorState,
    blogFeed: BlogFeed | null,
    banners: Banner[],
    events: CalendarEvent[],
    marketing: MarketingState,
    imageResizerUrl: string
}

interface HomeDispatchProps {
    getDistributorFromSeneSiteUrl(seneSiteUrl: string);
    getDistributorBlogFeed()
}

interface HomeState {
    carouselImages: string[],
    galleryImages: string[],
    currentLocale: string
}

type HomePageProps = RouteComponentProps<{}> & HomeStateProps & HomeDispatchProps & WithNamespaces;

class HomePage extends React.Component<HomePageProps, HomeState> {

    private readonly carouselFallbackImage = '/comingsoon.png';

    state = {
        carouselImages: [],
        galleryImages: [],
        currentLocale: ''
    };

    componentDidMount() {
        this.props.getDistributorBlogFeed();
        this.setState({
            carouselImages: this.getImageUrlsBasedOnLocale(this.props.banners)
        });
        if (this.props.marketing) {
            this.setState({
                galleryImages: this.props.marketing.images.map(mi => `products/${mi.image}`)
            });
        }
    }

    componentDidUpdate(prevProps: HomePageProps) {
        if (this.state.currentLocale !== this.props.i18n.language) {
            this.setState({
                currentLocale: this.props.i18n.language,
                carouselImages: this.getImageUrlsBasedOnLocale(this.props.banners)
            });
        }
        if (prevProps.marketing !== this.props.marketing && this.props.marketing) {
            this.setState({
                galleryImages: this.props.marketing.images.map(mi => mi.image)
            });
        }
    };

    private getImageUrlsBasedOnLocale(array): string[] {
        const urls = array.map(item => {
            // TODO: might not be the best url, but  this is where the sliders are stored
            const imageUrl = `products/${item.image}`;
            const dotIndex = imageUrl.lastIndexOf('.');
            const localeCode = this.props.i18n && this.props.i18n.language;
            const langCode = localeCode ? localeCode.substr(0,2) : 'en';
            let newUrl = imageUrl;
            if (langCode !== 'en') {
                newUrl = `${imageUrl.substr(0, dotIndex)}_${langCode}${imageUrl.substr(dotIndex)}`;
            }
            return newUrl;
        });
        return urls;
    }

    render() {
        const {t} = this.props;
        return (
            (this.props.distributor && this.props.i18n && this.props.marketing) &&
            <div>
                <DesktopOrTablet>
                    <SeneCarousel images={this.state.carouselImages} fallback={this.carouselFallbackImage} 
                                  imageWidth={1110} imageHeight={0} imageCrop="max" numberOfAdjacentImages={1} />
                </DesktopOrTablet>
                <Mobile>
                    <SeneHomeShopNowBanner imageResizerUrl={this.props.imageResizerUrl} showNowTranslate={t('shopNow')}/>
                </Mobile>
                <SeneHomeDistributorInfo distributor={this.props.distributor} />
                <SeneHomeSocialFeed 
                    socialInfo={this.props.distributor.seneSiteInfo.socialInfo}
                    senesiteMarketing={this.props.marketing}
                    t={this.props.t}
                    distributor={this.props.distributor}/> 
                <BlogContainer t={t} feed={this.props.blogFeed} />
                <SeneHomeEvent t={t} events={this.props.events} />
                <SeneHomeGallery t={t} images={this.state.galleryImages} fallback={this.carouselFallbackImage} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    distributor: selectSenesiteDistributor(state),
    blogFeed: selectBlogFeed(state),
    banners: selectBanners(state),
    events: selectUpcomingEvents(state),
    marketing: selectMarketing(state),
    imageResizerUrl: selectImageResizerUrl(state)
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getDistributorBlogFeed: blogActions.getDistributorBlogFeed
    }, dispatch);
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(HomePage));
