import { UserInfo } from './../models/userInfo';
import { CreateCustomerModel, CreateCustomerRequest } from './../models/customer';
import { selectSelectedLanguageCode } from './../store/selectors/languageSelectors';
import { selectDistributorId, selectDistributorCountry } from './../store/selectors/distributorSelectors';
import { Thunk } from './../store/index';
import * as customerApi from '../api/customerApi';
import { seneSiteNavigateTo } from './router';
import { setLoading } from './ui';
import { selectLoggedInUser } from '../store/selectors/userSelectors';
import * as toast from './../utils/toastHelper';
import { ActionToastProperties } from '../models/ui';
import { sendCreateCustomerErrorLog } from './loggingActions';
import { shouldSwithToMaintenance } from "../utils/helpers";

export enum CustomerActionTypes {
    createCustomerSuccess = '[Customer] Create customer success',
    createCustomerError = '[Customer] Create customer error',
    updateLoggedInCustomerSuccess = '[Customer] Update current customer success',
    updateLoggedInCustomerError = '[Customer] Update current customer error',
}

export const createCustomer = (customer: CreateCustomerModel, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    const state = getState();
    const distId = selectDistributorId(state);
    const distCountry = selectDistributorCountry(state);
    const locale = selectSelectedLanguageCode(state);
    const signalRid = state.signalRid;

    const request: CreateCustomerRequest = {
        customer: {
            ...customer,
            distId,
            country: distCountry
        },
        locale,
        signalRConnectionId: signalRid
    }
    toast.info(toastProps);
    return customerApi.createCustomer(request);
};

export const createCustomerSuccess = () => (dispatch) => {
    dispatch({type: CustomerActionTypes.createCustomerSuccess});
    return dispatch(seneSiteNavigateTo('/account-created'));
}

export const createCustomerError = (errors: any) => (dispatch, getState) => {
    sendCreateCustomerErrorLog(errors, getState());
    return dispatch({type: CustomerActionTypes.createCustomerError, errors});
}

export const updateLoggedInCustomer = (newInfo: Partial<UserInfo>, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    const state = getState();
    const currentUser = selectLoggedInUser(state);

    if (!currentUser) {
        return null;
    }

    const updatedUser: UserInfo = {
        ...currentUser,
        ...newInfo
    };

    dispatch(setLoading(true));
    return customerApi.updateLoggedInCustomer(updatedUser)
        .then((customer) => {
            toast.success(toastProps);
            dispatch(setLoading(false));
            return dispatch({type: CustomerActionTypes.updateLoggedInCustomerSuccess, customer})
        })
        .catch((exp) => {
            shouldSwithToMaintenance(exp);
            toast.error(toastProps);
            dispatch(setLoading(false));
            return dispatch({type: CustomerActionTypes.updateLoggedInCustomerError})
        });
};
