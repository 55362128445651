import React from 'react';
import i18next from 'i18next';
import Modal from 'reactstrap/lib/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Order, OrderStatus } from '../../../models/order';
import { Distributor } from '../../../models/distributor';
import { Mobile } from '../../common/responsive/ResponsiveHelpers';
import SeneButton from '../../common/inputs/SeneButton';
import Currency from '../../common/Currency';
import './OrderDetails.scss';
import * as orderApi from './../../../api/orderApi';
import * as uiActions from '../../../actions/ui';
import ImageComponent from '../../common/ImageComponent';
import { shouldSwithToMaintenance } from "../../../utils/helpers";

export interface OrderDetailsStateProps {
    t: i18next.TranslationFunction;
    isModalOpen: boolean;
    distributor: Distributor;
    order: Order;
    orderStatuses: any[];
    handleStatusChange(updatedOrder: Order);
    handleOrderDetailsToggle(value: boolean, order?: Order);
}

export interface OrderDetailsDispatchProps {
    setBlockUi(blockUi: boolean);
}

type OrderDetailsProps = OrderDetailsStateProps & OrderDetailsDispatchProps;

export interface OrderDetailsState {
    selectedOrder: Order;
}

class OrderDetails extends React.PureComponent<OrderDetailsProps, OrderDetailsState> {

    state: OrderDetailsState = {
        selectedOrder: {} as Order
    }

    componentDidMount() {
        const { distributor, order } = this.props;

        if (distributor && order) {
            this.props.setBlockUi(true);
            orderApi.getOrderById(distributor.dist_ID, order.orderId)
                .then(response => {
                    this.setState({ selectedOrder: { ...response } });
                    this.props.setBlockUi(false);
                })
                .catch(err => {
                    shouldSwithToMaintenance(err);
                    this.props.setBlockUi(false);
                })
        }
    }

    handleClose = () => {
        this.props.handleOrderDetailsToggle(false);
    }

    handleSave = () => {
        this.props.handleStatusChange(this.state.selectedOrder)
    }

    handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            selectedOrder: {
                ...this.state.selectedOrder,
                orderStatus: e.target.value as OrderStatus
            }
        })
    }

    renderOrderStatusSelect = () => {
        const { t, orderStatuses } = this.props;
        const { selectedOrder } = this.state;
        return (
            <>
                <div className="row mt-2">
                    <div className="col-12 font-weight-bold">
                        {t('status')}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <select className="custom-select" value={selectedOrder.orderStatus} onChange={(e) => { this.handleStatusChange(e) }} key={selectedOrder.orderId}>
                            {orderStatuses.map(os => (<option value={os.value} key={os.key}> {t(os.key)} </option>))}
                        </select>
                    </div>
                </div>
            </>
        )
    }

    renderBody = () => {
        const { t } = this.props;
        const { selectedOrder } = this.state;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Mobile>
                            {this.renderOrderStatusSelect()}
                        </Mobile>
                        <div className="row mt-2">
                            <div className="col-12">
                                <h3> {t('order')} # {selectedOrder.orderId} </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 mt-2">
                                <div className="card">
                                    <div className="card-header">
                                        {t('shippingInformation')}
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">{selectedOrder.shippingName}</h5>
                                        <p className="card-text">
                                            {t('email')} - <a href={`mailto: ${selectedOrder.customerEmail}`}> {selectedOrder.customerEmail} </a>
                                        </p>
                                        <p className="card-text">
                                            {t('phone')} - {selectedOrder.customerPhone}
                                        </p>
                                        <p className="card-text">
                                            {selectedOrder.shippingAddress1} {selectedOrder.shippingAddress2}
                                        </p>
                                        <p className="card-text">
                                            {selectedOrder.shippingCity}, {selectedOrder.shippingState} {selectedOrder.shippingZip} {selectedOrder.country}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 mt-2">
                                <div className="card">
                                    <div className="card-header">
                                        {t('paymentInformation')}
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">{t('paymentMethod')} - {selectedOrder.paymentMethod}</h5>
                                        <div className="card-text d-flex justify-content-between">
                                            <div> {t('itemTotal')} </div>
                                            <div> <Currency>{selectedOrder.itemTotal}</Currency> </div>
                                        </div>
                                        <div className="card-text d-flex justify-content-between">
                                            <div> {t('shippingFee')} </div>
                                            <div> <Currency>{selectedOrder.shippingFee}</Currency> </div>
                                        </div>
                                        <div className="card-text d-flex justify-content-between">
                                            <div> {t('handlingFee')} </div>
                                            <div> <Currency>{selectedOrder.handlingFee}</Currency> </div>
                                        </div>
                                        <div className="card-text d-flex justify-content-between">
                                            <div> {t('taxTotal')} </div>
                                            <div> <Currency>{selectedOrder.taxTotal}</Currency> </div>
                                        </div>
                                        <div className="card-text d-flex justify-content-between">
                                            <div> {t('grandTotal')} </div>
                                            <div> <Currency>{selectedOrder.grandTotal}</Currency> </div>
                                        </div>
                                        <div className="card-text d-flex justify-content-between">
                                            <div> {t('totalPaid')} </div>
                                            <div> <Currency>{selectedOrder.totalPaid}</Currency> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        {t('customerNote')}
                                    </div>
                                    <div className="card-body">
                                        {selectedOrder.customerNotes}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        {
                            selectedOrder.lineItems &&
                            selectedOrder.lineItems.map((item, idx) => {
                                const productImageUrl = item.imageUrl ? `products/ProductImages/` + item.imageUrl : `products/ProductImages/${item.sku}.tif`
                                return (
                                    <div className="row mt-2" key={idx}>
                                        <div className="col-md-4 col-sm-12 text-center">
                                            <ImageComponent
                                                src={productImageUrl}
                                                width={200}
                                                height={200}
                                            />
                                        </div>
                                        <div className="col-md-8 col-sm-12">
                                            <h4 className="product-name mt-2"> {item.productName} </h4>
                                            <p className="product-property mb-2"> {t('price')} - <Currency>{item.price}</Currency> </p>
                                            <p className="product-property mb-2"> {t('sku')} - {item.sku} </p>
                                            <p className="product-property mb-2"> {t('quantity')} - {item.quantity} </p>
                                            <p className="product-property mb-2"> {t('total')} - <Currency>{item.totalPrice}</Currency> </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderFooter = () => {
        const { t } = this.props;
        return (
            <>
                <SeneButton
                    onClick={this.handleClose}
                >
                    {t('close')}
                </SeneButton>
                <Mobile>
                    <SeneButton
                        onClick={this.handleSave}
                        buttonClass="secondary"
                        icon="fas fa-save"
                    >
                        {t('save')}
                    </SeneButton>
                </Mobile>
            </>
        )
    }

    render() {
        const { t, isModalOpen } = this.props;
        const { selectedOrder } = this.state;
        if (!selectedOrder.orderId) {
            return (<></>)
        } else {
            return (
                <Modal
                    isOpen={isModalOpen}
                    className="modal-dialog modal-lg">
                    <div className="modal-content overflow-scroll">
                        <div className="modal-header">
                            <h5 className="modal-title">{t('orderInformation')}</h5>
                            <button type="button" className="close" onClick={this.handleClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.renderBody()}
                        </div>
                        <div className="modal-footer">
                            {this.renderFooter()}
                        </div>
                    </div>
                </Modal>
            )
        }
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setBlockUi: uiActions.setBlockUi
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
