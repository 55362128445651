import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import {Alert, Collapse} from 'reactstrap';
import { Path } from 'history';

import './AdminHeader.scss';
import LanguageSelector from '../../common/LanguageSelector';
import { UserManager } from '../../../utils/auth/UserManager';
import { State } from '../../../store';
import { selectAdminPageTitle } from '../../../store/selectors/adminSelectors';
import { selectAvailableLanguages } from "../../../store/selectors/languageSelectors";
import { CountryLanguage } from "../../../models/language";
import {changeSelectedLanguage} from "../../../actions/languageActions";
import LanguageSelectorMobile from "../../common/LanguageSelectorMobile";
import { setCallbackUrl } from '../../../utils/auth/CallbackStorage';
import {selectDistributorName, selectSeneSiteUrl} from '../../../store/selectors/distributorSelectors';

interface AdminHeaderProps {
    adminTitle: string;
    pathname: string;
    push(path: Path);
    languages: CountryLanguage[];
    changeSelectedLanguage(selectedLanguage: CountryLanguage, seneSiteUrl: string | null);
    seneSiteUrl: string | null;
    distributorName: string | null;
    isImpersonation: boolean;
}

interface AdminHeaderState {
    isOnDashboard: boolean;
    isMobileMenuOpen: boolean;
    displayImpersonationWarning: boolean;
}

class AdminHeader extends React.PureComponent<AdminHeaderProps & WithNamespaces, AdminHeaderState> {
    onLogout = () => {
        setCallbackUrl(`/${this.props.seneSiteUrl}`);
        UserManager.signoutRedirect({ui_locales: this.props.i18n.language.toLowerCase()});
    }

    state = {
        isOnDashboard: false,
        isMobileMenuOpen: false,
        displayImpersonationWarning: false
    };

    componentDidMount() {
        this.checkRoute(null);
        this.setState({displayImpersonationWarning: this.props.isImpersonation});
    }

    componentDidUpdate(prevProps: AdminHeaderProps) {
        this.checkRoute(prevProps.pathname);
        if(this.props.isImpersonation && !prevProps.isImpersonation) {
            this.setState({displayImpersonationWarning: true});
        }
    }

    toggleMobileMenu = () => {
        this.setState({
            isMobileMenuOpen: !this.state.isMobileMenuOpen
        });
    }

    hideImpersonationWarning = () => {
        this.setState({displayImpersonationWarning: false});
    }

    navigateToDashboard = () => {
        this.props.push('/administration/Dashboard');
    }

    checkRoute(prevPath: string | null) {
        const currentPath = this.props.pathname.toLocaleLowerCase();
        if (!prevPath || prevPath.toLocaleLowerCase() !== currentPath) {
            const isOnDashboard = currentPath === '/administration/dashboard';

            this.setState({
                isOnDashboard,
                isMobileMenuOpen: isOnDashboard ? this.state.isMobileMenuOpen : false
            });
        }
    }

    translate = (lang: CountryLanguage, e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        this.props.changeSelectedLanguage(lang, this.props.seneSiteUrl);
    }

    render() {
        const { t, distributorName } = this.props;
        return (
            <>
                <div className="admin-header">
                    <div className="row admin-header__row container">
                        {
                            this.state.isOnDashboard
                                ? (
                                    <div className="admin-header__navigation" onClick={this.toggleMobileMenu}>
                                        <i className="fas fa-bars"></i>
                                    </div>
                                )
                                : (
                                    <div className="admin-header__navigation" onClick={this.navigateToDashboard}>
                                        <i className="fas fa-arrow-left"></i>
                                    </div>
                                )
                        }
                        <h2 className="admin-header__title">{t(this.props.adminTitle)}</h2>
                        <div className="admin-header__language"><LanguageSelector /></div>
                        <div className="admin-header__logout">
                            <a style={{ cursor: 'pointer' }} id="loggedIn_menu" onClick={this.onLogout}> {t('logout')}</a>
                        </div>
                    </div>
                    <Collapse isOpen={this.state.isMobileMenuOpen}>
                        <div className="list-group">
                            <LanguageSelectorMobile renderInAdminHeader={true} onSelectedLanguageChanged={this.toggleMobileMenu}/>
                            <span className="list-group-item list-group-item-action" onClick={this.onLogout}>{t('logout')}</span>
                        </div>
                    </Collapse>
                </div>
                <div className="container">
                    <div className="col-12">
                        <Alert className="alert alert-warning"
                               isOpen={this.state.displayImpersonationWarning}
                               toggle={this.hideImpersonationWarning}>
                            {t('impersonationHeaderWarning', {distributorName})}
                        </Alert>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: State) => ({
    adminTitle: selectAdminPageTitle(state),
    pathname: state.router.location.pathname,
    languages: selectAvailableLanguages(state),
    seneSiteUrl: selectSeneSiteUrl(state),
    distributorName: selectDistributorName(state)
});

export default withNamespaces('AdminHeader')(connect(mapStateToProps, { push, changeSelectedLanguage })(AdminHeader));
