import React from "react";
import axios from 'axios';
import InstagramEmbed from 'react-instagram-embed';

import { Distributor } from "../../../../models/distributor";

interface IInstagramPageProps {
    instagramData: any[];
}

export class SeneInstagramFeed extends React.Component<IInstagramPageProps> {

    renderInstagramFeed = () => {
        let feed = this.props.instagramData;
        const feedLinks = [] as string[];
        for (let i = 0; i < feed.length; i++) {
            if (feed[i].link) {
                feedLinks.push(feed[i].link);
            }
        }
        return (<div>
            {feedLinks.length ? (feedLinks.map(item => (
                <InstagramEmbed
                    key={item}
                    url={item}
                    hideCaption={false}
                    containerTagName={'div'}
                    protocol=''
                />))
            ) : ''}
        </div>);
    }

    render() {
        return this.props.instagramData ? (
            <div style={{width: '100%'}} className="pre-scrollable">
                {this.renderInstagramFeed()}
           </div>
        ) : <i className="fa fa-spinner spin" />
        
    }
}

export default SeneInstagramFeed;