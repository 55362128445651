import React from 'react';
import { orderBy } from 'lodash';
import { Collapse } from 'reactstrap';

import './CategoryMenu.scss';
import CategoryMenuMobile from './CategoryMenuMobile';
import { Mobile, DesktopOrTablet } from '../../../common/responsive/ResponsiveHelpers';
import { Category, SubCategory } from '../../../../models/category';
import SeneSiteLink from '../../../common/SeneSiteLink';
import { MaybeCategoryOrSubcategory, isSameCategory } from '../../../../utils/types';
import i18n from '../../../../i18n';
import { getCategoryName, getSubCategoryName } from '../../../../utils/helpers';

export interface CategoryMenuProps {
    categories: Category[];
    currentCategory?: MaybeCategoryOrSubcategory;
    t: i18n.TranslationFunction;
    locale: string;
}

interface CategoryMenuState {
    openedCategoryId: number | null;
}

class CategoryMenu extends React.PureComponent<CategoryMenuProps, CategoryMenuState> {
    state: CategoryMenuState = {
        openedCategoryId: 0
    }

    componentDidMount() {
        this.handleCategoryChange();
    }

    componentDidUpdate(prevProps: CategoryMenuProps) {
        this.handleCategoryChange(prevProps.currentCategory);
    }

    handleCategoryChange(prevCategory: MaybeCategoryOrSubcategory = null) {
        if (this.props.currentCategory && !isSameCategory(this.props.currentCategory, prevCategory)) {
            if (this.state.openedCategoryId !== this.props.currentCategory.categoryId) {
                this.setState({ openedCategoryId: this.props.currentCategory.categoryId });
            }
        }
    }

    isCategoryOpen = (category: Category) => {
        return category.categoryId === this.state.openedCategoryId;
    }

    isSubCategoryActive = (category: SubCategory) => {
        const currentCategory = this.props.currentCategory || null;
        return isSameCategory(currentCategory, category);
    }

    handleCategoryClick = (categoryId: number) => {
        let newCategoryId: number | null = null;
        if (categoryId !== this.state.openedCategoryId) {
            newCategoryId = categoryId;
        }

        this.setState({ openedCategoryId: newCategoryId });
    }

    renderCategory = (category: Category) => {
        const isOpen = this.isCategoryOpen(category);
        return (
            <div className="categories-menu" key={category.categoryId}>
                <div className={`categories-menu__category${isOpen ? '--active' : ''}`}>
                    <button
                        onClick={() => this.handleCategoryClick(category.categoryId)}
                        className="btn btn-link categories-menu__category-name"
                        aria-expanded="true"
                        aria-controls={`panel_${category.categoryId}`}
                    >
                        {getCategoryName(category, this.props.locale)}
                        <span className="d-md-none d-lg-none d-xl-none">
                            <i className="fa fa-plus " />
                        </span>
                    </button>
                </div>
                <Collapse isOpen={isOpen}>
                    <div className="categories-menu__subcategory">
                        <ul>
                            {
                                orderBy(category.subCategories, ['OrderBy']).map((subcat) => {
                                    const subCategoryName = getSubCategoryName(subcat, this.props.locale);
                                    return (
                                        <li key={subcat.id} className={`categories-menu__subcategory-name${this.isSubCategoryActive(subcat) ? '--active' : ''}`}>
                                            <SeneSiteLink
                                                to={`/shopproducts/${subcat.categoryId}/${subcat.id}/${encodeURIComponent(subCategoryName.toLowerCase())}`}
                                            >
                                                {subCategoryName}
                                            </SeneSiteLink>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Collapse>
            </div>
        )
    }

    render() {
        const orderedCategories = orderBy(this.props.categories, ['orderBy']);
        const { t } = this.props;
        return (
            <div className="categories-menu">
                <DesktopOrTablet>
                    <h5 className="categories-menu__title">{t('categories')}</h5>
                    {orderedCategories.map(cat => this.renderCategory(cat))}
                </DesktopOrTablet>
                <Mobile>
                    <CategoryMenuMobile {...this.props} />
                </Mobile>
            </div>
        )
    }
}

export default CategoryMenu;
