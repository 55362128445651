
import React from 'react';
import { CustomInput } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './SeneProfilePage.scss';
import SeneButton from '../../common/inputs/SeneButton';
import { DistributorAddressValidator, DistributorContactValidator, DistributorProfileValidator } from './DistributorValidator';
import { selectRegionsForDistributorCountry } from '../../../store/selectors/regionSelectors';
import { getRegions } from '../../../actions/regionActions';
import TextInput from '../../common/inputs/SeneTextInput';
import SelectInput from '../../common/inputs/SeneSelectInput';
import { Distributor, DistributorAddress, SeneSiteInfo, DistributorProfile, DistributorContact } from "../../../models/distributor";
import { CountryState } from '../../../models/region';
import i18n from '../../../i18n';
import { ValidationErrors } from '../../../utils/validation/validator';
import { cloneDeep } from 'lodash';
import DistributorInfo from '../dashboard/DistributorInfo';
import produce from 'immer';
import { ActionToastProperties } from '../../../models/ui';
import { TranslationRemapper } from "../../../../src/utils/helpers";

interface SeneProfileDetailsStateProps {
    t: i18n.TranslationFunction;
    distributor: Distributor;
    regions: CountryState[];
    updateDistributorMyProfile(distId: number, distributorProfile: DistributorProfile, toastProps?: ActionToastProperties);
}

interface SeneProfileDetailsState {
    errors: ValidationErrors<SeneProfileErrors>;
    distributorProfile: DistributorProfile;
}

export interface SeneProfileErrors {
	firstName: string;
    lastName: string;
	email: string;
    phoneNumber1: string;
	address1: string;
	city: string;
	stateName: string;
	zip: string;
}

export class SeneProfileDetails extends React.PureComponent<SeneProfileDetailsStateProps, SeneProfileDetailsState> {

    state: SeneProfileDetailsState = {
        errors: {},
        distributorProfile: {} as DistributorProfile
    }

    get regions() {
        return this.props.regions.map(country => ({value: country.abbreviation, text: country.name}));
    }

    getDefaultSeneSiteAddress = () => {
        return {
            address1: '',
            address2: '',
            address3: '',
            city: '',
            stateName: '',
            zip: '',
            isMailingAddress: false,
            isSeneSiteAddress: true,
            isShippingAddress: false,
            isOtherShippingAddress: false,
            stateId: 0
        }
    }

    addMissingObjectsToDistributorProfile(from: DistributorProfile) {

        if (from.addresses && from.addresses.some(t => t.isSeneSiteAddress) && from.contact) {
            return from;
        }

        let distributorProfile = cloneDeep(from);
        
        if (!distributorProfile.addresses) {
			distributorProfile.addresses = [];
			distributorProfile.addresses.push(this.getDefaultSeneSiteAddress());
        } else if (!distributorProfile.addresses.some(t => t.isSeneSiteAddress)) {
            distributorProfile.addresses.push(this.getDefaultSeneSiteAddress());
        }

        if (!distributorProfile.contact) {
            distributorProfile.contact = {
                email: '',
                phoneNumber1: '',
                phoneNumber2: '',
                showPhone: false,
                showAddress: false,
            } as DistributorContact;
        };
        
        return distributorProfile;
    }

    componentDidMount() {
        if(this.props.distributor) {
            this.setState({
                distributorProfile: this.addMissingObjectsToDistributorProfile(this.props.distributor.profile)
            });
        }
    }

    componentDidUpdate(nextProps: SeneProfileDetailsStateProps) {
        if(nextProps.distributor !== this.props.distributor && this.props.distributor) {
            this.setState({
                distributorProfile: this.addMissingObjectsToDistributorProfile(this.props.distributor.profile)
            });
        }
    };

    updateContact = (e: React.ChangeEvent<HTMLInputElement>) => {
        const property = e.target.name;
        const value = e.target.value;
        this.setState(
            produce(draft => {
                draft.distributorProfile.contact[property] = value;
                return draft;
            })
        );
    }

    updateCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const property = e.target.name;
        const value = e.target.checked;
        this.setState(
            produce(draft => {
                draft.distributorProfile.contact[property] = value;
                return draft;
            })
        );
    }

    updateAddress = (e: React.ChangeEvent<any>) => {
        const property = e.target.name;
        const value = e.target.value;
        const index = this.state.distributorProfile.addresses.findIndex(s => (s.isSeneSiteAddress));
        this.setState(
            produce(draft => {
                draft.distributorProfile.addresses[index][property] = value;
                return draft;
            })
        );
    }

    updateProfile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const property = e.target.name;
        const value = e.target.value;
        this.setState(
            produce(draft => {
                draft.distributorProfile[property] = value;
                return draft;
            })
        );
    }

    getTranslator() {
        const dic = {
            'NZ': {
                'address1': 'address',
                'address3': 'suburb',
                'zip': 'postalCode',
                'zipError': 'postalCodeError'
            },
            'HK': {
                'address1': 'address1Hk',
                'address2': 'address2Hk',
                'address3': 'address3Hk',
                'city': 'cityHk',
                'state': 'stateHk'
            }
        };
        return new TranslationRemapper(dic, this.props.t).getTranslatorFor(this.props.distributor.countryName);
    }

    isValidated = () => {
        let senesitesAddress = this.state.distributorProfile.addresses.find(s => (s.isSeneSiteAddress !== null && s.isSeneSiteAddress));
        if(!senesitesAddress) {
            senesitesAddress = {} as DistributorAddress;
        }

        const countryName = this.props.distributor && this.props.distributor.countryName ? this.props.distributor.countryName : '';
        const distributorAddressValidator = new DistributorAddressValidator(this.getTranslator(), countryName);
        const distributorAddressResult = distributorAddressValidator.validate(senesitesAddress);

        const distributorContactValidator = new DistributorContactValidator(this.props.t);
        const distributorContactResult = distributorContactValidator.validate(this.state.distributorProfile.contact);

        const distributorProfileValidator = new DistributorProfileValidator(this.props.t);
        const distributorProfileResult = distributorProfileValidator.validate(this.state.distributorProfile);

        const errors = {
            ...distributorAddressResult.errors,
            ...distributorContactResult.errors,
            ...distributorProfileResult.errors
        };

        this.setState({
            errors: errors
        });
        return Object.keys(errors).length === 0;
    }

    
    handleSave = () => {
        const isValidated = this.isValidated();
        if(isValidated && this.state.distributorProfile) {
            const dist = this.props.distributor;

            this.props.updateDistributorMyProfile(dist.dist_ID, this.state.distributorProfile,
                { 
                    success: this.props.t('profileUpdateSuccess'), 
                    error: this.props.t('profileUpdateError') 
                })
            
        }
    }

    renderInfo() {

        const dist = this.state.distributorProfile;
        if (!dist.contact) {
            return <i className="fa fa-spinner spin" />;
        }
        const { t } = this.props;

		return <div >
            <TextInput 
                label={`${t('firstName')} *`} 
                name={"firstName"}
                disabled={true}
                value={dist.firstName} 
                error={this.state.errors.firstName} 
            />
            <TextInput 
                label={`${t('lastName')} *`} 
                name={"lastName"} 
                disabled={true}
                value={dist.lastName} 
                error={this.state.errors.lastName} 
            />
            <TextInput 
                label={`${t('phoneNumber1')} *`}
                name={"phoneNumber1"} 
                onChange={this.updateContact}
                value={dist.contact.phoneNumber1} 
                error={this.state.errors.phoneNumber1} 
            />
            <TextInput 
                label={`${t('phoneNumber2')}`} 
                name={"phoneNumber2"} 
                onChange={this.updateContact}
                value={dist.contact.phoneNumber2}
            />
            <TextInput 
                label={`${t('email')} *`} 
                name={"email"} 
                onChange={this.updateContact}
                value={dist.contact.email} 
                error={this.state.errors.email} 
            />
            <CustomInput
                type="checkbox"
                id="sameasshipping"
                label={t('showPhoneOption')}
                name={'showPhone'}
                checked={this.state.distributorProfile.contact.showPhone}
                onChange={this.updateCheckbox}
            />
		 </div>
	}

	renderAddress() {

        const dist = this.state.distributorProfile;
        if (!dist.addresses) {
            return <i className="fa fa-spinner spin" />;
        }
		const senesitesAddress = dist.addresses.find(s => (s.isSeneSiteAddress !== null && s.isSeneSiteAddress));
		if(senesitesAddress == null) {
            return;
        }

        const t = this.getTranslator();

		return(<div >
			 <TextInput
				 label={`${t('address1')} *`}
				 name={"address1"}
				 onChange={this.updateAddress}
				 value={senesitesAddress.address1}
				 id='Address1'
				 error={this.state.errors.address1} />

			 <TextInput
				 label={`${t('address2')}`}
				 name={"address2"}
				 onChange={this.updateAddress}
				 value={senesitesAddress.address2}
				 id='Address2' />

            {["MX", "NZ", "HK"].indexOf(this.props.distributor.countryName.toUpperCase()) > -1 &&
                <TextInput
                    label={`${t('address3')}`}
                    name={"address3"}
                    onChange={this.updateAddress}
                    value={senesitesAddress.address3}
                    id='Address3' />    
            }

			 <TextInput
				 label={`${t('city')} *`}
				 name={"city"}
				 onChange={this.updateAddress}
				 value={senesitesAddress.city}
				 id='City'
                error={this.state.errors.city} />

            {this.props.distributor.countryName.toUpperCase() !== "NZ" &&
                <SelectInput
                    label={`${t('state')} *`}
                    name={"stateName"}
                    options={this.regions}
                    onChange={this.updateAddress}
                    value={senesitesAddress.stateName}
                    id='StateName'
                    error={this.state.errors.stateName}
                />
            }

            {this.props.distributor.countryName.toUpperCase() !== "HK" &&
                <TextInput
                    label={`${t('zip')} *`}
                    name={"zip"}
                    onChange={this.updateAddress}
                    value={senesitesAddress.zip}
                    id='Zip'
                    error={this.state.errors.zip} />
            }

            <CustomInput
                type="checkbox"
                id="showAddressOption"
                label={t('showAddressOption')}
                name={'showAddress'}
                checked={this.state.distributorProfile.contact.showAddress}
                onChange={this.updateCheckbox}
            />
	   </div>);
	}
	 
	renderSocialLinks() {
         const dist = this.state.distributorProfile;
		 if (!dist) {
             return <i className="fa fa-spinner spin" />;
         }
         const { t } = this.props;
		 
		 return (
			 <div className="row">
				 <div className='col-md-6'>
                    <TextInput 
                        label={`${t('facebook')}`} 
                        name={"facebookAccount"} 
                        onChange={this.updateProfile}
                        value={dist.facebookAccount} 
                    />
                    <TextInput 
                        label={`${t('instagram')}`} 
                        name={"instagramAccount"} 
                        onChange={this.updateProfile}
                        value={dist.instagramAccount} 
                    />
				 </div>
				 <div className='col-md-6'>
                     <TextInput 
                        label={`${t('twitter')}`} 
                        name={"twitterAccount"} 
                        onChange={this.updateProfile}
                        value={dist.twitterAccount} 
                    />
                     <TextInput 
                        label={`${t('youtube')}`} 
                        name={"youTubeAccount"} 
                        onChange={this.updateProfile}
                        value={dist.youTubeAccount} 
                    />
				 </div>
			 </div>);
    }
    
    render () {
        const { t } = this.props;
        return this.state.distributorProfile && 
            <div id="Dist_Info">        
                <div className="row sub-panel-title">{t('personalInformation')}</div>
                <div className="row">
                    
                    <div className="col-md-6">
                        {this.renderInfo()}
                    </div>
                    <div className="col-md-6">
                        {this.renderAddress()}
                    </div> 
                </div>
                <hr />
                <div className="row sub-panel-title">{t('mySocialLinks')}</div>
                {this.renderSocialLinks()}
                <div className="row">
                    <div className="col-xs-12 col-md-10" />
                    <div className="col-xs-12 col-md-2">
                        <SeneButton onClick={this.handleSave}>
                            {t('save')}
                        </SeneButton>
                    </div>
                </div>
            </div>
            
    }
}

export default SeneProfileDetails;
