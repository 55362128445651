import React from 'react';
import { BlogFeed as IBlogFeed } from '../../../../models/blog';
import BlogFeed from '../../../common/blog/BlogFeed';
import './BlogContainer.scss'
import SectionHeader from '../../../common/headers/SectionHeader';
import i18n from '../../../../i18n';
import { withNamespaces } from 'react-i18next';

export interface BlogContainerProps {
    translation?: any;
    feed: IBlogFeed | null;
    t: i18n.TranslationFunction;
}

export class BlogContainer extends React.PureComponent<BlogContainerProps> {
    render() {
        const { t } = this.props;
        return (
            <>
                {
                    this.props.feed && this.props.feed.posts && this.props.feed.posts.length > 0 &&
                    <div className="container">
                        <SectionHeader>{t('blogFeeds')}</SectionHeader>
                        <BlogFeed feed={this.props.feed} />
                    </div>
                }
            </>
        )
    }
}

export default BlogContainer;
