import React from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import i18next from 'i18next';

import './SeneTablePagination.scss';
const entriesPerPage = 20;

const createPaginationSettings = (t?: i18next.TranslationFunction, customConfig?) => ({
    sizePerPage: entriesPerPage,
    prePageText: t ? t('previous') : 'Previous',
    nextPageText: t ? t('next') : 'Next',
    withFirstAndLast: false,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            <span>
                <span>{t ? t('rowsPerPage') : "Rows per page"} {entriesPerPage} </span>
            </span>
            <span>{from} - {to} {t ? t('of') : "of"} {size}</span>
        </span>
    ),
    ...customConfig
});


const senePaginationFactory = (t?, customConfig?) => {
    return paginationFactory(createPaginationSettings(t, customConfig));
}

export default senePaginationFactory;
