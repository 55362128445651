import React, { memo } from 'react';
import InputMask from 'react-input-mask';

export interface TextAreaProps {
    name: string;
    label: string;
    onChange?(e: React.ChangeEvent<any>): void;
    onBlur?(e: any): void;
    placeholder?: string;
    value?: string;
    error?: string;
    id?: string;
    key?: string;
    tabIndex?: number;
    autocomplete?: string;
    disabled?: boolean;
    mask?: string;
    rows?: number;
}

const SeneAreaInput = memo((params: TextAreaProps) => {
    const props = {
        className: 'form-control',
        tabIndex: params.tabIndex,
        name: params.name,
        placeholder: params.placeholder,
        value: params.value || '',
        id: params.id,
        onBlur: params.onBlur,
        onChange: params.onChange,
        key: params.key,
        autoComplete: params.autocomplete,
        disabled: params.disabled,
        mask: params.mask,
        rows: params.rows
    };

    return (
        <div className="form-group">
            <label className="font-weight-bold" htmlFor={params.name}>{params.label}</label>
            {
                params.mask
                    ? <InputMask {...props} />
                    : <textarea {...props} />
            }
            {params.error && <div className="alert alert-danger">{params.error}</div>}
        </div>
    );
});

export default SeneAreaInput;
