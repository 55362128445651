import { selectDistributorCountry } from './distributorSelectors';
import { State } from './../index';
import { selectLoggedInUserCountry } from './userSelectors';

export const selectRegions = (state: State) => state.regions;
export const selectRegionsForCountry = (state: State, countryCode: string) => state.regions[countryCode] || [];
export const selectRegionsForDistributorCountry = (state: State) => {
    const distCountryCode = selectDistributorCountry(state);
    return selectRegionsForCountry(state, distCountryCode);
};
export const selectRegionsForCustomerCountry = (state: State) => {
    const countryCode = (state.user && state.user.country && state.user.country !== '') ? 
        selectLoggedInUserCountry(state) :
        selectDistributorCountry(state);
    return selectRegionsForCountry(state, countryCode);
};
