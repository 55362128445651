import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as authActions from '../../../actions/authActions';
import { selectUserProfile, selectIsLoadingUser } from '../../../store/selectors/authSelectors';
import { hasCustomerRights } from '../../../utils/auth';
import { State } from '../../../store';

type withCustomerGuardProps = {
    user: any;
    isLoadingUser: boolean;
    notAuthorized();
}

export default function withCustomerGuard<OwnProps>(Component: React.ComponentType<OwnProps>) {
    class GuardedComponent extends React.Component<OwnProps & withCustomerGuardProps> {
        componentWillMount() {
            this.checkAuth();
        }

        componentDidUpdate() {
            this.checkAuth();
        }

        private checkAuth() {
            const { user, isLoadingUser, notAuthorized } = this.props;

            if (isLoadingUser) {
                return;
            }

            if (!this.isAuthed(user)) {
                notAuthorized();
            }
        }

        private isAuthed(user) {
            if (!user ||  !user.role) {
                return false;
            }

            return hasCustomerRights(user);
        }

        render() {
            return <Component {...this.props}/>
        }
    }

    const mapStateToProps = (state: State) => ({
        user: selectUserProfile(state),
        isLoadingUser: selectIsLoadingUser(state)
    });

    const mapDispatchToProps = dispatch => bindActionCreators({
        notAuthorized: authActions.notAuthorized
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(GuardedComponent as any);
}
