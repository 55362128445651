import React from 'react';

const Spinner = ({ style, className = '' }: { style?: React.CSSProperties, className?: string }) => {
    return (
        <h2 className={className} style={{ textAlign: 'center', ...style }}>
            <i className="fas fa-spinner fa-spin" />
        </h2>
    );
}

export default Spinner;
