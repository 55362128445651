import React from 'react';

import { DesktopOrTablet, Mobile } from '../../../common/responsive/ResponsiveHelpers';
import SectionHeader from '../../../common/headers/SectionHeader';
import SeneCarousel from '../../../common/SeneCarousel';
import i18n from '../../../../i18n';

export interface SeneHomeGalleryProps {
    images: string[];
    fallback: string;
    t: i18n.TranslationFunction
}

export class SeneHomeGallery extends React.PureComponent<SeneHomeGalleryProps> {

    private readonly numberOfAdjacentImagesForMobile = 1;
    private readonly numberOfAdjacentImagesForDesktop = 3;
    private readonly imageWidth = 350;
    private readonly imageHeight = 300;

    render() {

        const carouselImages = this.props.images;
        const {t} = this.props;

        return (
            <div className="gallery">
                <div className="container">
                    <div className="pb-3">
                        <SectionHeader>{t('gallery')}</SectionHeader>
                    </div>
                    <DesktopOrTablet>
                        <SeneCarousel images={carouselImages} fallback={this.props.fallback} imageWidth={this.imageWidth} imageHeight={this.imageHeight} numberOfAdjacentImages={this.numberOfAdjacentImagesForDesktop} />
                    </DesktopOrTablet>
                    <Mobile>
                        <SeneCarousel images={carouselImages} fallback={this.props.fallback} imageWidth={this.imageWidth} imageHeight={this.imageHeight} numberOfAdjacentImages={this.numberOfAdjacentImagesForMobile} />
                    </Mobile>
                </div>
            </div>
        )
    }
}

export default SeneHomeGallery;
