import React from 'react';
import { CustomInput, FormGroup } from 'reactstrap';
import { camelCase, uniqBy } from 'lodash';
import i18next from 'i18next';

import {
    CashOrCheckPaymentMethod, CreditCardPaymentMethod, Distributor,
    PublicPaymentMethod as PaymentMethod
} from '../../../../models/distributor';
import { PaymentType } from '../../../../models/payment';
import CreditCardForm, { CreditCardFormModel } from './CreditCardForm';
import { ValidationErrors } from '../../../../utils/validation/validator';
import { CreditCardValidator, SquarePaymentValidator } from './PaymentValidators';
import { CheckoutState, PaymentInfoState } from '../../../../reducers/checkout';
import { CountryState } from '../../../../models/region';
import SquareFormContainer from './SquareFormContainer';
import { SquarePaymentFormModel } from './SquareForm';

interface PaymentFormProps {
    t: i18next.TranslationFunction;
    distributor: Distributor;
    paymentMethods: PaymentMethod[];
    checkoutState: CheckoutState;
    savePaymentInfo(info: PaymentInfoState);
    regions: CountryState[];
}


interface PaymentFormState {
    selectedPaymentMethod: PaymentMethod;
    creditCardInfo: CreditCardFormModel;
    squarePaymentInfo: SquarePaymentFormModel;
    errors: {
        creditCardInfo: ValidationErrors<CreditCardFormModel>
        squarePaymentInfo: ValidationErrors<SquarePaymentFormModel>
    }
}

class PaymentForm extends React.Component<PaymentFormProps, PaymentFormState> {
    state = {
        selectedPaymentMethod: this.getDefaultSelectedPaymentMethod(),
        creditCardInfo: {
            agree: false,
            address: '',
            ccExpirationDate: '',
            ccNumber: '',
            ccType: '',
            city: '',
            cvvNumber: '',
            firstName: '',
            lastName: '',
            state: '',
            zipCode: '',
            ccImageUrl: '',
        },
        squarePaymentInfo: {
            nonce: '',
            last4: '',
            cardBrand: '',
            googlePay: false,
            applePay: false,
            masterpass: false,
            sourceId: "",
        },
        errors: {
            creditCardInfo: {},
            squarePaymentInfo: {}
        }
    }

    getDefaultSelectedPaymentMethod(): PaymentMethod {
        if (this.props.paymentMethods && this.props.paymentMethods.length == 1) {
            return this.props.paymentMethods[0];
        } else {
            return {} as PaymentMethod;
        }
    }

    get selectedPaymentType() {
        return this.state.selectedPaymentMethod.paymentMethodType;
    }

    componentDidMount() {
        this.loadSavedPaymentInfo();
    }

    isValidated() {
        let ccValidationResult = { isValid: true, errors: {} };
        let sqValidationResult = { isValid: true, errors: {} };

        if (this.selectedPaymentType === PaymentType.CreditCard) {
            const ccInfoValidator = new CreditCardValidator(this.props.t, 
                this.props.distributor.countryName,
                (this.state.selectedPaymentMethod as CreditCardPaymentMethod).selectedCreditCards,
                this.state.creditCardInfo.ccNumber);
            ccValidationResult = ccInfoValidator.validate(this.state.creditCardInfo);
        }

        if (this.selectedPaymentType === PaymentType.Square) {
            const sqValidator = new SquarePaymentValidator(this.props.t);
            sqValidationResult = sqValidator.validate(this.state.squarePaymentInfo);
        }

        if (this.selectedPaymentType && ccValidationResult.isValid && sqValidationResult.isValid) {
            this.props.savePaymentInfo({
                paymentMethod: this.state.selectedPaymentMethod,
                ccInfo: this.selectedPaymentType === PaymentType.CreditCard ? this.state.creditCardInfo : undefined,
                sqInfo: this.selectedPaymentType === PaymentType.Square ? this.state.squarePaymentInfo : undefined
            });
            return true;
        } else {
            this.setState({
                errors: {
                    creditCardInfo: ccValidationResult && ccValidationResult.errors || {},
                    squarePaymentInfo: sqValidationResult && sqValidationResult.errors || {}
                }
            });
            return false;
        }
    }

    loadSavedPaymentInfo() {
        const { paymentInfo } = this.props.checkoutState;
        if (paymentInfo) {
            const { paymentMethod, ccInfo, sqInfo } = paymentInfo;
            paymentMethod && this.setState({ selectedPaymentMethod: paymentMethod });
            ccInfo && this.setState({
                creditCardInfo: {
                    ...this.state.creditCardInfo,
                    ...ccInfo
                }
            });
            sqInfo && this.setState({
                squarePaymentInfo: {
                    ...this.state.squarePaymentInfo,
                    ...sqInfo
                }
            })
        }
    }

    handlePaymentMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const selectedPaymentMethod = this.props.paymentMethods.find(p => p.paymentMethodType === value);

        if (selectedPaymentMethod) {
            this.setState({ selectedPaymentMethod });
        }
    }

    handleCcInfoChange = (changes: any) => {
        this.setState({
            creditCardInfo: {
                ...this.state.creditCardInfo,
                ...changes
            }
        });
    }

    handleSquarePaymentInfoChange = (changes: any) => {
        this.setState({
            squarePaymentInfo: {
                ...this.state.squarePaymentInfo,
                ...changes
            }
        });
    }

    handleSquarePaymentErrorsChange = (changes: any) => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...changes
            }
        });
    }

    setSameAsShipping = () => {
        const shippingAddress = this.props.checkoutState.shippingAddress;

        shippingAddress && this.setState({
            creditCardInfo: {
                ...this.state.creditCardInfo,
                address: shippingAddress.address,
                address2: shippingAddress.address2,
                address3: shippingAddress.address3,
                city: shippingAddress.city,
                country: shippingAddress.country,
                zipCode: shippingAddress.zipCode,
                state: shippingAddress.state
            }
        });
    }

    renderPaymentInformation() {
        const { t } = this.props;
        switch (this.selectedPaymentType) {
            case PaymentType.CashOrCheck:
                return this.renderCashOrCheckInfo();

            case PaymentType.CreditCard:
                return <CreditCardForm
                    creditCardInfo={this.state.creditCardInfo}
                    onChange={this.handleCcInfoChange}
                    t={this.props.t}
                    errors={this.state.errors.creditCardInfo}
                    setSameAsShipping={this.setSameAsShipping}
                    regions={this.props.regions}
                    countryAlpha2={this.props.distributor.countryName}
                    distributorsAllowedCreditCards={(this.state.selectedPaymentMethod as CreditCardPaymentMethod).selectedCreditCards}
                />;

            case PaymentType.PayPal:
            case PaymentType.PayPalApi:
                return this.renderPayPalInfo(this.selectedPaymentType == PaymentType.PayPalApi);

            case PaymentType.Square:
                return (
                    <SquareFormContainer
                        t={t}
                        sqPaymentInfo={this.state.squarePaymentInfo}
                        errors={this.state.errors.squarePaymentInfo}
                        handleSqPaymentInfoChange={this.handleSquarePaymentInfoChange}
                        handleSqPaymentErrorsChange={this.handleSquarePaymentErrorsChange} />
                )

            default:
                return null;
        }
    }

    renderCashOrCheckInfo() {
        const { t } = this.props;

        const cashOrCheckPayment = this.props.distributor
            && this.props.distributor.paymentInfo
            && this.props.distributor.paymentInfo.paymentMethods.find(pm => pm.paymentMethodType == PaymentType.CashOrCheck) as CashOrCheckPaymentMethod;

        return cashOrCheckPayment
            && (
                <div >
                    <h4>{t('instructions')}</h4>
                    <div className="alert alert-warning">{cashOrCheckPayment.cashOrCheckInstructions}</div>
                </div>
            );
    }

    renderPayPalInfo(isPayPalApi) {
        const { t } = this.props;

        return (
            <div className="alert alert-warning">
                {isPayPalApi ? t('payPalApiPayment') : t('payPalPayment')}
            </div>
        )
    }

    render() {
        const { t } = this.props;

        // TODO: It seems like ECheck is not supported in the current version, so filter it out for now
        const paymentMethods = uniqBy(this.props.paymentMethods, 'paymentMethodType')
            .filter(pm => pm.paymentMethodType !== PaymentType.ECheck && pm.paymentMethodType !== PaymentType.Unknown);

        return (
            <>
                <h3 className="paragraph-title">{t('paymentMethod')}</h3>

                {paymentMethods.length != 0
                    ? <>
                        <FormGroup className="payment-form">
                            {
                                paymentMethods.map((p, i) => (
                                    <CustomInput
                                        key={i}
                                        id={i.toString()}
                                        type="radio"
                                        name="paymentMethod"
                                        onChange={this.handlePaymentMethodChange}
                                        value={p.paymentMethodType}
                                        checked={this.state.selectedPaymentMethod === p}
                                        label={t(camelCase(p.paymentMethodType.toString()))}/>
                                ))
                            }
                        </FormGroup>
                        {this.renderPaymentInformation()}
                    </>
                    :
                    <div className="alert alert-warning"> {t('noPaymentOptionMsg')} </div>
                }
            </>
        )
    }
}

export default PaymentForm;
