import axios from 'axios';
import { AdminPaymentOption as PaymentOption } from "../models/payment";
import { Distributor, SeneSiteInfo, DistributorProfile, HandlingInfo, ShippingInfo } from './../models/distributor';
import { ContactMeData } from '../models/contact';

export const getDistributorFromSeneSiteUrl = (seneSiteUrl: string): Promise<Distributor> => {
    return axios.get<Distributor>(`api/distributor/findBySeneSite?seneSite=${seneSiteUrl}`).then(res => {
        if(res.data.currencyCode) {
            return res.data;
        }
        else{
            throw new Error("Currency code is missing");
        }
    })
}

export const getDistributorFromDistId = (distId: number) => {
    return axios.get<Distributor>(`api/distributor/${distId}`).then(res => res.data);
}

export const updateDistributorSeneSiteInfo = (distId: number, seneSiteInfo: SeneSiteInfo) => {
    return axios.post(`/api/distributor/${distId}/mySeneSites`, seneSiteInfo);
}

export const updateDistributorHandlingInfo = (distId: number, handlingInfo: HandlingInfo) => {
    return axios.post(`/api/distributor/${distId}/handlingInfo`, handlingInfo);
}

export const deleteDistributorHandlingInfo = (distId: number) => {
    return axios.delete(`/api/distributor/${distId}/handlingInfo`);
}

export const updateDistributorShippingInfo = (distId: number, shippingInfo: ShippingInfo[]) => {
    return axios.post(`/api/distributor/${distId}/shippingInfo`, shippingInfo);
}

export const updateDistributorMyProfile = (distId: number, myProfile: DistributorProfile) => {
    return axios.post(`/api/distributor/${distId}/myProfile`, myProfile);
}

export const uploadProfileImage = (distId: number, file: any): Promise<Distributor> => {
    return axios.post(`/api/distributor/${distId}/UploadProfileImage`, file, 
    {headers: { 'Content-Type': 'multipart/form-data' }}).then(res => res.data);
}

export const postContactMessage = (distId: number, contactMeData: ContactMeData): Promise<any> => {
    return axios.post<any>(`api/distributor/${distId}/contact/SendMessage`, contactMeData).then(res => res.data);
}

export const getInstagramFeed = (distId: number) => {
    return axios.get(`/api/distributor/${distId}/Social/InstagramFeeds`).then(res => res.data.data);
}
