import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import './PlaceOrder.scss';
import { CartViewModel } from '../../../../models/cart';
import CartProduct from '../../cart/CartProduct';
import { CheckoutErrors, CheckoutState } from '../../../../reducers/checkout';
import { PaymentType } from '../../../../models/payment';
import OrderSummary from './OrderSummary';
import { DistributorState } from '../../../../reducers/distributor';
import { CashOrCheckPaymentMethod } from "../../../../models/distributor";
import withRetryComponent, { RetryComponentProps } from '../../../common/withRetryComponent';
import { ActionToastProperties } from '../../../../models/ui';
import SeneAreaInput from '../../../common/inputs/SeneAreaInput';
import { shouldSwithToMaintenance } from "../../../../utils/helpers";

interface PlaceOrderProps {
    cart: CartViewModel;
    checkoutState: CheckoutState;
    handlingRate: number;
    distributor: DistributorState;
    calculateCharges(toastProps?: ActionToastProperties);
    jumpToStep?(step: number);
    submitPayment();
    checkoutErrors?: CheckoutErrors;
    customerNotes: string;
    setCustomerNotes(note: string);
}

interface PlaceOrderState {
    isCalculatingTotals: boolean;
}

class PlaceOrder extends React.Component<PlaceOrderProps & RetryComponentProps & WithNamespaces, PlaceOrderState> {
    state = {
        isCalculatingTotals: false,
    }

    get totalItemCount() {
        return this.props.cart.items.reduce((acc, i) => acc += i.qty, 0);
    }

    get cashOrCheckInstructions() {
        const cashOrCheckPayment = this.props.distributor
            && this.props.distributor.paymentInfo
            && this.props.distributor.paymentInfo.paymentMethods.find(pm => pm.paymentMethodType == PaymentType.CashOrCheck) as CashOrCheckPaymentMethod;

        return cashOrCheckPayment
            && cashOrCheckPayment.cashOrCheckInstructions
            || '';
    }

    componentDidMount() {
        this.calculateCharges();
    }

    componentDidUpdate(prevProps: PlaceOrderProps) {
        if (
            this.props.checkoutErrors != prevProps.checkoutErrors && !this.state.isCalculatingTotals
        ) {
            this.calculateCharges();
        }
    }

    onSubmitOrder = () => {
        this.props.submitPayment();
    }

    customerNoteOnChanged = (e: React.ChangeEvent<any>) => {

        const element = e.target as HTMLInputElement;
        this.props.setCustomerNotes(element.value);
    }

    calculateCharges() {
        if(this.props.cart.items.length > 0)
        {
            this.setState({ isCalculatingTotals: true });
            this.props.calculateCharges({ error: this.props.t('calculateTotalsError') })
                .then(() => this.setState({ isCalculatingTotals: false }))
                .catch((exp) => {
                    shouldSwithToMaintenance(exp);
                    this.props.initFailed();
                });
        }
    }

    renderShippingInfo() {
        const t = this.props.t;
        const shippingMethod = this.props.checkoutState.shippingMethod || { carrierName: t('notApplicable') };
        const customerInfo = this.props.checkoutState.customerInfo!;
        const shippingAddress = this.props.checkoutState.shippingAddress!;

        return (
            <div className="card place-order-card place-order-card--shiping">
                <div className="row place-order-card__header">
                    <div className="col-8 place-order-card__title-container">
                        <h4 className="place-order-card__title mt-0">{t('shippingInfo')}</h4>
                    </div>
                    <div className="col-4 text-right">
                        <button className="btn btn-outline-primary"
                            onClick={e => this.props.jumpToStep && this.props.jumpToStep(0)}
                        >
                            {t('btnChange')}
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-5 place-order-card__label">
                        <strong>{t('shippingMethod')}</strong>
                    </div>
                    <div className="col-7">{shippingMethod.carrierName}

                    </div>
                </div>
                <div className="row">
                    <div className="col-5 place-order-card__label">
                        <strong>{t('shipTo')}</strong>
                    </div>
                    <div className="col-7">
                        <p>
                            {customerInfo.firstName} {customerInfo.lastName}
                            <br />
                            {shippingAddress.address} {shippingAddress.address2}
                            {shippingAddress.address3  &&
                                <span> {shippingAddress.address3}</span>
                            }
                            <br />
                            {shippingAddress.city}, {shippingAddress.state}
                            <br />
                            {shippingAddress.zipCode} {shippingAddress.country}
                            <br />
                            {t('phone')} {customerInfo.phone}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    renderPaymentInfo() {
        const { t } = this.props;

        return (
            <div className="card place-order-card">
                <div className="row place-order-card__header">
                    <div className="col-8 place-order-card__title-container">
                        <h4 className="place-order-card__title mt-0">{t('paymentInfo')}</h4>
                    </div>
                    <div className="col-4 text-right">
                        <button className="btn btn-outline-primary"
                            onClick={e => this.props.jumpToStep && this.props.jumpToStep(1)}
                        >
                            {t('btnChange')}
                        </button>
                    </div>
                </div>
                {this.renderPaymentMethodDetails()}
            </div>
        );
    }

    renderPaymentMethodDetails() {
        const type = this.props.checkoutState.paymentInfo && this.props.checkoutState.paymentInfo.paymentMethod.paymentMethodType;
        const { t } = this.props;

        switch (type) {
            case PaymentType.Square:
                return (
                    <>
                        {this.renderPaymentMethodName('Square')}
                    </>
                );
            case PaymentType.CashOrCheck:
                return (
                    <>
                        {this.renderPaymentMethodName(t('cashOrCheck'))}
                        {
                            this.cashOrCheckInstructions &&
                            <div style={{ display: 'flex' }}>
                                <div className="col-12 alert alert-warning">
                                    {this.cashOrCheckInstructions}
                                </div>
                            </div>
                        }
                    </>
                );
            case PaymentType.CreditCard:
                const ccInfo = this.props.checkoutState.paymentInfo
                    && this.props.checkoutState.paymentInfo.ccInfo;
                const ccNumber = ccInfo && ccInfo.ccNumber;
                const last4 = ccNumber && ccNumber.substring(ccNumber.length - 4);
                const name = last4 && `${t('ccEnding')} ${last4}` || '';

                return (
                    <>
                        {this.renderPaymentMethodName(name)}
                        {
                            ccInfo &&
                            <>
                                <div className="row">
                                    <div className="col-5 place-order-card__label">
                                        <strong>{t('billingAddress')}</strong>
                                    </div>
                                    <div className="col-7">
                                        <p>
                                            {ccInfo.firstName} {ccInfo.lastName}
                                            <br />
                                            {ccInfo.address} {ccInfo.address2}
                                            <br />
                                            {ccInfo.city}, {ccInfo.state}
                                            <br />
                                            {ccInfo.zipCode} {/*{ccInfo.country}*/}
                                        </p>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                )
            case PaymentType.PayPalApi:
                return (
                    <>
                        {this.renderPaymentMethodName('PayPal')}
                        <div style={{ display: 'flex' }}>
                            <div className="col-12 alert alert-warning">
                                {t('payPalApiPlaceOrder')}
                            </div>
                        </div>
                    </>
                );
            case PaymentType.PayPal:
                return (
                    <>
                        {this.renderPaymentMethodName('PayPal')}
                        <div style={{ display: 'flex' }}>
                            <div className="col-12 alert alert-warning">
                                {t('payPalPlaceOrder')}
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }

    }

    renderPaymentMethodName(methodName: string) {
        return methodName && (
            <div className="row">
                <div className="col-5 place-order-card__label">
                    <strong>{this.props.t('paymentMethod')}</strong>
                </div>
                <div className="col-7">{methodName}</div>
            </div>
        );
    }

    renderContinue = () => {
        if (this.props.cart.items.length > 0) {
            return <div id='inventoryError' className="alert alert-warning col-12" role="alert">
                <strong> {this.props.t('continueCheckout')}</strong>
            </div>;
        } else {
            return <span></span>
        }

    }

    renderAlerts() {
        const checkoutErrors = this.props.checkoutErrors;
        if (checkoutErrors && (checkoutErrors.declinedPaymentErrors || checkoutErrors.missingInventoryErrors)) {
            return <>
                {<div className="alert alert-danger col-12" role="alert">
                    {checkoutErrors.missingInventoryErrors &&
                        <div key={'missingItems'}>{this.props.t('notEnoughInventory')}</div>}
                    {checkoutErrors.missingInventoryErrors && checkoutErrors.missingInventoryErrors.map((error) => {
                        return <div key={error}>{error}</div>
                    })}
                    {checkoutErrors.declinedPaymentErrors && checkoutErrors.declinedPaymentErrors.map((error) => {
                        return <div key={error}>{error}</div>
                    })}
                </div>}
                {checkoutErrors.canContinue && this.renderContinue()}
            </>

        } else {
            return <span></span>
        }
    }

    renderTotal() {
        const { t } = this.props;
        const productsIncludeTaxes = this.props && this.props.checkoutState && this.props.checkoutState.totalCharges && this.props.checkoutState.totalCharges.productsIncludeTaxes;
        return (
            <>
                <span>{`${productsIncludeTaxes ? t('productTotal') : t('subTotal')} (${this.totalItemCount} ${t('items')}): `}</span>
                <span className="cart-subtotal__price">{this.props.cart.totalDisplayPrice}</span>
            </>
        )
    }

    render() {
        
        return (<>
            <div className="row">
                {this.renderAlerts()}
            </div>
            <div className="row order-summary">
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card-deck">
                                {this.renderShippingInfo()}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card-deck">
                                {this.renderPaymentInfo()}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" >
                            <h4 className="paragraph-title">{this.props.t('customerNotes')}</h4>
                        </div>
                        <div className="col-12">
                            <SeneAreaInput label='' name='customerComments' onChange={this.customerNoteOnChanged} value={this.props.customerNotes}></SeneAreaInput>
                        </div>
                    </div>
                    {this.props.cart.items.map(item => (
                        <CartProduct t={this.props.t} key={item.product.productId} cartProduct={item} />
                    ))}
                    <div className="cart-subtotal">
                        {
                            this.renderTotal()
                        }
                    </div>
                </div>
                <div className="col-md-4">
                    <OrderSummary
                        cart={this.props.cart}
                        totalCharges={this.props.checkoutState.totalCharges}
                        shippingMethod={this.props.checkoutState.shippingMethod}
                        handlingRate={this.props.handlingRate}
                        paymentInfoState={this.props.checkoutState.paymentInfo}
                        isLoading={this.state.isCalculatingTotals}
                        t={this.props.t}
                        onSubmitOrder={this.onSubmitOrder}
                        buttonLabel={'placeOrder'}
                    />
                </div>
            </div>
        </>
        )
    }
}

export default withRetryComponent()(withNamespaces('CheckoutPage')(PlaceOrder));
