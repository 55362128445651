import React from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';

import './SeneCarousel.scss';
import ImageComponent from './ImageComponent';

export interface SeneCarouselProps {
    images: string[];
    fallback?: string;
    imageWidth?: number;
    imageHeight?: number;
    imageCrop?:  'pad' | 'max' | 'crop' | 'stretch';
    numberOfAdjacentImages?: number;
}

export interface SeneCarouselState {
    activeIndex: number;
    images: any[]
}

class SeneCarousel extends React.PureComponent<SeneCarouselProps, SeneCarouselState> {
    animating = false;
    state = {
        activeIndex: 0,
        images: []
    };

    static defaultProps = {
        numberOfAdjacentImages: 1
    }

    componentDidMount() {
        const images = this.chunk([...this.props.images], this.props.numberOfAdjacentImages);
        this.setState({ images });
    }

    componentDidUpdate(prevProps: SeneCarouselProps) {
        if (prevProps.images !== this.props.images) {
            const images = this.chunk([...this.props.images], this.props.numberOfAdjacentImages);
            this.setState({ images });
        }
    }

    onExiting = () => {
        this.animating = true;
    }

    onExited = () => {
        this.animating = false;
    }

    next = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.images.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.images.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex = (newIndex) => {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    chunk = (array, size) => {
        let results: any[] = [];

        while (array.length) {
            results.push(array.splice(0, size));
        }

        return results;
    }

    render() {
        const { images, activeIndex } = this.state;
        const { imageWidth, imageHeight, imageCrop } = this.props;

        const slides = images.map((imageArr: any[], i) => (
            <CarouselItem
                onExiting={this.onExiting}
                onExited={this.onExited}
                key={`item-${i}`}
                className="carousel-item"
            >
                {
                    imageArr.map((image, i) => {
                        return (
                            <ImageComponent src={image} alt={`${i} slide`} className="carousel-image" 
                                        width={imageWidth}
                                        height={imageHeight}
                                        crop={imageCrop}
                                        key={`img-${i}`}
                                        fallback={this.props.fallback}/>
                        )
                    })
                }
            </CarouselItem>
        ));

        return images &&
            (
                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                    className="sene-carousel"
                >
                    <CarouselIndicators items={images as unknown as object[]} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
            )
    }
}

export default SeneCarousel;
