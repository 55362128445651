import React, { memo } from 'react';

export interface SeneAlertProps {
    success?: string;
    error?: string;
    id?: string;
}

const SeneAlert = memo((params: SeneAlertProps) => {
    if (params.success) {
        return <SeneSuccess {...{ message: params.success, id: params.id }} />
    } else if (params.error) {
        return <SeneError {...{ message: params.error, id:params.id }} />;
    }
    return (<></>);
});

const SeneSuccess = ({ message, id }: { message: string, id?: string }) => (
    <>
        <i className="fas fa-check" style={{ color: 'green' }}></i>
        <small className={"text-success font-weight-bold ml-2" + (id ? ` success-${id}` : '')}>{message}</small>
    </>
);

const SeneError = ({ message, id }: { message: string, id?: string }) => (
    <>
        <i className="fas fa-exclamation-triangle" style={{ color: 'red' }}></i>
        <small className={"text-danger font-weight-bold ml-2" + (id ? ` error-${id}` : '')}>{message}</small>
    </>
);

export default SeneAlert;
