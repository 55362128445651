import axios from 'axios';

import { AdminPaymentOption } from '../models/payment';

export const getAvailableAdminPaymentOptions = (distributorId: number) => {
    return axios.get<AdminPaymentOption[]>(`/api/Distributor/${distributorId}/AdminPaymentOptions`).then(res => res.data);
}

export const verifyPayPalApi = (clientId: string, clientSecret: string) => {
    return axios.get<string>(`/api/PaymentOptions/VerifyPayPalApi?clientId=${clientId}&clientSecret=${clientSecret}`).then(res => res.data);
}

export const getSquareURL = (distributorId: number) => {
    return axios.get<string>(`/api/Distributor/${distributorId}/SquarePayment/GetSquareURL`).then(res => res.data);
}

export const updateDistributorPaymentOptions = (distId: number, paymentOptions: AdminPaymentOption[]) => {
    return axios.post(`/api/distributor/${distId}/AdminPaymentOptions`, paymentOptions).then(res => res.data);
}