import React from 'react';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import i18next from 'i18next';
import { isEmpty } from 'lodash';

import { ProductVm, ProductAttribute } from '../../../models/product';
import { DesktopOrTablet, Mobile } from '../../common/responsive/ResponsiveHelpers';
import ProductAttributesMobile from './ProductAttributesMobile';
import Spinner from '../../common/Spinner';
import * as dompurify from 'dompurify';
interface ProductAttributesProps {
    product: ProductVm;
    locale: string;
    t: i18next.TranslationFunction;
}

interface ProductAttributesState {
    activeTabIndex: number;
}

class ProductAttributes extends React.PureComponent<ProductAttributesProps, ProductAttributesState> {
    state: ProductAttributesState = {
        activeTabIndex: 1
    }

    get productAttributes(): ProductAttribute {
        let attributes = this.props.product.productDetails && this.props.product.productDetails.productAttributes[this.props.locale];

        // try to match the language if can't find the exact locale
        if (!attributes) {
            const emptyAttributes: ProductAttribute = {
                benefits: '',
                details: '',
                applicationTechniques: '',
                ingredients: '',
                name: ''
            }

            if (this.props.product.productDetails) {
                const lang = this.props.locale.substring(0, 2);

                const localeWithSameLang = Object.keys(this.props.product.productDetails.productAttributes)
                    .find(key => key.substring(0, 2) === lang);

                attributes = localeWithSameLang
                    ? this.props.product.productDetails.productAttributes[localeWithSameLang]
                    : emptyAttributes;
            } else {
                attributes = emptyAttributes;
            }
        }

        return attributes;
    }

    getActiveClass = (index: number) => index === this.state.activeTabIndex ? 'active' : '';

    toggle = (index: number) => {
        if (this.state.activeTabIndex !== index) {
            this.setState({
                activeTabIndex: index
            });
        }
    }

    renderDesktop() {
        const { t } = this.props;

        return (
            <div className="row" style={{ marginTop: '1.5rem' }}>
                <div className="col-md-10 offset-md-1">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={this.getActiveClass(1)}
                                onClick={() => this.toggle(1)}
                            >
                                {t('benefits')}
                            </NavLink>
                        </NavItem>

                        {/*<NavItem>
                        <NavLink
                            className={this.getActiveClass(2)}
                            onClick={() => this.toggle(2)}
                        >
                            {t('details')}
                        </NavLink>
                    </NavItem>*/}
                        <NavItem>
                            <NavLink
                                className={this.getActiveClass(3)}
                                onClick={() => this.toggle(3)}
                            >
                                {t('ingredients')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={this.getActiveClass(4)}
                                onClick={() => this.toggle(4)}
                            >
                                {t('appTechniques')}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTabIndex}>
                        <TabPane tabId={1}>
                            <p className="product-details__tab" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(this.productAttributes.benefits) }}>
                            </p>
                        </TabPane>
                        <TabPane tabId={2}>
                            <p className="product-details__tab" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(this.productAttributes.details) }}>
                            </p>
                        </TabPane>
                        <TabPane tabId={3}>
                            <p className="product-details__tab" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(this.productAttributes.ingredients) }}>
                            </p>
                        </TabPane>
                        <TabPane tabId={4}>
                            <p className="product-details__tab" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(this.productAttributes.applicationTechniques) }}>
                            </p>
                        </TabPane>
                    </TabContent>
                </div>
            </div>

        )
    }

    render() {
        const { product } = this.props;

        if (product.productDetails) {
            if (isEmpty(product.productDetails.productAttributes)) {
                return null;
            } else {
                return <>
                    <DesktopOrTablet>
                        {this.renderDesktop()}
                    </DesktopOrTablet>
                    <Mobile>
                        <ProductAttributesMobile attributes={this.productAttributes} t={this.props.t} />
                    </Mobile>
                </>
            }
        }

        return null;
    }
}

export default ProductAttributes;
