import React, { Validator } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import './SeneSiteApplicationForm.scss';
import SeneSiteApplication from '../../../assets/images/senesite_application.png';
import SenegenceLogo from '../../../assets/images/senegence_logo.png';
import { SeneImage } from '../../common/Images';

class SeneSiteApplicationFormConfirmation extends React.PureComponent<WithNamespaces> {

    renderFooter = () => {
        const { t } = this.props;
        const year = (new Date()).getFullYear();
        return (
            <footer className="mt-4">
                <p>&copy;{` ${year} ${t('allRightsReserved')}`}</p>
                <p>{t('noPortionCanBeReproduced')}</p>
                <p>
                    <span>{`${t('clickHereFor')} `}</span>
                    <span><a href="https://assets.senegence.com/SenegenceCom/Senesite/Application/TermsOfUse.pdf">{t('termsOfUse')}</a></span>
                    <span>{` ${t('and')} `}</span>
                    <span><a href="https://assets.senegence.com/SenegenceCom/Senesite/Application/PrivacyPolicyEnglish.pdf">{t('privacyStatement')}</a></span>
                </p>
            </footer>
        )
    }

    render() {
        return (
            <div className="container application-form">
                <div className="row text-center justify-content-center spacer">
                    <div className="col-8 ">
                        <SeneImage {...{
                            src: SenegenceLogo,
                            className: 'header-image mt-4 mb-2'
                        }} />
                    </div>
                </div>
                <div className="row justify-content-center spacer">
                    <div id="applicationinprogress" className="col-8 text-center py-5 ">
                        <h1>{this.props.t('seneSiteApplicationFormAccepted')}</h1>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-8 text-center">
                        {this.renderFooter()}
                    </div>
                </div>
            </div>
        )
    }

}

export default withNamespaces('SeneSiteApplicationForm')(SeneSiteApplicationFormConfirmation);
