import React from 'react';
import memoize from 'memoize-one';

import { cloneDeep } from 'lodash';
import Spinner from '../../common/Spinner';
import './MySeneSitesPage.scss';
import { DistributorState } from '../../../reducers/distributor';
import { MarketingState } from '../../../reducers/marketing';
import { MarketingBanner } from '../../../models/marketing';
import { Distributor } from '../../../models/distributor';
import ImageComponent from '../../common/ImageComponent';
import i18n from '../../../i18n';

import { CustomInput } from 'reactstrap';

interface IBannerListElement {
    banner: MarketingBanner;
    isChecked: boolean;
}

interface MySeneSitesPageStateProps {
    distributor: DistributorState;
    banners: Array<MarketingBanner>;
    setDistributor(dist: Distributor);
    t: i18n.TranslationFunction;
}
type MySeneSitesPageProps = MySeneSitesPageStateProps;

class MySeneSitesBanner extends React.Component<MySeneSitesPageProps> {

    render() {
        const { t } = this.props;
        const selectedLocale = this.props.distributor && this.props.distributor.countryID == 6 ? 'en-hk' : 'en-us';
        const banners = this.props.banners.filter(x => x.locale.toLowerCase() == selectedLocale);
        if (banners && banners.length > 0 && this.props.distributor) {
            let collection = this.createBannerArrays(this.props.distributor, banners);
            let bundleIndex = 1;
            return  <> 
                        <div className="row admin-personal-msg">{t('bannersTitle')}</div> 
                        {collection.map((row, i) => (
                            <div className="card-deck" key={i} >
                                {
                                    row.map((element: IBannerListElement, j) => (
                                        <div className='card' key={`card-${i}-${j}`} >
                                            <CustomInput
                                                id={`checkbox-${i}-${j}`}
                                                type="checkbox"
                                                label={`${t('bundleTitle')} ${t('zero')}${t((bundleIndex++).toString())}`}
                                                checked={element.isChecked}
                                                onChange={e => this.handleCheckbox(e, element.banner.image)}
                                                className='margin-left-checkbox-fix'
                                                />
                                            <ImageComponent src={`products/${element.banner.image}`} alt={`${i} slide`} className="banner-image" height={300} crop='max' key={`img-${i}-${j}`} />
                                        </div>
                                    ))
                                }
                            </div>
                        ))}
                    </>
            
        }
        else {
            return <Spinner />;
        }
    }

    createBannerArrays = memoize((distributor: Distributor, banners: Array<MarketingBanner>) => {
        const selectedLocale = this.props.distributor && this.props.distributor.countryID == 6 ? 'en-hk' : 'en-us';
        const BannerList = this.props.banners.filter(x => x.locale.toLowerCase() == selectedLocale)
        const rows = Math.ceil(BannerList.length / 3);
        let collection: any[][] = [[]];
        for (let i = 0; i < rows; i++) {
            const offset = i * 3;
            const row = BannerList.slice(offset, offset+3);
            collection[i] = [] as Array<IBannerListElement>;
            for (let j = 0; j < row.length; j++) {
                const isChecked = this.props.distributor != null && this.props.distributor.seneSiteInfo.seneSiteLooks.banners
                    .findIndex((a) => a.image === row[j].image) > -1;
                collection[i][j] = {
                    banner: row[j],
                    isChecked
                }  
            }
        }
        return collection;
    })

    handleCheckbox = (e, path: string) => {
        if(!this.props.distributor) return;
        let distributorCopy = cloneDeep(this.props.distributor);
        let banners = distributorCopy.seneSiteInfo.seneSiteLooks.banners;
        const selectedLocale = this.props.distributor && this.props.distributor.countryID == 6 ? 'en-hk' : 'en-us';

        if (e.target.checked) {

            if (banners.findIndex(b => b.image === path) === -1) {
                banners.push({ locale: selectedLocale, image: path, required: true });
            }
        }
        else {
            const index = banners.findIndex(b => b.image === path)
            banners.splice(index, 1);
        }
        this.props.setDistributor(distributorCopy);
    }
}

export default MySeneSitesBanner