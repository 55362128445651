import React from 'react';
import { orderBy } from 'lodash';

import { Category } from '../../../../models/category';
import CategoryTile from './CategoryTile';

interface CategoryGridProps {
    categories: Category[];
    locale: string;
}

const CategoryGrid = ({categories, locale}: CategoryGridProps) => {
    const orderedCategories = orderBy(categories, ['orderBy']);

    return (
        <div className="row" style={{marginTop: '10px'}}>
            {
                orderedCategories.map(cat => <CategoryTile key={cat.categoryId} category={cat} locale={locale} />)
            }
        </div>
    );
};

export default CategoryGrid;
