import { Thunk } from './../store';
import * as orderApi from './../api/orderApi';
import { OrderStatus } from '../models/order';
import { setBlockUi } from './ui';
import * as toast from './../utils/toastHelper';
import { ActionToastProperties } from '../models/ui';
import { shouldSwithToMaintenance } from "../utils/helpers";

export enum OrderActionTypes {
    getAllOrdersForDistributorSuccess = '[Order] Get All Orders For Distributor Success',
    getAllOrdersForDistributorError = '[Order] Get All Orders For Distributor Error',
    updateDistributorOrderStatusSuccess = '[Order] Update Distributor Order Status Success',
    updateDistributorOrderStatusError = '[Order] Update Distributor Order Status Error'
}


export const getAllOrdersForDistributor = (distId: number): Thunk => (dispatch, getState) => {
    const state = getState(),
        distributor = state && state.distributor;

    if (!distributor || !distId) {
        return;
    }

    return orderApi.getAllOrdersForDistributor(distId)
        .then(orders => {
            dispatch({ type: OrderActionTypes.getAllOrdersForDistributorSuccess, orders });
        })
        .catch(exp => {
            shouldSwithToMaintenance(exp);
            dispatch({ type: OrderActionTypes.getAllOrdersForDistributorError });
        });
}

export const updateDistributorOrderStatus = (distId: number, orderId: number, orderStatus: OrderStatus, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    if (!distId || !orderId || !orderStatus) {
        return;
    }

    dispatch(setBlockUi(true));
    return orderApi.updateDistributorOrderStatus(distId, orderId, orderStatus)
        .then(response => {
            toast.success(toastProps);
            dispatch(setBlockUi(false));
            dispatch({ type: OrderActionTypes.updateDistributorOrderStatusSuccess, order: response.data });
        })
        .catch(exp => {
            shouldSwithToMaintenance(exp);
            toast.success(toastProps);
            dispatch(setBlockUi(false));
            dispatch({ type: OrderActionTypes.updateDistributorOrderStatusError });
        })
}
