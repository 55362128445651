import React from 'react';

import { Category } from '../../../../models/category';
import SeneGradientHeader from '../../../common/headers/SeneGradientHeader';
import CategoryGrid from './CategoryGrid';
import i18n from '../../../../i18n';
import Spinner from "../../../common/Spinner";
import SectionHeader from '../../../common/headers/SectionHeader';

interface ShopCategoriesProps {
    categories: Category[];
    t: i18n.TranslationFunction;
    locale: string;
    isCategoryQueried: boolean;
}

const ShopCategories = ({ categories, t, locale, isCategoryQueried }: ShopCategoriesProps) => (
    <>
        <SeneGradientHeader>{t('shopNow')}</SeneGradientHeader>
        {
            isCategoryQueried ? 
                (categories.length > 0 ? 
                    <CategoryGrid categories={categories} locale={locale} /> : 

                    <div style={{marginTop: '2rem'}}>
                        <SectionHeader>{t('noProductsFound')}</SectionHeader>
                    </div>
                ) : <Spinner />
        }
        
    </>
);

export default ShopCategories;
