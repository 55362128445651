import { CreditCard } from "../../models/payment";
import { isCreditCardValid, isCvvValid } from "./../creditCardHelper";

export interface IValidator<T> {
    validate(instance: Readonly<T>): ValidationResult;
}

export interface ValidationFailure {
    errorMessage: string;
    attemptedValue: any;
}

export type ValidationErrors<T = {}> = {
    [P in keyof T]?: string;
}

export abstract class Validator<T> implements IValidator<T> {
    private rules: PropertyRule<T>[] = [];

    validate = (instance: Readonly<T>): ValidationResult => {
        if (!instance) {
            throw new Error("instance is null");
        }

        const results = this.validateContext(instance as Readonly<T>);

        return results;
    }

    private validateContext = (context: Readonly<T>): ValidationResult => {
        const errors: ValidationErrors = {};

        this.rules.forEach((rule) => {
            const results = rule.validate(context);
            if (results.length > 0) {
                errors[rule.propertyName] = (results[0].errorMessage);
            }
        });

        return new ValidationResult(errors);
    }

    ruleFor(propertyName: Extract<keyof T, string>): RuleBuilder<T> {
        const rule = new PropertyRule<T>(propertyName);
        this.rules.push(rule);
        const builder = new RuleBuilder(rule, this);
        return builder;
    }
}

export class ValidationResult {
    get isValid(): boolean { return Object.keys(this.errors).length === 0; }

    constructor(public errors: ValidationErrors) { }
}

class PropertyRule<T> {
    validators: PropertyValidator<T>[] = [];
    constructor(public propertyName: string) { }

    addValidator = (validator: PropertyValidator<T>) => {
        this.validators.push(validator);
    }

    validate = (context: Readonly<T>): ValidationFailure[] => {
        const results: ValidationFailure[] = [];
        this.validators.forEach((validator) => {
            const failures = this.invokePropertyValidator(context, validator, this.propertyName);
            Object.keys(failures).forEach(key => {
                results.push(failures[key])
            })

        });

        return results;
    }

    invokePropertyValidator = (
        context: Readonly<T>,
        validator: PropertyValidator<T>,
        propertyName: string): ValidationFailure[] => {

        const propertyContext = new PropertyValidatorContext(context, this, propertyName);
        const failures = validator.validate(propertyContext);
        return failures;
    }
}

class RuleBuilder<T> {
    constructor(public rule: PropertyRule<T>, public validator: IValidator<T>) { }

    setValidator = (validator: PropertyValidator<T>) => {
        this.rule.addValidator(validator);
    }

    notEmpty = (message?: string): RuleBuilder<T> => {
        this.setValidator(new NotEmptyValidator(message));
        return this;
    }

    isSame = (propertyName: string, errorMessage?: string): RuleBuilder<T> => {
        this.setValidator(new IsSameValidator(propertyName, errorMessage));
        return this;
    }

    isTrue = (message?: string): RuleBuilder<T> => {
        this.setValidator(new IsTrueValidator(message));
        return this;
    }

    email = (message?: string): RuleBuilder<T> => {
        this.setValidator(new EmailValidator(message));
        return this;
    }

    phone = (message?: string, country?: string): RuleBuilder<T> => {
        this.setValidator(new PhoneValidator(message, country));
        return this;
    }

    creditCard = (distributorAllowedCreditCards: CreditCard[], message?: string): RuleBuilder<T> => {
        this.setValidator(new CreditCardValidator(distributorAllowedCreditCards, message));
        return this;
    }

    cvv = (distributorAllowedCreditCards: CreditCard[], ccNumber: string, message?: string): RuleBuilder<T> => {
        this.setValidator(new CvvValidator(distributorAllowedCreditCards, ccNumber, message));
        return this;
    }

    expirationDate = (message?: string): RuleBuilder<T> => {
        this.setValidator(new ExpirationValidator(message));
        return this;
    }

    min = (minimumValue: number, message?: string): RuleBuilder<T> => {
        this.setValidator(new MinimumValidator(minimumValue, message));
        return this;
    }

    max = (maximumValue: number, message?: string): RuleBuilder<T> => {
        this.setValidator(new MaximumValidator(maximumValue, message));
        return this;
    }

    lessThan = (maximumValue: number, message?: string): RuleBuilder<T> => {
        this.setValidator(new LessThanValidator(maximumValue, message));
        return this;
    }

    greaterThan = (minimumValue: number, message?: string): RuleBuilder<T> => {
        this.setValidator(new GreaterThanValidator(minimumValue, message));
        return this;
    }

    // youtubeVideo = (message?: string): RuleBuilder<T> => {
    //     this.setValidator(new YouTubeVideoValidator(message));
    //     return this;
    // }

    isFloat = (message?: string): RuleBuilder<T> => {
        this.setValidator(new FloatValidator(message));
        return this;
    }

    birthDateDayMonth = (message?: string): RuleBuilder<T> => {
        this.setValidator(new BirthDateMonthValidator(message));
        return this;
    }
}


class PropertyValidatorContext<T> {
    propertyValue: any;
    constructor(public instance: Readonly<T>, public rule: PropertyRule<T>, public propertyName: string) {
        const instanceKeys = Object.keys(instance);

        if (instanceKeys.indexOf(propertyName) === -1) {
            this.propertyValue = null;
        } else {
            this.propertyValue = instance[propertyName];
        }
    }
}

abstract class PropertyValidator<T> {
    validate(context: PropertyValidatorContext<T>): ValidationFailure[] {
        const results: ValidationFailure[] = [];
        if (!this.isValid(context)) {
            results.push({
                errorMessage: this.prepareMessage(context),
                attemptedValue: context.propertyValue
            });
        }
        return results;
    }
    abstract isValid(context: PropertyValidatorContext<T>): boolean;
    abstract prepareMessage(context: PropertyValidatorContext<T>): string;
}


class NotEmptyValidator<T = {}> extends PropertyValidator<T> {
    constructor(public errorMessage?: string) { super(); }
    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is empty`;
    }

    isValid(context: PropertyValidatorContext<T>): boolean {
        if (context.propertyValue === undefined || context.propertyValue === null) {
            return false;
        } else if (!Number.isInteger(context.propertyValue) && (!context.propertyValue.trim() || !context.propertyValue)) {
            return false;
        }
        else {
            return true;
        }
    }
}

class EmailValidator<T = {}> extends PropertyValidator<T>  {
    constructor(public errorMessage?: string) { super(); }
    isValid(context: PropertyValidatorContext<T>): boolean {
        const value = context.propertyValue;
        if (value === undefined || !value) return true;


        const regex = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]{2,63})+$');
        if (!regex.test(context.propertyValue)) {
            return false;
        }
        return true;
    }
    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is not a valid email address`;
    }

}

class PhoneValidator<T = {}> extends PropertyValidator<T> {
    constructor(public errorMessage?: string, public country?: string) { super(); }
    isValid(context: PropertyValidatorContext<T>): boolean {
        let value = context.propertyValue;
        if (value === undefined || !value) return true;
        const phoneChars = ['-', ' ', '\\+', '\\(', '\\)'];
        phoneChars.forEach((char) => {
            value = value.replace(new RegExp(char, 'g'), '');
        });

        let matchResult;

        if (this.country) {
            if (this.country === 'US' || this.country === 'CA') {

                const regexUSCA = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})$/;
                matchResult = regexUSCA.exec(context.propertyValue);

            }
            else if (this.country === 'AU') {
                const regexAU = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
                matchResult = regexAU.exec(context.propertyValue);
            }

            if (matchResult !== null) {
                if (matchResult[0]) {
                    return true;
                }
            }
        }
        else {
            const regexDigits = new RegExp('^(0|[0-9][0-9]*)$');
            return regexDigits.test(value);
        }

        return false;
    }
    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is not a valid phone number`;
    }
}


class FloatValidator<T = {}> extends PropertyValidator<T> {
    constructor(public errorMessage?: string) { super(); }
    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is empty`;
    }

    isValid(context: PropertyValidatorContext<T>): boolean {
        if (context.propertyValue === undefined || context.propertyValue === null) {
            return false
        } else {
            return !isNaN(parseFloat(context.propertyValue));
        }
    }
}

class IsSameValidator<T = {}> extends PropertyValidator<T> {
    constructor(public otherPropertyName: string, public errorMessage?: string) { super(); }

    isValid(context: PropertyValidatorContext<T>): boolean {
        const otherValue = context.instance[this.otherPropertyName];
        if (!otherValue
            || otherValue !== context.propertyValue) {
            return false;
        }
        return true;
    }
    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} and ${this.otherPropertyName} are not the same`;
    }
}

class BirthDateMonthValidator<T = {}> extends PropertyValidator<T> {
    constructor(public errorMessage?: string) { super(); }

    isValid(context: PropertyValidatorContext<T>): boolean {
        if (!context.propertyValue) {
            return true;
        }

        const sections = context.propertyValue.split("/");
        const month = parseInt(sections[0].replace(/\D+/g, ''));
        const day = parseInt(sections[1].replace(/\D+/g, ''));
        const year = 1904; //1904 is a leap year, and Senegence does not use the year part of the date

        if (isNaN(day) && isNaN(month)) {
            return true;
        }

        const date = new Date(year, month - 1, day);

        if (date.getFullYear() == year && date.getMonth() == month - 1 && date.getDate() == day &&
            month > 0 && month < 13 && day > 0 && day < 32) {
            return true;
        }

        return false;
    }

    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is not a valid birth date day and month`;
    }
}

class IsTrueValidator<T = {}> extends PropertyValidator<T> {
    constructor(public errorMessage?: string) { super(); }

    isValid(context: PropertyValidatorContext<T>): boolean {
        if (!context.propertyValue || context.propertyValue === false) {
            return false;
        }
        return true;
    }
    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is false`;
    }
}

class MinimumValidator<T = {}> extends PropertyValidator<T> {
    constructor(public minimumValue: number, public errorMessage?: string) { super(); }
    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is empty`;
    }

    isValid(context: PropertyValidatorContext<T>): boolean {
        if (context.propertyValue === undefined || context.propertyValue === null) {
            return false
        } else {
            return context.propertyValue >= this.minimumValue;
        }
    }
}

class MaximumValidator<T = {}> extends PropertyValidator<T> {
    constructor(public maximumValue: number, public errorMessage?: string) { super(); }
    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is empty`;
    }

    isValid(context: PropertyValidatorContext<T>): boolean {
        if (context.propertyValue === undefined || context.propertyValue === null) {
            return false
        } else {
            return context.propertyValue <= this.maximumValue;
        }
    }
}

class LessThanValidator<T = {}> extends PropertyValidator<T> {
    constructor(public maximumValue: number, public errorMessage?: string) { super(); }
    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is empty`;
    }

    isValid(context: PropertyValidatorContext<T>): boolean {
        if (context.propertyValue === undefined || context.propertyValue === null) {
            return false
        } else {
            return context.propertyValue < this.maximumValue;
        }
    }
}

class GreaterThanValidator<T = {}> extends PropertyValidator<T> {
    constructor(public minimumValue: number, public errorMessage?: string) { super(); }
    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is empty`;
    }

    isValid(context: PropertyValidatorContext<T>): boolean {
        if (context.propertyValue === undefined || context.propertyValue === null) {
            return false
        } else {
            return context.propertyValue > this.minimumValue;
        }
    }
}

class CreditCardValidator<T = {}> extends PropertyValidator<T> {
    private distributorAllowedCreditCards;
    constructor(distributorAllowedCreditCards: CreditCard[], public errorMessage?: string) {
        super();
        this.distributorAllowedCreditCards = distributorAllowedCreditCards;
    }

    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is invalid`;
    }

    isValid(context: PropertyValidatorContext<T>): boolean {
        let result = false;
        if (this.distributorAllowedCreditCards) {
            result = isCreditCardValid(this.distributorAllowedCreditCards, context.propertyValue);
        }
        return result;
    }
}

class CvvValidator<T = {}> extends PropertyValidator<T> {
    private distributorAllowedCreditCards;
    private ccNumber;
    constructor(distributorAllowedCreditCards: CreditCard[], ccNumber: string, public errorMessage?: string) {
        super();
        this.distributorAllowedCreditCards = distributorAllowedCreditCards;
        this.ccNumber = ccNumber;
    }

    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is invalid`;
    }

    isValid(context: PropertyValidatorContext<T>): boolean {
        let result = false;
        if (this.distributorAllowedCreditCards && this.ccNumber) {
            result = isCvvValid(this.distributorAllowedCreditCards, this.ccNumber, context.propertyValue);
        }
        return result;
    }
}

class ExpirationValidator<T = {}> extends PropertyValidator<T> {
    constructor(public errorMessage?: string) { super(); }
    prepareMessage(context: PropertyValidatorContext<T>): string {
        return this.errorMessage || `${context.propertyName} is not a valid expiration date`;
    }

    isValid(context: PropertyValidatorContext<T>): boolean {
        if (context.propertyValue === undefined || context.propertyValue === null) {
            return false;
        } else {
            const regex = new RegExp('(0[1-9]|10|11|12)/20[0-9]{2}$');
            return regex.test(context.propertyValue);
        }
    }
}