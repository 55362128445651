import React from 'react';
import memoize from 'memoize-one';
import * as dompurify from 'dompurify';

import { CustomContent } from './../../../models/distributor';

export interface SeneHomeCustomContentStateProps {
    currentLanguage: string,
    contents: CustomContent[]
}

interface SeneHomeCustomContentState {
    customContent: CustomContent | null
}

export class SeneHomeCustomContent extends React.PureComponent<SeneHomeCustomContentStateProps, SeneHomeCustomContentState> {

    state: SeneHomeCustomContentState = {
        customContent: null
    }

    componentDidMount() {
        this.setState({
            customContent: this.getContent()
        });
    }

    componentDidUpdate(prevProps: SeneHomeCustomContentStateProps) {
        if (prevProps.currentLanguage !== this.props.currentLanguage) {
            this.setState({
                customContent: this.getContent()
            });
        }
    }

    private getContent(): CustomContent {
        
        let content;
        const language = this.props.currentLanguage.split('-')[0];
        this.props.contents.forEach(c => {
            const locale = c.locale.split('-')[0];
            if (locale === language) {
                content = c;
            }
        })

        // en by default
        if (!content) {
            content = this.props.contents.find(c => c.locale.split('-')[0] === 'en');
        }

        // fallback
        if (!content) {
            content = this.props.contents.length > 0 && this.props.contents[0];
        }
        return content;
    }

    sanitize = memoize((content: string) => {
        return dompurify.sanitize(content);
    })

    render() {
        return (
            this.state.customContent &&
            <div dangerouslySetInnerHTML={{ __html: this.sanitize(this.state.customContent.content) }} />   
        )
}
}

export default SeneHomeCustomContent;
