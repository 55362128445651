import i18next from 'i18next';

import { Validator } from '../../../utils/validation/validator';
import { ContactMeData } from '../../../models/contact';

export class ContactMeValidator extends Validator<ContactMeData> {
    constructor(t: i18next.TranslationFunction, captchasDisabled: boolean) {
        super();

        this.ruleFor('name').notEmpty(t('nameRequired'));
        this.ruleFor('sender').notEmpty(t('emailRequired')).email(t('invalidEmail'));
        this.ruleFor('message').notEmpty(t('messageRequired'));
        this.ruleFor('phone').notEmpty(t('phoneMessage')).phone(t('invalidPhone'));
        if(!captchasDisabled) {
            this.ruleFor('recaptchaToken').notEmpty(t('recaptchaTokenRequired'));
        }
    }
}