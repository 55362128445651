import React from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroup,
    Input,
    InputGroupAddon,
    Button
} from 'reactstrap';

interface SeneNumberSelectProps {
    value: number;
    onChange(val: number);
    isNonPositiveAllowed?: boolean;
}

interface SeneNumberSelectState {
    internalValue: number;
    isTextInputMode: boolean;
}

class SeneNumberSelect extends React.PureComponent<SeneNumberSelectProps, SeneNumberSelectState> {
    state: SeneNumberSelectState = {
        internalValue: 0,
        isTextInputMode: false
    };
    textInputRef = React.createRef<HTMLInputElement>();

    componentDidMount() {
        this.setState({ internalValue: this.props.value });
    }

    componentDidUpdate(prevProps: SeneNumberSelectProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({ internalValue: this.props.value });
        }
    }

    setValue(val: number) {
        this.props.onChange(val);
    }

    setTextInputMode = () => {
        this.setState({ isTextInputMode: true }, () => { this.textInputRef.current!.select() });
    }

    handleInternalValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseInt(e.target.value);
        if (isNaN(value) || (!this.props.isNonPositiveAllowed && value == 0)) {
            value = 1;
        }
        if(!this.props.isNonPositiveAllowed && value < 0)
        {
            value = -value;
        }
        this.setState({ internalValue: value });
    }

    handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.submitChange();
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.exitTextInputMode();
        }
    }

    submitChange = () => {
        this.props.onChange(this.state.internalValue);
        this.exitTextInputMode();
    }

    exitTextInputMode = () => {
        this.setState({
            internalValue: this.props.value,
            isTextInputMode: false
        });
    }

    render() {
        return (
            <div className="select-number">
                {
                    this.state.isTextInputMode
                        ? (
                            <InputGroup>
                                <Input
                                    style={{ flex: '0 0 75px' }}
                                    value={this.state.internalValue}
                                    onChange={this.handleInternalValueChange}
                                    onKeyDown={this.handleOnKeyDown}
                                    innerRef={this.textInputRef} />
                                <InputGroupAddon addonType="append">
                                    <Button outline color="danger" onClick={this.exitTextInputMode}>
                                        <i className="fas fa-times"></i>
                                    </Button>
                                    <Button color="secondary" onClick={this.submitChange}>
                                        <i className="fas fa-check"></i>
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        ) : (
                            <UncontrolledDropdown>
                                <DropdownToggle tag="button" className="form-control" caret>
                                    {this.props.value}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {Array.from(Array(10).keys()).map(i => (
                                        <DropdownItem onClick={() => this.setValue(i+1)} key={i + 1}>{i + 1}</DropdownItem>
                                    ))}
                                    <DropdownItem divider />
                                    <DropdownItem onClick={this.setTextInputMode}>10+</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )
                }
            </div>
        )
    }
}

export default SeneNumberSelect;
