import React from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import i18next from 'i18next';

import { IBigCalendarEvent } from './Calendar';
import SeneButton from '../inputs/SeneButton';

export interface EventDetailsModalStateProps {
    t: i18next.TranslationFunction;
    isOpen: boolean;
    event: IBigCalendarEvent;
    isAdmin: boolean;
    edit();
    delete();
    close();
}

class EventDetailsModal extends React.PureComponent<EventDetailsModalStateProps> {

    render() {
        const { t, event, isAdmin } = this.props;
        const startDate = moment(event.start).format('MM/DD/YYYY h:mm A');
        const endDate = moment(event.end).format('MM/DD/YYYY h:mm A');

        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.close} className="modal-dialog modal-lg">
                <div className="modal-content overflow-scroll">
                    <div className="modal-header">
                        <h5>{event.title}</h5>
                        <button type="button" className="close" onClick={() => this.props.close()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group">
                                <label><i className="fas fa-clock"></i></label>
                                <label className="ml-2">{startDate} - {endDate}</label>
                            </div>
                            <div className="form-group">
                                <label><i className="fas fa-map-marker-alt"></i></label>
                                <label className="ml-2">{event.location}</label>
                            </div>
                            <div className="form-group">
                                <label><i className="fas fa-comments"></i></label>
                                <label className="ml-2">{event.description}</label>
                            </div>
                        </form>
                    </div>
                    {
                        isAdmin &&
                        <div className="modal-footer justify-content-right">
                            <SeneButton
                                buttonClass="secondary"
                                onClick={this.props.edit}
                            >
                                {t('edit')}
                            </SeneButton>
                            <SeneButton
                                buttonClass="secondary"
                                onClick={this.props.delete}
                            >
                                {t('delete')}
                            </SeneButton>
                        </div>
                    }
                </div>
            </Modal>
        )
    }
}

export default (EventDetailsModal);
