import React from 'react';
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import memoize from 'memoize-one';

import './AppHeader.scss';
import CartInfo from './CartInfo';
import { selectDistributorName } from '../../../store/selectors/distributorSelectors';
import { selectCartItemCount } from '../../../store/selectors/cartSelectors';
import { selectUserProfile } from '../../../store/selectors/authSelectors';
import { UserManager } from '../../../utils/auth/UserManager';
import LanguageSelector from "../../common/LanguageSelector";
import { hasAdminRights, hasCustomerRights } from '../../../utils/auth';
import SeneSiteLink from '../../common/SeneSiteLink';
import { setCallbackUrl } from '../../../utils/auth/CallbackStorage';
import { createGuid } from '../../../utils/helpers';
import {getIpAddress} from "../../../actions/ipAddressActions";
import {selectIpAddress} from "../../../store/selectors/ipAddressSelector";
import {bindActionCreators} from "redux";

interface HeaderStateProps {
    distributorName: string;
    cartItemCount: number;
    userProfile: any;
    ipAddress: string;
}

interface HeaderDispatchProps {
    getIpAddress();
}

type AppHeaderProps = HeaderStateProps & HeaderDispatchProps & WithNamespaces;

class AppHeader extends React.PureComponent<AppHeaderProps> {

    componentDidMount() {
        if (!this.props.ipAddress) {
            this.props.getIpAddress();
        }
    }

    loginRedirect = () => {
        setCallbackUrl(window.location.pathname);
        UserManager.signinRedirect({ui_locales: this.props.i18n.language.toLowerCase()});
    }

    logoutRedirect = () => {
        setCallbackUrl(window.location.pathname);
        UserManager.signoutRedirect({ui_locales: this.props.i18n.language.toLowerCase()});
    }

    getAuthorityURL = memoize(
        (language, redirect) => {
            const locale = language.toLowerCase();
            const guid = createGuid();
            const clientID = UserManager.settings._client_id;
            const authURL = UserManager.settings._authority;

            const query = encodeURIComponent(`clientId=${clientID}&redirectUri=${redirect}&versionid=${guid}&ui_locales=${locale}`);
            
            return `${authURL}Account/Manage?${query}`;
        }
    );

    renderLoggedInItem() {
        const { t } = this.props;

        return (
            <UncontrolledDropdown>
                <DropdownToggle
                    tag="span"
                    className="header__action"
                    caret
                >
                    {`${t('hi')}, ${this.props.userProfile.given_name} ${this.props.userProfile.family_name}`}
                </DropdownToggle>
                <DropdownMenu>
                    {
                        hasAdminRights(this.props.userProfile) && (
                            <DropdownItem>
                                <NavLink to="/administration">
                                    {t('dashboard')}
                                </NavLink>
                            </DropdownItem>
                        )
                    }
                    {
                        hasCustomerRights(this.props.userProfile) && (
                            <>
                                <DropdownItem>
                                    <SeneSiteLink to="/customer-profile">
                                        {t('customerProfile')}
                                    </SeneSiteLink>
                                </DropdownItem>
                                <DropdownItem>
                                    <a href={this.getAuthorityURL(this.props.i18n.language, window.location.href)}>
                                        {t('customerLoginSecurity')}
                                    </a>
                                </DropdownItem>
                            </>
                        )

                    }
                    <DropdownItem onClick={this.logoutRedirect}>
                        {t('logout')}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }

    renderLoggedOutItem() {
        const { t } = this.props;

        return (
            <span id="login_link" className="text-uppercase font-weight-bold header__action" onClick={this.loginRedirect}>
                {t('login')}
            </span>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <header>
                <nav className="navbar navbar-dark bg-secondary text-white">
                    <div className="container">
                        <div style={{ flex: '1' }} className="header-container">
                            <div>
                                {
                                    this.props.distributorName &&
                                    <>
                                        <span>{t('independentDistributor')}:</span>
                                        <strong className="font-weight-bold"> {this.props.distributorName}</strong>
                                    </>
                                }
                            </div>
                            <div className="header--right">
                                <div className="header__language"><LanguageSelector /></div>
                                {
                                    this.props.userProfile
                                        ? this.renderLoggedInItem()
                                        : this.renderLoggedOutItem()
                                }
                                <div className="header__action">
                                    {
                                        this.props.distributorName &&
                                        <CartInfo t={t} itemCount={this.props.cartItemCount} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}

const mapStateToProps = (state): HeaderStateProps => ({
    distributorName: selectDistributorName(state),
    cartItemCount: selectCartItemCount(state),
    userProfile: selectUserProfile(state),
    ipAddress: selectIpAddress(state)
});

const mapDispatchToProps = (dispatch): HeaderDispatchProps => bindActionCreators({
    getIpAddress
}, dispatch);

// TODO use compose when using multiple HOC
export default withNamespaces('Header')(connect(mapStateToProps, mapDispatchToProps)(AppHeader));
