import { orderBy, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import memoize from 'memoize-one';

import { State } from '../index';
import { selectDistributorCountry } from './distributorSelectors';
import { Product, ProductVm, mapProductViewModel } from '../../models/product';
import { MaybeCategoryOrSubcategory } from '../../utils/types';
import { selectProductSort } from './uiSelectors';
import { selectSelectedLanguageCode } from './languageSelectors';

export const selectCategories = (state: State) => state.categories;
export const selectCategory = (state: State, categoryId: number, subCategoryId?: number): MaybeCategoryOrSubcategory => {
    const categories = selectCategories(state);
    const category = categories.find(cat => cat.categoryId == categoryId) || null;

    if (!category || !subCategoryId) {
        return category;
    }

    return category.subCategories.find(cat => cat.id === subCategoryId) || null;
};
export const selectProducts = (state: State) => state.products;
export const selectProductsForCategory = memoize((state: State, categoryId: number, subCategoryId?: number): ProductVm[] => {
    const category = selectCategory(state, categoryId, subCategoryId);
    if (!category || !category.products) {
        return [];
    }

    const products = selectProductsVm(state);
    const productsList = category.products.map(id => products[id]);

    return productsList.filter(t => t.isForSale);
});
export const selectProductById = (state: State, productId: number) => state.products[productId];
export const selectProductVmById = (state: State, productId: number) => selectProductsVm(state)[productId];
export const selectProductsByIds = (state: State, productIds: number[]): Product[] => {
    const products = selectProducts(state);
    const selectedProducts: Product[] = [];
    productIds.forEach(id => {
        if (products[id]) {
            selectedProducts.push(products[id]);
        }
    });
    return selectedProducts;
};

export const selectCurrency = (state: State) => state.distributor && state.distributor.currencyCode || "";
export const selectCurrencyForced = () => false;

/**
 * Selects all products from the store, mapping them to the Product ViewModel based on the distributor's country
 */
export const selectProductsVm = createSelector(
    selectDistributorCountry,
    selectCurrency,
    selectProducts,
    selectSelectedLanguageCode,
    selectCurrencyForced,
    (country, currency, products, lang, currencyForced) => {
        const locale = lang || 'en-us';
        const productsVm = {};

        Object.keys(products).forEach(id => {
            // Filter out products those don't match the distributor country
            if (products[id].countryDetails[country]) {
                productsVm[id] = mapProductViewModel(products[id], country, locale, currency, currencyForced);
            }
        });

        return productsVm;
    }
);

export const selectProductsForCategorySorted = createSelector(
    selectProductSort,
    selectProductsForCategory,
    (sort, products) => {
        if (!products || products.length === 0) {
            return products;
        }

        const filteredProductList = products.filter(t => t.isForSale);

        return (sort && sort.sortBy)
            ? orderBy(filteredProductList, [sort.sortBy], [sort.direction || 'asc'])
            : filteredProductList;
    }
);


export const selectAllProductsSorted = createSelector(
    selectProductSort,
    selectProductsVm,
    (sort, products) => {
        if (!products || isEmpty(products)) {
            return [];
        }

        const productList: ProductVm[] = Object.keys(products).reduce((arr: any[], key) => {
            arr.push(products[key]);
            return arr;
        }, []);

        const filteredProductList = productList.filter(t => t.isForSale);

        return (sort && sort.sortBy)
            ? orderBy(filteredProductList, [sort.sortBy], [sort.direction || 'asc'])
            : filteredProductList;
    }
);


/**
 * Selects the cross selling products that are still on sale for a product
 */
export const selectCrossSellingProducts = (state: State, product: ProductVm): ProductVm[] => {
    const productIds = product && product.productDetails && product.productDetails.crossSellingProductIds;

    if (!productIds || !productIds.length) {
        return [];
    }

    const selectedProducts: ProductVm[] = [];
    const products = selectProductsVm(state);
    productIds.forEach(id => {
        if (products[id] && products[id].isForSale) {
            selectedProducts.push(products[id]);
        }
    });

    return selectedProducts;
};
