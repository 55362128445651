import React from 'react';
import { connect } from 'react-redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { bindActionCreators } from 'redux';

import { UserInfo, UserInfoBillingAddress } from '../../../models/userInfo';
import { State } from '../../../store';
import { selectLoggedInUser } from '../../../store/selectors/userSelectors';
import TextInput from '../../common/inputs/SeneTextInput';
import SelectInput from '../../common/inputs/SeneSelectInput';
import { ValidationErrors } from '../../../utils/validation/validator';
import { selectRegionsForCustomerCountry } from '../../../store/selectors/regionSelectors';
import { CountryState } from '../../../models/region';
import { getRegions } from '../../../actions/regionActions';
import SeneButton from '../../common/inputs/SeneButton';
import { selectLoading } from '../../../store/selectors/uiSelectors';
import { CustomerBillingAddressValidator } from './CustomerProfileValidators';
import { updateLoggedInCustomer } from '../../../actions/customerActions';
import { ActionToastProperties } from '../../../models/ui';
import { selectDistributorCountry } from '../../../store/selectors/distributorSelectors';
import { TranslationRemapper } from "../../../../src/utils/helpers";

interface CustomerBillingAddressStateProps {
    customerInfo: UserInfo | null;
    regions: CountryState[];
    distributorCountry: string;
    isLoading: boolean;
}

interface CustomerBillingAddressDispatchProps {
    getRegions(countryCode?: string);
    updateCustomer({billingAddress}: {billingAddress: UserInfoBillingAddress}, toastProps?: ActionToastProperties);
}

type CustomerBillingAddressProps = CustomerBillingAddressStateProps & CustomerBillingAddressDispatchProps & WithNamespaces;

type CustomerBillingAddressState = {
    billingAddressModel: UserInfoBillingAddress;
    errors: ValidationErrors<UserInfoBillingAddress>;
}

class CustomerBillingAddress extends React.PureComponent<CustomerBillingAddressProps, CustomerBillingAddressState> {
    state: CustomerBillingAddressState = {
        billingAddressModel: {
            address1: '',
            address2: '',
            address3: '',
            city: '',
            state: '',
            zipCode: '',
            firstName: '',
            lastName: ''
        },
        errors: {}
    }

    get regions() {
        return this.props.regions.map(country => ({ value: country.abbreviation, text: country.name }));
    }

    setBillingAddressState = (billingAddress: UserInfoBillingAddress) => {

        const isAvailableRegion = this.regions.some(t => t.value === billingAddress.state);
        this.setState({ 
            billingAddressModel: {
                ...billingAddress,
                state: isAvailableRegion ? billingAddress.state : ''
            } 
        });
    }

    componentDidMount() {
        if (this.props.customerInfo) {
            const { billingAddress } = this.props.customerInfo;
            this.props.getRegions(this.props.customerInfo.country).then(() => {
                this.setBillingAddressState(billingAddress);
            });
        } else {
            this.props.getRegions();
        }
    }

    componentDidUpdate(prevProps: CustomerBillingAddressProps) {
        if (this.props.customerInfo && prevProps.customerInfo !== this.props.customerInfo) {
            const { billingAddress } = this.props.customerInfo;
            if(!prevProps.customerInfo || this.props.customerInfo.country != prevProps.customerInfo.country) {
                this.props.getRegions(this.props.customerInfo.country).then(() => {
                    this.setBillingAddressState(billingAddress);
                });
            } else {
                this.setBillingAddressState(billingAddress);
            }
        }
    }

    updateCustomer = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const prop = e.target.name;
        const value = e.target.value;

        this.setState({
            billingAddressModel:{
                ...this.state.billingAddressModel,
                [prop]: value
            }
        });
    }

    saveCustomer = () => {
        const countryCode = this.props.customerInfo ? this.props.customerInfo.country : '';
        const validator = new CustomerBillingAddressValidator(this.props.t, countryCode);
        const validationResult = validator.validate(this.state.billingAddressModel);

        if (validationResult.isValid) {
            this.props.updateCustomer({billingAddress: this.state.billingAddressModel}, { success: this.props.t('updateCustomerSuccess'), error: this.props.t('updateCustomerError') });
        } else {
            this.setState({ errors: validationResult.errors });
        }
    };

    isDisplayMXOptions = () => {
        return this.props.customerInfo && this.props.customerInfo.country !== '' ? 
            this.props.customerInfo.country === "MX" : 
            this.props.distributorCountry === "MX";
    }

    isDisplayHKOptions = () => {
        return this.props.customerInfo && this.props.customerInfo.country !== '' ?
            this.props.customerInfo.country === "HK" :
            this.props.distributorCountry === "HK";
    }

    getCountry = () => {
        return this.props.customerInfo && this.props.customerInfo.country !== '' ? 
            this.props.customerInfo.country : 
            this.props.distributorCountry;
    }

    getTranslator() {
        const dic = {
            'NZ': {
                'zipCode': 'postalCode'
            },
            'HK': {
                'address1': 'address1Hk',
                'address2': 'address2Hk',
                'address3': 'address3Hk',
                'city': 'cityHk',
                'stateName': 'stateNameHk'
            }
        };
        return new TranslationRemapper(dic, this.props.t).getTranslatorFor(this.props.distributorCountry);
    }

    render() {
        const t = this.getTranslator();

        return this.props.customerInfo && (
            <>
                <h2 className="customer-profile__title">{t('title')}</h2>
                <div className="row pt-3">
                    <div className="col-sm-4 col-md-5">
                        <TextInput {...{
                            name: 'firstName',
                            label: `${t('firstName')} *`,
                            id: 'firstName',
                            onChange: this.updateCustomer,
                            value: this.state.billingAddressModel.firstName,
                            error: this.state.errors.firstName,
                            autocomplete: 'firstName'
                        }} />
                    </div>
                    <div className="col-sm-4 col-md-5">
                        <TextInput {...{
                            name: 'lastName',
                            label: `${t('lastName')} *`,
                            id: 'lastName',
                            onChange: this.updateCustomer,
                            value: this.state.billingAddressModel.lastName,
                            error: this.state.errors.lastName,
                            autocomplete: 'lastName'
                        }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-md-5">
                        <TextInput {...{
                            name: 'address1',
                            label: `${t('address1')} *`,
                            id: 'address1',
                            onChange: this.updateCustomer,
                            value: this.state.billingAddressModel.address1,
                            error: this.state.errors.address1,
                            autocomplete: 'address-line1'
                        }} />
                    </div>
                    <div className="col-sm-4 col-md-5">
                        <TextInput {...{
                            name: 'address2',
                            label: t('address2'),
                            id: 'address2',
                            onChange: this.updateCustomer,
                            value: this.state.billingAddressModel.address2,
                            autocomplete: 'address-line2'
                        }} />
                    </div>
                    { (this.isDisplayMXOptions() || this.isDisplayHKOptions()) &&
                        <div className="col-sm-4 col-md-5">
                            <TextInput {...{
                                name: 'address3',
                                label: t('address3'),
                                id: 'address3',
                                onChange: this.updateCustomer,
                                value: this.state.billingAddressModel.address3,
                                autocomplete: 'address-line3'
                            }} />
                        </div>
                    }
                </div>
                <div className="row">
                    <div className="col-sm-4 col-md-5">
                        <TextInput {...{
                            name: 'city',
                            label: `${t('city')} *`,
                            id: 'city',
                            onChange: this.updateCustomer,
                            value: this.state.billingAddressModel.city,
                            error: this.state.errors.city,
                            autocomplete: 'city'
                        }} />
                    </div>
                    <div className="col-sm-4 col-md-5">
                    {this.props.customerInfo.country.toUpperCase() !== "NZ" ? 
                        <SelectInput {...{
                            name: 'state',
                            label: `${t('stateName')} *`,
                            id: 'state',
                            onChange: this.updateCustomer,
                            options: this.regions,
                            value: this.state.billingAddressModel.state,
                            error: this.state.errors.state,
                            autocomplete: 'address-level1'
                        }} />
                        :
                        <TextInput {...{
                            name: 'address3',
                            label: t('suburb'),
                            id: 'address3',
                            onChange: this.updateCustomer,
                            value: this.state.billingAddressModel.address3,
                            error: this.state.errors.address3,
                            autocomplete: 'address-line3'
                        }} />
                    }
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-md-5">
                        {this.props.customerInfo.country.toUpperCase() !== "HK" &&
                            <TextInput {...{
                                name: 'zipCode',
                                label: `${t('zipCode')} *`,
                                id: 'zipCode',
                                onChange: this.updateCustomer,
                                value: this.state.billingAddressModel.zipCode,
                                error: this.state.errors.zipCode,
                                autocomplete: 'postal-code'
                            }} />
                        }
                    </div>
                    <div className="col-sm-4 col-md-5">
                        <label className="font-weight-bold" >{t('country')}</label>
                        <div>{this.getCountry()}</div>
                    </div>
                </div>
                <div className="row">
                    <SeneButton
                        className="col-md-2 offset-md-10"
                        onClick={this.saveCustomer}
                        icon="fa-save"
                        loading={this.props.isLoading}
                    >
                        {t('save')}
                    </SeneButton>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: State): CustomerBillingAddressStateProps => ({
    customerInfo: selectLoggedInUser(state),
    regions: selectRegionsForCustomerCountry(state),
    distributorCountry: selectDistributorCountry(state),
    isLoading: selectLoading(state)
});

const mapDistpatchToProps = dispatch => bindActionCreators({
    getRegions,
    updateCustomer: updateLoggedInCustomer
}, dispatch);

export default withNamespaces('BillingAddress')(connect(mapStateToProps, mapDistpatchToProps)(CustomerBillingAddress));
