import { push } from 'connected-react-router';

import { selectSeneSiteUrl } from './../store/selectors/distributorSelectors';
import { Thunk } from './../store/index';

export const seneSiteNavigateTo = (to: string): Thunk => (dispatch, getState) => {
    const state = getState();
    const seneSiteUrl = selectSeneSiteUrl(state);

    let href = to.toString();
    if (!href.startsWith('/')) {
        href = `/${href}`;
    }

    if (seneSiteUrl) {
        href = `/${seneSiteUrl}${href}`;
    }

    dispatch(push(href));
}
