import i18next from 'i18next';

import { CreateCustomerModel } from './../../../models/customer';
import { Validator } from './../../../utils/validation/validator';

export class CustomerModelValidator extends Validator<CreateCustomerModel> {
    constructor(t: i18next.TranslationFunction, captchasDisabled: boolean) {
        super();

        this.ruleFor('firstName').notEmpty(t('firstNameMsg'));
        this.ruleFor('lastName').notEmpty(t('lastNameMsg'));
        this.ruleFor('email').notEmpty(t('emailMsg')).email(t('invalidEmailMsg')).isSame('confirmEmail', t('reenterEmailNotTheSameMsg'));
        this.ruleFor('confirmEmail').notEmpty(t('emailMsg')).email(t('invalidEmailMsg'));
        this.ruleFor('password').notEmpty(t('passwordMsg')).isSame('confirmPassword', t('confirmPwdNotTheSameMsg'));
        this.ruleFor('confirmPassword').notEmpty(t('passwordMsg'));
        this.ruleFor('hasAcceptedPrivacyTerms').isTrue(t('agreeMsg'));
        this.ruleFor('phone').phone(t('phoneMsg'));
        this.ruleFor('birthday').notEmpty(t('birthdayMsg')).birthDateDayMonth(t('birthdayMsg'));
        this.ruleFor('isEighteenOrOlder').isTrue(t('ageConfirmMsg'));
        if(!captchasDisabled) {
            this.ruleFor('recaptchaToken').notEmpty(t('recaptchaTokenRequired'));
        }
    }
}
