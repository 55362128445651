import { displayFormat as currencyDisplayFormat } from '../components/common/Currency'

export interface Product {
    productId: number;
    sku: string;
    name: string;
    skuOrderBy: number;
    isForSale: boolean;
    displayWeight: string,
    baseShade: string;
    countryDetails: CountryDetails;
    productDetails?: ProductDetails;
    imageUrl: string;
    colorSwatchImage: string;
}

export interface ProductVm extends Product {
    price: number;
    categoryId: number;
    subCategoryId: number;
    displayPrice: string;
    link: string;
    hasSwatch: boolean;
    showColorSwatches: boolean;
    placeholderName: string;
}

export interface CountryDetails {
    [countryCode: string]: CountryDetail;
}

export interface CountryDetail {
    price: number;
    categoryId: number;
    subCategoryId: number;
    hasSwatch: boolean;
    showColorSwatches: boolean;
}

export interface ProductDetails {
    crossSellingProductIds: number[];
    productAttributes: ProductAttributes;
}

export interface ProductAttributes {
    [countryCode: string]: ProductAttribute;
}

export interface ProductAttribute {
    benefits: string;
    details: string;
    applicationTechniques: string;
    ingredients: string;
    name: string;
}

/**
 * Map product entity to the ViewModel
 */
export const mapProductViewModel = (product: Product, country: string, locale: string, currency: string, currencyForced = false): ProductVm => {
    const placeholderName = product.name;
    const name = product && product.productDetails && product.productDetails.productAttributes[locale.toLowerCase()] && product.productDetails.productAttributes[locale.toLowerCase()].name || product.name;
    const price = product && product.countryDetails && product.countryDetails[country] && product.countryDetails[country].price;
    const displayPrice = price && currencyDisplayFormat(price, currency, locale, country, currencyForced) || '';
    const productNameLink = product.name.replace(/[^a-z0-9_-\s]+/i, '');
    const link = `/product/${product.productId}/${encodeURIComponent(productNameLink)}`;
    const categoryId = product
        && product.countryDetails
        && product.countryDetails[country]
        && product.countryDetails[country].categoryId || 0;
    const subCategoryId = product
        && product.countryDetails
        && product.countryDetails[country]
        && product.countryDetails[country].subCategoryId || 0;
    const hasSwatch = product && product.countryDetails && product.countryDetails[country] && product.countryDetails[country].hasSwatch;
    const showColorSwatches = product && product.countryDetails && product.countryDetails[country] && product.countryDetails[country].showColorSwatches;

    return {
        ...product,
        placeholderName,
        name,
        price,
        displayPrice,
        link,
        categoryId,
        subCategoryId,
        hasSwatch,
        showColorSwatches
    }
}
