import { produce } from 'immer';

import { Product } from './../models/product';
import { CatalogActionTypes } from './../actions/catalogActions';

interface ProductsState {
    [key: number]: Product;
}

export const reducer = (state: ProductsState = {}, action) =>
    produce(state, draft => {
        switch (action.type) {
            case CatalogActionTypes.getProductsForCategorySuccess:
            case CatalogActionTypes.getProductsForSubCategorySuccess:
            case CatalogActionTypes.getAllProductsSuccess:
                action.products.forEach(product => {
                    draft[product.productId] = {
                        ...product
                    }
                });
                return draft;
            case CatalogActionTypes.getProductByIdSuccess:
                draft[action.productId] = action.product;
                return draft;
            case CatalogActionTypes.getProductsByIdsSuccess:
                action.products.forEach(product => {
                    draft[product.productId] = product
                });
                return draft;
            default:
                return draft;
        }
    });
