import React, { memo } from 'react';

import './CategoryTile.scss';
import { Category } from '../../../../models/category';
import SeneSiteLink from '../../../common/SeneSiteLink';
import { getCategoryName } from '../../../../utils/helpers';
import ImageComponent from '../../../common/ImageComponent';
import { Configuration } from '../../../../models/configuration';
import { State } from '../../../../store';
import { selectConfiguration } from '../../../../store/selectors/configurationSelectors';
import { connect } from 'react-redux';

interface CategoryTileProps {
    category: Category;
    locale: string;
    config: Configuration | null;
}

const CategoryTile = memo(({ category, locale, config }: CategoryTileProps) => {
    const categoryName = getCategoryName(category, locale);
    return (
        <div className="col-lg-4">
            <div className="category-tile">
                <SeneSiteLink to={`/shopproducts/${category.categoryId}/${encodeURIComponent(categoryName.toLowerCase())}`}>
                    <div
                        className="category-tile__title"
                        style={{ backgroundImage: `url("${config ? config.apiConstants.IMAGESPRODUCTSDIRECT : '/'}ShopProducts/ttile_bg-01-01.png")` }}
                    >
                        {categoryName}
                    </div>
                    <ImageComponent
                        src={`products/ShopProducts/${category.image}`}
                        alt={`${categoryName} image`}
                        className="category-tile__img"
                        width={200}
                        height={200}
                        crop="crop"
                    />
                    <div className="overlay"></div>
                </SeneSiteLink>
            </div>
        </div>
    )
});

const mapStateToProps = (state: State) => ({
    config: selectConfiguration(state)
})

export default connect(mapStateToProps)(CategoryTile);
