import React from 'react';
import {Redirect, Route, RouteComponentProps, Switch} from 'react-router';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AdminHeader from './header/AdminHeader';
import { routes } from './routes';
import { getDistributorFromDistId } from "../../actions/distributor";
import { getCountryLanguages } from "../../actions/languageActions";
import { selectUserProfile } from "../../store/selectors/authSelectors";
import { Distributor } from "../../models/distributor";
import { getBrowserCountry, getLang } from "../../utils/helpers";
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { DistributorState } from '../../reducers/distributor';
import Spinner from '../common/Spinner';
import SeneSiteApplicationForm from './application/SeneSiteApplicationForm';
import SeneSiteApplicationFormConfirmation from './application/SeneSiteApplicationFormConfirmation';
import {hasSeneCareRights} from "../../utils/auth";
import queryString from "query-string";
import withRetryComponent, {RetryComponentProps} from "../common/withRetryComponent";
import { getImpersonatedDistId, setImpersonatedDistId } from "../../utils/auth/CallbackStorage";
import {selectDistributorId} from "../../store/selectors/distributorSelectors";


interface AdministrationLayoutStateProps {
    user: any;
    distId: number;
}

interface AdministrationLayoutDispatchProps {
    getDistributorFromDistId(distId: number);
    getCountryLanguages(country2Alpha: string, preferredLocale: string, distributor?: Distributor);
}

type AdministrationLayoutProps = AdministrationLayoutStateProps & AdministrationLayoutDispatchProps 
    & WithNamespaces & RouteComponentProps & RetryComponentProps;

interface AdministrationLayoutState {
    distributor: DistributorState;
    isImpersonation: boolean;
}

class AdministrationLayout extends React.Component<AdministrationLayoutProps, AdministrationLayoutState> {

    state: AdministrationLayoutState = {
        distributor: null,
        isImpersonation: false
    };

    loadUser(user: any) {
        if (user && user.preferred_username) {
            const preferredLocale = this.props.i18n.language ? this.props.i18n.language : getLang();
            const distributorId = hasSeneCareRights(user) ? 
                this.getImpersonatedDistId(user) : +user.preferred_username;
            const isImpersonation = +user.preferred_username !== distributorId;

            this.props.getDistributorFromDistId(distributorId)
                .then((distributor: Distributor) => {
                    this.setState({ distributor: distributor, isImpersonation });
                    this.props.getCountryLanguages(distributor.countryName, preferredLocale, distributor);
                })
                .catch(() => {
                    this.props.getCountryLanguages(getBrowserCountry(), preferredLocale);
                    this.props.initFailed();
                });
        }
    }

    getImpersonatedDistId(user: any) {
        const qs = queryString.parse(this.props.location.search);
        if (qs['distributorId']) {
            setImpersonatedDistId((qs['distributorId'] as string));
        } else {
            if (this.props.distId > 0) {
                setImpersonatedDistId(("" + this.props.distId));
            }    
        }        
        const impersonatedId = getImpersonatedDistId();
        
        if (impersonatedId) {
            return impersonatedId
        }

        return +user.preferred_username;
    }

    componentDidMount() {
        const { user } = this.props;
        if (user) {
            this.loadUser(user);
        }
    }

    componentDidUpdate(prevProps: AdministrationLayoutProps) {
        const { user } = this.props;

        const wasUserSet = !prevProps.user && !!user;
        const wasUserUpdated = !!prevProps.user && !!user && prevProps.user.preferred_username != user.preferred_username;

        if (wasUserSet || wasUserUpdated) {
            this.loadUser(user);
        }
    }

    renderAdministrationRoutes = () => {
        return (
            <Switch>
                {
                    routes.map((route) => (
                        <Route key={route.path} {...route} />
                    ))
                }
                <Redirect from="/administration" to="/administration/Dashboard" />
            </Switch>
        )
    };

    renderApplicationFormRoute = () => {
        return ( this.state.distributor && 
                 this.state.distributor.seneSiteInfo && 
                 this.state.distributor.seneSiteInfo.isApplicationInProgress) ?  (
            <Switch>
                <Route path={'/administration/SeneSiteApplicationFormConfirmation'}
                        render={() => <SeneSiteApplicationFormConfirmation />} />
                <Redirect from="/administration" to="/administration/SeneSiteApplicationFormConfirmation" />
                <Redirect from="/administration/*" to="/administration/SeneSiteApplicationFormConfirmation" />
            </Switch>
        ) : (
            <Switch>
                <Route path={'/administration/SeneSiteApplicationForm'}
                       render={() => <SeneSiteApplicationForm isImpersonation={this.state.isImpersonation} />} />
                <Route path={'/administration/SeneSiteApplicationFormConfirmation'}
                       render={() => <SeneSiteApplicationFormConfirmation/> } />
                <Redirect from="/administration" to="/administration/SeneSiteApplicationForm" />
                <Redirect from="/administration/*" to="/administration/SeneSiteApplicationForm" />
                
            </Switch>
        )
    };

    render() {
        const { distributor, isImpersonation } = this.state;
        if (!distributor) {
            return (<Spinner />)
        } else {
            return (
                <>
                    <AdminHeader isImpersonation={isImpersonation} />
                    <div>
                        {
                            distributor.seneSiteInfo && distributor.seneSiteInfo.seneSiteUrl ?
                                this.renderAdministrationRoutes() :
                                this.renderApplicationFormRoute()
                        }
                    </div>
                </>
            );
        }
    }
}

const mapStateToProps = (state): AdministrationLayoutStateProps => ({
    user: selectUserProfile(state),
    distId: selectDistributorId(state)
});

const mapDispatchToProps = (dispatch): AdministrationLayoutDispatchProps => (
    bindActionCreators({
        getDistributorFromDistId,
        getCountryLanguages
    }, dispatch)
);

export default withRetryComponent()(withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(AdministrationLayout)));

