import axios from 'axios';
import { UserManager } from './../utils/auth/UserManager';

axios.interceptors.request.use(async (config) => {
    if (config.url && ( config.url.startsWith('/api/') || config.url.startsWith('api/') )) {
        const user = UserManager && await UserManager.getUser();

        if (user) {
            config.headers = {
                ...config.headers,
                'Authorization': `Bearer ${user.access_token}`,
            }
        }
    }

    return config;
});
