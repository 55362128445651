import axios from 'axios';
import { SeneSiteNameValidationResult, SubmitSeneSiteApplicationFormData, DisplayApplicationFormData } from '../models/applicationForm';
import { cancelledVerifySeneSiteNameString } from '../utils/constants';
import { shouldSwithToMaintenance } from "../utils/helpers";

const CancelToken = axios.CancelToken;
let source;

export const verifySeneSiteName = (distId: number, seneSiteName: string) => {

    if (source) {
        source.cancel(cancelledVerifySeneSiteNameString);
    }

    source = CancelToken.source();

    return axios.get<SeneSiteNameValidationResult>(
        `/api/ApplicationForm/VerifySeneSiteName?distributorId=${distId}&seneSiteName=${seneSiteName}`,
        { cancelToken: source.token })
        .then(res => res.data)
        .catch(err => {
            shouldSwithToMaintenance(err);
            if (err.response && err.response.status === 400){
                return err.response.data;
            } else {
                throw err;
            }
        });
}

export const submitSeneSiteApplicationForm = (data: SubmitSeneSiteApplicationFormData) => {
    return axios.post<SeneSiteNameValidationResult>(
        `/api/ApplicationForm/SubmitApplication?distributorId=${data.distributorId}`, data)
        .then(res => res.data)
        .catch(err => {
            shouldSwithToMaintenance(err);
            if (err.response && err.response.status === 400){
                return err.response.data;
            } else {
                throw err;
            }
        });
}

export const submitSeneSiteChangeUrlForm = (data: SubmitSeneSiteApplicationFormData) => {
    return axios.post<SeneSiteNameValidationResult>(
        `/api/ApplicationForm/SubmitUrlChange?distributorId=${data.distributorId}`, data)
        .then(res => res.data)
        .catch(err => {
            shouldSwithToMaintenance(err);
            if (err.response && err.response.status === 400){
                return err.response.data;
            } else {
                throw err;
            }
        });
}

export const getApplicationformConfiguration = (distId: number) => {

    return axios.get<DisplayApplicationFormData>(
        `api/Distributor/${distId}/ApplicationForm`)
        .then(res => res.data)
        .catch(err => {
            shouldSwithToMaintenance(err);
            if (err.response && err.response.status === 400){
                return err.response.data;
            } else {
                throw err;
            }
        });
}
