import axios from 'axios';
import { Customer } from '../models/customer';
import { InventoryItem } from '../models/inventory';
import { getAllProducts } from './catalogApi';

export const getCustomers = (distId: number) => {
  return axios.get<Customer[]>(`/api/Customers/GetByDistributor?distributorId=${distId}`).then(res => res.data);
}

export const getInventory = (distId: number) => {
  return axios.get<InventoryItem[]>(`/api/Distributor/${distId}/Inventory`).then(res => res.data);
}

export const uploadInventory = (distId: number, file: any) => {
  return axios.post(`/api/distributor/${distId}/inventory/UpdateDistributorInventory`, file);
}

export const updateInventory = (distId: number, inventory: InventoryItem[]) => {
  return axios.post(`/api/distributor/${distId}/inventory`, inventory);
}

export const loadInventory = async (distId: number): Promise<InventoryItem[]> => {
  const [inventory, products] = await Promise.all([getInventory(distId), getAllProducts(distId, false)]);

  const mergedInventory = products.map(product => {
    const inventoryItem = inventory.find(item => item.sku === product.sku)
    return inventoryItem ? inventoryItem : {
      sku: product.sku,
      discontinued: product.isForSale,
      productName: product.name,
      qty: 0
    }
  });

  const sortedInventory = mergedInventory.sort((itemA, itemB) => {
    if (itemA.qty) {
      return itemB.qty > 0
        ? (itemA.productName.toLocaleLowerCase() > itemB.productName.toLocaleLowerCase() ? 1 : -1)
        : -1;
    } else {
      return itemB.qty > 0
        ? 1
        : (itemA.productName.toLocaleLowerCase() > itemB.productName.toLocaleLowerCase() ? 1 : -1);
    }
  });

  return sortedInventory;
};