import React from 'react';
import * as dompurify from 'dompurify';
import './404.scss';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';
import { State } from '../../store';
import { selectConfiguration } from '../../store/selectors/configurationSelectors';
import { connect } from 'react-redux';
import { Configuration } from '../../models/configuration';

export interface NotFoundProps {
    t: i18n.TranslationFunction,
    config?: Configuration | null
}

const NotFound = ({ t, config }: NotFoundProps) => (
    <div className="container ">
        <div className="navbar-header pull-left error-header" />
        <div className="error-bg justify-content-center">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="oops mb-1"> {t('oops')}</h2>
                    <div className="oops-text" dangerouslySetInnerHTML={{__html: dompurify.sanitize(t('sorryPageNotFound'))}}>
                </div>
                    <img src={`${config ? config.apiConstants.IMAGESPRODUCTSDIRECT : '/'}SG_logo-02-v2.png`} className="error-header" />
                </div>
            </div>
        </div>
    </div>
)

const mapStateToProps = (state: State) => ({
    config: selectConfiguration(state)
})

export default connect(mapStateToProps)(withNamespaces('Common')(NotFound));