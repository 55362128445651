import { Thunk } from "../store";
import * as languageApi from '../api/languagesApi';
import { CountryLanguage } from "../models/language";
import i18n from "../i18n";
import { setBlockUi } from "./ui";
import { Distributor } from "../models/distributor";
import { distributorDefaultLanguageLocalStorageKey } from "../utils/constants";
import { shouldSwithToMaintenance } from "../utils/helpers";

export enum LanguageActionTypes {
    loadLanguagesSuccess = '[Language] Load languages for country success',
    loadLanguagesError = '[Language] Load languages for country error',
    selectLanguage = '[Language] Select language',
}

export const getCountryLanguages = (countryAlpha2: string, preferredLocale: string, distributor?: Distributor): Thunk => (dispatch, getState) => {
    return languageApi.getLanguagesForCountry(countryAlpha2)
        .then(countryLanguages => {
            dispatch({ type: LanguageActionTypes.loadLanguagesSuccess, countryLanguages });
            
            const selectedLanguage = selectDefaultLanguage(countryLanguages, preferredLocale, distributor);
            if (selectedLanguage &&
                (selectedLanguage.code.toLocaleLowerCase() != preferredLocale.toLocaleLowerCase())) {
                dispatch(setBlockUi(true));
                i18n.changeLanguage(selectedLanguage.code).then(() => {
                    dispatch(setBlockUi(false));
                    //This is a fallback case, so i18next should not remember this selection choice
                    localStorage.removeItem('i18nextLng');
                });
            }
            return dispatch({ type: LanguageActionTypes.selectLanguage, selectedLanguage });
        })
        .catch((exp) => {
            shouldSwithToMaintenance(exp);
            dispatch({ type: LanguageActionTypes.loadLanguagesError });          
           
        });
}

export const changeSelectedLanguage = (selectedLanguage: CountryLanguage, seneSiteUrl: string | null): Thunk => (dispatch, getState) => {

    if(seneSiteUrl) {
        const distributorDefaultLanguageStorage = JSON.parse(localStorage.getItem(distributorDefaultLanguageLocalStorageKey) || '{}');
        distributorDefaultLanguageStorage[seneSiteUrl.toLowerCase()] = selectedLanguage.code;

        localStorage.setItem(distributorDefaultLanguageLocalStorageKey, JSON.stringify(distributorDefaultLanguageStorage));
    }

    return dispatch({ type: LanguageActionTypes.selectLanguage, selectedLanguage });
};

/**
 * Selects the default language based on the country and the preferred locale. If there's no exact match,
 * we fall back to the default language set in the database. If there's none defined, then we fall back to the first english language,
 * if no english language is specified we fall back to the first language in the list
 */
function selectDefaultLanguage(countryLanguages: CountryLanguage[], preferredLocale: string, distributor?: Distributor): CountryLanguage | null {
    const userSelectedLanguage = JSON.parse(localStorage.getItem(distributorDefaultLanguageLocalStorageKey) || '{}');
    if(userSelectedLanguage && distributor && distributor.seneSiteInfo && userSelectedLanguage[distributor.seneSiteInfo.seneSiteUrl.toLowerCase()])
    {
        const selectedLocale = userSelectedLanguage[distributor.seneSiteInfo.seneSiteUrl.toLowerCase()];
        const matchingLocale = countryLanguages.find(cl => cl.code.toLocaleLowerCase() == selectedLocale.toLocaleLowerCase());
        if(matchingLocale) {
            return matchingLocale;
        }
    }

    if(distributor && distributor.seneSiteInfo && distributor.seneSiteInfo.locale) {
        const matchingLocale = countryLanguages.find(cl => cl.code.toLocaleLowerCase() == distributor.seneSiteInfo.locale.toLocaleLowerCase());
        if(matchingLocale) {
            return matchingLocale;
        }
    }

    const defaultLanguage = countryLanguages.find(cl => cl.defaultLanguage);
    if (defaultLanguage) {
        return defaultLanguage;
    }

    if (distributor) {
        const userCountryLanguage = countryLanguages.find(cl => cl.countryAlpha2Code.toLowerCase() == distributor.countryName.toLowerCase());
        if (userCountryLanguage) {
            return userCountryLanguage;
        }
    }

    const matchingLocale = countryLanguages.find(cl => cl.code.toLocaleLowerCase() == preferredLocale.toLocaleLowerCase());
    if (matchingLocale) {
        return matchingLocale;
    }

    const preferredLanguage = preferredLocale.split("-")[0];
    const matchingLanguage = countryLanguages.find(cl => cl.languageAlpha2Code.toLocaleLowerCase() == preferredLanguage.toLocaleLowerCase());
    if (matchingLanguage) {
        return matchingLanguage;
    }

    return countryLanguages.length > 0 ? countryLanguages[0] : null;
}