import * as React from 'react';

import { selectImageResizerUrl, selectConfiguration } from '../../store/selectors/configurationSelectors';
import { connect } from 'react-redux';
import { AssetImage, IImageComponentParams } from './Images';

class AssetImageComponent extends React.PureComponent<IImageComponentParams> {

    render() {
        const {dispatch, ...params} = this.props as any;
        return <AssetImage {...params} />
    }
}

const mapStateToProps = (state) => {
    return {
        imageResizerUrl: selectImageResizerUrl(state),
        config: selectConfiguration(state)
    };
};

export default connect(mapStateToProps)(AssetImageComponent);