import * as React from "react";
import {RouteComponentProps} from "react-router";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getDistributorFromSeneSiteUrl} from "../../actions/distributor";
import {Distributor} from "../../models/distributor";
import {setCallbackUrl} from "../../utils/auth/CallbackStorage";
import {UserManager} from "../../utils/auth/UserManager";
import {getCountryLanguages} from "../../actions/languageActions";
import {getLang} from "../../utils/helpers";
import {WithNamespaces, withNamespaces} from "react-i18next";
import Spinner from "../common/Spinner";
import { shouldSwithToMaintenance } from "../../utils/helpers";

interface BackOfficeAdminLandingLayoutProps {

}

interface BackOfficeAdminLandingLayoutDispatchProps {
    getDistributorFromSeneSiteUrl(seneSiteUrl: string);
    getCountryLanguages(country2Alpha: string, preferredLocale: string, distributor?: Distributor);
}

class BackOfficeAdminLandingLayout extends React.Component<BackOfficeAdminLandingLayoutProps &
    BackOfficeAdminLandingLayoutDispatchProps & RouteComponentProps & WithNamespaces> {
    componentDidMount() {
        const seneSiteUrl = this.props.match.params && this.props.match.params[0];

        if (seneSiteUrl) {
            this.props.getDistributorFromSeneSiteUrl(seneSiteUrl)
                .then((distributor: Distributor) => {
                    setCallbackUrl(`/administration?distributorId=${distributor.dist_ID}`);

                    if (distributor.seneSiteInfo && distributor.seneSiteInfo.locale) {
                        UserManager.signinRedirect({ui_locales: distributor.seneSiteInfo.locale.toLowerCase()});
                    } else {
                        // Distributor has no preferred local set, we need to fetch the languages for her country and try to use that for IDS locale
                        const preferredLocale = this.props.i18n.language ? this.props.i18n.language : getLang();

                        this.props.getCountryLanguages(distributor.countryName, preferredLocale, distributor)
                            .then((action) => {
                                const language = action && action.selectedLanguage && action.selectedLanguage.code || 'en-us';
                                UserManager.signinRedirect({ui_locales: language.toLowerCase()});
                            });
                    }

                })
                .catch((exp) => {
                    shouldSwithToMaintenance(exp);
                    //Unable to determine who the distributor is, simply redirect to IDS without any preferred language
                    setCallbackUrl('/administration');
                    UserManager.signinRedirect();
                });
        } else {
            //If the url is missing the SenesiteUrl parameter, redirect to main page
            window.location.href = '';
        }
    }

    render(): React.ReactNode {
        return (<div><Spinner/></div>);
    }
}

const mapDispatchToProps = (dispatch): BackOfficeAdminLandingLayoutDispatchProps => (
    bindActionCreators({
        getDistributorFromSeneSiteUrl,
        getCountryLanguages
    }, dispatch)
);

export default withNamespaces()(connect(null, mapDispatchToProps)(BackOfficeAdminLandingLayout));

