import React from 'react';

import './SeneHomeMediaButton.scss';

interface ISocialMediaProps {
    link: string;
    icon: string;
}

export class SeneHomeMediaButton extends React.Component<ISocialMediaProps, {}> {

    render() {
        return <a href={this.props.link} target='_blank'><i className={"home-media-button fab " + this.props.icon} /></a>;
    }
}
