import React from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { Button } from 'reactstrap';
import Modal from 'react-modal';

import './MyCustomersPage.scss';
import * as adminApi from '../../../api/adminApi';
import * as customerApi from '../../../api/customerApi';
import { Customer } from '../../../models/customer';
import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';
import { DistributorState } from '../../../reducers/distributor';
import withAdminPageTitle from '../withAdminPageTitle';
import Spinner from '../../common/Spinner';
import { Mobile, DesktopOrTablet } from '../../common/responsive/ResponsiveHelpers';
import senePaginationFactory from '../../common/SeneTablePagination';
import CustomersMobile from './CustomersMobile';
import CustomerDetails from './CustomerDetails';
import produce from 'immer';
import SeneButton from '../../common/inputs/SeneButton';
import withRetryComponent, { RetryComponentProps } from '../../common/withRetryComponent';
import * as toastHelper from '../../../utils/toastHelper';
import { shouldSwithToMaintenance } from "../../../utils/helpers";

interface MyCustomersPageStateProps {
    distributor: DistributorState;
}
type MyCustomersPageProps = MyCustomersPageStateProps & RetryComponentProps & WithNamespaces;

interface MyCustomersPageState {
    customers: Customer[] | null;
    isEditCustomerModalOpen: boolean;
    selectedCustomer: Customer | null;
    isSelectedCustomerModified: boolean;
    isCustomerUpdateInProgress: boolean;
}

class MyCustomersPage extends React.Component<MyCustomersPageProps, MyCustomersPageState> {
    state: MyCustomersPageState = {
        customers: null,
        isEditCustomerModalOpen: false,
        selectedCustomer: null,
        isSelectedCustomerModified: false,
        isCustomerUpdateInProgress: false
    }

    componentDidMount() {
        this.getCustomers();
    }

    componentDidUpdate(prevProps: MyCustomersPageProps) {
        this.getCustomers(prevProps.distributor && prevProps.distributor.dist_ID || undefined);
    }

    getCustomers(prevDistId?: number) {
        const { distributor } = this.props;
        if (!distributor || !distributor.dist_ID || (prevDistId && distributor.dist_ID === prevDistId)) {
            return;
        }

        adminApi.getCustomers(distributor.dist_ID)
            .then(customers => {
                this.setState({ customers });
            })
            .catch(err => {
                shouldSwithToMaintenance(err);
                this.props.initFailed();
            });
    }

    onCustomerEditClicked = (customer: Customer) => {
        this.setState({
            selectedCustomer: customer,
            isEditCustomerModalOpen: true,
            isSelectedCustomerModified: false
        });

        if (!customer.isOpened) {
            const cust: Customer = {
                ...customer,
                isOpened: true
            };

            customerApi.updateCustomer(cust)
            .then(() => this.setState({ selectedCustomer: cust }))
                .catch(err => {
                    shouldSwithToMaintenance(err);
                    toastHelper.error({ error: this.props.t('updateCustomerError') });
                });
        }
    }

    handleCustomerUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const changedProp = e.target.name;
        const value = e.target.value;
        const customer = this.state.selectedCustomer!;

        const updatedCustomer = {
            ...customer,
            [changedProp]: value
        };

        this.setState({ selectedCustomer: updatedCustomer, isSelectedCustomerModified: true });
    }

    handleClose = () => {
        this.setState({ isEditCustomerModalOpen: false, selectedCustomer: null, isSelectedCustomerModified: false, isCustomerUpdateInProgress: false })
    }

    saveCustomer = () => {
        const customer = this.state.selectedCustomer;

        if (customer) {
            this.setState({ isCustomerUpdateInProgress: true }, () => {
                customerApi.updateCustomer(customer)
                .then(() => {
                    this.setState(
                        produce(draft => {
                            const index = draft.customers!.findIndex(c => c.id === customer.id);
                            if (draft.customers && index > -1) {
                                draft.customers[index] = customer;
                            }

                            draft.isEditCustomerModalOpen = false;
                            draft.isSelectedCustomerModified = false;
                            draft.selectedCustomer = null;
                            draft.isCustomerUpdateInProgress = false;
                            return draft;
                        })
                    );
                })
                    .catch(err => {
                        shouldSwithToMaintenance(err);
                        toastHelper.error({ error: this.props.t('updateCustomerError') });
                    });
            })

        }
    }

    renderModal = () => {
        const { t } = this.props;

        return (
            <Modal
                isOpen={this.state.isEditCustomerModalOpen}
                onRequestClose={this.handleClose} className="modal-dialog modal-lg">
                <div className="modal-content overflow-scroll">
                    <div className="modal-header">
                        <h5 className="modal-title" id="uploadContainerLabel">{t('customerProfile')}</h5>
                        <button type="button" className="close" onClick={this.handleClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <CustomerDetails
                            customer={this.state.selectedCustomer}
                            t={this.props.t}
                            onCustomerChanged={this.handleCustomerUpdate}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.handleClose}>{t('btnClose')}</button>
                        <SeneButton
                            disabled={!this.state.isSelectedCustomerModified}
                            loading={this.state.isCustomerUpdateInProgress}
                            onClick={this.saveCustomer}
                        >
                            {t('btnSave')}
                        </SeneButton>
                    </div>
                </div>
            </Modal>
        )
    }

    render() {
        if (!this.state.customers) {
            return <Spinner />;
        }
        else {
            const rows = this.state.customers;
            const { t } = this.props;
            const columns = [
                {
                    dataField: 'firstName', text: t('firstName'), sort: true,
                    filterValue: (cell, row) => row.firstName + ' ' + row.lastName
                },
                { dataField: 'lastName', text: t('lastName'), sort: true },
                { dataField: 'email', text: t('email'), sort: true },
                { dataField: 'phone', text: t('phone'), sort: true },
                {
                    dataField: 'id', text: t('edit'),
                    events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                            this.onCustomerEditClicked(row);
                        }
                    },
                    formatter: (cell, row) => {
                        return <Button style={{ padding: 0 }} color="link">{t('edit')}</Button>;
                    },
                    headerStyle: (column, colIndex) => {
                        return { width: '45px' };
                    }
                }
            ];
            const { SearchBar } = Search;

            return !rows ? <i className="fa fa-spinner spin" /> :
                <div className="row">
                    <div className="col-xl-12">
                        <div className="row">
                            <ToolkitProvider
                                keyField="id"
                                data={rows}
                                columns={columns}
                                condensed={true}
                                bootstrap4={true}
                                search
                            >
                                {props => (
                                    <>
                                        <div className="col-xl-3 col-md-4 customer-search">
                                            <SearchBar {...props.searchProps} />
                                        </div>
                                        <DesktopOrTablet>
                                            <div className="col-xl-12" style={{ flexDirection: 'column' }}>
                                                <BootstrapTable
                                                    bordered={false}
                                                    {...props.baseProps}
                                                    pagination={senePaginationFactory(this.props.t)} />
                                            </div>
                                        </DesktopOrTablet>
                                        <Mobile>
                                            <div className="col-12">
                                                <CustomersMobile {...props.baseProps} t={this.props.t} onCustomerEditClicked={this.onCustomerEditClicked} />
                                            </div>
                                        </Mobile>
                                    </>
                                )}
                            </ToolkitProvider>
                        </div>
                    </div>
                    {this.renderModal()}
                </div>
        }

    }
}

const mapStateToProps = state => ({
    distributor: selectSenesiteDistributor(state)
});

export default withRetryComponent()(withAdminPageTitle('mycustomers')(withNamespaces('SeneSiteCustomers')((connect(mapStateToProps)(MyCustomersPage)))));
