import * as paymentApi from '../api/paymentApi';
import { CustomerInformation, ShippingInfo, ShippingAddress } from '../models/checkout';
import { Thunk } from '../store';
import {CheckoutState, PaymentInfoState} from '../reducers/checkout';
import {
    selectShoppingCartDto,
    selectPaymentRequestDto,
    selectCheckoutState
} from '../store/selectors/checkoutSelectors';
import { selectDistributorCountry, selectHandlingRate } from '../store/selectors/distributorSelectors';
import { setBlockUi } from './ui';
import { removeProductFromCart } from './cartActions';
import { seneSiteNavigateTo } from './router';
import * as toast from './../utils/toastHelper';
import { ActionToastProperties } from '../models/ui';
import {PaymentType} from "../models/payment";
import { sendRemoveItemFromCartLog, sendDeclinedPaymentLog } from './loggingActions';
import { shouldSwithToMaintenance } from "../utils/helpers";

export enum CheckoutActionTypes {
    saveOrderInfo = '[Checkout] Save customer and shipping info',
    savePaymentInfo = '[Checkout] Save payment info',
    calculateChargesSuccess = '[Checkout] Calculate charges success',
    calculateChargesError = '[Checkout] Calculate charges error',
    paymentRequestSent = '[Checkout] Payment request sent',
    removeItemsNotInInventory = '[Checkout] Error: products not in inventory',
    orderSuccess = '[Checkout] Successful order',
    declinedPayment = '[Checkout] Declined Payment',
    payPalPaymentRedirect = '[Checkout] Start redirecting to PayPal',
    payPalApiPaymentRedirect = '[Checkout] PayPal Api Payment redirect',
    payPalApiPaymentIdReceived = '[Checkout] PayPal Api Payment id received',
    reloadCheckoutState = '[Checkout] Reload State',
    resetCheckoutErrors = '[Checkout] Reset Checkout Errors'
}

export type OrderInfoParams = {
    customerInfo: CustomerInformation,
    shippingAddress: ShippingAddress,
    shippingMethod: ShippingInfo
}

export const saveOrderInfo = ({ customerInfo, shippingAddress, shippingMethod }: OrderInfoParams): Thunk =>
    (dispatch, getState) => {
        const state = getState();
        const distCountry = selectDistributorCountry(state);

        return dispatch({ type: CheckoutActionTypes.saveOrderInfo, customerInfo, shippingAddress: { ...shippingAddress, country: distCountry }, shippingMethod });
    };


export const savePaymentInfo = (paymentInfo: PaymentInfoState): Thunk =>
    (dispatch, getState) => {
        return dispatch({ type: CheckoutActionTypes.savePaymentInfo, paymentInfo });
    };

export const calculateCharges = (toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    const state = getState();
    const shoppingCart = selectShoppingCartDto(state);

    if (!state.distributor || !state.distributor.dist_ID || !shoppingCart) {
        return;
    }

    return paymentApi.calculateTotals(state.distributor.dist_ID, shoppingCart)
        .then(total => {
            return dispatch({
                type: CheckoutActionTypes.calculateChargesSuccess,
                total
            });
        })
        .catch(err => {
            shouldSwithToMaintenance(err);
            toast.error(toastProps);
            dispatch({ type: CheckoutActionTypes.calculateChargesError });
            throw err;
        });
}


export const submitPayment = (customerNotes: string, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    const state = getState();
    let paymentRequest = selectPaymentRequestDto(state);
    if(paymentRequest) {
        paymentRequest.customerNotes = customerNotes;
    }

    if (!state.distributor || !state.distributor.dist_ID || !paymentRequest) {
        return;
    }

    dispatch(setBlockUi(true));
    return paymentApi.submitPayment(state.distributor.dist_ID, paymentRequest)
        .then((res) => {
            toast.success(toastProps);
            if (res.unavailableItems && res.unavailableItems.length > 0) {
                dispatch(removeItemsNotInInventory(res.unavailableItems));
            }
            else if(res.orderId != 0) {
                dispatch(orderSuccess(res.orderId));
            }
            else {
                toast.error(toastProps);
                dispatch(setBlockUi(false));
            }
            return dispatch({ type: CheckoutActionTypes.paymentRequestSent });
        })
        .catch((exp) => {
            shouldSwithToMaintenance(exp);
            toast.error(toastProps);
            dispatch(setBlockUi(false));
        });
}

export const createPayPalApiPayment = (customerNotes: string, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    const state = getState();
    let paymentRequest = selectPaymentRequestDto(state);
    if(paymentRequest) {
        paymentRequest.customerNotes = encodeURIComponent(customerNotes);
    }

    if (!state.distributor || !state.distributor.dist_ID || !paymentRequest) {
        return;
    }

    dispatch(setBlockUi(true));
    return paymentApi.createPayPalApiPayment(state.distributor.dist_ID, paymentRequest)
        .then(res => {
            toast.success(toastProps);
            return redirectToPayPalApi(res.redirectUrl, res.referenceToken)(dispatch, getState);
        })
        .catch(err => {
            shouldSwithToMaintenance(err);
            dispatch(setBlockUi(false));
            toast.error(toastProps);
        });
};

export const removeItemsNotInInventory = (items: any[]): Thunk => (dispatch, getState) => {
    items.map(p => dispatch(removeProductFromCart(p.sku)));
    dispatch(setBlockUi(false));
    sendRemoveItemFromCartLog(items, getState());
    return dispatch({ type: CheckoutActionTypes.removeItemsNotInInventory, items: items });
};

export const declinedPayment = (errors: any[]): Thunk => (dispatch, getState) => {
    sendDeclinedPaymentLog(errors, getState());
    dispatch(setBlockUi(false));
    return dispatch({ type: CheckoutActionTypes.declinedPayment, errors });
};

export const redirectToPayPal = (redirectUrl: string | undefined, orderNumber: number): Thunk => (dispatch, getState) => {
    if (redirectUrl) {
        const redirectInMs = 3000;
        orderSuccess(orderNumber)(dispatch, getState);
        dispatch({ type: CheckoutActionTypes.payPalPaymentRedirect, redirectUrl, redirectInMs: redirectInMs });
        setTimeout(() => window.location.href = redirectUrl, redirectInMs);
    }
};

export const redirectToPayPalApi = (redirectUrl: string, referenceToken: string): Thunk => (dispatch, getState) => {
    if (redirectUrl) {
        const redirectInMs = 3000;
        dispatch({ type: CheckoutActionTypes.payPalApiPaymentRedirect, redirectUrl, redirectInMs: redirectInMs });
        setTimeout(() => window.location.href = redirectUrl, redirectInMs);
    }
};

export const reloadCheckoutState = (payPalApiPaymentId: string, checkoutState: CheckoutState): Thunk => (dispatch, getState) => {
    dispatch({ type: CheckoutActionTypes.reloadCheckoutState, checkoutState });
    payPalApiPaymentIdReceived(payPalApiPaymentId)(dispatch, getState);
};

export const payPalApiPaymentIdReceived = (payPalApiPaymentId: string): Thunk => (dispatch, getState) => {
    return dispatch({ type: CheckoutActionTypes.payPalApiPaymentIdReceived, payPalApiPaymentId });
};

export const orderSuccess = (orderNumber: number): Thunk => (dispatch, getState) => {
    const state = getState();
    const handlingRate = selectHandlingRate(state);
    const checkoutState = state.checkout;
    const cart = state.cart;

    dispatch(seneSiteNavigateTo('/orderconfirmation'));
    dispatch(setBlockUi(false));
    return dispatch({ type: CheckoutActionTypes.orderSuccess, orderNumber, checkoutState, cart, handlingRate });
}

export const resetCheckoutErrors = (): Thunk => (dispatch, getState) => {
    return dispatch({ type: CheckoutActionTypes.resetCheckoutErrors });
};
