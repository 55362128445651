import * as userApi from '../api/userApi';
import { Thunk } from '../store';
import { shouldSwithToMaintenance } from "../utils/helpers";

export enum UserActionTypes {
    getCurrentUserSuccess = '[User] Get userinfo success',
    getCurrentUserError = '[User] Get userinfo error',
}

export const getCurrentUserInfo = (): Thunk => (dispatch) => {
    return userApi.getCurrentUser()
        .then(user => dispatch({type: UserActionTypes.getCurrentUserSuccess, user}))
        .catch((exp) => {
            shouldSwithToMaintenance(exp);
            dispatch({ type: UserActionTypes.getCurrentUserError })
        });
}
