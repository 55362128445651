export const hasAdminRights = (user: any) => {
    if (!user || !user.role) {
        return false;
    }

    const roles: any[] = Array.isArray(user.role) ? user.role : [user.role];
    return roles.indexOf('Distributor') > -1 || hasSeneCareRights(user);
};

export const hasSeneCareRights = (user: any) => {
    if (!user || !user.role) {
        return false;
    }

    const roles: any[] = Array.isArray(user.role) ? user.role : [user.role];
    return roles.indexOf('Administrator') > -1 || roles.indexOf('SeneCare') > -1;
};

export const hasCustomerRights = (user: any) => {
    if (!user || !user.role) {
        return false;
    }

    const roles: any[] = Array.isArray(user.role) ? user.role : [user.role];
    return roles.indexOf('Customer') > -1 || roles.indexOf('SeneCare') > -1;
};
