import React from 'react';
import i18next from 'i18next';
import { Nav, NavLink, NavItem, TabPane, TabContent } from 'reactstrap';

import { Customer } from '../../../models/customer';
import TextInput from '../../common/inputs/SeneTextInput';
import Spinner from '../../common/Spinner';
import { TranslationRemapper } from '../../../utils/helpers';

interface CustomerDetailsProps {
    customer: Customer | null,
    t: i18next.TranslationFunction;
    onCustomerChanged(e: React.ChangeEvent<HTMLInputElement>);
}

interface CustomerDetailsState {
    activeTab: 'shipping' | 'billing';
}

class CustomerDetails extends React.PureComponent<CustomerDetailsProps> {
    state: CustomerDetailsState = {
        activeTab: 'billing'
    }

    updateInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onCustomerChanged(e);
    }

    getTranslator() {
        const dic = {
            'HK': {
                'lblAddress1': 'lblAddress1Hk',
                'lblAddress2': 'lblAddress2Hk',
                'lblAddress3': 'lblAddress3Hk',
                'lblCity': 'lblCityHk',
                'city': 'cityHk',
                'lblState': 'lblStateHk'
            }
        };

        return this.props.customer !== null ?
            new TranslationRemapper(dic, this.props.t).getTranslatorFor(this.props.customer.shippingCountry):
            this.props.t;
    }

    render() {
        const t = this.getTranslator();

        return !this.props.customer
            ? <Spinner />
            : <div className="container" style={{ width: "95%", padding: "10px", margin: "10px" }}>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            style={{cursor: 'pointer'}}
                            className={`${this.state.activeTab === 'billing' ? 'active' : ''}`}
                            onClick={() => this.setState({activeTab: 'billing'})}>
                            &nbsp;{t('billing')}&nbsp;
                        </NavLink>
                    </NavItem>
                    <NavItem >
                        <NavLink
                            style={{cursor: 'pointer'}}
                            className={`${this.state.activeTab === 'shipping' ? 'active' : ''}`}
                            onClick={() => this.setState({activeTab: 'shipping'})}>
                            &nbsp;{t('shipping')}&nbsp;
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="shipping">
                        <div className="row admin-dist-name">
                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                <h6>{`${t('lblCountry')}: ${this.props.customer.shippingCountry}`}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <TextInput label={t('lblAddress1') + '*'} name={"shippingAddress1"} onChange={this.updateInfo} value={this.props.customer.shippingAddress1} {...{ tabIndex: 9 }} /></div>
                            <div className="col-md-6">
                                <TextInput label={t('lblAddress2') + '*'} name={"shippingAddress2"} onChange={this.updateInfo} value={this.props.customer.shippingAddress2} {...{ tabIndex: 10 }} /></div>
                        </div>
                        { this.props.customer.shippingCountry === 'HK' &&
                            <div className="row">
                                <div className="col-md-6">
                                    <TextInput label={t('lblAddress3') + '*'} name={"shippingAddress3"} onChange={this.updateInfo} value={this.props.customer.shippingAddress3} {...{ tabIndex: 11 }} /></div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-6">
                                <TextInput label={t('lblCity') + '*'} name={"shippingCity"} onChange={this.updateInfo} value={this.props.customer.shippingCity} {...{ tabIndex: 11 }} /></div>
                            <div className="col-md-6">
                                <TextInput label={t('lblState') + '*'} name={"shippingState"} onChange={this.updateInfo} value={this.props.customer.shippingState} {...{ tabIndex: 12 }} /></div>
                        </div>
                        <div className="row">
                            { this.props.customer.shippingCountry !== 'HK' &&
                                <div className="col-md-6">
                                    <TextInput label={t('lblZip') + '*'} name={"shippingZip"} onChange={this.updateInfo} value={this.props.customer.shippingZip} {...{ tabIndex: 13 }} /></div>
                            }
                        </div>
                    </TabPane>

                    <TabPane tabId="billing">
                        <div className="row admin-dist-name">
                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                <h6>{`${t('lblCountry')}: ${this.props.customer.billingCountry}`}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <TextInput label={t('lblFirstName') + '*'} name={"firstName"} onChange={this.updateInfo} value={this.props.customer.firstName} {...{ tabIndex: 1 }} /></div>
                            <div className="col-md-6">
                                <TextInput label={t('lblLastName') + '*'} name={"lastName"} onChange={this.updateInfo} value={this.props.customer.lastName} {...{ tabIndex: 2 }} /></div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <TextInput label={t('lblAddress1') + '*'} name={"billingAddress1"} onChange={this.updateInfo} value={this.props.customer.billingAddress1} {...{ tabIndex: 3 }} /></div>
                            <div className="col-md-6">
                                <TextInput label={t('lblAddress2') + '*'} name={"billingAddress2"} onChange={this.updateInfo} value={this.props.customer.billingAddress2} {...{ tabIndex: 4 }} /></div>
                        </div>
                        { this.props.customer.billingCountry === 'HK' &&
                            <div className="row">
                                <div className="col-md-6">
                                    <TextInput label={t('lblAddress3') + '*'} name={"billingAddress3"} onChange={this.updateInfo} value={this.props.customer.billingAddress3} {...{ tabIndex: 5 }} /></div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-6">
                                <TextInput label={t('lblCity') + '*'} name={"billingCity"} onChange={this.updateInfo} value={this.props.customer.billingCity} {...{ tabIndex: 5 }} /></div>
                            <div className="col-md-6">
                                <TextInput label={t('lblState') + '*'} name={"billingState"} onChange={this.updateInfo} value={this.props.customer.billingState} {...{ tabIndex: 6 }} /></div>
                        </div>
                        <div className="row">
                            { this.props.customer.billingCountry !== 'HK' &&
                                <div className="col-md-6">
                                    <TextInput label={t('lblZip') + '*'} name={"billingZipCode"} onChange={this.updateInfo} value={this.props.customer.billingZipCode} {...{ tabIndex: 7 }} /></div>
                            }
                            <div className="col-md-6">
                                <TextInput label={t('lblCountry') + '*'} name={"billingCountry"} onChange={this.updateInfo} value={this.props.customer.billingCountry} {...{ tabIndex: 8 }} /></div></div>
                    </TabPane>
                </TabContent>
            </div>
    }
}

export default CustomerDetails;
