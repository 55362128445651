import React from 'react';
import { RouteComponentProps, Route } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectSenesiteDistributor } from '../../store/selectors/distributorSelectors';
import { selectLoading } from '../../store/selectors/uiSelectors';
import { getDistributorFromSeneSiteUrl } from '../../actions/distributor';
import { loadCart } from '../../actions/cartActions';
import { DistributorState } from '../../reducers/distributor';
import NotFound from '../common/404';
import Spinner from '../common/Spinner';
import UnderConstruction from './UnderConstruction';
import SeneMenu from './menu/SeneMenu';
import { seneSiteRoutes } from './routes';
import { getMarketing } from '../../actions/marketingActions';
import { getCountryLanguages } from "../../actions/languageActions";
import { Distributor } from "../../models/distributor";
import { getBrowserCountry, getLang } from '../../utils/helpers';
import { getCategories } from '../../actions/catalogActions';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { selectCdoLink, selectKnTLink, redirectToKissTell } from "../../store/selectors/configurationSelectors";
import { selectUserProfile } from "../../store/selectors/authSelectors";


interface SeneSiteStateProps {
    distributor: DistributorState;
    loading: boolean;
    cdoLink: string;
    knTLink: string;
    redirectToKissTell: boolean;
    user: any;
}

interface SeneSiteDispatchProps {
    getDistributorFromSeneSiteUrl(seneSiteUrl: string);
    getCountryLanguages(country2Alpha: string, preferredLocale: string, distributor?: Distributor);
    loadCart(seneSiteUrl: string);
    getMarketing();
    getCategories();
}

type SeneSitePageProps = RouteComponentProps & SeneSiteDispatchProps & SeneSiteStateProps & WithNamespaces;

class SeneSitePage extends React.Component<SeneSitePageProps> {

    componentDidMount = () => {
        const seneSiteUrl = this.props.match.params['seneSiteUrl'];

        this.props.getMarketing();
        if (seneSiteUrl) {
            const preferredLocale = this.props.i18n.language ? this.props.i18n.language : getLang();
            this.props.getDistributorFromSeneSiteUrl(seneSiteUrl)
                .then(async (distributor: Distributor) => {
                    await this.props.getCountryLanguages(distributor.countryName, preferredLocale, distributor);

                    if ((this.props.cdoLink || this.props.knTLink) && distributor.cdoInfo && distributor.cdoInfo.isCdoOn && distributor.seneSiteInfo.redirectToCdo) {
                        let redirectUrl = this.props.cdoLink + distributor.dist_ID + "&culture=" + preferredLocale;

                        if (this.props.redirectToKissTell) {
                            const country = distributor.countryName.toLowerCase();

                            var lang = country === 'mx' ? 'es' : 'en';

                            redirectUrl = `${this.props.knTLink}${lang}-${country}/distributor/${distributor.dist_ID}`;
                        }
                        window.location.href = redirectUrl;
                    }
                    
                    this.props.getCategories();
                })
                .catch(() => {
                    this.props.getCountryLanguages(getBrowserCountry(), preferredLocale);
                });
            this.props.loadCart(seneSiteUrl);
        }
    }

    renderSeneSite() {
        if (!this.props.distributor || !this.props.distributor.seneSiteInfo) {
            return <NotFound />;
        }

        const { user, distributor } = this.props;
        const isUnderConstruction = distributor.seneSiteInfo.webStat === 'Setup';

        return (
            isUnderConstruction
                ? <UnderConstruction
                    isDistributorLoggedIn={!!user}
                    isOwnSeneSite={user && +user.preferred_username === distributor.dist_ID}
                />
                :
                <div className="container">
                    <SeneMenu />
                    {
                        seneSiteRoutes.map(route => (
                            <Route key={route.path} {...route} />
                        ))
                    }
                </div>
        )
    }

    render() {
        return (
            <div style={{ flex: '1', position: 'relative' }}>
                {
                    (!this.props.distributor && this.props.loading)
                        ? <Spinner style={{ position: 'absolute', width: '100%', top: '45%' }} />
                        : this.renderSeneSite()
                }
            </div>
        );
    }
}

const mapStateToProps = (state): SeneSiteStateProps => ({
    distributor: selectSenesiteDistributor(state),
    loading: selectLoading(state),
    cdoLink: selectCdoLink(state),
    user: selectUserProfile(state),
    knTLink: selectKnTLink(state),
    redirectToKissTell: redirectToKissTell(state)
});

const mapDispatchToProps = (dispatch): SeneSiteDispatchProps => (
    bindActionCreators({
        getDistributorFromSeneSiteUrl,
        loadCart,
        getMarketing,
        getCountryLanguages,
        getCategories,
    }, dispatch)
);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(SeneSitePage));
