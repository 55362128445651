import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import './MySeneSitesPage.scss';
import * as blogApi from './../../../api/blogApi';
import Spinner from '../../common/Spinner';
import { BlogFeed } from '../../common/blog/BlogFeed'
import { BlogFeed as IBlogFeed, BlogPost as IBlogPost } from '../../../models/blog'
import { Distributor } from '../../../models/distributor';
import i18n from '../../../i18n';
import { shouldSwithToMaintenance } from "../../../utils/helpers";

interface MySeneSitesPageProps {
    distributor: Distributor;
    isBlogPreviewLoading: boolean;
    hasBlogError: boolean;
    setHasBlogError(value: boolean);
    setIsBlogPreviewLoading(value: boolean);
    setDistributor(dist: Distributor);
    t: i18n.TranslationFunction;
}

interface MySeneSitesPageState {
    blogUrl: string;
    blogPreview: IBlogFeed;
    previewedUrl: string;
        
}

class MySeneSitesBlog extends React.Component<MySeneSitesPageProps, MySeneSitesPageState> {
    state: MySeneSitesPageState = {
        blogUrl: '',
        blogPreview: {} as IBlogFeed,
        previewedUrl: ''
    }

    componentDidMount() {
        this.setState({
            blogUrl: this.props.distributor.seneSiteInfo.blogUrl
        });
    }

    handleBlogChange = (e) => {
        this.updateBlogUrl(e.target.value);
    }

    updateBlogUrl = (url: string) => {
        this.setState({ blogUrl: url });

        const distCopy = {
            ...this.props.distributor,
            seneSiteInfo: {
                ...this.props.distributor.seneSiteInfo,
                blogUrl: url
            }
        }
        distCopy.seneSiteInfo.blogUrl = url;
        this.props.setDistributor(distCopy);
    }
    
    resetBlogState() {
        this.setState({
            blogPreview: {} as IBlogFeed,
            previewedUrl: '',
            blogUrl: ''
        });
    }
    
    previewBlog = () => {
        const url = this.state.blogUrl.trim();
    
        if (!url) {
            this.resetBlogState();
            return;
        }
    
        if (this.state.previewedUrl === url) {
            (this.state.blogUrl !== url) && this.setState({ blogUrl: url });
            return;
        }
    
        this.props.setIsBlogPreviewLoading(true);
        this.props.setHasBlogError(false);

        this.setState({ blogUrl: url }, () => {
            blogApi.getBlogFeedPreview(this.props.distributor.dist_ID, this.state.blogUrl)
                .then(res => {
                    this.setState({ 
                        blogUrl: res.feedUrl, 
                        previewedUrl: res.feedUrl, 
                        blogPreview: {url: res.feedUrl, posts: res.preview} 
                    });
                    this.updateBlogUrl(res.feedUrl);
                    this.props.setIsBlogPreviewLoading(false);
                })
                .catch((exp) => {
                    shouldSwithToMaintenance(exp);
                    this.setState({ blogPreview: {} as IBlogFeed });
                    this.props.setIsBlogPreviewLoading(false);
                    this.props.setHasBlogError(true);
                });
        });
    }
    
    renderBlogPreview() {
        if (this.props.hasBlogError) {
            return (
                <div className="alert alert-danger" role="alert">
                    Unable to get blog feed, please correct the link above.
                </div>
            )
        }
    
        return (this.state.blogPreview.posts && this.state.blogPreview.posts.length > 0 &&
            <BlogFeed feed={this.state.blogPreview} />);
    }
    
    render() {
        const {t} = this.props;
        return (
            <>
                <div className="admin-personal-msg">
                    <h4>{t('myBlog')}</h4>
                </div>
                <div className="form-group">
                    <label htmlFor="blogUrl">{t('blogURL')}</label>
                    <div className="input-group">
                        <input
                            placeholder="http://www.mypersonalblog.com"
                            className="form-control"
                            type="text"
                            id="blogUrl"
                            name="blogUrl"
                            value={this.state.blogUrl}
                            onChange={this.handleBlogChange}
                            onBlur={this.previewBlog}
                        />
                        <div className="input-group-append">
                            <button className="btn btn-primary" onClick={this.previewBlog}>{t('preview')}</button>
                        </div>
                    </div>
                </div>
                {
                    this.props.isBlogPreviewLoading
                        ? <Spinner />
                        : this.renderBlogPreview()
                }
            </>
        )
    }
}

export default MySeneSitesBlog
