import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { Category, SubCategory } from '../../../models/category';
import SeneSiteLink from '../../common/SeneSiteLink';
import { DesktopOrTablet, Mobile } from '../../common/responsive/ResponsiveHelpers';
import { getCategoryName, getSubCategoryName } from '../../../utils/helpers';
import i18n from '../../../i18n';

interface CategoryBreadcrumbsProps {
    category: Category;
    subCategory?: SubCategory;
    locale: string;
    t: i18n.TranslationFunction;
}

class CategoryBreadcrumbs extends React.PureComponent<CategoryBreadcrumbsProps> {
    get categoryUrl() {
        const { category } = this.props;
        const categoryName = getCategoryName(category, this.props.locale);
        return `/shopproducts/${category.categoryId}/${encodeURIComponent(categoryName).toLowerCase()}`;
    }

    get subCategoryUrl() {
        const { subCategory } = this.props;
        const subCategoryName = subCategory && getSubCategoryName(subCategory, this.props.locale);
        return subCategory && subCategoryName &&
            `/shopproducts/${subCategory.categoryId}/${subCategory.id}/${encodeURIComponent(subCategoryName).toLowerCase()}`
            || '#';
    }

    render() {
        const { category, subCategory } = this.props;
        const categoryName = getCategoryName(category, this.props.locale);
        const subCategoryName = subCategory && getSubCategoryName(subCategory, this.props.locale);
        return (
            <>
                <DesktopOrTablet>
                    <Breadcrumb className="category-breadcrumbs">
                        <BreadcrumbItem>
                            <SeneSiteLink to={this.categoryUrl}>
                                {categoryName}
                            </SeneSiteLink>
                        </BreadcrumbItem>
                        {
                            subCategory &&
                            <BreadcrumbItem>
                                <SeneSiteLink to={this.subCategoryUrl}>
                                    {subCategoryName}
                                </SeneSiteLink>
                            </BreadcrumbItem>
                        }
                    </Breadcrumb>
                </DesktopOrTablet>
                <Mobile>
                    <div className="category-breadcrumbs">
                        <SeneSiteLink to={subCategory ? this.subCategoryUrl : this.categoryUrl}>
                            <h4 className="back-navigation-link">
                                {`< ${this.props.t('backTo', { name: (subCategory ? subCategoryName : categoryName) })}`}
                            </h4>
                        </SeneSiteLink>
                    </div>
                </Mobile>
            </>

        )
    }
}

export default CategoryBreadcrumbs;
