import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'reactstrap';

import './OrderConfirmation.scss';
import { PreviousOrderState } from '../../../reducers/previousOrder';
import { selectPreviousOrder } from '../../../store/selectors/checkoutSelectors';
import { State } from '../../../store';
import { selectUserProfile } from '../../../store/selectors/authSelectors';
import { CartViewModel } from '../../../models/cart';
import { selectPreviousOrderCartVm } from '../../../store/selectors/cartSelectors';
import CartProduct from '../cart/CartProduct';
import OrderSummary from './place-order/OrderSummary';
import SeneSiteLink from '../../common/SeneSiteLink';
import { seneSiteNavigateTo } from '../../../actions/router';
import { PaymentType } from "../../../models/payment";

interface OrderConfirmationStateProps {
    previousOrder: PreviousOrderState;
    orderedProducts: CartViewModel;
    isLoggedIn: boolean;
    navigate(to: string);
}
type OrderConfirmationProps = OrderConfirmationStateProps & WithNamespaces;

class OrderConfirmationPage extends React.Component<OrderConfirmationProps> {
    componentWillMount() {
        if (!this.props.previousOrder) {
            this.props.navigate('/shopproducts');
        }
    }
    
    isPayPalPayment() {
        const previousOrder  = this.props.previousOrder;
        return previousOrder && 
            previousOrder.paymentInfo && 
            previousOrder.paymentInfo.paymentMethod.paymentMethodType== PaymentType.PayPal;
    }

    render() {
        const { t } = this.props;

        const isPaidWithPayPal = this.isPayPalPayment();
        return (
            this.props.previousOrder &&
            <div className="order-confirmation">
                <div className="order-confirmation__header">
                    <h2>{ isPaidWithPayPal ? t('payPalTitle') : t('title')}</h2>
                    <div>{isPaidWithPayPal ? '': t('content')}</div>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <h4>{t('orderNum')}: {this.props.previousOrder.orderNumber}</h4>
                        <span>{t('emailMsg')} <i>{this.props.previousOrder.customerInfo.email}</i>.</span>
                    </div>
                    {
                        !isPaidWithPayPal &&
                        <div className="col-md-5 order-confirmation__buttons">
                            {
                                !this.props.isLoggedIn &&
                                <SeneSiteLink to="/newaccount">
                                    <Button outline color="primary" className="order-confirmation__sign-up">{t('btnNewAccount')}</Button>
                                </SeneSiteLink>
                            }
                            <SeneSiteLink to="/shopproducts">
                                <Button color="secondary">{t('btnShopping')}</Button>
                            </SeneSiteLink>
                        </div>    
                    }
                </div>
                <div className="row">
                    <div className="col-12">
                        <h5 style={{marginBottom: 0}}>{t('status')}: {t('pending')}</h5>
                    </div>
                </div>
                <hr />
                <h5 style={{marginBottom: '1rem'}}>{t('itemsShipped')}</h5>
                <div className="row">
                    <div className="col-md-8">
                        {this.props.orderedProducts.items.map(item => (
                            <CartProduct t={t} key={item.product.productId} cartProduct={item} showTotal={false} />
                        ))}
                    </div>
                    <div className="col-md-4">
                        <OrderSummary
                            cart={this.props.orderedProducts}
                            handlingRate={this.props.previousOrder.handlingRate}
                            t={t}
                            shippingMethod={this.props.previousOrder.shippingMethod}
                            totalCharges={this.props.previousOrder.totalCharges}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: State) => ({
    previousOrder: selectPreviousOrder(state),
    isLoggedIn: !!selectUserProfile(state),
    orderedProducts: selectPreviousOrderCartVm(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ navigate: seneSiteNavigateTo }, dispatch);

export default withNamespaces('OrderConfirmation')(connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationPage));
