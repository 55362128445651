import { Action } from 'redux';
import * as signalR from '@aspnet/signalr';
import { ThunkDispatch } from 'redux-thunk';

import { State } from '../../store';
import { connectionStarted } from '../../actions/signalR';
import {
    declinedPayment,
    redirectToPayPal
} from '../../actions/checkoutActions';
import { createCustomerSuccess, createCustomerError } from '../../actions/customerActions';
import { submitApplicationFormAccepted, submitApplicationFormError, submitURLChangeFormAccepted, submitURLChangeFormError } from '../../actions/administration/applicationFormActions';

export enum SignalRNotificaitonType {
    Fail = 0,
    Success = 1,
    Warning = 3
}

type NotificationPayload = {
    Scenario: string;
    Items?: any;
    OrderNumber?: number;
    Errors?: any;
    Redirect?: string;
};

// Logging for signalR was added for testing , should be commented out for production
export const startSignalR = (dispatch: ThunkDispatch<State, void, Action>, signalRHub : string) => {
	const connection = new signalR.HubConnectionBuilder()
		.withUrl(signalRHub)
		.configureLogging(signalR.LogLevel.Trace)
        .build();

    connection.on("sendMessage", (message: string, notificationType: SignalRNotificaitonType, jsonPayload: string) => {
        const payload: NotificationPayload = JSON.parse(jsonPayload);
        const action = getActionForScenario(payload);
        action
            ? dispatch(action)
            : showDefaultNotification(message, notificationType);
	});

	connection.on("messageReceived", (message: string, notificationType: SignalRNotificaitonType, jsonPayload: string) => {
		const payload: NotificationPayload = JSON.parse(jsonPayload);
		const action = getActionForScenario(payload);
		action
			? dispatch(action)
			: showDefaultNotification(message, notificationType);
	});

    connection.start().then(() => {
        // There is currently no way to get the connectionId from the JS client https://github.com/aspnet/AspNetCore/issues/5314
        connection.invoke("getConnectionId").then(id => {
			dispatch(connectionStarted(id));
			console.log("signalR connection id :" + id);
        });
	}).catch(error => console.log(error));

    return connection;
}

const getActionForScenario = (payload: NotificationPayload) => {
    console.log(payload);
    switch (payload.Scenario) {
        case 'CreateCustomerSuccess':
            return createCustomerSuccess();
        case 'CreateCustomerFailed':
            return createCustomerError(payload.Errors);
        case 'DeclinedPayment':
            return declinedPayment(payload.Errors);
        case 'PayPalRedirect':
            return redirectToPayPal(payload.Redirect, payload.OrderNumber!);
        case 'SubmitApplicationFormAccepted':
            return submitApplicationFormAccepted();
        case 'SubmitApplicationFormError':
            return submitApplicationFormError(payload.Errors);
        case 'SubmitURLChangeFormAccepted':
            return submitURLChangeFormAccepted();
        case 'SubmitURLChangeFormError':
            return submitURLChangeFormError(payload.Errors);
        default:
            return null;
    }
}

const showDefaultNotification = (message: string, notificationType: SignalRNotificaitonType) => {
    switch (notificationType) {
        case SignalRNotificaitonType.Fail: {
            console.error(message);
            break;
        }
        case SignalRNotificaitonType.Success: {
            console.log(message);
            break;
        }
        case SignalRNotificaitonType.Warning: {
            console.warn(message);
            break;
        }
    }
}
