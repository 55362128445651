import React from "react";
import { withNamespaces, WithNamespaces } from 'react-i18next';

import './SeneAboutMePage.scss'
import { CreateBackgroundImageUrl } from '../../common/Images';
import SeneSiteLink from "../../common/SeneSiteLink";
import { DesktopOrTablet, Mobile } from "../../common/responsive/ResponsiveHelpers";
import * as dompurify from 'dompurify';
import {connect} from "react-redux";
import {selectDistributorCountry} from "../../../store/selectors/distributorSelectors";
import ImageComponent from '../../common/ImageComponent';
import { selectImageResizerUrl, selectConfiguration } from "../../../store/selectors/configurationSelectors";
import { Configuration } from "../../../models/configuration";

interface IAboutCompParams {
    src: string;
    title: string;
    config: Configuration | null;
}

const ProdFactsElement = (params: IAboutCompParams) => {
    return <div >
                <ImageComponent {...{ src: params.src, className: 'icons', alt: 'img', config: params.config }} />
                <div className="card-body">
                    <p className="card-title">{params.title}</p>
                </div>
           </div>;
}

const CellularRenewal = (params: IAboutCompParams) => {
    return <div className="col-sm">
                <ImageComponent {...{ src: params.src, className: 'icons', alt: 'img'}} />
                <div className="text-center justify-content-center"><p className="text-white">{params.title}</p></div>
		   </div>;
}

interface SeneAboutMePageProps {
    distributorCountry: string,
    imageResizerUrl: string,
    config: Configuration | null
}

export class SeneAboutMePage extends React.Component<SeneAboutMePageProps & WithNamespaces> {

    renderProductDescriptionMobile = () => {
        const { t, distributorCountry } = this.props;
        const SpecializeTreatmentSrc = distributorCountry.toLocaleUpperCase() != 'HK' ?
            'products/reallyworks/Senesite_skin02.jpg' : 'products/reallyworks/Senesite_skin02_HK.png';
        return <Mobile>
            <div className="col-12 mt-3">
                <ImageComponent {...{
                    src: 'products/reallyworks/Senesite_skin01.jpg',
                    className: 'card-img-top',
                    alt: 'img'
                }}/>
            </div>
            <div className="col-12">
                <h3 className="text-center text-dark">{t('antiAgingTitle')}</h3>
                <p dangerouslySetInnerHTML={{__html: dompurify.sanitize(t('antiAgingContent1'))}}></p>
                <p >{t('antiAgingContent2')} </p>
            </div>
            <div className="col-12 mt-3">
            <ImageComponent {...{
                    src: SpecializeTreatmentSrc,
                    className: 'card-img-top',
                    alt: 'img'
            }}/>
            </div>
            <div className="col-12">
                <h3 className="text-center text-dark">{t('treatmentsTitle')}</h3>
                <p dangerouslySetInnerHTML={{ __html: dompurify.sanitize(t('treatmentsContent1')) }}></p>
                <p >{t('treatmentsContent2')}</p>
            </div>
            <div className="col-12 mt-3">
                <ImageComponent {...{
                    src: 'products/reallyworks/Senesite_skin03.jpg',
                    className: 'card-img-top',
                    alt: 'img'
                }}/>
            </div>
            <div className="col-12">
                <h3 className="text-center text-dark">{t('longLastTitle')}</h3>
                <p >{t('longLastContent1')}</p>
                <p >{t('longLastContent2')}</p>
                <p >{t('longLastContent3')}</p>
            </div>
        </Mobile>
    }
    renderProductDescriptionDesktop = () => {
        const { t, distributorCountry } = this.props;
        const SpecializeTreatmentSrc = distributorCountry.toLocaleUpperCase() != 'HK' ?
            'products/reallyworks/Senesite_skin02.jpg' : 'products/reallyworks/Senesite_skin02_HK.png';
        return <DesktopOrTablet>

            <div className="card-deck">
                <div className="col-md-5">
                    <h3 className="mt-1 text-center text-dark">{t('antiAgingTitle')}</h3>
                    <p dangerouslySetInnerHTML={{__html: dompurify.sanitize(t('antiAgingContent1'))}}></p>
                    <p >{t('antiAgingContent2')} </p>
                </div>
                <div className="col-md-7">
                    <ImageComponent {...{
                        src: 'products/reallyworks/Senesite_skin01.jpg',
                        className: 'card-img-top',
                        alt: 'img'
                    }}/>
                </div>
            </div>

            <div className="card-deck">
                <div className="col-md-7">
                    <ImageComponent {...{
                        src: SpecializeTreatmentSrc,
                        className: 'card-img-top',
                        alt: 'img'
                    }}/>
                </div>
                <div className="col-md-5">
                    <h3 className="mt-1 text-center text-dark">{t('treatmentsTitle')}</h3>
                    <p dangerouslySetInnerHTML={{ __html: dompurify.sanitize(t('treatmentsContent1')) }}></p>
                    <p >{t('treatmentsContent2')}</p>
                </div>
            </div>

            <div className="card-deck">
                <div className="col-md-5">
                    <h3 className="mt-1 text-center text-dark">{t('longLastTitle')}</h3>
                    <p >{t('longLastContent1')}</p>
                    <p >{t('longLastContent2')}</p>
                    <p >{t('longLastContent3')}</p>
                </div>
                <div className="col-md-7">
                    <ImageComponent {...{
                        src: 'products/reallyworks/Senesite_skin03.jpg',
                        className: 'card-img-top',
                        alt: 'img'
                    }}/>
                </div>
            </div>
        </DesktopOrTablet>
    }

    renderIntro = () => {
        const {t, distributorCountry, config} = this.props;
        const aboutMeBannerSrc = () => {
            if (distributorCountry.toLocaleUpperCase() === 'NZ')
                return 'products/HeaderImages/collection.png'
            else if (distributorCountry.toLocaleUpperCase() === 'HK')
                return 'products/HeaderImages/Spring_Summer005_HK.png'
            else
                return 'products/HeaderImages/Spring_Summer005.jpg'
        }

        return (<div className="container mb-3">
            <div className="row aboutme-header-image aboutme-header-image-2" style={{ backgroundImage: CreateBackgroundImageUrl({ "imageResizerUrl": this.props.imageResizerUrl, "config": config, "src": aboutMeBannerSrc(), "width": 1024, "height": 0, "crop": "max" }) }}></div>
            <div className="row paragraph-text justify-content-center text-center ">
                <h3 className="text-dark">{t('title')}</h3>
                <p className="text-center" dangerouslySetInnerHTML={{__html: dompurify.sanitize(t('content'))}}></p>
            </div>
        </div>)
    }
    renderFormula = () => {
        const {t, config} = this.props;
        return (<div className="mb-3">
            <div className="container results justify-content-center" style={{backgroundImage: CreateBackgroundImageUrl({"imageResizerUrl": this.props.imageResizerUrl, "config": config, "src": "products/SellSenegence/IMG_4180%20copy.jpg","width": 1024,"height": 0,"crop": "max"})}}>
                <div className="row text-center justify-content-center">
                    <h2 className="card-title text-white mt-5">{t('propFormTitle')}</h2>
                </div>
                <div className="row justify-content-center">
                    <p className="card-text text-white justify-content-center" dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(t('propFormContent'))
                    }}></p>
                </div>
                <div className="row justify-content-center ">
                    <div className="embed-responsive embed-responsive-16by9 col-sm-11 col-md-6 mt-4">
                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/kzdfqCKnrWM"></iframe>
                    </div>
                </div>
            </div>
            <div className="separator"></div>
            <h2 className="row text-center justify-content-center mt-3">{t('scienceTitle')}</h2>
        </div>)
    }
    renderClinicalTest = () => {
        const {t, config} = this.props;
        return (<div className="container clinical text-white " style={{backgroundImage: CreateBackgroundImageUrl({"imageResizerUrl": this.props.imageResizerUrl, "config": config, "src": "products/reallyworks/micro_bg.jpg","width": 1024,"height": 0,"crop": "max"})}}>
            <h2 className="row text-white justify-content-center text-center mt-5">{t('clinicalTitle')}</h2>
            <h4 className="row text-white justify-content-center text-center">{t('clinicalContentTitle')}</h4>
            <p className="mb-4 row text-white justify-content-center text-center">{t('clinicalContent')}</p>
            <div className="mt-3 row text-white text-center card-deck">
                <div className="card bg-transparent">
                    <div className="card-body">
                        <h3 className="m-0 p-0 text-white" ><strong>{t('week8')}</strong> </h3>
                        <h4 className="m-0 p-0 mb-3 text-white">{t('resultsTitle')}</h4>
                        <p className="text-white">{t('resultsContent')}</p>
                    </div>
                </div>
                <div className="card cellular">
                    <div className="card-body">
                        <h6 className="text-white mt-1">{t('cellularTitle')}</h6>
                        <div className="card-deck">
                            <CellularRenewal {...{ src: 'products/reallyworks/skin_04.png', title: t('cellularWeek'), config: config }} />
                            <CellularRenewal {...{ src: 'products/reallyworks/skin_02.png', title: t('cellularWeek2'), config: config }}/>
                        </div>

                        <div className="card-deck">
                            <CellularRenewal {...{ src: 'products/reallyworks/skin_03.png', title: t('cellularWeek4'), config: config }}/>
                            <CellularRenewal {...{ src: 'products/reallyworks/skin_01.png', title: t('cellularWeek8'), config: config }}/>
                        </div>
                        <div className="row text-white justify-content-center text-center">                        
                            <p className="text-white" style={{ marginBottom: 0 }}>{ t('cellularContent')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
    renderProductFacts = () => {
        const {t, config} = this.props;
        return (<div className="container text-center my-5">
            <div className="row justify-content-center">
                <h2 className="text-dark">{t('factsTitle')}</h2>
            </div>
            <div className="row my-4">
                <div className="col-sm-12 col-md-6">
                    <ProdFactsElement {...{
                        src: 'products/reallyworks/No-animal-prodcut.png',
                        title: t('factsItem2'),
                        config: config
                    }}/>
                </div>
                <div className="col-sm-12 col-md-6">
                    <ProdFactsElement {...{ 
                        src: 'products/reallyworks/made-in-usa.png',
                        title: t('factsItem5'),
                        config: config
                    }}/>
                </div>
            </div>
        </div>)
    }
    renderPromise = () => {
        const {t} = this.props;
        return (<div className="container my-5">
            <div className="row justify-content-center text-center ">
                <h2 >{t('promise')}</h2>
            </div>
            <div className="row justify-content-center text-center ">
                <SeneSiteLink to="/sell">
                    <div className="company-policy btn btn-light text-dark">
                        {t('sellSenegence')}
                    </div>
                </SeneSiteLink>
                <SeneSiteLink to="/shopproducts">
                    <div className="btn btn-secondary ml-3">
                        {t('shopNow')}
                    </div>
                </SeneSiteLink>
            </div>
        </div>)
    }

    render() {
        return <div id="about_page">
                    {this.renderIntro()}
                    {this.renderFormula()}
                    {this.renderClinicalTest()}
                    {this.renderProductFacts()}
                    {this.renderProductDescriptionDesktop()}
                    {this.renderProductDescriptionMobile()}
                    {this.renderPromise()}
               </div>;
    }
}

const mapStateToProps = (state) => ({
    distributorCountry: selectDistributorCountry(state),
    imageResizerUrl: selectImageResizerUrl(state),
    config: selectConfiguration(state)
});

export default withNamespaces("AboutMe")(connect(mapStateToProps)(SeneAboutMePage));