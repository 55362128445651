import React from 'react';

import RadioButton from '../../common/inputs/SeneRadioButton'

interface ISocialFeedOptions {
    title: string;
    option1: string;
    option2: string;
    onHandleChanged(event: any);
    checked: boolean;

}

const SocialFeedOption = (params: ISocialFeedOptions) => {

    const faClass = `fab fa-${params.title.toLowerCase()}`;

    return (

        <div className=''>
            <i className={faClass} />
            <span className='social-feeds-title'>{params.title.toUpperCase()}</span>
            <div className='social-feeds-panel'>
                <RadioButton
                    itemStyle='dummy'
                    groupname={params.title}
                    identifier={`${params.title}-senegenece`}
                    onChange={params.onHandleChanged}
                    value='test'
                    labelDisplayValue={params.option1}
                    checked={!params.checked} />

                <RadioButton
                    itemStyle='dummy'
                    groupname={params.title}
                    identifier={`${params.title}-mine`}
                    onChange={params.onHandleChanged}
                    value='test'
                    labelDisplayValue={params.option2}
                    checked={params.checked} />
            </div>
        </div>
    );

}

export default SocialFeedOption;