import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import { State } from './';
import { cartPersister } from './middlewares/cartPersister';
import { createRootReducer } from '../reducers';

declare const window: {
  devToolsExtension(): never;
}

export default function configureStore(history, initialState: Partial<State>) {
  const middleware = [
    routerMiddleware(history),
    thunk,
    cartPersister
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
        const nextReducer = createRootReducer(history);

        store.replaceReducer(nextReducer);
    });
  }

    return store;
}
