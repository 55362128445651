import { State } from '../index';
import { Configuration } from '../../models/configuration';

export const selectConfiguration = (state: State): Configuration | null => state && state.configuration ? state.configuration as Configuration : null;
export const selectSecurityConfiguration = (state: State) => state && state.configuration && state.configuration.securityConfiguration;
export const selectRecaptchaKey = (state: State) => state && state.configuration && state.configuration.recaptchaKey || '';
export const selectCaptchasDisabled = (state: State) => state && state.configuration && state.configuration.captchasDisabled || false;
export const selectCdoLink = (state: State) => state && state.configuration && state.configuration.cdoLink || '';
export const selectKnTLink = (state: State) => state && state.configuration && state.configuration.knTLink || '';
export const redirectToKissTell = (state: State) => state && state.configuration && state.configuration.redirectToKissTell || false;
export const selectDistributorSignupUrl = (state: State) => state && state.configuration && state.configuration.distributorSignupUrl || '';
export const selectDistributorSignupUsAndCa = (state: State) => state && state.configuration && state.configuration.distributorSignupUsAndCa || '';
export const selectImageResizerUrl = (state: State) => state && state.configuration && state.configuration.imageResizerUrl || '';
export const selectPromotionServiceEnabled = (state: State) => state && state.configuration && state.configuration.promotionServiceEnabled || false;
export const selectCookieBlocker = (state: State) => state && state.configuration && state.configuration.cookieBlockerUrl || '';