import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';

import colorPickerImage from '../../../assets/images/icon_color_picker.png';
import './CustomEditor.scss';

interface IEditorState {
    editorState1: EditorState;
}

interface IEditorProps {
    content: ContentState;
    locale: string;
    updateContent(currentContent: ContentState);

}

class CustomEditor extends React.Component<IEditorProps, IEditorState> {

    state: IEditorState = {
        editorState1: EditorState.createEmpty()
    }

    componentDidMount() {
        this.convertContent(this.props.content);
    }

    componentDidUpdate(prevProps: IEditorProps) {
        if(this.props.content !== prevProps.content) {
            this.convertContent(prevProps.content);
        }
    }

    convertContent = (content: ContentState) => {
        var editorState = EditorState.createWithContent(content);
        this.setState({
            editorState1: editorState
        });
    }

    onEditorStateChange: Function = (s) => {
        this.setState({
            editorState1: s
        }, () => {
            this.props.updateContent(s.getCurrentContent());
        });
    };

    render() {
        const { editorState1 } = this.state;
        return (
            <div >
                <Editor
                    editorState={editorState1}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={this.onEditorStateChange}
                    readonly="false"
                    toolbar={
                        {
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history'],
                            inline: {
                                options: ['italic', 'bold', 'underline', 'strikethrough']
                            },
                            colorPicker: { visible: true, icon: colorPickerImage, },
                            link: {
                                defaultTargetOption: '_blank',
                            },
                        }
                    }
                />
            </div>
        );
    }
}

export default CustomEditor;
