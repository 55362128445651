import React from 'react';
import { BlogPost } from './BlogPost';
import { BlogFeed as IBlogFeed } from '../../../models/blog';
import './BlogFeed.scss'

export interface BlogFeedProps {
    feed: IBlogFeed;
}

export class BlogFeed extends React.PureComponent<BlogFeedProps> {
    render() {
        return (
            <div className="card-deck">
                {this.props.feed.posts.map(post => <BlogPost post={post} key={post.link} />)}
            </div>
        )
    }
}

export default BlogFeed;
