import * as React from "react";
import AvatarEditor from 'react-avatar-editor';
import Modal from 'react-modal';

import placeholder from '../../../assets/images/image_placeholder-01.png';
import './ImageUpload.scss';
import i18next from "i18next";

interface IImageUploadState {
    file: any;
    width: number;
    height: number;
    rotate: number;
    allowZoomOut: boolean;
    position: any;
    scale: number;
    isModalOpen: boolean;
}

interface IImageUploadProps {
    t: i18next.TranslationFunction
    uploadFileHandler: (file: any) => any;
}

export class ImageUpload extends React.Component<IImageUploadProps, IImageUploadState> {
    editor: any;
    
    setEditorRef = (editor) => this.editor = editor;

    state : IImageUploadState = {
        file: null,
        width: 300,
        height: 300,
        rotate: 0,
        allowZoomOut: false,
        position: null,
        scale: 0,
        isModalOpen: false
    }

    componentWillMount () {
        this.setState({
            allowZoomOut: false,
            position: { x: 0.5, y: 0.5 },
            scale: 1,
        });
    }

    onChange = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    
    componentDidMount() {
        this.setState({ file: placeholder });
        this.setState({rotate: 0});
    }

    canvasToImage = (e) => {
        this.setState({ width: 300 });
        this.setState({ height: 300 });
    }

    rotateImage(e, direction: number) {
        e.preventDefault();
        if (direction == 0) // left
            this.setState({ rotate: this.state.rotate - 90, })
        else
            this.setState({ rotate: this.state.rotate + 90, })
    }

    handleScale = e => {
        const scale = parseFloat(e.target.value)
        this.setState({ scale })
    }


    uploadFile(blob) {
        let theFile = new File([blob], 'img.jpg');
        this.props.uploadFileHandler(theFile);
    }

    dataURLToBlobfunction(dataURL){
        const BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) == -1)
        {
            const parts = dataURL.split(',');
            const contentType = parts[0].split(':')[1];
            const raw = decodeURIComponent(parts[1]);
            return new Blob([raw], {type: contentType});
        }
        const parts = dataURL.split(BASE64_MARKER);
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
    return new Blob([uInt8Array], {type: contentType});
    }

    uploadFileHandler = (e) =>
    {
        const canvas = this.editor.getImageScaledToCanvas().toDataURL();
        const blob = this.dataURLToBlobfunction(canvas);
        this.uploadFile(blob);
        this.closeModal();
    }


    openModal = () => {
        this.setState({
            isModalOpen: true
        });
    }

    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    }

    renderModal = () => {
        const {t} = this.props;
        return <Modal
                isOpen={this.state.isModalOpen}>
                
                <div className="modal-header">
                    <h5 className="modal-title" id="uploadContainerLabel">{t('chooseAFile')}</h5>
                    <button type="button" onClick={() => this.closeModal()} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body modal-align-center">
                    <div className="file-loading">
                        <input
                            onChange={this.onChange} 
                            type="file" 
                            accept="image/png, image/jpeg, image/gif"/>
                    </div>
                    <div>
                        <AvatarEditor
                            ref={this.setEditorRef}
                            image={this.state.file}
                            width={300}
                            height={300}
                            border={0}
                            color={[255, 255, 255, 0.6]}
                            scale={this.state.scale}
                            rotate={this.state.rotate}
                            onLoadSuccess={this.canvasToImage}
                        />
                    </div>
                    <div>
                        <button type="button" className="btn fas fa-undo" onClick={e => this.rotateImage(e, 0)}></button>&nbsp;&nbsp;
                    <input
                            name="scale"
                            type="range"
                            onChange={this.handleScale}
                            min={this.state.allowZoomOut ? '0.1' : '1'}
                            max="2" step="0.01"
                            defaultValue="1"
                        />&nbsp;&nbsp;
                    <button type="button" className="btn fas fa-redo" onClick={e => this.rotateImage(e, 1)}></button>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.closeModal()}>{t('cancel')}</button>
                    <button type="button" className="btn btn-primary" title="btnSave" data-dismiss="modal" onClick={this.uploadFileHandler}>{t('save')}</button>
                </div>
            </Modal>
    }


    render() {
        const {t} = this.props;
        return <div>
            <button type='button' className="btn btn-primary" data-toggle="modal" data-target="#uploadContainer" onClick={() => this.openModal()}>
                {t('changePicture')}
            </button>
            {this.renderModal()}
        </div>
    }
}

export default ImageUpload;
