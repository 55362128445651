import React, {ChangeEvent} from 'react';

import './MySeneSitesPage.scss';

import i18n from '../../../i18n';
import { Languages } from '../../../models/language';
import SelectInput from '../../common/inputs/SeneSelectInput';

interface MySeneSitesDefaultLanguagePageProps {
    defaultLocale: string;
    languages: Languages;
    setLocale(locale: string);
    t: i18n.TranslationFunction;
}

class MySeneSitesDefaultLanguage extends React.PureComponent<MySeneSitesDefaultLanguagePageProps> {

    onLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
	    const select = e.target as HTMLSelectElement,
		    value = select.value;
		if (value) {
            this.props.setLocale(value);
		}
    }

    get languages() {
        return this.props.languages.availableLanguages.map(lang => ({ value: lang.code, text: lang.languageDisplayName }));
    }

    render() {
        const { t } = this.props;
        return <>
            <div className='admin-personal-msg row'>{t('defaultLanguageTitle')}</div>
            <div className='row'>{t('defaultLanguageDetails')}</div>
            <div className="row">
                
                <SelectInput {...{
                            name: 'language',
                            label: '',
                            id: 'languageSelect',
                            onChange: this.onLanguageChange,
                            options: this.languages,
                            value: this.props.defaultLocale,
                        }} />
            </div>
        </>
    }
}

export default MySeneSitesDefaultLanguage
