import React from 'react';
import moment from 'moment';
import { NavLink } from "react-router-dom";

import { CalendarEvent } from './../../../../models/distributor';
import SectionHeader from './../../../common/headers/SectionHeader';
import SeneHomeEventWidget from './SeneHomeEventWidget';
import SeneSiteLink from '../../../common/SeneSiteLink';
import i18n from '../../../../i18n';


export interface SeneHomeEventProps {
    events: CalendarEvent[];
    t: i18n.TranslationFunction
}

export class SeneHomeEvent extends React.PureComponent<SeneHomeEventProps> {

    private readonly date = moment(new Date()).format('YYYY-MM-DD');

    renderWidgets() {
        return this.props.events.map((event, idx) => {
            const date = moment(event.startDate).format('YYYY-MM-DD');
            return <div key={idx} className="col-md-3 col-8">
                <SeneHomeEventWidget t={this.props.t} event={event} to={`/Calendar/EventCalendar/${date}/day`} />
            </div>
        })
    }

    render() {
        const {t} = this.props;
        return (
            this.props.events && this.props.events.length > 0 &&
            <div className="event-calendar ">
                <div className="pb-3">
                    <SectionHeader>{t('myEventCalendar')}</SectionHeader>
                </div>
                <div className="container">
                    <div className="row justify-content-center pb-3">
                        {this.renderWidgets()}
                    </div>
                    <div className="row justify-content-center">
                        <SeneSiteLink to={`/Calendar/EventCalendar/${this.date}/month`}> {t('completeEvents')} </SeneSiteLink>
                    </div>
                </div>
            </div>
        )
    }
}

export default SeneHomeEvent;
