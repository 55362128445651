import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { CustomInput } from 'reactstrap';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';

import { createCustomer } from '../../../actions/customerActions';
import { selectRecaptchaKey, selectCaptchasDisabled, selectDistributorSignupUrl } from "../../../store/selectors/configurationSelectors";
import { CustomerModelValidator } from './NewAccountValidator';
import { CreateCustomerModel } from '../../../models/customer';
import { ValidationErrors } from '../../../utils/validation/validator';
import TextInput from '../../common/inputs/SeneTextInput';
import SeneButton from '../../common/inputs/SeneButton';
import SeneSiteLink from '../../common/SeneSiteLink';
import { State } from '../../../store';
import { selectCreateCustomerErrors } from '../../../store/selectors/uiSelectors';
import SenePageHeader from '../../common/headers/SeneGradientHeader';
import { ActionToastProperties } from '../../../models/ui';
import Reaptcha from 'reaptcha';
import { selectSelectedLanguageAlpha2Code } from '../../../store/selectors/languageSelectors';
import { CountryLanguage } from '../../../models/language';
import { shouldSwithToMaintenance } from "../../../utils/helpers";
import { Distributor } from '../../../models/distributor';
import * as customerApi from '../../../api/customerApi';
import { CustomerSignUpEmailAddressAvailability } from '../../../models/CustomerSignUpEmailAddressAvailability';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import { UserManager } from '../../../utils/auth/UserManager';
import { setCallbackUrl } from '../../../utils/auth/CallbackStorage';
import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';

interface NewAccountStateProps {
    serverErrors: any[];
    recaptchaKey: string;
    selectedLanguageAlpha2Code: string;
    captchasDisabled: boolean;
    distributor: Distributor | null;
}

interface NewAccountDispatchProps {
    createCustomer(customer: CreateCustomerModel, toastProps?: ActionToastProperties);
}

type NewAccountProps = NewAccountStateProps & NewAccountDispatchProps & WithNamespaces;

interface NewAccountState {
    customer: CreateCustomerModel;
    errors: ValidationErrors<CreateCustomerModel>;
    loading: boolean;
    isEmailExistsLoginModal: boolean;
}

class NewAccountPage extends React.Component<NewAccountProps, NewAccountState> {

    captcha: any;

    state: NewAccountState = {
        customer: {
            firstName: '',
            lastName: '',
            birthday: '',
            email: '',
            password: '',
            optInEmail: false,
            phone: '',
            confirmEmail: '',
            confirmPassword: '',
            isEighteenOrOlder: false,
            hasAcceptedPrivacyTerms: false,
            recaptchaToken: '',
            country: ''
        },
        loading: false,
        isEmailExistsLoginModal: false,
        errors: {}
    }

    componentDidUpdate(prevProps: NewAccountProps) {
        if (prevProps.serverErrors !== this.props.serverErrors) {
            this.setState({ loading: false });
        }
        if(this.props.selectedLanguageAlpha2Code != prevProps.selectedLanguageAlpha2Code) {
            this.onExpire();
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const prop = e.target.name;
        const value = e.target.value;

        this.setState({
            customer: {
                ...this.state.customer,
                [prop]: value
            }
        });
    }

    handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const prop = e.target.name;
        const value = e.target.checked;

        this.setState({
            customer: {
                ...this.state.customer,
                [prop]: value
            }
        });
    }

    validateCustomerInfo = () => {
        const validator = new CustomerModelValidator(this.props.t, this.props.captchasDisabled);
        const validationResult = validator.validate(this.state.customer);

        return validationResult;
    }

    createCustomer = () => {
        const validationResult = this.validateCustomerInfo();
        if (validationResult.isValid) {
            this.props.createCustomer(this.getCreateCustomerData(), { info: this.props.t('pleaseWait') }).then(
                res => {
                    this.setState({ loading: true });
                    this.captcha && this.captcha.reset();
                }
            ).catch(error => {
                shouldSwithToMaintenance(error);
                this.captcha && this.captcha.reset();
                this.setState({ loading: false });
            });

        } else {
            this.setState({ errors: validationResult.errors, loading: false });
        }
    }

    createCustomerOrLogin = () => {
        const distId = this.props.distributor ? this.props.distributor.dist_ID : 0;
        this.setState({ loading: true });
        customerApi.validateCustomerSignupEmailAvailability(this.state.customer.email, distId)
            .then(availability => {
                switch (availability) {
                    case CustomerSignUpEmailAddressAvailability.Unknown: {
                        this.setState({
                            isEmailExistsLoginModal: false,
                        });
                        this.setState({ loading: false });
                        break;
                    }
                    case CustomerSignUpEmailAddressAvailability.Available: {
                        this.setState({
                            isEmailExistsLoginModal: false,
                        });
                        this.createCustomer();
                        break;
                    }
                    case CustomerSignUpEmailAddressAvailability.SignedUpUnderDistributor: {
                        this.setState({
                            isEmailExistsLoginModal: true,
                        });
                        this.setState({ loading: false });
                        break;
                    }
                    case CustomerSignUpEmailAddressAvailability.SignedUpUnderAnotherDistributor: {
                        this.setState({
                            isEmailExistsLoginModal: true,
                        });
                        this.setState({ loading: false });
                        break;
                    }
                }
            })
            .catch(_ => {
                this.setState({ loading: false });
            })
    }

    login = () => {
        let distributor: Distributor | null = this.props.distributor;
        let callBackUrl = distributor ? distributor.seneSiteInfo.seneSiteUrl : window.location.pathname;
        setCallbackUrl(callBackUrl);
        UserManager.signinRedirect({ui_locales: this.props.i18n.language.toLowerCase()});
    }

    toggleEmailExistsLoginModal = () => {
        this.setState({ isEmailExistsLoginModal: !this.state.isEmailExistsLoginModal });
    }

    renderEmailExistsLoginModal = () => {
        const { t } = this.props;
        return (
            <Modal isOpen={this.state.isEmailExistsLoginModal} toggle={this.toggleEmailExistsLoginModal} centered={true} fade={false}>
                <ModalHeader toggle={this.toggleEmailExistsLoginModal}>{t('signIn')}</ModalHeader>
                <ModalBody>
                    <div className="col-md-12">
                        {t('accountExistsLogIn')}
                    </div>
                    <div className="col-md-12">
                        <button type="button" id="login-btn" className="btn btn-primary btn-block checkout-type-btn checkout-type-login"
                            onClick={this.login} style={{ margin: '7px 0' }}>{t('login')}</button>
                    </div>
                </ModalBody>
            </Modal>
            )
    }

    getCreateCustomerData = (): CreateCustomerModel => {
        let birthday = this.state.customer.birthday.replace(/_/g, '');
        return {
            ...this.state.customer,
            birthday: "1904/" + birthday
        }
    }

    displayServerErrors = () => {
        return this.props.serverErrors && this.props.serverErrors.length > 0 && (
            <div className="alert alert-danger" role="alert">
                <ul>
                    {
                        this.props.serverErrors.map((error) => <li>{error}</li>)
                    }
                </ul>
            </div>
        )
    }

    onVerify = (recaptchaToken: string) => {
        this.setState({
            customer: {
                ...this.state.customer,
                recaptchaToken: recaptchaToken
            }
        });
    }

    onExpire = () => {
        this.setState({
            customer: {
                ...this.state.customer,
                recaptchaToken: ''
            }
        });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <SenePageHeader>{t('title')}</SenePageHeader>
                {this.displayServerErrors()}
                <form id="credit_card_form" noValidate>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-4">
                                <TextInput {...{
                                    name: 'firstName',
                                    label: `${t('firstName')}`,
                                    id: 'firstName',
                                    onChange: this.handleChange,
                                    value: this.state.customer.firstName,
                                    error: this.state.errors.firstName,
                                }} />
                            </div>
                            <div className="col-sm-4">
                                <TextInput {...{
                                    name: 'lastName',
                                    label: `${t('lastName')}`,
                                    id: 'lastName',
                                    onChange: this.handleChange,
                                    value: this.state.customer.lastName,
                                    error: this.state.errors.lastName,
                                }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <TextInput {...{
                                    name: 'phone',
                                    label: `${t('phoneNumber')}`,
                                    id: 'phone',
                                    onChange: this.handleChange,
                                    value: this.state.customer.phone,
                                    error: this.state.errors.phone,
                                }} />
                            </div>
                            <div className="col-sm-4">
                                <TextInput {...{
                                    name: 'birthday',
                                    label: `${t('birthday')} *`,
                                    id: 'birthday',
                                    onChange: this.handleChange,
                                    value: this.state.customer.birthday,
                                    error: this.state.errors.birthday,
                                    mask: '99/99'
                                }} />
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-4">
                                <TextInput {...{
                                    name: 'email',
                                    label: `${t('email')}`,
                                    id: 'email',
                                    onChange: this.handleChange,
                                    value: this.state.customer.email,
                                    error: this.state.errors.email,
                                }} />
                            </div>
                            <div className="col-sm-4">
                                <TextInput {...{
                                    name: 'confirmEmail',
                                    label: `${t('reenterEmail')}`,
                                    id: 'confirmEmail',
                                    onChange: this.handleChange,
                                    value: this.state.customer.confirmEmail,
                                    error: this.state.errors.confirmEmail,
                                }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <TextInput {...{
                                    name: 'password',
                                    label: `${t('password')}`,
                                    id: 'password',
                                    onChange: this.handleChange,
                                    value: this.state.customer.password,
                                    error: this.state.errors.password,
                                    type: 'password'
                                }} />
                            </div>
                            <div className="col-sm-4">
                                <TextInput {...{
                                    name: 'confirmPassword',
                                    label: `${t('confirmPassword')}`,
                                    id: 'confirmPassword',
                                    onChange: this.handleChange,
                                    value: this.state.customer.confirmPassword,
                                    error: this.state.errors.confirmPassword,
                                    type: 'password'
                                }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-1">
                                <CustomInput
                                    name="isEighteenOrOlder"
                                    id="isEighteenOrOlder"
                                    type="checkbox"
                                    onChange={this.handleCheckboxChange}
                                    label={t('ageConfirm')}
                                />
                            </div>
                            <div className="col-12 mb-1">
                                <CustomInput
                                    name="optInEmail"
                                    id="optInEmail"
                                    type="checkbox"
                                    onChange={this.handleCheckboxChange}
                                    label={t('receivedEmails')}
                                />
                            </div>
                            <div className="col-12 mb-1">
                                <CustomInput
                                    name="hasAcceptedPrivacyTerms"
                                    id="hasAcceptedPrivacyTerms"
                                    type="checkbox"
                                    onChange={this.handleCheckboxChange}
                                    label={t('agree')}
                                />
                            </div>

                            {
                                (this.state.errors.isEighteenOrOlder || this.state.errors.hasAcceptedPrivacyTerms) &&
                                <div className="alert alert-danger col-12 mb-1">
                                    {this.state.errors.isEighteenOrOlder && (
                                        <>
                                            {this.state.errors.isEighteenOrOlder}
                                            <br />
                                        </>
                                    )}
                                    {this.state.errors.hasAcceptedPrivacyTerms || null}
                                </div>
                            }
                            <div className="col-12 mb-1">
                                <div key={this.props.selectedLanguageAlpha2Code + "_captcha"}>
                                    {!this.props.captchasDisabled && <Reaptcha
                                        hl={this.props.selectedLanguageAlpha2Code}
                                        ref={e => (this.captcha = e)}
                                        sitekey={this.props.recaptchaKey}
                                        onVerify={this.onVerify}
                                        onExpire={this.onExpire}
                                        />}
                                </div>
                            </div>
                            {this.state.errors['recaptchaToken'] && <div className="alert alert-danger">{this.state.errors['recaptchaToken']}</div>}
                        </div>
                    </div>
                </form>
                <div className="footer-buttons">
                    <SeneSiteLink to="/cart">
                        <button className="btn btn-light">{t('cancel')}</button>
                    </SeneSiteLink>
                    <SeneButton onClick={this.createCustomerOrLogin} loading={this.state.loading}>{t('createAccount')}</SeneButton>
                </div>
                {this.renderEmailExistsLoginModal()}
            </>
        )
    }
}

const mapStateToProps = (state: State): NewAccountStateProps => ({
    serverErrors: selectCreateCustomerErrors(state),
    recaptchaKey: selectRecaptchaKey(state),
    selectedLanguageAlpha2Code: selectSelectedLanguageAlpha2Code(state),
    captchasDisabled: selectCaptchasDisabled(state),
    distributor: selectSenesiteDistributor(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    createCustomer
}, dispatch);

export default withNamespaces('NewAccount')(connect(mapStateToProps, mapDispatchToProps)(NewAccountPage));
