import { Thunk } from "../store";
import { getUserIpAddress } from "../api/userApi";
import { shouldSwithToMaintenance } from "../utils/helpers";

export enum IpAddressActionTypes {
    getIpAddress = '[IpAddress] Get IP Address',
    getIpAddressSuccess = '[IpAddress] Get IP Address Success',
    getIpAddressError = '[IpAddress] Get IP Address Error'
}

export const getIpAddress = (): Thunk => (dispatch) => {
    dispatch({ type: IpAddressActionTypes.getIpAddress})
    return getUserIpAddress()
        .then(response => {
            dispatch({ type: IpAddressActionTypes.getIpAddressSuccess, data: response })
        })
        .catch(err => {
            shouldSwithToMaintenance(err);
            dispatch({ type: IpAddressActionTypes.getIpAddressError })
    })
}