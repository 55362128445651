import React from 'react';

import './CartProduct.scss';
import { CartProductVm } from '../../../models/cart';
import SeneSiteLink from '../../common/SeneSiteLink';
import SeneNumberSelect from '../../common/inputs/SeneNumberSelect';
import i18n from '../../../i18n';
import { ActionToastProperties } from '../../../models/ui';
import ImageComponent from '../../common/ImageComponent';

interface CartProductProps {
    cartProduct: CartProductVm;
    showTotal?: boolean;
    removeProduct?(sku: string);
    changeQty?(productId: number, qty: number, toastProps?: ActionToastProperties);
    t: i18n.TranslationFunction
}

class CartProduct extends React.PureComponent<CartProductProps> {
    static defaultProps = {
        showTotal: true
    }

    onQtyChange = (val: number) => {
        this.props.changeQty &&
            this.props.changeQty(
                this.props.cartProduct.product.productId,
                val,
                { success: `${this.props.t('cartQtyUpdateSuccess')} ${this.props.cartProduct.product.name}` });
    }

    render() {
        const product = this.props.cartProduct.product;
        const { t } = this.props;
        const productImageUrl = product.imageUrl ? `products/ProductImages/` + product.imageUrl : `products/ProductImages/${product.sku}.tif`;
        return (
            <div className="cart-product">
                <div className="col-4 col-sm-3 text-center">
                    <SeneSiteLink to={product.link}>
                        <ImageComponent
                            height={200}
                            src={productImageUrl}
                            alt={product.name}
                        />
                    </SeneSiteLink>
                </div>
                <div className="col-8 col-sm-9 cart-product-info">
                    <h4 className="cart-product-info__title">{product.name}</h4>
                    <p className="cart-product-info__size">{product.displayWeight}</p>
                    <p className="cart-product-info__price">{product.displayPrice}</p>
                    <div className="cart-product-info__qty">
                        <span>{t('qty')}: </span>
                        {
                            this.props.changeQty
                                ? (
                                    <SeneNumberSelect
                                        value={this.props.cartProduct.qty}
                                        onChange={this.onQtyChange}
                                    />
                                )
                                : <span>{this.props.cartProduct.qty}</span>
                        }
                    </div>
                    <div className="cart-product-info-flex">
                        {
                            this.props.removeProduct &&
                            <a
                                className="cart-product-info__delete"
                                onClick={e => this.props.removeProduct!(product.sku)}
                            >
                                {t('delete')}
                            </a>
                        }
                        {
                            this.props.showTotal &&
                            <div className="total-price-block">
                                <span> {t('totalPrice')}</span>
                                <span className="cart-product-info__total-price">{this.props.cartProduct.totalDisplayPrice}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default CartProduct;
