import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from "query-string";

import * as authActions from '../../../actions/authActions';
import { selectUserProfile, selectIsLoadingUser } from '../../../store/selectors/authSelectors';
import { hasAdminRights } from '../../../utils/auth';
import { State } from '../../../store';
import { setCallbackUrl } from '../../../utils/auth/CallbackStorage';
import { UserManager } from '../../../utils/auth/UserManager';
import { selectSelectedLanguageCode } from '../../../store/selectors/languageSelectors';
import { RouteComponentProps } from 'react-router';
import { getCountryLanguages } from '../../../actions/languageActions';
import { getDistributorFromDistId } from '../../../actions/distributor';
import { Distributor } from '../../../models/distributor';
import { getBrowserCountry, getLang, shouldSwithToMaintenance } from '../../../utils/helpers';
import { WithNamespaces } from 'react-i18next';

type withAdministrationGuardProps = {
    user: any;
    isLoadingUser: boolean;
    notAuthorized();
    getDistributorFromDistId(distId: number);
    getCountryLanguages(country2Alpha: string, preferredLocale: string, distributor?: Distributor);
    languageCode: string;
}

export default function withAdministrationGuard<OwnProps>(Component: React.ComponentType<OwnProps>) {
    class GuardedComponent extends React.Component<OwnProps & withAdministrationGuardProps & RouteComponentProps & WithNamespaces> {

        componentDidUpdate(prevProps: withAdministrationGuardProps) {
            if (!this.props.isLoadingUser && !prevProps.user && !this.props.user) {
                setCallbackUrl(window.location.pathname + window.location.search);
                const qs = queryString.parse(this.props.location.search);
                if (qs['distributorId']) {
                    const distId = +(qs['distributorId'] as string);
                    this.props.getDistributorFromDistId(distId)
                        .then((distributor: Distributor) => {
                            const lang = this.props.getCountryLanguages(distributor.countryName, this.props.languageCode.toLowerCase(), distributor);
                            UserManager.signinRedirect({ ui_locales: lang.toLowerCase() });
                        })
                        .catch((exp) => {
                            shouldSwithToMaintenance(exp);
                            UserManager.signinRedirect({ ui_locales: this.props.languageCode.toLowerCase() });
                        });
                } else {
                    UserManager.signinRedirect({ ui_locales: this.props.languageCode.toLowerCase() });
                }
            } else {
                this.checkAuth();
            }
        }

        componentDidMount() {
            this.checkAuth();
        }

        private checkAuth() {
            const { user, isLoadingUser, notAuthorized } = this.props;

            if (isLoadingUser) {
                return;
            }

            if (!this.isAuthed(user)) {
                notAuthorized();
            }
        }

        private isAuthed(user) {
            if (!user || !user.role) {
                return false;
            }

            return hasAdminRights(user);
        }

        render() {
            return <Component {...this.props} />
        }
    }

    const mapStateToProps = (state: State) => ({
        user: selectUserProfile(state),
        isLoadingUser: selectIsLoadingUser(state),
        languageCode: selectSelectedLanguageCode(state)
    });

    const mapDispatchToProps = dispatch => bindActionCreators({
        notAuthorized: authActions.notAuthorized,
        getDistributorFromDistId,
        getCountryLanguages
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(GuardedComponent as any) //HACK: try to figure out TS issue with props later
}
