import React from 'react';
import i18next from 'i18next';
import { CustomInput } from 'reactstrap';

import TextInput from '../../../common/inputs/SeneTextInput';
import SelectInput from '../../../common/inputs/SeneSelectInput';
import { CreditCardInfo } from '../../../../models/checkout';
import { ValidationErrors } from '../../../../utils/validation/validator';
import { CountryState } from '../../../../models/region';
import { CreditCard } from "../../../../models/payment";
import { findCreditCardData, getCreditCardImageUrlByName, checkDigits } from './../../../../utils/creditCardHelper';
import { TranslationRemapper } from "../../../../../src/utils/helpers";

export type CreditCardFormModel = CreditCardInfo & { agree: boolean };

interface CreditCardFormProps {
    t: i18next.TranslationFunction;
    onChange(changes: any);
    creditCardInfo: CreditCardFormModel;
    errors: ValidationErrors<CreditCardFormModel>;
    setSameAsShipping();
    regions: CountryState[];
    distributorsAllowedCreditCards: CreditCard[];
    countryAlpha2: string;
}

interface CreditCardFormState {
    sameAsShipping: boolean;
}

class CreditCardForm extends React.Component<CreditCardFormProps, CreditCardFormState> {
    state: CreditCardFormState = {
        sameAsShipping: false
    };

    expMonthOptions = Array.from(Array(12).keys()).map(i => {
        return {
            text: (i + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 }),
            value: (i + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 })
        };
    });

    expYearOptions = Array(20).fill(new Date().getFullYear()).map((currYear, i) => {
        return {
            text: (currYear + i).toString(),
            value: (currYear + i).toString()
        };
    });

    get expirationMonth() {
        return this.props.creditCardInfo.ccExpirationDate && this.props.creditCardInfo.ccExpirationDate.split('/')[0] || '';
    }

    get expirationYear() {
        return this.props.creditCardInfo.ccExpirationDate && this.props.creditCardInfo.ccExpirationDate.split('/')[1] || '';
    }

    get regions() {
        return this.props.regions.map(state => ({ value: state.abbreviation, text: state.name }));
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const prop = e.target.name;
        const value = e.target.value;

        this.props.onChange({ [prop]: value });
    };

    handleCcNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const prop = e.target.name;
        let value = e.target.value;
        if(value.length > 19) {
            value = value.substring(0, 19);
        }

        if (value && !checkDigits(value)) {
            return;
        }

        const result = findCreditCardData(this.props.distributorsAllowedCreditCards, value);
        if (result) {
            this.props.onChange({
                ccNumber: value,
                ccType: result.id,
                ccImageUrl: result.imageUrl
            });
        } else {
            this.props.onChange({
                ccNumber: value,
                ccType: '',
                ccImageUrl: ''
            });
        }
    }

    handleCvvNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const prop = e.target.name;
        let value = e.target.value;
        if(value.length > 4){
            value = value.substring(0, 4);
        }

        if (value && !checkDigits(value)) {
            return;
        }

        this.props.onChange({ [prop]: value });
    }

    handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const prop = e.target.name;
        const value = e.target.checked;

        this.props.onChange({ [prop]: value });
    };

    handleExpirationDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const isMonth = e.target.name === 'expirationMonth';

        const value = isMonth ? `${e.target.value}/${this.expirationYear}` : `${this.expirationMonth}/${e.target.value}`;
        this.props.onChange({ ccExpirationDate: value });
    };

    handleSameAsShippingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.checked;

        if (val) {
            this.props.setSameAsShipping();
            this.setState({ sameAsShipping: true });
        } else {
            this.setState({ sameAsShipping: false });
        }
    };

    getTranslator() {
        const dic = {
            'NZ': {
                'zipCode': 'postalCode',
                'zipCodeError': 'msgPostal'
            }
        };
        return new TranslationRemapper(dic, this.props.t).getTranslatorFor(this.props.countryAlpha2);
    }

    render() {
        const { distributorsAllowedCreditCards } = this.props;
        const t = this.getTranslator();
        const distributorsAcceptedCreditCardImages = distributorsAllowedCreditCards ? distributorsAllowedCreditCards.map(cc => {
            return getCreditCardImageUrlByName(cc.name);
        }) : [];

        return (
            <form id="credit_card_form">
                <div className="container-fluid">
                    <h6 className="paragraph-title">{t('cardInfo')}</h6>
                    <div className="row">
                        <div className="col-sm-4">
                            <TextInput {...{
                                name: 'firstName',
                                label: `${t('firstName')} *`,
                                id: 'firstName',
                                onChange: this.handleChange,
                                value: this.props.creditCardInfo.firstName,
                                error: this.props.errors.firstName,
                                autocomplete: 'given-name'
                            }} />
                        </div>
                        <div className="col-sm-4">
                            <TextInput {...{
                                name: 'lastName',
                                label: `${t('lastName')} *`,
                                id: 'lastName',
                                onChange: this.handleChange,
                                value: this.props.creditCardInfo.lastName,
                                error: this.props.errors.lastName,
                                autocomplete: 'family-name'
                            }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            <TextInput {...{
                                length: 19,
                                name: 'ccNumber',
                                label: `${t('cardNumber')} *`,
                                id: 'ccNumber',
                                onChange: this.handleCcNumberChange,
                                value: this.props.creditCardInfo.ccNumber,
                                error: this.props.errors.ccNumber,
                                autocomplete: 'cc-number'
                            }} />
                        </div>
                        <div className="col-sm-1">
                            <div>
                                <label className="font-weight-bold">&nbsp;</label>
                                {
                                    this.props.creditCardInfo.ccImageUrl &&
                                    <img src={this.props.creditCardInfo.ccImageUrl} width={'auto'} height={40} alt="credit card type" />
                                }
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="row">
                                <label className="font-weight-bold">{t('acceptedCreditCards')}</label>

                            </div>
                            <div className="row">
                                {
                                    distributorsAcceptedCreditCardImages.map((ccImageUrl, idx) => {
                                        return (
                                            <img src={ccImageUrl} width={'auto'} height={40} alt="credit card type" className="mr-2" key={idx} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <TextInput {...{
                                length: 4,
                                name: 'cvvNumber',
                                label: `${t('cvvNumber')} *`,
                                id: 'cvvNumber',
                                onChange: this.handleCvvNumberChange,
                                value: this.props.creditCardInfo.cvvNumber,
                                error: this.props.errors.cvvNumber,
                                autocomplete: 'cc-csc'
                            }} />
                        </div>
                        <div className="col-sm-2">
                            <SelectInput {...{
                                name: 'expirationMonth',
                                label: `${t('expMonth')} *`,
                                id: 'expirationMonth',
                                onChange: this.handleExpirationDateChange,
                                options: this.expMonthOptions,
                                value: this.expirationMonth,
                                error: this.props.errors.ccExpirationDate,
                            }} />
                        </div>
                        <div className="col-sm-2">
                            <SelectInput {...{
                                name: 'expirationYear',
                                label: `${t('expYear')} *`,
                                id: 'expirationYear',
                                onChange: this.handleExpirationDateChange,
                                options: this.expYearOptions,
                                value: this.expirationYear,
                                error: this.props.errors.ccExpirationDate,
                            }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group" style={{ marginLeft: 10 }}>
                            <CustomInput
                                className="font-weight-bold"
                                type="checkbox"
                                id="agree"
                                name="agree"
                                label={`* ${t('acceptTerms')}`}
                                onChange={this.handleCheckBoxChange}
                                checked={this.props.creditCardInfo.agree}
                            />
                            {this.props.errors.agree &&
                                <div className="alert alert-danger">{this.props.errors.agree}</div>}
                        </div>
                    </div>
                    <h6 className="paragraph-title">{t('billingAddress')}</h6>
                    <div className="row">
                        <div className="form-group">
                            <CustomInput
                                type="checkbox"
                                id="sameasshipping"
                                label={t('sameAsBilling')}
                                onChange={this.handleSameAsShippingChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <TextInput {...{
                                name: 'address',
                                label: `${t('address')} *`,
                                id: 'address',
                                onChange: this.handleChange,
                                value: this.props.creditCardInfo.address,
                                error: this.props.errors.address,
                                autocomplete: 'address-line1',
                                disabled: this.state.sameAsShipping
                            }} />
                        </div>
                        <div className="col-sm-4">
                            <TextInput {...{
                                name: 'address2',
                                label: t('address2'),
                                id: 'address2',
                                onChange: this.handleChange,
                                value: this.props.creditCardInfo.address2,
                                key: 'address2',
                                disabled: this.state.sameAsShipping
                            }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <TextInput {...{
                                name: 'city',
                                label: `${t('city')} *`,
                                id: 'city',
                                onChange: this.handleChange,
                                value: this.props.creditCardInfo.city,
                                error: this.props.errors.city,
                                autocomplete: 'address-level2',
                                disabled: this.state.sameAsShipping
                            }} />
                        </div>
                        <div className="col-sm-4">
                            {this.props.countryAlpha2 !== "NZ" &&
                                <SelectInput {...{
                                    name: 'state',
                                    label: `${t('state')} *`,
                                    id: 'state',
                                    onChange: this.handleChange,
                                    options: this.regions,
                                    value: this.props.creditCardInfo.state,
                                    error: this.props.errors.state,
                                    autocomplete: 'address-level1',
                                    disabled: this.state.sameAsShipping
                                }} />
                            }
                            {this.props.countryAlpha2 == "NZ" &&
                                <TextInput {...{
                                    name: 'address3',
                                    label: t('suburb'),
                                    id: 'address3',
                                    onChange: this.handleChange,
                                    value: this.props.creditCardInfo.address3,
                                    key: 'address3',
                                    disabled: this.state.sameAsShipping
                                }} />
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <TextInput {...{
                                name: 'zipCode',
                                label: `${t('zipCode')} *`,
                                id: 'zipCode',
                                onChange: this.handleChange,
                                value: this.props.creditCardInfo.zipCode,
                                error: this.props.errors.zipCode,
                                autocomplete: 'postal-code',
                                disabled: this.state.sameAsShipping
                            }} />
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default CreditCardForm;
