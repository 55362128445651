import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { State } from '../../../../store';

import Spinner from '../../../common/Spinner';
import { selectSquareConfig } from '../../../../store/selectors/distributorSelectors';
import SquareFormSDK from './SquareFormSDK';
import { SquarePaymentInfo } from '../../../../models/checkout';


interface SquareFormContainerProps {
    t: i18next.TranslationFunction;
    sqPaymentInfo: SquarePaymentInfo;
    sqPaymentConfig: any;
    errors: any;
    handleSqPaymentInfoChange(changes: any);
    handleSqPaymentErrorsChange(changes: any);
}

interface SquareFormContainerState {
    sqPaymentScript: any;
}

class SquareFormContainer extends React.Component<SquareFormContainerProps, SquareFormContainerState> {

    state: SquareFormContainerState = {
        sqPaymentScript: null
    }

    componentWillMount() {
        const script = document.getElementById("squareup");
        if (!script) {
            const sqPaymentScript = document.createElement('script');
            sqPaymentScript.id = "squareup";
            sqPaymentScript.src = "https://web.squarecdn.com/v1/square.js";
            sqPaymentScript.type = "text/javascript"
            sqPaymentScript.async = false;
            sqPaymentScript.onload = () => {
                const sqPaymentScript = window['Square'];
                this.setState({ sqPaymentScript: sqPaymentScript });
            };
            document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
        } else {
            let sqPaymentScript = window['Square'];
            this.setState({ sqPaymentScript: sqPaymentScript });
        }
    }

    render() {
        const { t, sqPaymentConfig, sqPaymentInfo, errors } = this.props;
        const { sqPaymentScript } = this.state;

        if (!sqPaymentScript || !sqPaymentConfig) {
            return (<Spinner />)
        } else {
            return (
                <SquareFormSDK
                    t={t}
                    sqPaymentConfig={sqPaymentConfig}
                    sqPaymentInfo={sqPaymentInfo}
                    sqPaymentScript={sqPaymentScript}
                    errors={errors}
                    handleSqPaymentInfoChange={this.props.handleSqPaymentInfoChange}
                    handleSqPaymentErrorsChange={this.props.handleSqPaymentErrorsChange} />
            )
        }
    }

}

const mapStateToProps = (state: State) => ({
    sqPaymentConfig: selectSquareConfig(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SquareFormContainer);
