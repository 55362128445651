import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as dompurify from 'dompurify';

import './CartPage.scss';
import { selectCartViewModel } from '../../../store/selectors/cartSelectors';
import { loadProductsInCart, changeProductQty, removeProductFromCart } from '../../../actions/cartActions';
import { CartViewModel } from '../../../models/cart';
import CartProduct from './CartProduct';
import { selectUserProfile } from '../../../store/selectors/authSelectors';
import SeneSiteLink from '../../common/SeneSiteLink';
import { UserManager } from '../../../utils/auth/UserManager';
import { setCallbackUrl } from '../../../utils/auth/CallbackStorage';
import { selectSeneSiteUrl, selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';
import { selectLoading } from '../../../store/selectors/uiSelectors';
import Spinner from '../../common/Spinner';
import { selectHasStoredCheckoutInfo } from '../../../store/selectors/checkoutSelectors';
import { ActionToastProperties } from '../../../models/ui';
import { selectSelectedLanguage } from '../../../store/selectors/languageSelectors';
import { DistributorState } from '../../../reducers/distributor';
import { CountryLanguage } from '../../../models/language';
import { CreateBackgroundImageUrl } from '../../common/Images';
import { selectImageResizerUrl, selectConfiguration } from '../../../store/selectors/configurationSelectors';
import { Configuration } from '../../../models/configuration';

interface CartStateProps {
    cart: CartViewModel;
    isLoggedIn: boolean;
    seneSiteUrl: string | null;
    isLoading: boolean;
    hasStoredCheckout: boolean;
    distributor: DistributorState;
    language: CountryLanguage | null;
    imageResizerUrl: string;
    config: Configuration | null;
}

interface CartDispatchProps {
    loadProductsInCart();
    changeProductQty(productId: number, qty: number, toastProps?: ActionToastProperties);
    removeProductFromCart(sku: string);
}

type CartPageProps = CartStateProps & CartDispatchProps & WithNamespaces;

interface CartPageState {
    isModalOpen: boolean;
}

class CartPage extends React.Component<CartPageProps, CartPageState> {
    _signUpUrl: string = "https://www.senegence.com/distributorsignup?";

    state: CartPageState = {
        isModalOpen: false
    }

    get totalItemCount() {
        return this.props.cart.items.reduce((acc, i) => acc += i.qty, 0);
    }

    componentDidMount() {
        this.props.loadProductsInCart();
        this.props.i18n.loadNamespaces('CheckoutPage');
    }

    openModal = () => {
        this.setState({ isModalOpen: true });
    }

    hideModal = () => {
        this.setState({ isModalOpen: false });
    }

    onCreateAccount = () => {
        setCallbackUrl(`/${this.props.seneSiteUrl}/checkout`);
        this.hideModal();
    }

    toggleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    login = () => {
        setCallbackUrl(`/${this.props.seneSiteUrl}/checkout`);
        UserManager.signinRedirect({ui_locales: this.props.i18n.language.toLowerCase()});
    }

    renderCheckout() {
        if (this.totalItemCount === 0) {
            return null;
        }

        const { t } = this.props;

        return this.props.isLoggedIn || this.props.hasStoredCheckout
            ? (
                <SeneSiteLink to="/checkout" className="proceed-checkout__btn">
                    <Button color="primary" style={{ width: '100%' }}>{t('checkOutLink')}</Button>
                </SeneSiteLink>
            ) : (
                <Button
                    color="primary"
                    className="proceed-checkout__btn"
                    onClick={this.openModal}>{t('checkOutLink')}</Button>
            );
    }

    renderModal() {
        const { t } = this.props;

        return (
            <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal} centered={true} fade={false}>
                <ModalHeader toggle={this.toggleModal}>{t('signIn')}</ModalHeader>
                <ModalBody>
                    <div className="col-md-6 offset-md-3">
                        <button type="button" className="btn btn-primary btn-block checkout-type-btn checkout-type-login"
                            onClick={this.login} style={{ margin: '7px 0' }}>{t('login')}</button>
                        <div className="section-title-login">
                            <h3 className="subtitle-login">{t('or')}</h3>
                        </div>
                        <SeneSiteLink to="/checkout" className="btn btn-light btn-block checkout-type-btn checkout-type-guest"
                            onClick={this.hideModal}>{t('checkoutGuest')}</SeneSiteLink>
                        <SeneSiteLink to="/newaccount" className="btn btn-light btn-block checkout-type-btn checkout-type-newacc"
                            onClick={this.onCreateAccount}>{t('createAccount')}</SeneSiteLink>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <h2 className="cart-title">{t('title')}</h2>
                <div className="row">
                    <div className="col-lg-8">
                        {
                            this.props.isLoading
                                ? <Spinner />
                                : this.props.cart.items.map(p => {
                                    return (
                                        <CartProduct
                                            key={p.product.productId}
                                            cartProduct={p}
                                            changeQty={this.props.changeProductQty}
                                            removeProduct={this.props.removeProductFromCart}
                                            t={t}
                                        />
                                    )
                                })}
                        <div className="cart-subtotal">
                            <span>{`${t('subtotal')} (${this.totalItemCount} ${t('items')}): `}</span>
                            <span className="cart-subtotal__price">{this.props.cart.totalDisplayPrice}</span>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="proceed-checkout">
                            <div>{`${t('subtotal')} (${this.totalItemCount} ${t('items')}): `}</div>
                            <div className="cart-subtotal__price">{this.props.cart.totalDisplayPrice}</div>
                            {this.renderCheckout()}
                        </div>
                        { this.props.distributor && this.props.language &&
                            <div className="card cartpage__promotion">
                                <div className="cartpage__card-signup" style={{
                                    backgroundImage: CreateBackgroundImageUrl({
                                        "imageResizerUrl": this.props.imageResizerUrl, 
                                        "src": "products/ShoppingCartImages/Distributor_signup_bg.png",
                                        "width": 450,
                                        "height": 350,
                                        "crop": "crop",
                                        config: this.props.config
                                    }),
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}>
                                    <div className="cartpage__card-signup-header">
                                        <p> {t('sideHeaderTitle1')} </p>
                                        <p className="cartpage__card-signp-header2"> {t('sideHeaderTitle2')} </p>
                                    </div>
                                    <div>
                                        <p className="cartpage__card-signup-body"> {t('sideHeaderContent1')} </p>
                                        <p className="cartpage__become-distributor"> {t('sideHeaderContent2')} </p>
                                    </div>
                                    <a className="btn cartpage__btn-signup" 
                                        href={this._signUpUrl + 
                                            "c=" + this.props.distributor.countryID + 
                                            "&s=" + this.props.distributor.dist_ID + 
                                            "&l=" + this.props.language.languageAlpha2Code} target="_blank">
                                            {t('sideHeaderLink').toUpperCase()}
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {this.renderModal()}
            </>
        )
    }
}

const mapStateToProps = state => ({
    cart: selectCartViewModel(state),
    distributor: selectSenesiteDistributor(state),
    language: selectSelectedLanguage(state),
    isLoggedIn: !!selectUserProfile(state),
    seneSiteUrl: selectSeneSiteUrl(state),
    isLoading: selectLoading(state),
    hasStoredCheckout: selectHasStoredCheckoutInfo(state),
    imageResizerUrl: selectImageResizerUrl(state),
    config: selectConfiguration(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadProductsInCart,
    changeProductQty,
    removeProductFromCart
}, dispatch);

export default withNamespaces("Cart")(connect(mapStateToProps, mapDispatchToProps)(CartPage));
