import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { Route } from 'react-router';

import './CustomerProfilePage.scss';
import withCustomerGuard from '../../common/guards/withCustomerGuard';
import SenePageHeader from '../../common/headers/SenePageHeader';
import SeneSiteLink from '../../common/SeneSiteLink';
import CustomerProfile from './CustomerProfile';
import CustomerShippingAddress from './CustomerShippingAddres';
import CustomerBillingAddress from './CustomerBillingAddress';

class CustomerProfilePage extends React.Component<WithNamespaces> {
    render() {
        const { t } = this.props;
        return (
            <>
                <SenePageHeader>{t('myAccount')}</SenePageHeader>
                <div className="row">
                    <div className="col-md-3 pt-md-3">
                        <div className="customer-profile__menu">
                            <ul className="fa-ul">
                                <li>
                                    <SeneSiteLink to={"/customer-profile"}>
                                        <span className="fa-li">
                                            <i className="fa fa-user text-secondary" />
                                        </span>
                                        {t('MyProfile')}
                                    </SeneSiteLink>
                                </li>
                                <li>
                                    <SeneSiteLink to={"/customer-profile/shipping"}>
                                        <span className="fa-li">
                                            <i className="fa fa-map-marker text-secondary" />
                                        </span>
                                        {t('shippingAddress')}
                                    </SeneSiteLink>
                                </li>
                                <li>
                                    <SeneSiteLink to={"/customer-profile/billing"}>
                                        <span className="fa-li">
                                            <i className="fa fa-credit-card text-secondary" />
                                        </span>
                                        {t('billingAddress')}
                                    </SeneSiteLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <Route path="/:seneSiteUrl/customer-profile" component={CustomerProfile} exact />
                        <Route path="/:seneSiteUrl/customer-profile/shipping" component={CustomerShippingAddress} exact />
                        <Route path="/:seneSiteUrl/customer-profile/billing" component={CustomerBillingAddress} exact />
                    </div>
                </div>
            </>
        )
    }
}

export default withCustomerGuard(withNamespaces('CustomerProfileSidebar')(CustomerProfilePage));
