import React from 'react';
import moment from 'moment';
import { NavLink } from "react-router-dom";

import { CalendarEvent } from '../../../../models/distributor';
import './SeneHomeEventWidget.scss';
import SeneSiteLink from '../../../common/SeneSiteLink';
import i18n from '../../../../i18n';

export interface SeneHomeEventWidgetProps {
    event: CalendarEvent;
    to: string;
    t: i18n.TranslationFunction
}

export class SeneHomeEventWidget extends React.PureComponent<SeneHomeEventWidgetProps> {

    renderDateRange() {
        const { event } = this.props;
        return <>
            <div className="row">
                <div className="col-5"> {moment(event.startDate).format('MMMM')} </div>
                <div className="col-5 offset-2"> {moment(event.endDate).format('MMMM')} </div>
            </div>
            <div className="row">
                <div className="col-5"> <h2 className="font-weight-bold">{moment(event.startDate).format('DD')}</h2> </div>
                <div className="col-5 offset-2"> <h2 className="font-weight-bold">{moment(event.endDate).format('DD')}</h2> </div>
            </div>
            <div className="row">
                <div className="col-5"> {moment(event.startDate).format('YYYY')} </div>
                <div className="col-5 offset-2"> {moment(event.endDate).format('YYYY')} </div>
            </div>
            <div className="row">
                <div className="col-5"> {moment(event.startDate).format('hh:mm a')} </div>
                <div className="col-2"> ~ </div>
                <div className="col-5"> {moment(event.endDate).format('hh:mm a')} </div>
            </div>
        </>
    }

    renderDate() {
        const { event } = this.props;
        return <>
            <div className="row">
                <div className="col-5"> {moment(event.startDate).format('MMMM')} </div>
                <div className="col-5 offset-2"> &nbsp; </div>
            </div>
            <div className="row">
                <div className="col-5"> <h2 className="font-weight-bold">{moment(event.startDate).format('DD')}</h2> </div>
                <div className="col-5 offset-2"> &nbsp; </div>
            </div>
            <div className="row">
                <div className="col-5"> {moment(event.startDate).format('YYYY')} </div>
            </div>
            <div className="row">
                <div className="col-5"> {moment(event.startDate).format('hh:mm a')} </div>
                <div className="col-2"> ~ </div>
                <div className="col-5"> {moment(event.endDate).format('hh:mm a')} </div>
            </div>
        </>
    }

    render() {

        const { event, to } = this.props;
        const {t} = this.props;

        let isDateRange = false;
        if (moment(event.startDate).format('YYYY-MM-DD') !== moment(event.endDate).format('YYYY-MM-DD')) {
            isDateRange = true;
        }

        return (
            <div className="card bg-white rounded border container">
                <div className="row justify-content-end mt-3 mb-1 title-row">
                    <span className="py-4 pl-2 title">{event.eventName}</span>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {
                            isDateRange ? this.renderDateRange() : this.renderDate()
                        }
                        <div className="row">
                            <div className="col-md-12" >@ {event.location}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <SeneSiteLink to={to}>{t('calendarView')}</SeneSiteLink>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}
export default SeneHomeEventWidget;