import { RegionActionTypes } from './../actions/regionActions';
import { CountryState } from './../models/region';

export type RegionsState = {
    [countryCode: string]: CountryState[]
}

export const reducer = (state: RegionsState = {}, action) => {
    switch (action.type) {
        case RegionActionTypes.getRegionsForCountrySucces:
            return {
                ...state,
                [action.countryCode]: action.regions
            };
        default:
            return state;
    }
}
