import { State } from './../index';
import { OrderStatus } from '../../models/order';

export const selectOrders = (state: State) => state && state.orders;
export const selectNewOrders = (state: State) => state && state.orders && state.orders.filter(order => {
    if (order.orderStatus == OrderStatus.Unknown ||
        order.orderStatus == OrderStatus.PendingPayment ||
        order.orderStatus == OrderStatus.DeclinedPayment ||
        order.orderStatus == OrderStatus.PayedFullPayment) {
            return order;
        } else {
            return;
        }
})
export const selectInProcessOrders = (state: State) => state && state.orders && state.orders.filter(order => {
    if (order.orderStatus == OrderStatus.OnHoldPayment ||
        order.orderStatus == OrderStatus.BeingProcessPayment) {
            return order;
        } else {
            return;
        }
})
export const selectCompletedOrders = (state: State) => state && state.orders && state.orders.filter(order => {
    if (order.orderStatus == OrderStatus.CanceledPayment ||
        order.orderStatus == OrderStatus.CompletePayment) {
            return order;
        } else {
            return;
        }
})