import produce from 'immer';

import { CatalogActionTypes } from './../actions/catalogActions';
import { Category } from '../models/category';

const initialState = [] as Category[];
export type CategoryState = typeof initialState;

export const reducer = (state: CategoryState = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case CatalogActionTypes.getCategoriesSuccess:
                return action.categories as Category[];
            case CatalogActionTypes.getProductsForCategorySuccess: {
                const category = draft.find(cat => cat.categoryId === action.categoryId);
                if (category) {
                    category.products = action.products.map(p => p.productId);
                }
                return draft;
            }
            case CatalogActionTypes.getProductsForSubCategorySuccess: {
                const category = draft.find(cat => cat.categoryId === action.categoryId);
                const subCategory = category
                    && category.subCategories
                    && category.subCategories.find(cat => cat.id === action.subCategoryId);

                if (subCategory) {
                    subCategory.products = action.products.map(p => p.productId);
                }
                return draft;
            }
            default:
                return draft;
        }
    });
