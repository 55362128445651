import React from 'react';

import { RadioButton } from '../../common/inputs/SeneRadioButton';
import SeneTextInput from '../../common/inputs/SeneTextInput';
import Button from '../../common/inputs/SeneButton';
import { HandlingInfo } from '../../../models/distributor';
import { ValidationErrors } from '../../../utils/validation/validator';
import { HandlingFeeValidator } from './SeneFeesValidator';

interface SeneHandlingFeesStateProps {
    t: any;
    handlingInfo?: HandlingInfo;
    saveHandlingInfo(handlingInfo: HandlingInfo)
    deleteHandlingInfo()
}

interface SeneHandlingFeesState {
    handlingInfo: HandlingInfo;
    useHandlingFee: boolean;
    errors: ValidationErrors<HandlingDetailErrors>
}

export interface HandlingDetailErrors {
    rate: string;
}

const FEE = 'fee';
const NO_FEE = 'noFee';

export class SeneHandlingFeesPage extends React.Component<SeneHandlingFeesStateProps, SeneHandlingFeesState> {


    state: SeneHandlingFeesState = {
        handlingInfo: {
            lowerLimit: 0,
            upperLimit: 99999,
            rate: 0
        },
        useHandlingFee: false,
        errors: {}
    }

    componentDidMount() {
        this.setState({
            handlingInfo: {
                ...this.state.handlingInfo,
                rate: this.props.handlingInfo && this.props.handlingInfo.rate || 0
            },
            useHandlingFee: this.props.handlingInfo && this.props.handlingInfo.rate > 0 || false
        });
    }

    componentDidUpdate(prevProps: SeneHandlingFeesStateProps) {
        if (prevProps.handlingInfo !== this.props.handlingInfo && this.props.handlingInfo) {
            this.setState({
                handlingInfo: this.props.handlingInfo,
                useHandlingFee: this.props.handlingInfo.rate > 0
            });
        }
    };

    isValidated = () => {

        const handlingFeeValidator = new HandlingFeeValidator(this.props.t);
        const handlingFeeResult = handlingFeeValidator.validate(this.state.handlingInfo);
        const errors = {
            ...handlingFeeResult.errors,
        };

        this.setState({
            errors: errors
        });

        return Object.keys(errors).length === 0;
    }

    changeHandlingFee = (e: React.ChangeEvent<HTMLInputElement>) => {
        const useHandlingFee = e.target.value === FEE;
        this.setState({
            useHandlingFee
        })
    }

    updateHandlingInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const prop = e.target.name;
        const value = e.target.value;
        this.setState({
            handlingInfo: {
                ...this.state.handlingInfo,
                [prop]: value
            }
        })
    }

    submit = () => {
        if (!this.state.useHandlingFee) {
            this.setState({
                handlingInfo: {
                    ...this.state.handlingInfo,
                    'rate': 0
                }
            }, () => {this.props.saveHandlingInfo(this.state.handlingInfo);})
        }
        else {
            if (this.isValidated()) {
                this.props.saveHandlingInfo(this.state.handlingInfo);
            }
        }
    }

    render() {
        const { t } = this.props;

        return <div>
            <div className="row admin-dist-name">
                <h4><u>{t('tabHandlingFeesTitle')}</u></h4>
            </div>
            <RadioButton
                itemStyle="dummy"
                groupname="handling_fees"
                identifier="no-handling"
                onChange={this.changeHandlingFee}
                value={NO_FEE}
                labelDisplayValue={t('handlingFeeDisable')}
                checked={!this.state.useHandlingFee} />

            <RadioButton
                itemStyle="dummy"
                groupname="handling_fees"
                identifier="with-handling"
                onChange={this.changeHandlingFee}
                value={FEE}
                labelDisplayValue={t('handlingFeeEnable')}
                checked={this.state.useHandlingFee} />

            <div style={{ marginBottom: '5px' }} />
            {this.state.useHandlingFee &&
                <SeneTextInput
                    label={t('handlingFeesRate')}
                    name={"rate"}
                    onChange={(e) => this.updateHandlingInfo(e)}
                    value={(this.state.handlingInfo.rate).toString()}
                    type="number"
                    placeholder='0'
                    error={this.state.errors.rate}
                />
            }
            <Button
                className="btn btn-secondary"
                onClick={this.submit}
                icon="fa-save"
                id="contact_form_submit">
                {t('save')}
            </Button>
        </div>
    }
}

export default SeneHandlingFeesPage;