import axios from 'axios';
import { UserInfo } from './../models/userInfo';
import { Customer, CreateCustomerRequest } from './../models/customer';
import { CustomerSignUpEmailAddressAvailability } from '../models/CustomerSignUpEmailAddressAvailability';

export const updateCustomer = (customer: Customer) => {
    return axios.post(`/api/Customers/${customer.id}`, customer);
};

export const createCustomer = (request: CreateCustomerRequest) => {
    return axios.post('/api/Customers', request);
};

export const updateLoggedInCustomer = (info: UserInfo) => {
    return axios.post<UserInfo>('/api/Customers/UpdateLoggedIn', info).then(res => res.data);
};

export const getCustomerBadgeCount = (distId: number) => {
    return axios.get<number>(`/api/Customers/GetCustomerBadgeCount?distributorId=${distId}`).then(res => res.data);
}

export const validateCustomerSignupEmailAvailability = (address: string, referralDistributorId: number) => {
    return axios.get<CustomerSignUpEmailAddressAvailability>(`/api/Customers/validateCustomerSignupEmailAvailability?address=${address}&referralDistributorId=${referralDistributorId}`).then(res => res.data);
}
