export interface Log {
    message: string,
    severity: LogSeverityLevels,
    data: string
}

export enum LogSeverityLevels {
    Information,
    Warning,
    Trace,
    Error,
    Debug
}
