import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectLoading } from '../../store/selectors/uiSelectors';
import { getCountryLanguages } from "../../actions/languageActions";
import { getBrowserCountry, getLang } from '../../utils/helpers';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import NotFound from '../common/404';
import {selectUserProfile} from "../../store/selectors/authSelectors";
import {getDistributorFromDistId} from "../../actions/distributor";
import {Distributor} from "../../models/distributor";
import {setBlockUi} from "../../actions/ui";

interface IndexStateProps {
    loading: boolean;
    user: any;
}

interface SeneSiteDispatchProps {
    getCountryLanguages(country2Alpha: string, preferredLocale: string, distributor?: Distributor);
    getDistributorFromDistId(distId: number);
    setBlockUi(block: boolean);
}

interface SeneSiteState {
    distributor?: any
}

type SeneSitePageProps = RouteComponentProps & SeneSiteDispatchProps & IndexStateProps & WithNamespaces;

class IndexPage extends React.Component<SeneSitePageProps, SeneSiteState> {

    state: SeneSiteState = {
        distributor: null
    }
    componentDidMount() {
        const preferredLocale = this.props.i18n.language ? this.props.i18n.language : getLang();
        if (this.props.user && this.props.user.preferred_username && +this.props.user.preferred_username != NaN) {
            this.props.setBlockUi(true);
            this.props.getDistributorFromDistId(+this.props.user.preferred_username)
                .then((distributor: Distributor) => {
                    this.setState({
                        distributor
                    })
                    this.props.setBlockUi(false);
                    this.props.getCountryLanguages(distributor.countryName, preferredLocale, distributor);
                })
                .catch(() => {
                    this.props.setBlockUi(false);
                    this.props.getCountryLanguages(getBrowserCountry(), preferredLocale);
                });
        } else {
            this.props.getCountryLanguages(getBrowserCountry(), preferredLocale);
        }
    }

    render() {
        if (this.state.distributor && this.state.distributor.seneSiteInfo && this.state.distributor.seneSiteInfo.webStat) {
            const dist = this.state.distributor as Distributor;
            const redirectToCdo = dist.cdoInfo && dist.cdoInfo.isCdoOn && dist.seneSiteInfo.redirectToCdo;

            if(this.state.distributor.seneSiteInfo.webStat == "Published") {
                if(!redirectToCdo) {
                    return <Redirect from="*" to={`/${this.state.distributor.seneSiteInfo.seneSiteUrl}`} />
                }
                return <Redirect from="*" to={"/administration/Dashboard"} />
            }
            else if(this.state.distributor.seneSiteInfo.webStat == "Setup") {
                return <Redirect from="*" to={"/administration/Dashboard"} />
            }
        }
        return (
            <div style={{ flex: '1', position: 'relative'}}>
                <NotFound/>
            </div>
        );
    }
}

const mapStateToProps = (state): IndexStateProps => ({
    loading: selectLoading(state),
    user: selectUserProfile(state)
});

const mapDispatchToProps = (dispatch): SeneSiteDispatchProps => (
    bindActionCreators({
        getCountryLanguages,
        getDistributorFromDistId,
        setBlockUi
    }, dispatch)
);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(IndexPage));
