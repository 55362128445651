import { Thunk } from "../store";

export enum UIActionTypes {
    setLoading = '[UI] Set Loading',
    setProductSort = '[UI] Set Product sorting order',
    setBlockUi = '[UI] Set UI block'
}

export const setLoading = (loading: boolean) => ({type: UIActionTypes.setLoading, loading});
export const setBlockUi = (blockUi: boolean) => ({type: UIActionTypes.setBlockUi, blockUi});
export const setProductSort = (sortBy: string, direction: string): Thunk => {
    return (dispatch) => {
        if (!direction.match(/^(asc|desc)$/)) {
            direction = 'asc';
        }

        return dispatch({type: UIActionTypes.setProductSort, sort: {sortBy, direction}});
    }
};
