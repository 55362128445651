import React from 'react';
import moment from 'moment';
import i18next from 'i18next';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import SwipeableInkTabBar from 'rc-tabs/lib/SwipeableInkTabBar';

import { DistributorState } from '../../../reducers/distributor';
import { Order } from '../../../models/order';
import './SeneSiteOrdersMobile.scss';


export interface SeneSiteOrdersMobileProps {
    t: i18next.TranslationFunction;
    locale: string;
    distributor: DistributorState;
    activeTab: string;
    changeTab(tab: 'currentorders' | 'inProcessOrders' | 'completedOrders');
    newOrders: Order[];
    inProcessOrders: Order[];
    completedOrders: Order[];
    orderStatuses: any[];
    handleStatusChange(updatedOrder: Order);
    handleOrderDetailsToggle(isModalOpen: boolean, order?: Order);
}

class SeneSiteOrdersMobile extends React.PureComponent<SeneSiteOrdersMobileProps> {

    handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>, order: any) => {
        this.props.handleStatusChange({ ...order, orderStatus: e.target.value })
    }

    handleOrderDetailsOpen = (order: Order) => {
        this.props.handleOrderDetailsToggle(true, order);
    }

    renderTabContent = (orders: Order[]) => {
        const { t } = this.props;
        return (
            <div className="tab-content-container">
                <div className="container">
                    <div className="row mt-2 mb-2">
                        <div className="col-3 font-weight-bold"> {t('colDate')} </div>
                        <div className="col-4 font-weight-bold"> {t('colName')} </div>
                        <div className="col-3 font-weight-bold"> {t('colOrderId')} </div>
                        <div className="col-2 font-weight-bold"> &nbsp; </div>
                    </div>
                    <div className="row table-container border-top">
                        {this.renderTable(orders)}
                    </div>
                </div>
            </div>
        )
    }

    renderTable = (orders: Order[]) => {
        const { locale } = this.props;
        return orders.map(order => {
            return (
                <div className={`table-row border-bottom`} key={order.orderId}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-3"> {moment(order.orderDate).locale(locale).format("LL")} </div>
                            <div className="col-4"> {order.shippingName} </div>
                            <div className="col-3"> {order.orderId} </div>
                            <div className="col-2">
                                <button type="button" className="btn btn-secondary text-center" onClick={() => this.handleOrderDetailsOpen(order)}>
                                    <i className="fas fa-save"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        const { t, activeTab, newOrders, inProcessOrders, completedOrders } = this.props;
        return (
            <div className="content-container" >
                <Tabs
                    data-extra="tabs"
                    tabBarPosition="top"
                    renderTabBar={() =>
                        <SwipeableInkTabBar
                            pageSize={3}
                            speed={5}
                            data-extra="tabbar"
                        />
                    }
                    renderTabContent={() => <TabContent />}
                    defaultActiveKey={activeTab}
                    onChange={(key) => this.props.changeTab(key)}
                >
                    <TabPane tab={t('mobileNewProcess')} data-extra="tabpane" key="currentorders">
                        {this.renderTabContent(newOrders)}
                    </TabPane>
                    <TabPane tab={t('mobileInProcess')} data-extra="tabpane" key="inProcessOrders">
                        {this.renderTabContent(inProcessOrders)}
                    </TabPane>
                    <TabPane tab={t('mobileCompleted')} data-extra="tabpane" key="completedOrders">
                        {this.renderTabContent(completedOrders)}
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

export default SeneSiteOrdersMobile;
