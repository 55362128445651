import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import './SeneSortDropdown.scss';

export interface SeneDropdownProps {
    classNav?: string;
    title: string;
    items: SeneNavItem[];
    activeData: any;
    onClick(item: SeneNavItem);
}

export interface SeneNavItem {
    text?: string;
    data?: any;
    divider?: boolean;
}

export class SeneSortDropdown extends React.PureComponent<SeneDropdownProps> {
    get activeItem() {
        return this.props.items.find(item => item.data === this.props.activeData)!;
    }

    onItemClick = (item, event) => {
        event.preventDefault();

        this.props.onClick(item);
    }

    render() {
        const activeItem = this.activeItem;

        if (!activeItem) {
            this.props.onClick(this.props.items[0]);
            return null;
        }

        return (
            <div className="sort-by">
                <span className="sort-by__label">{this.props.title}</span>
                <UncontrolledDropdown>
                    <DropdownToggle tag="a" className="nav-link" caret>
                        {activeItem.text}
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.props.items.map((item, i) => {
                            return item.divider
                                ? <DropdownItem key={i} divider />
                                : <DropdownItem key={i} tag="a" onClick={(e)=> this.onItemClick(item, e)} active={item === activeItem}>{item.text}</DropdownItem>
                        })}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        );
    }
}

export default SeneSortDropdown;
