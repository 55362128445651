import React from 'react';
import { connect } from 'react-redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { bindActionCreators } from "redux";
import produce from 'immer';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { selectLanguageState } from '../../../store/selectors/languageSelectors';
import './MySeneSitesPage.scss';
import * as distributorActions from '../../../actions/distributor';
import { DistributorState } from '../../../reducers/distributor';
import withAdminPageTitle from '../withAdminPageTitle';
import Spinner from '../../common/Spinner';
import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';
import { selectMarketingBanners } from '../../../store/selectors/marketingSelectors';
import { getMarketing } from "../../../actions/marketingActions";
import { IsYouTubeVideoURL } from "../../../utils/helpers";
import SeneButton from '../../common/inputs/SeneButton';
import { SeneSiteInfo, Distributor, CustomContent, DistributorSocialMedia } from '../../../models/distributor';

import MySeneSitesDefaultLanguage from './MySeneSitesDefaultLanguage';
import MySeneSitesBlog from './MySeneSitesBlog';
import MySeneSitesSocialMedia from './MySeneSitesSocialMedia';
import MySeneSitesCustomContent, { CustomContentHtml } from './MySeneSitesCustomContent';
import MySeneSitesBanner from './MySeneSitesBanner';
import { MarketingBanner } from '../../../models/marketing';
import { Languages } from '../../../models/language';
import { toast } from 'react-toastify';
import { ActionToastProperties } from '../../../models/ui';
import { shouldSwithToMaintenance } from "../../../utils/helpers";

interface MySeneSitesPageStateProps {
    distributor: DistributorState;
    marketingBanners: MarketingBanner[];
    languages: Languages;
}
interface SeneSiteDispatchProps {
    getMarketing();
    updateSeneSiteInfo(distId: number, seneSiteInfo: SeneSiteInfo, toastProps?: ActionToastProperties);
}

type MySeneSitesPageProps = MySeneSitesPageStateProps & WithNamespaces & SeneSiteDispatchProps;

interface MySeneSitesPageState {
    distributor: DistributorState,
    customContents: CustomContentHtml[],
    marketingBanners: MarketingBanner[],
    isSaveInProgress: boolean,
    isBlogPreviewLoading: boolean,
    hasBlogError: boolean,
    hasYoutubeError: boolean
}

class MySeneSitesPage extends React.Component<MySeneSitesPageProps, MySeneSitesPageState> {
    state: MySeneSitesPageState = {
        distributor: null,
        marketingBanners: [],
        customContents: [],
        isSaveInProgress: false,
        isBlogPreviewLoading: false,
        hasBlogError: false,
        hasYoutubeError: false
    }

    componentDidMount() {
        this.props.getMarketing();
        this.setState({
            distributor: this.props.distributor,
            customContents: []
        });
    }

    componentDidUpdate(prevProps: MySeneSitesPageStateProps) {
        if (prevProps.marketingBanners !== this.props.marketingBanners) {
            this.setState({
                marketingBanners: this.props.marketingBanners
            });
        }
        if (prevProps.distributor !== this.props.distributor) {
            this.setState({
                distributor: this.props.distributor
            });
        }
    };

    get isSaveDisabled() {
        return this.state.isBlogPreviewLoading || this.state.hasBlogError;
    }

    convertContent = (customHTML: CustomContentHtml) => {
        const content = draftToHtml(convertToRaw(customHTML.content));

        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");
        
        return (doc.body.textContent && doc.body.textContent.trim() === "") ? "" : content;
    }

    submit = () => {
        if (!this.state.distributor || !this.state.distributor.seneSiteInfo) return;
        const socialInfo = this.state.distributor.seneSiteInfo.socialInfo;
        const youtube = this.state.distributor.seneSiteInfo.socialInfo.youTubeVideo;
        let isFormValid = true;

        if (socialInfo.useMyYouTube) {
            const hasYoutubeError = !IsYouTubeVideoURL(youtube);
            this.setState({
                hasYoutubeError
            });
            isFormValid = !hasYoutubeError;
        }

        if (isFormValid) {

            this.setState({
                isSaveInProgress: true
            });

            this.setState(
                produce(draft => {
                    if (draft.distributor) {
                        const convertedCustomContents = this.state.customContents.map(t =>
                            ({ content: this.convertContent(t), locale: t.locale }));
                        draft.distributor.seneSiteInfo.seneSiteLooks.customContents = convertedCustomContents;
                        if (!socialInfo.useMyYouTube) {
                            draft.distributor.seneSiteInfo.socialInfo.youTubeVideo = '';
                        }
                    }
                    return draft;
                }), () => {
                    if (this.state.distributor) {
                        this.props.updateSeneSiteInfo(this.state.distributor.dist_ID, this.state.distributor.seneSiteInfo, { success: this.props.t('userUpdateSuccess'), error: this.props.t('userUpdateError') })
                            .then((response) => {
                                this.setState({ isSaveInProgress: false });
                            })
                            .catch((exp) => {
                                shouldSwithToMaintenance(exp);
                                this.setState({ isSaveInProgress: false })
                            });
                    }
                }
            )
        }
    }

    setIsBlogPreviewLoading = (isBlogPreviewLoading: boolean) => {
        this.setState({ isBlogPreviewLoading });
    }

    setHasBlogError = (hasBlogError: boolean) => {
        this.setState({ hasBlogError });
    }

    setDistributor = (distributor: Distributor) => {
        this.setState({
            distributor
        });
    }

    setSocialInfo = (socialInfo: DistributorSocialMedia) => {
        this.setState(
            produce(draft => {
                if (draft.distributor) {
                    draft.distributor.seneSiteInfo.socialInfo = socialInfo;
                }
                return draft;
            }));

    }

    setLocale = (locale: string) => {
        this.setState(
            produce(draft => {
                if (draft.distributor) {
                    draft.distributor.seneSiteInfo.locale = locale;
                }
                return draft;
            }));
    }

    setCustomContent = (customContents: CustomContentHtml[]) => {
        this.setState({
            customContents
        });
    }

    render() {
        if (!this.state.distributor ||
            !this.state.marketingBanners ||
            !this.props.languages ||
            (this.props.languages && this.props.languages.availableLanguages.length == 0)) {
            return <Spinner />;
        }
        else {
            const { t } = this.props;
            return (
                <div className='widget-body'>
                    <div className="row my-senesite-panel">
                        <div className="col-xl-12">
                            <MySeneSitesBanner distributor={this.state.distributor}
                                banners={this.state.marketingBanners}
                                setDistributor={this.setDistributor}
                                t={this.props.t} />
                        </div>
                        <div className="col-xl-12">
                            <MySeneSitesDefaultLanguage 
                                defaultLocale={this.state.distributor.seneSiteInfo.locale}
                                setLocale={this.setLocale}
                                languages={this.props.languages}
                                t={this.props.t} />
                        </div>
                        <div className="col-xl-12">
                            <MySeneSitesCustomContent distributor={this.state.distributor}
                                setCustomContent={this.setCustomContent}
                                languages={this.props.languages}
                                t={this.props.t} />
                        </div>
                        <div className="col-xl-12">
                            <MySeneSitesSocialMedia socialInfo={this.state.distributor.seneSiteInfo.socialInfo}
                                hasYoutubeError={this.state.hasYoutubeError}
                                setSocialInfo={this.setSocialInfo}
                                t={this.props.t} />
                        </div>
                        <div className="col-xl-12">
                            <MySeneSitesBlog distributor={this.state.distributor}
                                isBlogPreviewLoading={this.state.isBlogPreviewLoading}
                                hasBlogError={this.state.hasBlogError}
                                setIsBlogPreviewLoading={this.setIsBlogPreviewLoading}
                                setHasBlogError={this.setHasBlogError}
                                setDistributor={this.setDistributor}
                                t={this.props.t}
                            />
                        </div>
                        <div className="col-12 text-right">
                            <SeneButton
                                icon="fa-save"
                                onClick={this.submit}
                                disabled={this.isSaveDisabled}
                                loading={this.state.isSaveInProgress}
                            >
                                {t('save')}
                            </SeneButton>
                        </div>

                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        distributor: selectSenesiteDistributor(state),
        marketingBanners: selectMarketingBanners(state),
        languages: selectLanguageState(state)
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        getMarketing,
        updateSeneSiteInfo: distributorActions.updateDistributorSeneSiteInfo
    }, dispatch)
);

export default withAdminPageTitle('MySeneSites')(withNamespaces('MySenesite')(connect(mapStateToProps, mapDispatchToProps)(MySeneSitesPage)));