import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

import './AdminBreadcrumbs.scss';

export interface BreadcrumbModel {
    text: string;
    link: string;
}

type AdminBreadcrumbsProps = {
    t: i18next.TranslationFunction
    additionalBreadcrumbs?: BreadcrumbModel[];
    currentPage: string;
}

class AdminBreadcrumbs extends React.Component<AdminBreadcrumbsProps> {
    render() {
        const { t, currentPage, additionalBreadcrumbs } = this.props;
        return (
            <div className="admin-breadcrumbs d-none d-sm-block">
                <div className="container">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/administration/Dashboard">{t('dashboard')}</Link>
                        </BreadcrumbItem>
                        {
                            additionalBreadcrumbs &&
                            additionalBreadcrumbs.map(b => (
                                <BreadcrumbItem>
                                    <Link to={b.link}>{t(b.text)}</Link>
                                </BreadcrumbItem>
                            ))
                        }
                        <BreadcrumbItem active>
                            {t(currentPage)}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>
        )
    }
}

export default AdminBreadcrumbs;
