import { Thunk } from './../store';
import * as blogApi from './../api/blogApi';
import { shouldSwithToMaintenance } from "../utils/helpers";

export enum BlogActionTypes {
    getDistributorBlogFeedSuccess = '[Blog] Get Distributor Blog Feed success',
    getDistributorBlogFeedError = '[Blog] Get Distributor Blog Feed error'
}


export const getDistributorBlogFeed = (): Thunk => (dispatch, getState) => {
    const state = getState(),
        distributorUrl = state && state.distributor && state.distributor.seneSiteInfo && state.distributor.seneSiteInfo.blogUrl,
        storedBlogFeedUrl = state && state.blog && state.blog.url;

    if (!state.distributor || !state.distributor.dist_ID || distributorUrl === storedBlogFeedUrl) {
        return;
    }

   return blogApi.getDistributorBlogFeed(state.distributor.dist_ID)
       .then(blogPosts => {
           dispatch({ type: BlogActionTypes.getDistributorBlogFeedSuccess, payload: { url: distributorUrl, posts: blogPosts } });
        })
       .catch(err => {
            shouldSwithToMaintenance(err);
            dispatch({ type: BlogActionTypes.getDistributorBlogFeedError });
        });
}
