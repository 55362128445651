import { createSelector } from 'reselect';

import { CartItem, CartProductVm, CartViewModel } from './../../models/cart';
import { State } from './../index';
import { selectDistributorCountry } from './distributorSelectors';
import { selectCurrency, selectProductsByIds } from './catalogSelectors';
import { mapProductViewModel } from '../../models/product';
import { selectSelectedLanguageCode } from './languageSelectors';
import { displayFormat as currencyDisplayFormat } from '../../components/common/Currency'

export const selectCartItemCount = (state: State) => state.cart.reduce((acc, v) => acc += v.qty, 0);
export const selectCart = (state: State): CartItem[] => state.cart;
export const selectPreviousOrderCart = (state: State) => state.previousOrder && state.previousOrder.cart || [];

const createProductsInCartSelector = (cartSelector: (state: State) => CartItem[]) => (state: State) => {
    const cart = cartSelector(state);
    const products = selectProductsByIds(state, cart.map(p => p.productId));
    const productsWithDetails = products.filter(p => !!p.productDetails);

    return productsWithDetails;
}

export const selectProductsInCart = createProductsInCartSelector(selectCart);
export const selectProductsInPreviousCart = createProductsInCartSelector(selectPreviousOrderCart);

/**
 * Creates a cart ViewModel selector for the given cartSelector
 */
const createCartVmSelector = (cartSelector: (state: State) => CartItem[]) => createSelector(
    selectDistributorCountry,
    selectCurrency,
    createProductsInCartSelector(cartSelector),
    cartSelector,
    selectSelectedLanguageCode,
    (country, currency, products, cart, lang): CartViewModel => {
        const locale = lang || 'en-us';

        const items = products.map(product => {
            const productVm = mapProductViewModel(product, country, locale, currency, false);
            const qty = cart.find(item => item.productId === product.productId)!.qty;
            const totalPrice = productVm.price * qty;
            const totalDisplayPrice = currencyDisplayFormat(totalPrice, currency, locale, country, true);
            const imageUrl = productVm.imageUrl;

            const cartProduct: CartProductVm = {
                product: productVm,
                qty,
                totalPrice,
                totalDisplayPrice,
                imageUrl
            };

            return cartProduct;
        });

        const totalPrice = items.reduce((acc, i) => acc += i.totalPrice, 0);
        const totalDisplayPrice = currencyDisplayFormat(totalPrice, currency, locale, country, true);

        return {
            items,
            totalPrice,
            totalDisplayPrice
        };
    }
);

export const selectCartViewModel = createCartVmSelector(selectCart);
export const selectPreviousOrderCartVm = createCartVmSelector(selectPreviousOrderCart);
