import { Thunk, State } from "../store";
import * as loggingApi from '../api/loggingApi';
import { Log, LogSeverityLevels } from "../models/logging";
import { selectDistributorId } from "../store/selectors/distributorSelectors";

export const sendLog = (log: Log) => {

    loggingApi.postLog(log);

};

export const sendCreateCustomerErrorLog = (errors: any, state: State) => {

    const log = {
        'message' : 'There is a SignalR error response during creting a customer.',
        'severity' : LogSeverityLevels.Error,
        'data': JSON.stringify(errors)
    }
    loggingApi.postLog(log);

};

export const sendRemoveItemFromCartLog = (items: any, state: State) => {

    const distributorId = selectDistributorId(state);
    const log = {
        'message' : 'There is a SignalR error response regarding item removal from the cart',
        'severity' : LogSeverityLevels.Error,
        'data': JSON.stringify({...items, distributorId})
    }
    loggingApi.postLog(log);

};

export const sendDeclinedPaymentLog = (errors: any, state: State) => {

    const distributorId = selectDistributorId(state);
    const log = {
        'message' : 'There is a SignalR error response regarding payment decline',
        'severity' : LogSeverityLevels.Error,
        'data': JSON.stringify({...errors, distributorId})
    }
    loggingApi.postLog(log);

};

export const sendSubmitApplicationFormErrorLog = (errors: any, state: State) => {

    const distributorId = selectDistributorId(state);
    const log = {
        'message' : 'There is a SignalR error response for submitting the application form',
        'severity' : LogSeverityLevels.Error,
        'data': JSON.stringify({...errors, distributorId})
    }
    loggingApi.postLog(log);

};

export const sendSubmitURLChangeFormErrorLog = (errors: any, state: State) => {

    const distributorId = selectDistributorId(state);
    const log = {
        'message' : 'There is a SignalR error response for submitting the url change form',
        'severity' : LogSeverityLevels.Error,
        'data': JSON.stringify({...errors, distributorId})
    }
    loggingApi.postLog(log);

};