import React, { memo } from 'react';

import './SeneGradientHeader.scss';

interface SeneGradientHeaderProps {
    children: string;
    id?: string;
}

const SeneGradientHeader = memo(({children, id}: SeneGradientHeaderProps) => {
    return (
        <div className="sene-gradient-header" id={id}>
            <h2>{children}</h2>
        </div>
    )
});

export default SeneGradientHeader;
