import React from 'react';
import './UnderConstruction.scss';
import {WithNamespaces, withNamespaces} from 'react-i18next';
import {NavLink} from "react-router-dom";

export interface UnderConstructionProps {
    isDistributorLoggedIn: boolean,
    isOwnSeneSite: boolean
}

const UnderConstruction = ({ t, isOwnSeneSite, isDistributorLoggedIn }: UnderConstructionProps & WithNamespaces) => (
    <>
        <div className="under-construction">
            <div className="under-construction__title">{t('underConstruction')}</div>
            <br />
            <div className="under-construction__subtitle">{t('thanksForPatience')}</div>
        </div>
        {isOwnSeneSite && <div className="finish-setup">
            <NavLink to="/administration/Dashboard">
                {t('finishSetup')}
            </NavLink>
        </div>}
        {!isDistributorLoggedIn && <div className="finish-setup">
            {t('finishSenesiteSetup')}
        </div>}
        
    </>
);

export default withNamespaces('Common')(UnderConstruction);
