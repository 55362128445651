import { Thunk } from './../store';
import * as catalogApi from '../api/catalogApi';
import { selectCategory } from '../store/selectors/catalogSelectors';
import { setLoading } from './ui';
import { shouldSwithToMaintenance } from "../utils/helpers";


export enum CatalogActionTypes {
    getCategoriesSuccess = '[Catalog] Get Categories success',
    getCategoriesError = '[Catalog] Get Categories error',
    getProductsForSubCategorySuccess = '[Catalog] Get Products for Subcategory success',
    getProductsForSubCategoryError = '[Catalog] Get Products for Subcategory error',
    getProductsForCategorySuccess = '[Catalog] Get Products for Category success',
    getProductsForCategoryError = '[Catalog] Get Products for Category error',
    getProductByIdSuccess = '[Catalog] Get Product by Id success',
    getProductByIdError = '[Catalog] Get Product by Id error',
    getProductsByIdsSuccess = '[Catalog] Get Products by Ids success',
    getProductsByIdsError = '[Catalog] Get Products by Ids error',
    getAllProductsSuccess = '[Catalog] Get all products success',
    getAllProductsError = '[Catalog] Get all products error',
}

export const getCategories = (): Thunk => (dispatch, getState) => {
    const state = getState();

    if (!state.distributor || !state.distributor.dist_ID) {
        return;
    }

    if (state.categories && state.categories.length > 0) {
        // Return promise to allow action chaining
        return Promise.resolve(state.categories);
    }

    dispatch(setLoading(true));
    return catalogApi.getCategories(state.distributor.dist_ID)
        .then(categories => {
            dispatch(setLoading(false));
            dispatch({type: CatalogActionTypes.getCategoriesSuccess, categories});
        })
        .catch((exp) => {
            shouldSwithToMaintenance(exp);
            dispatch(setLoading(false));
            dispatch({type: CatalogActionTypes.getCategoriesError});
        });
}

export const getAllProducts = (availableOnly: boolean): Thunk => (dispatch, getState) => {
    const state = getState();

    if (!state.distributor || !state.distributor.dist_ID) {
        return;
    }

    dispatch(setLoading(true));
    return catalogApi.getAllProducts(state.distributor.dist_ID, availableOnly)
        .then(products => {
            dispatch(setLoading(false));
            dispatch({ type: CatalogActionTypes.getAllProductsSuccess, products });
        })
        .catch((exp) => {
            shouldSwithToMaintenance(exp);
            dispatch(setLoading(false));
            dispatch({type: CatalogActionTypes.getAllProductsError});
        })
}

export const getProductsForCategory = (categoryId: number, subCategoryId?: number): Thunk => (dispatch, getState) => {
    const state = getState();
    if (!state.distributor || !state.distributor.dist_ID) {
        return;
    }

    const category = selectCategory(state, categoryId, subCategoryId);
    if (!category || category.products ) {
        return;
    }

    if (subCategoryId) {
        dispatch(setLoading(true));
        return catalogApi.getProductsForSubcategory(state.distributor.dist_ID, categoryId, subCategoryId)
        .then(products => {
                dispatch(setLoading(false));
                dispatch({type: CatalogActionTypes.getProductsForSubCategorySuccess, categoryId, subCategoryId, products});
            })
            .catch((exp) => {
                shouldSwithToMaintenance(exp);
                dispatch(setLoading(false));
                dispatch({type: CatalogActionTypes.getProductsForSubCategoryError});
            })

        } else {
            dispatch(setLoading(true));
            return catalogApi.getProductsForCategory(state.distributor.dist_ID, categoryId)
            .then(products => {
                dispatch(setLoading(false));
                dispatch({type: CatalogActionTypes.getProductsForCategorySuccess, categoryId, products});
            })
                .catch((exp) => {
                shouldSwithToMaintenance(exp);
                dispatch(setLoading(false));
                dispatch({type: CatalogActionTypes.getProductsForCategoryError});
            });
    }

}

export const getProductById = (productId: number): Thunk => (dispatch, getState) => {
    const state = getState();

    if (!state.distributor || !state.distributor.dist_ID) {
        return Promise.resolve(null);
    }

    if (state.products && Object.keys(state.products).length > 0) {
        const product = state.products[productId];
        if (product && product.productDetails) {
            return Promise.resolve(product);
        }
    }

    dispatch(setLoading(true));
    return catalogApi.getProductById(state.distributor.dist_ID, productId)
        .then(product => {
            dispatch({type: CatalogActionTypes.getProductByIdSuccess, productId, product});
            dispatch(setLoading(false));
            return product;
        })
        .catch((exp) => {
            shouldSwithToMaintenance(exp);
            dispatch({type: CatalogActionTypes.getProductByIdError});
            dispatch(setLoading(false));
            return exp;
        });
}

export const getProductsByIds = (productIds: number[]): Thunk => (dispatch, getState) => {
    const state = getState();

    if (!state.distributor || !state.distributor.dist_ID) {
        return;
    }

    let missingProducts: number[] = [];
    if (state.products && Object.keys(state.products).length > 0) {
        productIds.forEach(id => {
            if (!state.products[id] || !state.products[id].productDetails) {
                missingProducts.push(id);
            }
        });

        if (missingProducts.length === 0) {
            return;
        }
    } else {
        missingProducts = productIds;
    }

    dispatch(setLoading(true));
    return catalogApi.getProductsByIds(state.distributor.dist_ID, missingProducts)
        .then(products => {
            dispatch({type: CatalogActionTypes.getProductsByIdsSuccess, products});
            dispatch(setLoading(false));
        })
        .catch((exp) => {
            shouldSwithToMaintenance(exp);
            dispatch({type: CatalogActionTypes.getProductsByIdsError});
            dispatch(setLoading(false));
        });
}
