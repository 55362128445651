import React from 'react';

import './CartInfo.scss';
import { AssetDirectImage } from '../../common/Images';
import SeneSiteLink from '../../common/SeneSiteLink';
import i18n from '../../../i18n';

export interface CartProps {
    itemCount: number;
    isMobile?: boolean;
    t: i18n.TranslationFunction
}

const CartInfo = ({ t, itemCount, isMobile = false }: CartProps) => (
    <SeneSiteLink to={"/cart"} className={`cart-info${isMobile ? '--mobile' : ''}`}>
        {
            itemCount > 0 &&
            <div className="cart-info__count">
                {itemCount}
            </div>
        }
        <AssetDirectImage {...{
            src: `icons/icon_shopping_cart${isMobile ? '_01' : ''}.svg`,
            className: 'cart-info__icon',
            alt: 'Bag'
        }} />
        <span className="cart-info__text">{t('bagTranslation')}</span>
    </SeneSiteLink>
)

export default CartInfo;
