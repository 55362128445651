import axios from 'axios';
import { UserInfo } from '../models/userInfo';

export const getCurrentUser = () => {
    return axios.get<UserInfo>('/api/user/CurrentUser').then(res => res.data);
}

export const getUserIpAddress = () => {
    return axios.get<string>('/api/user/ip').then(res => res.data);
}
