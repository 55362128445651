import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import withAdminPageTitle from '../withAdminPageTitle';
import Spinner from '../../common/Spinner';
import { DistributorState } from '../../../reducers/distributor';
import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';
import { selectOrders, selectNewOrders, selectInProcessOrders, selectCompletedOrders } from '../../../store/selectors/orderSelectors';
import { Order, OrderStatus } from '../../../models/order';
import * as orderActions from '../../../actions/orderActions';
import { DesktopOrTablet, Mobile } from '../../common/responsive/ResponsiveHelpers';
import SeneSiteOrdersDesktop from './SeneSiteOrdersDesktop';
import SeneSiteOrdersMobile from './SeneSiteOrdersMobile';
import OrderDetails from './OrderDetails';
import { ActionToastProperties } from '../../../models/ui';
import withRetryComponent, { RetryComponentProps } from '../../common/withRetryComponent';
import { shouldSwithToMaintenance } from "../../../utils/helpers";

export interface SeneSiteOrdersPageStateProps {
    distributor: DistributorState;
    orders: Order[];
    newOrders: Order[];
    inProcessOrders: Order[];
    completedOrders: Order[];
}

export interface SeneSiteOrdersPageDispatchProps {
    getAllOrdersForDistributor(distId: number);
    updateDistributorOrderStatus(distId: number, orderId: number, orderStatus: OrderStatus, toastProps?: ActionToastProperties);
}

export interface SeneSiteOrdersPageState {
    isLoading: boolean;
    activeTab: 'currentorders' | 'inProcessOrders' | 'completedOrders';
    isModalOpen: boolean;
    selectedOrder: Order | null;
}

type SeneSiteOrdersPageProps = SeneSiteOrdersPageStateProps & SeneSiteOrdersPageDispatchProps & RetryComponentProps & WithNamespaces;

class SeneSiteOrdersPage extends React.Component<SeneSiteOrdersPageProps, SeneSiteOrdersPageState> {

    // TODO: could be used if order statuses would match the translations keys
    /*
    private readonly orderStatuses = (Object.keys(OrderStatus)).map(os => {
        return {
            key: camelCase(os),
            value: os
        }
    });
    */

    // TODO: temporary solution until translations keys are fixed
    private readonly orderStatuses = [
        {
            key: 'unKnown',
            value: OrderStatus.Unknown
        },
        {
            key: 'pendingPayment',
            value: OrderStatus.PendingPayment
        },
        {
            key: 'declinedPayment',
            value: OrderStatus.DeclinedPayment
        },
        {
            key: 'payFullPayment',
            value: OrderStatus.PayedFullPayment
        },
        {
            key: 'beingProcessPayment',
            value: OrderStatus.BeingProcessPayment
        },
        {
            key: 'canceledPayment',
            value: OrderStatus.CanceledPayment
        },
        {
            key: 'completePayment',
            value: OrderStatus.CompletePayment
        },
        {
            key: 'onHoldPayment',
            value: OrderStatus.OnHoldPayment
        },
    ];

    state: SeneSiteOrdersPageState = {
        isLoading: false,
        activeTab: 'currentorders',
        isModalOpen: false,
        selectedOrder: null
    }

    componentDidMount() {
        const { distributor } = this.props;
        if (distributor && distributor.dist_ID) {
            this.loadOrdersForDistributor(distributor.dist_ID);
        }
    }

    componentDidUpdate(prevProps: SeneSiteOrdersPageProps) {
        const
            distributor = this.props && this.props.distributor,
            distId = distributor && distributor.dist_ID,
            prevDistributor = prevProps && prevProps.distributor,
            prevDistId = prevDistributor && prevDistributor.dist_ID;
        if (!distributor || !distId || (prevDistributor && prevDistId && (distId === prevDistId))) {
            return;
        }
        this.loadOrdersForDistributor(distId);
    }

    loadOrdersForDistributor = (distId: number) => {
        this.setState({ isLoading: true });
        this.props.getAllOrdersForDistributor(distId)
            .then(response => {

                this.setState({ isLoading: false });
            })
            .catch(err => {
                shouldSwithToMaintenance(err);
                this.props.initFailed();
            });
    }

    changeTab = (tab: 'currentorders' | 'inProcessOrders' | 'completedOrders') => {
        this.setState({ activeTab: tab });
    }

    handleStatusChange = (updatedOrder: Order) => {
        const { distributor } = this.props;
        if (!distributor) {
            return;
        }

        this.props.updateDistributorOrderStatus(
            distributor.dist_ID,
            updatedOrder.orderId,
            updatedOrder.orderStatus,
            { success: this.props.t('orderUpdateSuccess'), error: this.props.t('orderUpdateError') })
            .then(() => {
                this.setState({ isModalOpen: false, selectedOrder: null })
            });
    }

    handleOrderDetailsToggle = (isModalOpen: boolean, order?: Order) => {
        if (order) {
            this.setState({ isModalOpen: isModalOpen, selectedOrder: order });
        } else {
            this.setState({ isModalOpen: isModalOpen, selectedOrder: null });
        }
    }

    render() {
        const { t, distributor, newOrders, inProcessOrders, completedOrders } = this.props;
        const { isLoading, activeTab, isModalOpen, selectedOrder } = this.state;

        if (isLoading || !distributor) {
            return <Spinner />
        } else {
            return (
                <>
                    <DesktopOrTablet>
                        <SeneSiteOrdersDesktop
                            t={t}
                            locale={this.props.i18n.language}
                            distributor={distributor}
                            activeTab={activeTab}
                            changeTab={this.changeTab}
                            newOrders={newOrders}
                            inProcessOrders={inProcessOrders}
                            completedOrders={completedOrders}
                            orderStatuses={this.orderStatuses}
                            handleStatusChange={this.handleStatusChange}
                            handleOrderDetailsToggle={this.handleOrderDetailsToggle}
                        />
                    </DesktopOrTablet>
                    <Mobile>
                        <SeneSiteOrdersMobile
                            t={t}
                            locale={this.props.i18n.language}
                            distributor={distributor}
                            activeTab={activeTab}
                            changeTab={this.changeTab}
                            newOrders={newOrders}
                            inProcessOrders={inProcessOrders}
                            completedOrders={completedOrders}
                            orderStatuses={this.orderStatuses}
                            handleStatusChange={this.handleStatusChange}
                            handleOrderDetailsToggle={this.handleOrderDetailsToggle}
                        />
                    </Mobile>
                    {
                        (selectedOrder && isModalOpen) &&
                        <OrderDetails
                            t={t}
                            isModalOpen={isModalOpen}
                            distributor={distributor}
                            order={selectedOrder}
                            orderStatuses={this.orderStatuses}
                            handleStatusChange={this.handleStatusChange}
                            handleOrderDetailsToggle={this.handleOrderDetailsToggle}
                        />
                    }
                </>
            )
        }
    }

}

const mapStateToProps = state => ({
    distributor: selectSenesiteDistributor(state),
    orders: selectOrders(state),
    newOrders: selectNewOrders(state),
    inProcessOrders: selectInProcessOrders(state),
    completedOrders: selectCompletedOrders(state)
});

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        getAllOrdersForDistributor: orderActions.getAllOrdersForDistributor,
        updateDistributorOrderStatus: orderActions.updateDistributorOrderStatus
    }, dispatch)
);

export default withRetryComponent()(withAdminPageTitle("customersorders")(withNamespaces('SeneSiteOrders')((connect(mapStateToProps, mapDispatchToProps)(SeneSiteOrdersPage)))));
