import * as React from "react";
import { Button } from "reactstrap";
import { Customer } from "../../../models/customer";
import i18n from "../../../i18n";

export interface ICustomerProps {
    customer: Customer;
    onCustomerEditClicked: (customer: Customer) => void;
    t: i18n.TranslationFunction
}


class CustomersMobileLineItem extends React.PureComponent<ICustomerProps> {

    onClick = (customer: Customer) => {
        this.props.onCustomerEditClicked(customer);
    }

    render() {
        const {t} = this.props;
        const row = this.props.customer;

        return (
            <div className="form-row inventory-content-row-border  customer-mobile-row col-12">
                <div className="col-12"><h6>{row.firstName} {row.lastName}</h6></div>
                <div className="col-12">
                    <p>
                        <a href={"mailto:" + row.email}>
                            <span className="fa fa-envelope" />
                            <span>{row.email}</span>
                        </a>
                    </p>
                </div>
                <div className="col-12" style={{ paddingTop: "5px" }}>
                    <p style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start"
                    }}>
                        <a href={"tel:" + row.phone}>
                            <span className="fa fa-phone" />
                        </a>
                        <span>{row.phone}</span>
                    </p>
                </div>
                <div className="col-12 customer-edit-button">
                    <Button color="primary" onClick={() => this.onClick(row)}>
                        <i className="fa pencil" />{t('edit')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default CustomersMobileLineItem;
