import { AdminActionTypes } from './../actions/administration/adminActions';

const initialState = {
    pageTitle: ''
}
export type AdminState = typeof initialState;

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AdminActionTypes.setAdminPageTitle:
            return {
                ...state,
                pageTitle: action.title
            }
        default:
            return state;
    }
};
