import React from "react";

import SeneButton from '../../../common/inputs/SeneButton';
import { ProductCardProps } from "./ProductCard";
import SeneSiteLink from "../../../common/SeneSiteLink";
import ImageComponent from "../../../common/ImageComponent";


class ProductCardMobile extends React.PureComponent<ProductCardProps> {
	addToCart = () => {
		this.props.addToCart(this.props.product.productId, this.props.product.sku);
	}

	get productLink(): string {
		const prod = this.props.product;
		const productName = encodeURI(prod.name.replace('/', ''));
		return `/product/${prod.productId}/${productName}`;
	}

	render() {
		if (!this.props.product) {
			return null;
		}

		const prod = this.props.product;
		const { t } = this.props;
		const productImageUrl = prod.imageUrl ? `products/ProductImages/` + prod.imageUrl : `products/ProductImages/${prod.sku}.tif`;

		return (
			<div className='row product--mobile'>
				<div className="col-5 text-center">
					<SeneSiteLink to={this.productLink}>
						<ImageComponent
							src={productImageUrl}
							height={200}
							className='product-img'
							alt={prod.sku}
							fallback="/image_placeholder.png"
						/>
					</SeneSiteLink>
				</div>
				<div className="col-7 product-desc">
					<SeneSiteLink to={this.productLink}>
						<h4 className="product-desc__name">{prod.name}</h4>
					</SeneSiteLink>
					<p className="product-desc__price">{prod.displayPrice}</p>
					<SeneButton
						className="product__add-to-bag"
						onClick={this.addToCart}
						icon="sene-shopping-bag"
					>
						{t('addToBag')}
					</SeneButton>
				</div>
			</div>
		);
	}
}

export default ProductCardMobile;
