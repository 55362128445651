import moment from 'moment';
import { State } from '../index';
import { SquarePaymentMethod, Distributor, SeneSiteInfo, DistributorAddress, DistributorProfile } from '../../models/distributor';

export const selectSenesiteDistributor = (state: State) => {

    const dist = state.distributor;
    let blogUrl;
    let seneSiteLooks;
    let socialInfo;
    let cdoInfo;
    let newAddress: DistributorAddress | null = null;

    if (dist && !dist.cdoInfo) {
        cdoInfo = {isCdoOn: false};
    }

    if (dist && dist.seneSiteInfo) {
        if (dist.seneSiteInfo.blogUrl === null || dist.seneSiteInfo.blogUrl === undefined ) {
            blogUrl = '';
        }
        if (dist.seneSiteInfo.seneSiteLooks === null) {
            seneSiteLooks = { customContents: [], banners: [], seneSiteDistrbutorImage: '' }
        }
        if (dist.seneSiteInfo.socialInfo === null) {
            socialInfo = { facebookAccount: '', instagramAccount: '', twitterAccount: '', youTubeAccount: '', useMyTwitter: false, useMyInstagram: false, useMyYouTube: false, instagramToken: '', instagramUserId: '', youTubeVideo: '' };
        }
    }

    if(dist && dist.profile && dist.profile.addresses && dist.profile.addresses.length > 0) {
        const seneSiteAddress = dist.profile.addresses.filter(t => t.isSeneSiteAddress);
        if(seneSiteAddress.length == 0) {
            newAddress = {
                isSeneSiteAddress: true,
                isMailingAddress: false,
                isShippingAddress: false,
                isOtherShippingAddress: false,
                address1: dist.profile.addresses[0].address1,
                address2: dist.profile.addresses[0].address2,
                address3: dist.profile.addresses[0].address3,
                stateName: dist.profile.addresses[0].stateName,
                city: dist.profile.addresses[0].city,
                zip: dist.profile.addresses[0].zip,
                stateId: dist.profile.addresses[0].stateId
            }
        }
    }

    return state.distributor && {
        ...state.distributor,
        profile: {
            ...state.distributor.profile,
            addresses: newAddress != null ? [...state.distributor.profile.addresses, newAddress] : state.distributor.profile.addresses
        } as DistributorProfile,
        cdoInfo: state.distributor.cdoInfo || cdoInfo,
        seneSiteInfo: state.distributor.seneSiteInfo && {
            ...state.distributor.seneSiteInfo,
            blogUrl: state.distributor.seneSiteInfo.blogUrl || blogUrl,
            seneSiteLooks: state.distributor.seneSiteInfo.seneSiteLooks || seneSiteLooks,
            socialInfo: state.distributor.seneSiteInfo.socialInfo || socialInfo
        } as SeneSiteInfo
    } as Distributor
}
export const selectDistributorName = (state: State) => (state.distributor
    && state.distributor.profile
    && `${state.distributor.profile.firstName} ${state.distributor.profile.lastName}`) || '';
export const selectDistributorId = (state: State) => state.distributor && state.distributor.dist_ID || 0;
export const selectSeneSiteUrl = (state: State) => state.distributor && state.distributor.seneSiteInfo && state.distributor.seneSiteInfo.seneSiteUrl || null;
export const selectDistributorCountry = (state: State) => state.distributor && state.distributor.countryName || 'US';
export const selectBanners = (state: State) => state.distributor && state.distributor.seneSiteInfo && state.distributor.seneSiteInfo.seneSiteLooks && state.distributor.seneSiteInfo.seneSiteLooks.banners || [];
export const selectCustomContents = (state: State) => state.distributor && state.distributor.seneSiteInfo && state.distributor.seneSiteInfo.seneSiteLooks && state.distributor.seneSiteInfo.seneSiteLooks.customContents || [];
export const selectShippingMethods = (state: State) => {
    return state.distributor
        && state.distributor.seneSiteInfo
        && state.distributor.seneSiteInfo.shippingInfo
        || [];
};
export const selectPaymentMethods = (state: State) => {
    return state.distributor
        && state.distributor.paymentInfo
        && state.distributor.paymentInfo.paymentMethods
        || [];
};
export const selectDistributorPaymentInfo = (state: State) => {
    return state.distributor
        && state.distributor.paymentInfo.paymentMethods
        || [];
};
export const selectUpcomingEvents = (state: State) => {
    const distributor = selectSenesiteDistributor(state);
    if (!distributor || !distributor.seneSiteInfo || !distributor.seneSiteInfo.calendarEvents) {
        return [];
    }

    const now = moment(new Date());
    const events = distributor.seneSiteInfo.calendarEvents.sort((a, b) => {
        if (a.startDate < b.startDate) {
            return -1;
        }
        if (a.startDate > b.startDate) {
            return 1;
        }
        return 0;
    });

    return events.filter(e => moment(e.endDate) >= now).slice(0, 4);
};
export const selectHandlingRate = (state: State) => {
    return state.distributor
        && state.distributor.seneSiteInfo
        && state.distributor.seneSiteInfo.handlingInfo
        && state.distributor.seneSiteInfo.handlingInfo.rate
        || 0;
}
export const selectDistributorEvents = (state: State) => {
    const distributor = selectSenesiteDistributor(state);
    if (!distributor || !distributor.seneSiteInfo || !distributor.seneSiteInfo.calendarEvents) {
        return [];
    }
    return distributor.seneSiteInfo.calendarEvents;
};

export const selectSquareConfig = (state: State) => {
    const paymentMethods = selectPaymentMethods(state);
    const squarePaymentMethod = paymentMethods && paymentMethods.find(pm => pm.paymentMethodType === 'Square');
    if (!squarePaymentMethod) {
        return;
    }

    return {
        applicationId: (squarePaymentMethod as SquarePaymentMethod).applicationId,
        locationId: (squarePaymentMethod as SquarePaymentMethod).locationId
    }
}
