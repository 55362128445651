import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';

import * as catalogActions from '../../../actions/catalogActions';
import * as cartActions from '../../../actions/cartActions';
import * as uiActions from '../../../actions/ui';
import { selectCategories, selectCategory, selectProductsForCategorySorted, selectAllProductsSorted } from '../../../store/selectors/catalogSelectors';
import { selectLoading, selectProductSort } from '../../../store/selectors/uiSelectors';
import { Category } from '../../../models/category';
import CategoryMenu from './categories/CategoryMenu';
import { ProductVm } from '../../../models/product';
import { isSameCategory, MaybeCategoryOrSubcategory } from '../../../utils/types';
import ShopProducts from './products/ShopProducts';
import ShopSearchResults from './products/ShopSearchResults';
import ShopCategories from './categories/ShopCategories';
import { ActionToastProperties } from '../../../models/ui';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { selectSelectedLanguageCode } from '../../../store/selectors/languageSelectors';

interface ShopNowStateProps {
    categories: Category[];
    selectedCategory: MaybeCategoryOrSubcategory;
    products: ProductVm[];
    productsInCategory: ProductVm[];
    isLoading: boolean;
    sort: { sortBy: string, direction: string };
    locale: string;
}

interface ShopNowState {
    isCategoryQueried: boolean;
}

interface ShopNowDispatchProps {
    setSort(sortBy: string, direction: string);
    getCategories();
    getProductsForCategory(categoryId: number, subCategoryId?: number);
    getAllProducts(availableOnly: boolean);
    addToCart(productId: number, sku: string, quantity?: number, toastProps?: ActionToastProperties);
}
type ShopNowProps = ShopNowStateProps & ShopNowDispatchProps & RouteComponentProps & WithNamespaces;

class ShopNowPage extends React.Component<ShopNowProps, ShopNowState> {

    state: ShopNowState = {
        isCategoryQueried: false
    };

    componentDidMount() {
        this.props.getCategories().then(() => {
            this.setState({
                isCategoryQueried: true
            });
            this.handleCategoryChange();
        });
    }

    componentDidUpdate(prevProps: ShopNowProps) {
        this.handleCategoryChange(prevProps.selectedCategory);
    }

    addToCart = (productId: number, sku: string) => {
        this.props.addToCart(productId, sku, 1, { success: this.props.t('addToCartSuccess') });
    }

    handleCategoryChange(prevCategory: MaybeCategoryOrSubcategory = null) {
        if (this.props.selectedCategory && !isSameCategory(prevCategory, this.props.selectedCategory)) {
            this.props.getProductsForCategory(this.props.selectedCategory.categoryId, this.props.selectedCategory["id"]);
        }
    }

    renderResults() {
        const categoryId = this.props.match.params['categoryId'];
        const qs = queryString.parse(this.props.location.search);
        const query = Array.isArray(qs.q) ? qs.q[0] : qs.q || '';
        const { t, locale } = this.props;

        if (categoryId) {
            return <ShopProducts {...this.props} addToCart={this.addToCart} />
        } else if (qs.q) {
            return <ShopSearchResults t={t} {...this.props} query={query} getAllProducts={this.props.getAllProducts} addToCart={this.addToCart} />
        } else {
            return <ShopCategories locale={locale} t={t} categories={this.props.categories} isCategoryQueried={this.state.isCategoryQueried} />
        }
    }

    render() {
        const { t, locale } = this.props;
        return (
            <div className="row">
                <div className="col-md-3">
                    <CategoryMenu locale={locale} t={t} categories={this.props.categories} currentCategory={this.props.selectedCategory} />
                </div>
                <div style={{ position: 'relative' }} className="col-md-9">
                    {this.renderResults()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps: ShopNowProps): ShopNowStateProps => ({
    categories: selectCategories(state),
    selectedCategory: selectCategory(state, +ownProps.match.params['categoryId'], +ownProps.match.params['subCategoryId']),
    products: selectAllProductsSorted(state),
    productsInCategory: selectProductsForCategorySorted(state, +ownProps.match.params['categoryId'], +ownProps.match.params['subCategoryId']),
    isLoading: selectLoading(state),
    sort: selectProductSort(state),
    locale: selectSelectedLanguageCode(state)
});

const mapDispatchToProps = dispatch => (bindActionCreators({
    getCategories: catalogActions.getCategories,
    getProductsForCategory: catalogActions.getProductsForCategory,
    setSort: uiActions.setProductSort,
    addToCart: cartActions.addProductToCart,
    getAllProducts: catalogActions.getAllProducts
}, dispatch));

export default (withNamespaces('ShopNow')(connect(mapStateToProps, mapDispatchToProps)(ShopNowPage)));
