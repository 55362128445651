import {CountryLanguage, Languages} from '../models/language';
import { LanguageActionTypes } from "../actions/languageActions";

export type LanguageState = Languages;
const initialState : LanguageState = {
  availableLanguages: [],
  selectedLanguage: null  
};
    
export const reducer = (state: LanguageState = initialState, action) => {
    switch (action.type) {
        case LanguageActionTypes.loadLanguagesSuccess:
            return {
                ...state,
                availableLanguages: action.countryLanguages as CountryLanguage[]
            };
        case LanguageActionTypes.selectLanguage:
            return {
                ...state,
                selectedLanguage : action.selectedLanguage as CountryLanguage
            };
        default:
            return state;
    }
}