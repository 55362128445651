import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

export const initGoogleAnalytics = (trackingId: string, history: History | any) => {
    ReactGA.initialize(trackingId);

    history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
      });
}

