import HomePage from './home/HomePage';
import NewAccountPage from './customer-signup/NewAccountPage';
import AccountCreatedPage from './customer-signup/AccountCreatedPage';
import CustomerProfilePage from './customer-profile/CustomerProfilePage';
import ShopNowPage from './shop-now/ShopNowPage';
import CartPage from './cart/CartPage';
import CheckoutPage from './checkout/CheckoutPage';
import OrderConfirmationPage from './checkout/OrderConfirmationPage';
import ProductDetailsPage from './product-details/ProductDetailsPage';
import EventCalendarPage from './event-calendar/EventCalendarPage';
import SenePrivacyPolicyPage from './footer/SenePrivacyPolicyPage';
import SeneCopyrightPolicyPage from './footer/SeneCopyrightPolicyPage';
import SeneTermsOfUsePage from './footer/SeneTermsOfUsePage';
import SeneContactPage from './contact/SeneContactPage';
import SeneAboutMePage from './aboutme/SeneAboutMePage';
import SeneSellPage from './sell/SeneSellPage';
import withCheckoutState from "../common/guards/withCheckoutState";
import PlaceOrderRedirectPage from './checkout/PlaceOrderRedirectPage';

export const seneSiteRoutes: any[] = [
    {
        path: '/:seneSiteUrl',
        component: HomePage,
        exact: true
    },
    {
        path:'/:seneSiteUrl/newaccount',
        component: NewAccountPage,
        exact: true
    },
    {
        path:'/:seneSiteUrl/account-created',
        component: AccountCreatedPage,
        exact: true
    },
    {
        path:'/:seneSiteUrl/customer-profile',
        component: CustomerProfilePage
    },
    {
        path: '/:seneSiteUrl/cart',
        component: CartPage,
        exact: true
    },
    {
        path: '/:seneSiteUrl/checkout',
        component: withCheckoutState(CheckoutPage),
        exact: true
    },
    {
        path: '/:seneSiteUrl/checkout/payPalPaymentSuccess',
        component: PlaceOrderRedirectPage,
        exact: true
    },
    {
        path: '/:seneSiteUrl/orderconfirmation',
        component: OrderConfirmationPage,
        exact: true
    },
    {
        path: '/:seneSiteUrl/shopproducts/:categoryId?/:subCategoryId?',
        component: ShopNowPage,
    },
    {
        path: '/:seneSiteUrl/product/:productId/:productName',
        component: ProductDetailsPage,
        exact: true
    },
    {
        path: '/:seneSiteUrl/Calendar/EventCalendar/:selectedDate/:viewMode',
        component: EventCalendarPage
    },
    {
        path: '/:seneSiteUrl/privacy-policy',
        component: SenePrivacyPolicyPage,
    },
    {
        path: '/:seneSiteUrl/copyright-policy',
        component: SeneCopyrightPolicyPage,
    },
    {
        path: '/:seneSiteUrl/terms-of-use',
        component: SeneTermsOfUsePage,
    },
    {
        path: '/:seneSiteUrl/sell',
        component: SeneSellPage,
    },
    {
        path: '/:seneSiteUrl/aboutme',
        component: SeneAboutMePage,
    },
    {
        path: '/:seneSiteUrl/contact',
        component: SeneContactPage,
    }
]
