import { createUserManager } from 'redux-oidc';
import { UserManagerSettings } from 'oidc-client';
import { getCurrentUserInfo } from '../../actions/userActions';
import { UserInfo } from "../../models/userInfo";

export let UserManager;

export const instantiateUserManager = (config: Partial<UserManagerSettings>, dispatch, language: string) => {
    const RedirectAfterUserLogIn = (user: UserInfo) => {
        if (user.assignedDistributor) {
            const assignedDistributorSenesiteUrl = user.assignedDistributor.seneSiteInfo.seneSiteUrl;
            if (location.pathname.indexOf(assignedDistributorSenesiteUrl) < 0) {
                location.href = assignedDistributorSenesiteUrl;
            }
        }
    }

    UserManager = createUserManager(config);
    UserManager.events.addAccessTokenExpired(() => {
        UserManager.signoutRedirect({ui_locales: language.toLowerCase()});
    });
    UserManager.events.addUserLoaded(() => {
        dispatch(getCurrentUserInfo()).then(x => RedirectAfterUserLogIn(x.user))
    });

    return UserManager;
}
