import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";

import { seneSiteNavigateTo } from '../../actions/router';

interface SearchProps {
    placeHolder: string;
    navigate(to: string);
    size?: 'small' | 'large';
}

interface SearchState {
    searchTerm: string;
}

class SearchProducts extends React.PureComponent<SearchProps, SearchState> {
    state: SearchState = {
        searchTerm: ''
    }

    get sizeClass() {
        return this.props.size
            ? (this.props.size === 'small' ? 'input-group-sm' : 'input-group-lg')
            : '';
    }

    handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchTerm: e.target.value });
    };

    navigateToSearchResults = () => {
        const trimmedSearch = this.state.searchTerm.trim();

        if (!trimmedSearch) {
            return;
        }

        this.props.navigate(`/shopproducts?q=${encodeURIComponent(trimmedSearch)}`);
    }

    navigateOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            this.navigateToSearchResults();
        }
    }

    render() {
        return (
            <div className={`input-group ${this.sizeClass}`}>
                <input
                    className="form-control product-search-input"
                    type="text"
                    placeholder={this.props.placeHolder}
                    value={this.state.searchTerm}
                    onChange={this.handleSearchTermChange}
                    onKeyDown={this.navigateOnEnter}
                />
                <div className="input-group-append" >
                    <span className="input-group-text" style={{cursor: 'pointer'}} onClick={this.navigateToSearchResults}>
                        <i className="fa fa-search" />
                    </span>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        navigate: seneSiteNavigateTo
    }, dispatch)
}
export default connect(null, mapDispatchToProps)(SearchProducts);
