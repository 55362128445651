import AppLayout from './components/public/AppLayout';
import CallbackPage from './components/public/CallbackPage';
import AdministrationLayout from './components/administration/AdministrationLayout';
import withAdministrationGuard from './components/common/guards/withAdministrationGuard';
import LegacyBackofficeAdminLanding from "./components/administration/LegacyBackofficeAdminLanding";


export const mainRoutes: any[] = [
    {
        path: '/loggedout',
        component: CallbackPage
    },
    {
        path: '/callback',
        component: CallbackPage
    },
    {
        path: '/administration',
        component: withAdministrationGuard(AdministrationLayout)
    },
    {
        path: '/*/Administration/*',
        component: LegacyBackofficeAdminLanding
    },
    {
        path: '**',
        component: AppLayout
    }
]
