import axios from 'axios';

import { BlogPost, BlogFeedPreview } from './../models/blog';

export const getDistributorBlogFeed = (distId: number) => {
    return axios.get<BlogPost[]>(`/api/Distributor/${distId}/Social/BlogFeed`).then(res => res.data);
}

export const getBlogFeedPreview = (distId: number, feedUrl: string) => {
    return axios.post<BlogFeedPreview>(`/api/Distributor/${distId}/Social/BlogFeed`, 
        `"${feedUrl}"`,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
         ).then(res => res.data);
}