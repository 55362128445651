import { push, replace } from 'connected-react-router';
import { getCallbackUrl, setCallbackUrl } from '../utils/auth/CallbackStorage';
import { selectSeneSiteUrl } from './../store/selectors/distributorSelectors';

export enum AuthActionTypes {
    notAuthorized = '[Auth] Not authorized',
}

export const notAuthorized = () => (dispatch, getState) => {
    const state = getState();
    const seneSiteUrl = selectSeneSiteUrl(state);
    dispatch({ type: AuthActionTypes.notAuthorized });
    if (seneSiteUrl !== null) {
        const redirectUrl = `/${seneSiteUrl}` || '/';
        setCallbackUrl(redirectUrl);
        dispatch(push(redirectUrl));
    }
}

