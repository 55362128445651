import { CreditCardInfo, CustomerInformation, ShoppingCart } from './checkout';
import { CashOrCheckPaymentMethod, CreditCardPaymentMethod, PublicPaymentMethod as PaymentMethod, PayPalApiPaymentMethod, SquarePaymentMethod } from "./distributor";

export interface PaymentRequest {
    signalRConnectionId: string;
    shoppingCart: ShoppingCart;
    payments: Payment[];
    customerInformation: CustomerInformation;
    customerNotes: string;
    locale: string;
}

export interface PaymentResponse {
    status: string;
    message: string;
    results: PaymentTypeResult[];
    unavailableItems: OrderMissingItem[];
    orderId: number;
}

export interface OrderMissingItem {
    sku: string;
    productName: string;
}

export interface Payment {
    paymentType: PaymentType;
    amount: number;
}

export interface PaymentTypeResult {
    paymentType: string;
    transactionStatus: string;
    creditCardTypeName: string;
    paymentProviderServiceResponseString: string;
    maskedCreditCardNumber: string;
    errorString: string;
    systemError: boolean;
    message: string;
    uniqueIdentifier: string;
    redirectUrl: string;
    isRedirectUrl: boolean;
    authCode: string;
    avsCode: string;
    merchantInvoiceNumber: string;
    transactionAmount: number;
    cvvCode: string;
    cvvCodeDescription: string;
    grandTotal: number;
    avsCodeDescription: string;
    responseCode: string;
    responseCodeDescription: string;
    refId: string;
    transactionId: string;
}


export type CreditCardPayment = Payment & CreditCardInfo;

export interface CashOrCheckPayment extends Payment {
    cashOrCheckDistrbutorInstructions: string;
}

export interface PayPalPayment extends Payment {
    payPalEmail: string;
}

export interface EcheckPayment extends Payment {
    accountName: string;
    bankName: string;
    accountNumber: string;
    routingCode: string;
    accountType: BankAccountType;
}

export enum BankAccountType {
    CHECKING,
    BUSINESSCHECKING,
    SAVINGS,
    UNKNOWN
}

export enum PaymentType {
    CashOrCheck = 'CashOrCheck',
    CreditCard = 'CreditCard',
    Square = 'Square',
    PayPal = 'PayPal',
    ECheck = 'ECheck',
    PayPalApi = 'PayPalApi',
    Unknown = 'Unknown',
}


export interface AvailablePaymentOptionsState {
    country: string;
    paymentOptionList: AdminPaymentOption[];
}

export interface AdminPaymentOption extends PaymentMethod {
    selected: boolean;
}

export interface CashOrCheckPaymentOption extends CashOrCheckPaymentMethod, AdminPaymentOption {
}

export interface SquarePaymentOption extends SquarePaymentMethod, AdminPaymentOption {
    location: SquareLocation;
    availableLocations: SquareLocation[];
}

export interface PayPalApiPaymentOption extends PayPalApiPaymentMethod, AdminPaymentOption {
    clientId: string;
    clientSecret: string;
}

export interface CreditCardPaymentOption extends CreditCardPaymentMethod, AdminPaymentOption {
    availableMerchantAccounts: MerchantAccount[];
    availableCreditCards: CreditCard[];
    selectedMerchant: MerchantAccount;
}

export interface MerchantAccount {
    id: number;
    name: string;
    fields: { [key: string]: MerchantAccountField }
}

export interface MerchantAccountField {
    label: string;
    value: string;
}

export interface CreditCard {
    id: number;
    name: string;
}

export interface CreditCardTypeConfiguration extends CreditCard {
    imageUrl: string;
    regex: RegExp;
}

export interface SquareLocation {
    id: string;
    name: string;
    capabilities: string[];
    country: string;
    currency: string;
}