import React from 'react';
import { BlogPost as IBlogPost } from '../../../models/blog';
import './BlogPost.scss'


export const BlogPost = ({ post, translation = { readMore: 'Read More' } }: { post: IBlogPost, translation?: any }) => (
    <div className="card blog-card">
        <div className="blog-card__image" style={{ backgroundImage: `url(${post.imageUrl})` }} />
        <div className="card-body">
            <h4 className="card-title">{post.title}</h4>
            <div className="card-text">{post.description}</div>
        </div>
        <div className="card-footer">
            <a className="card-footer__link" href={post.link} target="_blank">
                <h5>{translation.readMore}</h5>
            </a>
        </div>
    </div>
);

export default BlogPost;
