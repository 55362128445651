import React from 'react';
import i18next from 'i18next';
import memoize from 'memoize-one';
import { chunk } from 'lodash';
import { CarouselItem } from 'reactstrap';

import './CrossSellingProducts.scss';
import SectionHeader from '../../common/headers/SectionHeader';
import { ProductVm } from '../../../models/product';
import SeneCarouselBase from '../../common/SeneCarouselBase';
import ProductCard from '../shop-now/products/ProductCard';
import { DesktopOrTablet, Mobile } from '../../common/responsive/ResponsiveHelpers';

interface CrossSellingProductsProps {
    products?: ProductVm[];
    t: i18next.TranslationFunction;
    addToCart(productId: number, sku: string, quantity: number);
}

class CrossSellingProducts extends React.PureComponent<CrossSellingProductsProps> {
    private readonly desktopProductCount = 3;
    private readonly mobileProductCount = 1;

    getCarouselItems = memoize((products: ProductVm[], productCount: number) => {
        return chunk(products, productCount);
    })

    addProductToCart = (productId: number, sku: string) => this.props.addToCart(productId, sku, 1);

    renderItems = ({ items, onExited, onExiting }) => {
        const {t} = this.props;
        return items.map((page: ProductVm[], i) => {
            return (
                <CarouselItem
                    key={i}
                    onExited={onExited}
                    onExiting={onExiting}
                >
                    {
                        page.map((product, j) => (
                            <ProductCard
                                product={product}
                                key={product.productId}
                                addToCart={this.addProductToCart}
                                t={t}
                            />
                        ))
                    }

                </CarouselItem>
            )
        })
    }

    render() {
        const { t, products } = this.props;

        if (!products || !products.length) {
            return null;
        }

        return (
            <>
                <SectionHeader>
                    {t('youMayLike')}
                </SectionHeader>
                <DesktopOrTablet>
                    <SeneCarouselBase
                        className="cross-selling-products__carousel"
                        items={this.getCarouselItems(products, this.desktopProductCount)}
                        indicators={false}
                        renderItems={this.renderItems}
                        t={t}
                    />
                </DesktopOrTablet>
                <Mobile>
                    <SeneCarouselBase
                        className="cross-selling-products__carousel"
                        items={this.getCarouselItems(products, this.mobileProductCount)}
                        indicators={false}
                        renderItems={this.renderItems}
                        t={t}
                    />
                </Mobile>
            </>
        )
    }
}

export default CrossSellingProducts;
