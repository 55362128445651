import { Writeable } from './../utils/helpers';
import { Thunk } from './../store/index';
import { startSignalR } from '../utils/signalR/SignalRHelper';
import { HubConnection } from '@aspnet/signalr';

export enum SignalRActionTypes {
    connectionStarted = '[SignalR] Connection started'
}

export const connectionStarted = (connectionId): Thunk => (dispatch, getState) => {
    return dispatch({ type: SignalRActionTypes.connectionStarted, connectionId});
}
