import { Thunk } from '../../store/index';
import * as applicationFormApi from '../../api/applicationFormApi';
import { setLoading, setBlockUi } from '../ui';
import { ActionToastProperties } from '../../models/ui';
import * as toast from './../../utils/toastHelper';
import { selectSignalRConnectionId } from '../../store/selectors/signalrSelectors';
import { SeneSiteApplicationFormModel } from '../../components/administration/application/SeneSiteApplicationForm';
import { selectDistributorId, selectSeneSiteUrl } from '../../store/selectors/distributorSelectors';
import { SubmitSeneSiteApplicationFormData } from '../../models/applicationForm';
import { seneSiteNavigateTo } from '../router';
import * as loggingApi from '../../api/loggingApi';
import { LogSeverityLevels } from '../../models/logging';
import { sendLog, sendSubmitApplicationFormErrorLog, sendSubmitURLChangeFormErrorLog } from '../../actions/loggingActions';
import { shouldSwithToMaintenance } from "../../utils/helpers";

export enum ApplicationActionTypes {
    verifySeneSiteName = '[ApplicationForm] Verify SeneSite name',
    submitSeneSiteApplicationFormSuccess = '[ApplicationForm] Submit SeneSite Application Form Success',
    submitSeneSiteApplicationFormError = '[ApplicationForm] Submit SeneSite Application Form Error'
}

export const verifySeneSiteName = (seneSiteName: string): Thunk => (dispatch, getState) => {
    const state = getState();
    if (!state.distributor || !state.distributor.dist_ID) {
        return;
    }

    dispatch(setLoading(true));
    return applicationFormApi.verifySeneSiteName(state.distributor.dist_ID, seneSiteName)
        .then(res => {
            dispatch(setLoading(false));
            return res;
        })
        .catch(err => {
            shouldSwithToMaintenance(err);
            dispatch(setLoading(false));
            throw err;
        });
}

export const submitSeneSiteApplicationForm = (model: SeneSiteApplicationFormModel, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    const state = getState();
    const signalRConnectionId = selectSignalRConnectionId(state);
    const distributorId = selectDistributorId(state);
    
    const request: SubmitSeneSiteApplicationFormData = {
        signalRConnectionId: signalRConnectionId,
        distributorId,
        applicationFormModel: model
    }

    if (!distributorId || !signalRConnectionId) {
        sendLog({
            'message': `There is an error sending the application form`,
            'severity': LogSeverityLevels.Error,
            'data': JSON.stringify({distributorId, signalRConnectionId})
        });
        return;
    }

    dispatch(setBlockUi(true));
    return applicationFormApi.submitSeneSiteApplicationForm(request)
        .then(res => {
            toast.success(toastProps);
            return res;
        })
        .catch(err => {
            shouldSwithToMaintenance(err);
            dispatch(setBlockUi(false));
            toast.error(toastProps);
            throw err;
        });
}

export const submitSeneSiteUrlForm = (model: SeneSiteApplicationFormModel, toastProps?: ActionToastProperties): Thunk => (dispatch, getState) => {
    const state = getState();
    const signalRConnectionId = selectSignalRConnectionId(state);
    const distributorId = selectDistributorId(state);
    
    const request: SubmitSeneSiteApplicationFormData = {
        signalRConnectionId: signalRConnectionId,
        distributorId,
        applicationFormModel: model
    }

    if (!distributorId || !signalRConnectionId) {
        sendLog({
            'message': `There is an error sending the SeneSite Url form.`,
            'severity': LogSeverityLevels.Error,
            'data': JSON.stringify({distributorId, signalRConnectionId})
        });
        return;
    }

    dispatch(setBlockUi(true));
    return applicationFormApi.submitSeneSiteChangeUrlForm(request)
        .then(res => {
            toast.success(toastProps);
            return res;
        })
        .catch(err => {
            shouldSwithToMaintenance(err);
            dispatch(setBlockUi(false));
            toast.error(toastProps);
            throw err;
        });
}

export const submitApplicationFormAccepted = (): Thunk => (dispatch, getState) => {
    dispatch(setBlockUi(false));
    // Do a location change to trigger initializing the whole app
    window.location.href = '/administration/Dashboard';
}

export const submitApplicationFormError = (errors: any[]): Thunk => (dispatch, getState) => {
    sendSubmitApplicationFormErrorLog(errors, getState());
    dispatch(setBlockUi(false));
    return dispatch({ type: ApplicationActionTypes.submitSeneSiteApplicationFormError, errors });
}

export const submitURLChangeFormAccepted = (): Thunk => (dispatch, getState) => {
    const state = getState();
    const seneSiteUrl: string = selectSeneSiteUrl(state);
    window.location.href = seneSiteUrl;
}

export const submitURLChangeFormError = (errors: any[]): Thunk => (dispatch, getState) => {
    sendSubmitURLChangeFormErrorLog(errors, getState());
    dispatch(setBlockUi(false));
    return dispatch({ type: ApplicationActionTypes.submitSeneSiteApplicationFormError, errors });
}