import React from 'react';

export interface IOption {
    value: string;
    text: string;
};

export interface ISelectParams {
    name: string;
    label: string;
    onChange?(e: React.ChangeEvent<HTMLSelectElement>): void;
    onBlur?(e: any): void;
    placeholder?: string;
    value?: string;
    error?: string;
    id?: string;
    key?: string;
    tabIndex?: number;
    autocomplete?: string;
    options: IOption[];
    defaultOptionText?: string;
    disabled?: boolean;
};

export const SelectInput = (params: ISelectParams) => {

    return (
        <div className="form-group">
            <label className="font-weight-bold" htmlFor={params.id || ''}>{params.label}</label>
            <select
                className="form-control"
                id={params.id || ''}
                name={params.name}
                onChange={params.onChange}
                onBlur={params.onBlur}
                tabIndex={params.tabIndex}
                value={params.value || ''}
                disabled={params.disabled}
            >
                <option value="" disabled>{params.defaultOptionText || '-- Select One --'}</option>
                {
                    params.options
                    && params.options.map((item, i) => (
                        <option
                            value={item.value}
                            key={i}
                        >
                            {item.text}
                        </option>
                    ))
                }
            </select>
            {params.error && <div className="alert alert-danger">{params.error}</div>}
        </div>
    );
}

export default SelectInput;
