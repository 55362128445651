import { CartState } from './cart';
import { CheckoutActionTypes } from './../actions/checkoutActions';
import { CheckoutState } from './checkout';

export type PreviousOrderState = Required<CheckoutState> & {
    orderNumber: number;
    cart: CartState;
    handlingRate: number;
} | null;

export const reducer = (state: PreviousOrderState = null, action) => {
    switch (action.type) {
        case CheckoutActionTypes.orderSuccess: {
            return {
                ...action.checkoutState,
                handlingRate: action.handlingRate,
                cart: action.cart,
                orderNumber: action.orderNumber,
            } as PreviousOrderState;
        }
        default:
            return state;
    }
}
