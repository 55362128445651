import React from 'react';

import Button from '../../common/inputs/SeneButton';
import { ShippingInfo } from '../../../models/distributor';
import TextInput from '../../common/inputs/SeneTextInput';
import Collapse from 'reactstrap/lib/Collapse';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { ShippingFeeValidator } from './SeneFeesValidator';
import { ValidationErrors } from '../../../utils/validation/validator';

interface SeneShippingDetailsStateProps {
    t: any;
    shipping: ShippingInfo;
    id: number;
    updateShipping(shippingInfo: ShippingInfo, id: number);
    deleteShipping(id: number);
}

interface SeneShippingDetailsState {
    collapsed: boolean;
    isDeleteModalOpen: boolean;
    shipping: ShippingInfo;
    errors: ValidationErrors<ShippingDetailErrors>
}

export interface ShippingDetailErrors {
	carrierName: string;
    rate: string;
}

export class SeneShippingDetails extends React.Component<SeneShippingDetailsStateProps, SeneShippingDetailsState> {

    state: SeneShippingDetailsState = {
        collapsed: true,
        isDeleteModalOpen: false,
        shipping: {} as ShippingInfo,
        errors: {}
    }


    componentDidMount() {
        this.setState({
            shipping: this.props.shipping
        })
    }

    componentDidUpdate(prevProps: SeneShippingDetailsStateProps) {
        if(prevProps.shipping !== this.props.shipping && this.props.shipping) {
            this.setState({
                shipping: this.props.shipping
            });
        }
    };

    updateShippingInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            shipping: {
                ...this.state.shipping,
                [name]: value
            }
        })
    }

    submit = () => {
        if(this.isValidated()) {
            this.props.updateShipping(this.state.shipping, this.props.id);
        }
    }

    isValidated = () => {

        const shippingFeeValidator = new ShippingFeeValidator(this.props.t);
        const shippingFeeResult = shippingFeeValidator.validate(this.state.shipping);

        const errors = {
            ...shippingFeeResult.errors,
        };

        this.setState({
            errors: errors
        });
        return Object.keys(errors).length === 0;
    }

    
    closeModal = () => {
        this.toggleModal();
    }

    deleteShippingInfo = () => {
        this.props.deleteShipping(this.props.id);
        this.toggleModal();
    }

    toggleModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        })
    }

    displayDeleteShippingModal = () => {
        const { t } = this.props;
        return <Modal isOpen={this.state.isDeleteModalOpen}>
            <ModalHeader>
                {t('deleteShippingHeader')}
                
            </ModalHeader>
            <ModalBody>
                <span>{t('deleteShippingConfirm')}</span>
            </ModalBody>
            <ModalFooter>
                <Button className='btn btn-secondary'
                    onClick={this.closeModal}
                    id={'this.state.uniqueId.toString()'}>
                    {t('cancel')}
                </Button>
                <Button className='btn btn-danger'
                    onClick={this.deleteShippingInfo}
                    id={'this.state.uniqueId.toString()'}>
                    {t('delete')}
                </Button>
            </ModalFooter>
        </Modal>
    }

    onCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    render() {
        const {t} = this.props;
        return <div>
                    <div className="panel-heading" onClick={this.onCollapse}>
                        {!this.state.collapsed && <i className="fa fa-fw fa-chevron-down"></i>}
                        {this.state.collapsed && <i className="fa fa-fw fa-chevron-right"></i>}
                        {this.props.shipping.carrierName}
                    </div>
                        <div className="panel-body">
                            <Collapse isOpen={!this.state.collapsed} id={`bar${this.props.id}`}>
                                <div className={'shipping-detail-card'}>
                                    <div className="container">
                                        <div >
                                            <div className="col-md-6">
                                                <TextInput
                                                    label={t('shippingFeesCarrier')}
                                                    name="carrierName"
                                                    onChange={this.updateShippingInfo}
                                                    value={this.state.shipping.carrierName}
                                                    error={this.state.errors.carrierName}/>
                                            </div>
                                            <div className="col-md-6">
                                                <TextInput label={t('shippingFeesRate')}
                                                    name="rate"
                                                    value={`${this.state.shipping.rate}`}
                                                    type='number'
                                                    onChange={this.updateShippingInfo}
                                                    error={this.state.errors.rate}/>
                                            </div>
                                            <div>
                                                <Button className="btn btn-secondary"
                                                    onClick={this.submit}
                                                    icon="fa-save"
                                                    id={this.props.id.toString()}>
                                                    {t('shippingFeesUpdate')}
                                                </Button>
                                                <Button className="btn btn-secondary delete-button ml-1" data-toggle="modal" data-target="#deleteshipping"
                                                    id={this.props.id.toString()}   
                                                    onClick={this.toggleModal}>
                                                    {t('delete')} 
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse >
                        </div>
                        {this.displayDeleteShippingModal()}
                    <hr/>
                </div>;
    }
}

export default SeneShippingDetails;