import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { bindActionCreators } from "redux";

import { CountryLanguage } from "../../models/language";
import { selectAvailableLanguages, selectSelectedLanguageCode } from "../../store/selectors/languageSelectors";
import { changeSelectedLanguage } from "../../actions/languageActions";
import { selectSeneSiteUrl } from '../../store/selectors/distributorSelectors';

interface LanguageSelectorStateProps {
    languages: CountryLanguage[];
    selectedLanguageCode: string;
    seneSiteUrl: string | null;
    changeSelectedLanguage(selectedLanguage: CountryLanguage, seneSiteUrl: string | null);
}

type LanguageSelectorProps = LanguageSelectorStateProps & WithNamespaces;

class LanguageSelector extends React.PureComponent<LanguageSelectorProps> {

    onLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        this.changeLanguage(value);
    };
    
    changeLanguage(langCode: string) {
        const {i18n} = this.props;
        if (langCode) {
            const lang = this.props.languages.filter(lang => lang.code === langCode)[0];
            i18n.changeLanguage(langCode, () => {
                this.props.changeSelectedLanguage(lang, this.props.seneSiteUrl)
            });
        }
    }

    render() {
        return (
            <select className="form-control form-combobox" value={this.props.selectedLanguageCode} onChange={this.onLanguageChange}>
                {
                    this.props.languages.map(lang => <option key={lang.code} value={lang.code}>{lang.languageDisplayName}</option>)
                }
            </select>
        );
    }
}

const mapStateToProps = (state) => ({
    languages: selectAvailableLanguages(state),
    selectedLanguageCode: selectSelectedLanguageCode(state),
    seneSiteUrl: selectSeneSiteUrl(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        changeSelectedLanguage
    }, dispatch);
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(LanguageSelector));
