import DashboardPage from './dashboard/DashboardPage';
import MyCustomersPage from './customers/MyCustomersPage';
import SeneInventoryPage from './inventory/SeneInventoryPage';
import PaymentOptions from './payment/PaymentOptionsPage';
import MySeneSitesPage from './senesites/MySeneSitesPage';
import CalendarPage from './calendar/CalendarPage';
import SeneProfilePage from './profile/SeneProfilePage';
import SeneSiteOrdersPage from './orders/SeneSiteOrdersPage';
import SeneFeesPage from './fees/SeneFeesPage';
import PromotionPage from './promotion/PromotionPage';

export const routes = [
    {
        path: '/administration/Dashboard',
        component: DashboardPage
    },
    {
        path: '/administration/SeneSiteCustomers',
        component: MyCustomersPage
    },
    {
        path: '/administration/SeneInventory',
        component: SeneInventoryPage
    },
    {
        path: '/administration/PaymentOptions',
        component: PaymentOptions
    },
    {
        path: '/administration/MySeneSites',
        component: MySeneSitesPage
    },
    {
        path: '/administration/Calendar/CalendarPage/:selectedDate/:viewMode',
        component: CalendarPage
    },
    {
        path: '/administration/DistributorProfile',
        component: SeneProfilePage
    },
    {
        path: '/administration/Business',
        component: SeneFeesPage
    },
    {
        path: '/administration/SeneSiteOrders',
        component: SeneSiteOrdersPage
    },
    {
        path: '/administration/Promotion',
        component: PromotionPage,
    }
]
