import * as React from "react";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { State } from '../../../store';
import queryString from "query-string";
import {RouteComponentProps} from "react-router";
import {reloadCheckoutState} from "../../../actions/checkoutActions";
import {CheckoutState} from "../../../reducers/checkout";
import {selectCheckoutState} from "../../../store/selectors/checkoutSelectors";
import Spinner from "../Spinner";


type withCustomerGuardProps = {
    checkoutState: CheckoutState,
    reloadCheckoutState(payPalApiToken: string, payPalApiPaymentId: string)
}

type WithCheckoutStateProps = {
    isRestoringState: false
}

export interface WithCheckoutJumpState {
    jumpToPaymentSelection?: boolean;
}

export default function withCheckoutState<OwnProps, OwnState>(Component: React.ComponentType<OwnProps>) {
    class GuardedComponent extends React.Component<OwnProps & withCustomerGuardProps & RouteComponentProps, OwnState & WithCheckoutStateProps & WithCheckoutJumpState> {
        
        jumpToPaymentSelection: boolean = false;

        constructor(props) {
            super(props);

            const qs = queryString.parse(this.props.location.search);
            const paymentId = qs['paymentId'];
            const payPalApiToken = qs['token'];
            const jumpToPaymentString = qs['jumpToPaymentSelection'];
            this.jumpToPaymentSelection = jumpToPaymentString ? jumpToPaymentString.toString() === 'true' : false;
            if (paymentId && payPalApiToken) {
                this.props.reloadCheckoutState(payPalApiToken.toString(), paymentId.toString());
            }
        }

        render() {
            return <Component {...this.props} jumpToPaymentSelection={this.jumpToPaymentSelection} />;
        }
    }

    const mapStateToProps = (state: State) => ({
        checkoutState: selectCheckoutState(state)
    });

    const mapDispatchToProps = dispatch => bindActionCreators({
        reloadCheckoutState
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(GuardedComponent as any);
}
