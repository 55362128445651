import React from 'react';
import { ListGroup, ListGroupItem, Collapse } from 'reactstrap';
import i18next from 'i18next';
import { ProductAttribute } from '../../../models/product';
import * as dompurify from 'dompurify';

interface ProductAttributesMobileProps {
    attributes: ProductAttribute;
    t: i18next.TranslationFunction;
}

interface ProductAttributesMobileState {
    activeId: number;
}

class ProductAttributesMobile extends React.PureComponent<ProductAttributesMobileProps> {
    state: ProductAttributesMobileState = {
        activeId: 0
    }

    togglePanel = (id: number) => {
        this.setState({ activeId: this.state.activeId === id ? 0 : id });
    }

    render() {
        const { t, attributes } = this.props;

        return (
            <ListGroup className="attribute-accordion">
                <ListGroupItem active={this.state.activeId === 1} tag="button" action onClick={() => this.togglePanel(1)}>
                    {t('benefits')}
                </ListGroupItem>
                <Collapse isOpen={this.state.activeId === 1}>
                    <div className="attribute-accordion__item" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(attributes.benefits) }}>
                    </div>
                </Collapse>
                {/*<ListGroupItem active={this.state.activeId === 2} tag="button" action onClick={() => this.togglePanel(2)}>
                    {t('details')}
                </ListGroupItem>*/}
                {/*<Collapse isOpen={this.state.activeId === 2}>
                    <div className="attribute-accordion__item" >
                        {attributes.details}
                    </div>
                </Collapse>*/}
                <ListGroupItem active={this.state.activeId === 3} tag="button" action onClick={() => this.togglePanel(3)}>
                    {t('ingredients')}
                </ListGroupItem>
                <Collapse isOpen={this.state.activeId === 3}>
                    <div className="attribute-accordion__item" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(attributes.ingredients) }}>
                    </div>
                </Collapse>
                <ListGroupItem active={this.state.activeId === 4} tag="button" action onClick={() => this.togglePanel(4)}>
                    {t('appTechniques')}
                </ListGroupItem>
                <Collapse isOpen={this.state.activeId === 4}>
                    <div className="attribute-accordion__item" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(attributes.applicationTechniques) }}>
                    </div>
                </Collapse>
            </ListGroup>
        )
    }
}

export default ProductAttributesMobile;
