import React from 'react';
import ReactImageMagnify from 'react-image-magnify';

import './ProductImageViewer.scss';
import { ProductVm } from '../../../models/product';
import { CreatePrimaryImageUrl, CreateFallbackImageUrl } from '../../common/Images';
import ImageComponent from '../../common/ImageComponent';
import { Configuration } from '../../../models/configuration';
import { connect } from 'react-redux';
import { State } from '../../../store';
import { selectConfiguration } from '../../../store/selectors/configurationSelectors';

interface ProductImageViewerProps {
    product: ProductVm;
    imageResizerUrl: string;
    config: Configuration | null;
}

interface ProductImageViewerState {
    displayedSrc: string;
}

class ProductImageViewer extends React.PureComponent<ProductImageViewerProps, ProductImageViewerState> {
    state: ProductImageViewerState = {
        displayedSrc: ''
    }

    private readonly smSize = 470;
    private readonly lgSize = 800;

    getSrc = (size: 'lg' | 'sm', type: 'primary' | 'fallback') => {
        const { w, h } = {
            lg: { w: this.lgSize, h: this.lgSize },
            sm: { w: this.smSize, h: this.smSize }
        }[size];

        if(type == 'primary') {
            return CreatePrimaryImageUrl(this.props.config, `products/ProductImages/${this.state.displayedSrc}`, w, h, "pad", "png");
        }
        else {
            return CreateFallbackImageUrl(this.props.imageResizerUrl, `products/ProductImages/${this.state.displayedSrc}`, w, h, "pad", "png");
        }
    }

    componentDidMount() {
        this.props.product && this.setState({ displayedSrc: this.getImages()[0] });
    }

    componentDidUpdate(prevProps: ProductImageViewerProps) {
        if (this.props.product && (!prevProps.product || prevProps.product.sku !== this.props.product.sku)) {
            this.setState({ displayedSrc: this.getImages()[0] });
        }
    }

    handleOnClick = (src: string) => {
        this.setState({ displayedSrc: src });
    }

    getImages = () => {
        const images = this.props.product.imageUrl ? [this.props.product.imageUrl] : [`${this.props.product.sku}.tif`];

        if (this.props.product.hasSwatch) {
            images.push(this.props.product.colorSwatchImage ? this.props.product.colorSwatchImage : `${this.props.product.sku}_swatch.tif`);
        }

        return images;
    }

    renderImageThumbnail(src: string) {
        return <ImageComponent
            key={src}
            height={58}
            width={58}
            crop={"pad"}
            src={`products/ProductImages/${src}`}
            extension="png"
            onClick={() => this.handleOnClick(src)}
        />
    }

    onError = (e) => {

        const fallBackSrc = this.getSrc('sm', 'fallback');
        const finalSrc = "/image_placeholder.png";

        if(e.target.src == finalSrc) {
            e.target.src = "";
            (e.target as any).onerror = null; // avoid infinite loops if can't find fallback
        }
        else if(e.target.src == fallBackSrc) {
            e.target.src=finalSrc;
        }
        else {
            e.target.src = fallBackSrc;
        }
    }

    onLargeError = (e) => {
        const fallBackSrc = this.getSrc('lg', 'fallback');
        const finalSrc = "/image_placeholder.png";

        if(e.target.src == finalSrc) {
            e.target.src = "";
            (e.target as any).onerror = null; // avoid infinite loops if can't find fallback
        }
        else if(e.target.src == fallBackSrc) {
            e.target.src=finalSrc;
        }
        else {
            e.target.src = fallBackSrc;
        }
    }

    render() {
        if (!this.props.product) {
            return null;
        }

        const images = this.getImages();
        return (
            <div className="product-images">
                <ReactImageMagnify
                    largeImage={{
                        alt: this.props.product.name,
                        src: this.getSrc('lg', 'primary'),
                        width: this.lgSize,
                        height: this.lgSize,
                        onError: this.onLargeError
                    }}
                    smallImage={{
                        alt: this.props.product.name,
                        src: this.getSrc('sm', 'primary'),
                        isFluidWidth: true,
                        height: this.smSize,
                        onError: this.onError
                    }}
                    enlargedImagePosition="over"
                />
                <div className="product-images__preview-container">
                    {images.length > 1 && images.map(src => this.renderImageThumbnail(src))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    config: selectConfiguration(state)
})

export default connect(mapStateToProps)(ProductImageViewer);
