import { Thunk } from './../store';
import * as marketingApi from './../api/marketingApi';
import { shouldSwithToMaintenance } from "../utils/helpers";

export enum MarketingActionTypes {
    getMarketingSuccess = '[Marketing] Get Marketing data success',
    getMarketingError = '[Marketing] Get Marketing data error'
}


export const getMarketing = (): Thunk => (dispatch, getState) => {
    return marketingApi.getMarketing()
        .then(response => {
            dispatch({ type: MarketingActionTypes.getMarketingSuccess, payload: response });
        })
        .catch(exp => {
            shouldSwithToMaintenance(exp);
            dispatch({ type: MarketingActionTypes.getMarketingError });
        });
}
