import * as React from 'react';
import _ from 'underscore';

import CustomersMobileLineItem from './CustomerMobileLineItem';
import { Customer } from '../../../models/customer';
import i18n from '../../../i18n';

export interface ICustomersMobileProps {
    customers: any[];
    data: any[];
    search: any;
    t: i18n.TranslationFunction;
    onCustomerEditClicked: (customer: Customer) => void;
}

export class CustomersMobile extends React.PureComponent<ICustomersMobileProps> {

    SearchContext;
    constructor(props) {
        super(props);

        const noopFunc = (searchText) => {};
        this.SearchContext = props.search.searchContext(
            _, () => false, noopFunc);
        this.onCustomerEditClicked = this.onCustomerEditClicked.bind(this);
    }

    render() {
        return (<this.SearchContext.Provider
            { ...this.props }
            data={ this.props.data }
            searchText={ this.props.search.searchText }
        >
            <this.SearchContext.Consumer>
                {
                    searchProps => (
                        searchProps.data.map((row, i) => (
                            <CustomersMobileLineItem t={this.props.t} customer={row} key={i} onCustomerEditClicked={this.onCustomerEditClicked}/>
                        ))
                    )
                }
            </this.SearchContext.Consumer>
        </this.SearchContext.Provider>);
    }

    onCustomerEditClicked(customer) {
        this.props.onCustomerEditClicked(customer);
    }
}

export default CustomersMobile;
