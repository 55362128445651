import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";

import './DashboardWidget.scss';
import { AssetDirectImage } from '../../common/Images';
import NotificationBadge, { NotificationBadgeProps } from '../../common/NotficationBadge'
import { Configuration } from "../../../models/configuration";
import { connect } from "react-redux";
import { State } from "../../../store";
import { selectConfiguration } from "../../../store/selectors/configurationSelectors";

export interface IDashboardWidgetProps {
    icon: string;
    text: string;
    url?: string;
    isExternalLink?: boolean;
    badge?: NotificationBadgeProps;
    config: Configuration | null;
}

class DashboardWidgetLocal extends PureComponent<IDashboardWidgetProps> {
    render() {
        let LinkElement;

        if (!this.props.url )  {
            LinkElement = (props) => <div {...props}>{props.children}</div>
        } else {
            LinkElement =
                this.props.isExternalLink
                    ? (props) => <a {...props} href={this.props.url}>{props.children}</a>
                    : (props) => <NavLink {...props} to={this.props.url || ''}>{props.children}</NavLink>;
        }

        // TODO: Change it AssetDirectImage to AssetsImage once '/azure' storage mapping is done on backend
        return (
            <div className="col-4 dashboard-widget">
                <LinkElement className="dashboard-widget__main">
                    <AssetDirectImage {...{
                        config: this.props.config,
                        src: this.props.icon,
                        alt: this.props.text,
                        className: 'dashboard-widget__icon'
                    }} />
                    <span>{this.props.text}</span>
                    {
                        this.props.badge && this.props.badge.count > 0 &&
                        <NotificationBadge {...this.props.badge}/>
                    }
                </LinkElement>
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    config: selectConfiguration(state)
})

export const DashboardWidget = connect(mapStateToProps)(DashboardWidgetLocal);