import React from 'react';
import {
    Carousel,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';

import './SeneCarousel.scss';
import i18n from '../../i18n';

export interface SeneCarouselBaseProps {
    className?: string;
    items: any[];
    indicators?: boolean;
    slide?: boolean;
    renderItems(params: { items: any[], onExiting, onExited }): JSX.Element;
    t: i18n.TranslationFunction
}

export interface SeneCarouselBaseState {
    activeIndex: number;
}

/**
 * Fully customizable carousel, can render anything using given renderer prop
 */
class SeneCarouselBase extends React.PureComponent<SeneCarouselBaseProps, SeneCarouselBaseState> {
    animating = false;
    state = {
        activeIndex: 0,
    };

    static defaultProps = {
        autoPlay: false,
        indicators: true,
        slide: false
    }

    componentDidUpdate(prevProps: SeneCarouselBaseProps) {
        if (prevProps.items.length !== this.props.items.length) {
            this.setState({activeIndex: 0});
        }
    }

    onExiting = () => {
        this.animating = true;
    }

    onExited = () => {
        this.animating = false;
    }

    next = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex = (newIndex) => {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const {t} = this.props;
        return (
            <Carousel
                activeIndex={this.state.activeIndex}
                next={this.next}
                previous={this.previous}
                interval={0}
                slide={this.props.slide}
                className={this.props.className || ''}
            >
                {
                    this.props.indicators &&
                    <CarouselIndicators items={this.props.items} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                }
                {this.props.renderItems({ items: this.props.items, onExiting: this.onExiting, onExited: this.onExited })}
                <CarouselControl direction="prev" directionText={t('previous')} onClickHandler={this.previous} /> 
                <CarouselControl direction="next" directionText={t('next')} onClickHandler={this.next} />
            </Carousel>
        )
    }
}

export default SeneCarouselBase;
