import { CustomerActionTypes } from './../actions/customerActions';
import { UserActionTypes } from './../actions/userActions';
import { UserInfo } from './../models/userInfo';

export type UserState = UserInfo | null;

export const reducer = (state: UserState = null, action) => {
    switch (action.type) {
        case UserActionTypes.getCurrentUserSuccess:
            return action.user as UserInfo;
        case CustomerActionTypes.updateLoggedInCustomerSuccess:
            return action.customer as UserInfo;
        default:
            return state;
    }
}
