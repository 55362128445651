import { produce } from 'immer';
import { CartActionTypes } from './../actions/cartActions';
import { CartItem } from "../models/cart";
import { CheckoutActionTypes } from '../actions/checkoutActions';

const intialState: CartItem[] = [];
export type CartState = typeof intialState;

export const reducer = (state = intialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case CartActionTypes.loadCart: {
                draft = action.cart;
                return draft;
            }
            case CartActionTypes.addProductToCart: {
                const existingProduct = draft.find(p => p.productId === action.productId);
                const qty = action.qty;

                if (existingProduct) {
                    existingProduct.qty += qty;
                } else {
                    draft.push({
                        productId: action.productId,
                        qty,
                        sku: action.sku
                    });
                }

                return draft;
            }
            case CartActionTypes.removeProductFromCart: {
                const index = draft.findIndex(p => p.sku === action.sku);

                if (index > -1) {
                    draft.splice(index, 1);
                }

                return draft;
            }
            case CartActionTypes.changeProductQty: {
                const existingProduct = draft.find(p => p.productId === action.productId);

                if (!existingProduct) {
                    return draft;
                }

                if (existingProduct.qty > 0) {
                    existingProduct.qty = action.qty;
                } else {
                    draft.splice(draft.indexOf(existingProduct), 1);
                }

                return draft;
            }
            case CheckoutActionTypes.orderSuccess: {
                draft = [];

                return draft;
            }
            default:
                return draft;
        }
    });
