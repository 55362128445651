import React from 'react';
import { debounce } from 'lodash';
import SeneAlert from '../SeneAlert';

export interface SeneDebounceInputProps {
    value: string;
    wait: number;
    onDebounce(value: string);
    id?: string;
    label?: string;
    placeholder?: string;
    minLength?: number;
    maxLength?: number;
    forceDebounceOnBlur?: boolean;
    forceDebounceOnEnter?: boolean;
    loading?: boolean;
    success?: string;
    error?: string;
}

interface SeneDebounceInputState {
    value: string;
}

export class SeneDebounceInput extends React.Component<SeneDebounceInputProps, SeneDebounceInputState> {

    static defaultProps = {
        wait: 100,
        forceDebounceOnBlur: false,
        forceDebounceOnEnter: false
    }

    state: SeneDebounceInputState = {
        value: ''
    }

    onDebounce = debounce(() => { this.props.onDebounce(this.state.value) }, this.props.wait);

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e && e.target && e.target.value || '';
        this.setState({ value }, () => this.onDebounce());
    }

    onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (this.props.forceDebounceOnBlur) {
            const value = e && e.target && e.target.value || '';
            this.setState({ value }, () => this.onDebounce.flush());
        }
    }

    onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.props.forceDebounceOnEnter && e.keyCode == 13) {
            const value = e && e.currentTarget && e.currentTarget.value || '';
            this.setState({ value }, () => this.onDebounce.flush());
        }
    }

    render() {
        return (
            <>
                <div className="form-row align-items-center">
                    {this.props.label && <label className="col-12" htmlFor={this.props.id}>{this.props.label}</label>}
                    <div className="form-group col-11">
                        <input
                            {...{
                                id: this.props.id,
                                type: 'text',
                                className: 'form-control',
                                value: this.state.value,
                                placeholder: this.props.placeholder,
                                minLength: this.props.minLength,
                                maxLength: this.props.maxLength,
                                onChange: this.onChange,
                                onBlur: this.onBlur,
                                onKeyUp: this.onKeyUp
                            }}
                        />
                    </div>
                    <div className="form-group col-1">
                        <div>
                            {this.props.loading &&
                                <i className="fas fa-spinner fa-spin"></i>
                            }
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col">
                        <SeneAlert {...{
                            success: this.props.success,
                            error: this.props.error,
                            id: this.props.id
                        }} />
                    </div>
                </div>
            </>
        )
    }

}
export default SeneDebounceInput;