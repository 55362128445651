import React from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import ReactDatePicker from 'react-datepicker';
import i18next from 'i18next';

import { IBigCalendarEvent } from './Calendar';
import SeneButton from '../inputs/SeneButton';
import './EventModal.scss';

export interface EventModalStateProps {
    t: i18next.TranslationFunction;
    isOpen: boolean;
    event: IBigCalendarEvent;
    onChange(changes: any)
    save();
    close();
}

class EventModal extends React.PureComponent<EventModalStateProps> {

    private readonly timeIntervals = 15;

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const changes = { [e.target.name]: e.target.value };
        this.props.onChange(changes);
    }

    handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const changes = { [e.target.name]: e.target.value };
        this.props.onChange(changes);
    }

    handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { event } = this.props;
        const start = event.start ? moment(event.start).startOf('day').toDate() : moment().startOf('day').toDate();
        const end = event.start ? moment(event.start).endOf('day').toDate() : moment().endOf('day').toDate();
        const changes = {
            [e.target.name]: e.target.checked,
            start: start,
            end: end
        };
        this.props.onChange(changes);
    }

    handleDateChange = (date: Date, name: string) => {
        const { event } = this.props;
        if (date) {
            let changes;
            if (event.allDay) {
                changes = {
                    start: moment(date).startOf('day').toDate(),
                    end: moment(date).endOf('day').toDate()
                }
            } else {
                changes = {
                    [name]: date
                }
            }
            this.props.onChange(changes);
        } else {
            const changes = { [name]: date };
            this.props.onChange(changes);
        }
    }

    isDateInvalid = (event) => {
        const today = moment().startOf('day').toDate();
        return (!event) ||
            (event && event.allDay && !event.start) ||
            (event && !event.allDay && (!event.start || !event.end)) ||
            (event && event.start && (event.start < today));
    }

    render() {
        const { t, event } = this.props;
        const isDateInvalid = this.isDateInvalid(event);
        const isEndBeforeStart = (!event || (event.start && event.end && event.end < event.start)) ? true : false;
        const isTitleInvalid = (!event || !event.title || event.title === '') ? true : false;

        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.close} className="modal-dialog modal-lg">
                <div className="modal-content overflow-scroll">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={() => this.props.close()} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            {isTitleInvalid &&
                                <small className="text-danger">{t('errorEventName')}</small>
                            }
                            <div className="form-group">
                                <label className="font-weight-bold" htmlFor="title">{t('EventName')}</label>
                                <input type="text" className="form-control" id="title" name="title" value={event.title} onChange={e => this.handleInputChange(e)} />
                            </div>
                            <div className="form-check mt-2">
                                <input type="checkbox" className="form-check-input" id="allDay" name="allDay" checked={event.allDay} onChange={e => this.handleCheckBoxChange(e)} />
                                <label className="form-check-label font-weight-bold" htmlFor="allDay">{t('allday')}</label>
                            </div>
                            {
                                event.allDay ?
                                    <>
                                        <div className="form-group mt-2">
                                            <label className="font-weight-bold" htmlFor="start">{t('startDate')}</label>
                                        </div>
                                        <div className="form-group">
                                            <ReactDatePicker
                                                name="start"
                                                className="form-control col-sm-12"
                                                dateFormat="MM/dd/yyyy"
                                                peekNextMonth={true}
                                                isClearable={true}
                                                selected={event.start}
                                                minDate={new Date()}
                                                onChange={(date) => this.handleDateChange(date, 'start')}
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="form-row">
                                                <div className="col-md-6">
                                                    <div className="form-group mt-2 mb-0">
                                                        <label className="font-weight-bold" htmlFor="start">{t('startDate')}</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <ReactDatePicker
                                                            name="start"
                                                            className="form-control"
                                                            dateFormat="MM/dd/yyyy"
                                                            peekNextMonth={true}
                                                            isClearable={true}
                                                            selected={event.start}
                                                            selectsStart={true}
                                                            startDate={event.start}
                                                            endDate={event.end}
                                                            minDate={new Date()}
                                                            maxDate={event.end}
                                                            onChange={(date) => this.handleDateChange(date, 'start')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mt-2 mb-0">
                                                        <label className="font-weight-bold" htmlFor="start">{t('startTime')}</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <ReactDatePicker
                                                            name="startTime"
                                                            className="form-control"
                                                            timeCatpion="Time"
                                                            dateFormat="h:mm aa"
                                                            selected={event.start}
                                                            onChange={(date) => this.handleDateChange(date, 'start')}
                                                            showTimeSelect={true}
                                                            showTimeSelectOnly={true}
                                                            timeIntervals={this.timeIntervals}
                                                        />
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                                <div className="col-md-6">
                                                    <div className="form-group mt-2 mb-0">
                                                        <label className="font-weight-bold" htmlFor="end">{t('endDate')}</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <ReactDatePicker
                                                            name="end"
                                                            className="form-control"
                                                            dateFormat="MM/dd/yyyy"
                                                            peekNextMonth={true}
                                                            isClearable={true}
                                                            selected={event.end}
                                                            selectsEnd={true}
                                                            startDate={event.start}
                                                            endDate={event.end}
                                                            minDate={event.start || new Date()}
                                                            onChange={(date) => this.handleDateChange(date, 'end')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mt-2 mb-0">
                                                        <label className="font-weight-bold" htmlFor="start">{t('endTime')}</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <ReactDatePicker
                                                            name="entTime"
                                                            className="form-control"
                                                            timeCatpion="Time"
                                                            dateFormat="h:mm aa"
                                                            selected={event.end}
                                                            onChange={(date) => this.handleDateChange(date, 'end')}
                                                            showTimeSelect={true}
                                                            showTimeSelectOnly={true}
                                                            timeIntervals={this.timeIntervals}
                                                        />
                                                    </div>
                                                </div>
                                        </div>
                                    </>
                            }

                            {
                                isEndBeforeStart &&
                                <div className="form-group mt-2">
                                    <label className="text-danger">{t('errorEventTime')}</label>
                                </div>
                            }
                            {
                                isDateInvalid &&
                                <div className="form-group mt-2">
                                    <label className="text-danger">{t('dateRequired')}</label>
                                </div>
                            }
                            <div className="form-group">
                                <label className="font-weight-bold" htmlFor="location">{t('location')}</label>
                                <input type="text" className="form-control" id="location" name="location" value={event.location} onChange={e => this.handleInputChange(e)} />
                            </div>
                            <div className="form-group">
                                <label className="font-weight-bold" htmlFor="description">{t('description')}</label>
                                <textarea className="form-control" id="description" name="description" value={event.description} onChange={e => this.handleTextAreaChange(e)} />
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer justify-content-start">
                        {/*
                            //TODO: Would be better to disable the button instead of hiding
                            disabled={(isDateInvalid || isEndBeforeStart || isTitleInvalid)}
                        */}
                        {!(isDateInvalid || isEndBeforeStart || isTitleInvalid) &&
                            <SeneButton
                                buttonClass="secondary"
                                onClick={() => this.props.save()}
                            >
                                {t('save')}
                            </SeneButton>
                        }
                    </div>
                </div>
            </Modal>
        )
    }
}

export default (EventModal);
