import * as React from 'react';
import { connect } from 'react-redux';
import { Configuration } from '../../models/configuration';
import { State } from '../../store';
import { selectConfiguration } from '../../store/selectors/configurationSelectors';

export interface IImageComponentParams extends ISeneImageParams {
    imageResizerUrl: string,
    config: Configuration | null,
    width?: number,
    height?: number,
    extension?: 'jpg' | 'png';
    crop?: 'pad' | 'max' | 'crop' | 'stretch',
}

interface ISeneImageParams {
    src: string;
    alt?: string;
    className?: string;
    id?: string;
    fallback?: string,
    fallBackURL?: string,
    onClick?(e);
    onLoad?(e);
    config: Configuration | null;
}

const mapStateToProps = (state: State) => ({
    config: selectConfiguration(state)
})

const SeneImageLocal = ({ fallBackURL, ...params }: ISeneImageParams) => {
    var fallBackLink = !fallBackURL || fallBackURL && ['undefined', 'null'].includes(fallBackURL.toLowerCase()) ? '' : fallBackURL;

    return <img {...params}
        onError={(e) => {
            if (e.target['src'] == fallBackLink) {
                e.target['src'] = "";
                (e.target as any).onerror = null; // avoid infinite loops if can't find fallback
            }
            else if (e.target['src'] == params.src && fallBackLink) {
                e.target['src'] = fallBackLink;
            }
        }} />;
}

export const SeneImage = connect(mapStateToProps)(SeneImageLocal);

const AssetImageLocal = ({ config, imageResizerUrl, src, width = 0, height = 0, crop = 'max', extension = 'png', ...props }: IImageComponentParams) => {
    props.fallBackURL = CreateFallbackImageUrl(imageResizerUrl, src, width, height, crop, extension);
    let url = CreatePrimaryImageUrl(config, src, width, height, crop, extension);
    return <SeneImage src={url} {...props} />
}

export const AssetImage = connect(mapStateToProps)(AssetImageLocal)

const AssetDirectImageLocal = (params: ISeneImageParams) => {
    const { src, config, ...props } = params;
    return <SeneImage src={`${config ? config.apiConstants.IMAGESPRODUCTSDIRECT : '/'}${params.src}`} {...props} />;
}

export const AssetDirectImage = connect(mapStateToProps)(AssetDirectImageLocal)

export const CreatePrimaryImageUrl = (config: Configuration | null, src: string, width = 0, height = 0, crop = 'max', extension = 'png'): string => {
    const index = src.lastIndexOf('/') + 1;
    const [source, fileName] = [src.substring(0, index), src.substring(index)];
    const fileNameWithOutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
    const targetImg = `${fileNameWithOutExtension}.${extension}`;

    let url = "";
    if (width == 0 && height == 0) {
        url = `${config ? config.apiConstants.IMAGESDIRECT : '/'}${src}`;
    } else {
        url = `${config ? config.apiConstants.RESIZEDIMAGESPRODUCTSDIRECT : '/'}${width}/${height}/${crop}/${source}${targetImg}`;
    }
    return url;
}

export const CreateFallbackImageUrl = (imageResizerUrl: string, src: string, width = 0, height = 0, crop = 'max', extension = 'png'): string => {
    return `${imageResizerUrl}?image=${src}&width=${width}&height=${height}&mode=${crop}&convertedTo=${extension}&responseType=image`;
}
export const CreateBackgroundImageUrl = ({ config, imageResizerUrl, src, width = 0, height = 0, crop = 'max', extension = 'png' }): string => {
    const firstUrl = CreatePrimaryImageUrl(config, src, width, height, crop, extension);
    const secondUrl = CreateFallbackImageUrl(imageResizerUrl, src, width, height, crop, extension);
    return `url(${firstUrl}), url(${secondUrl})`;
}

