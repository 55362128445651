import { State } from '..';
import { Distributor } from "../../models/distributor";

export const selectLoggedInUser = (state: State) => state.user;
export const selectLoggedInUserCountry = (state: State) => state.user && state.user.country || 'US';
export const selectAssignedDistributor = (state: State): Distributor | null => {
    const loggedInUser = selectLoggedInUser(state)
    if (loggedInUser) {
        return loggedInUser.assignedDistributor;
    }
    return null;
};