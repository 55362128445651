import i18next from 'i18next';
import React from 'react';
import { SquarePaymentInfo } from '../../../../models/checkout';
import './SquareFormSDK.scss';

export type SquarePaymentFormModel = SquarePaymentInfo;

interface SquareFormSDKProps {
    t: i18next.TranslationFunction;
    sqPaymentInfo: SquarePaymentInfo;
    sqPaymentScript: any;
    sqPaymentConfig: any;
    errors: any;
    handleSqPaymentInfoChange(changes: any);
    handleSqPaymentErrorsChange(changes: any);
}

interface SquareFormSDKState {
    isFormLoaded: boolean;
    payments: any;
    card: any;
}

class SquareFormSDK extends React.Component<SquareFormSDKProps, SquareFormSDKState> {

    paymentScript: any = {};
    cardButton: any;

    state: SquareFormSDKState = {
        isFormLoaded: false,
        payments: null,
        card: null,
    }

    componentDidMount() {

        this.cardButton = document.getElementById('card-button');
        this.handlePaymentMethodSubmission = this.handlePaymentMethodSubmission.bind(this);
        this.loadPaymentMethod();
    }

    componentDidUpdate(prevProps: SquareFormSDKProps) {
        const prevSourceId = (prevProps && prevProps.sqPaymentInfo && prevProps.sqPaymentInfo.sourceId);
        const hasSourceId = (this.props.sqPaymentInfo.sourceId && this.props.sqPaymentInfo.sourceId.length > 0);
        if (prevSourceId && !hasSourceId) {
            this.loadPaymentMethod();
        } 
    }

    loadPaymentMethod = async () => {
        const { sqPaymentConfig, sqPaymentScript } = this.props;

        let applicationId = sqPaymentConfig.applicationId;
        let locationId = sqPaymentConfig.locationId;

        if (!sqPaymentScript) {
            throw new Error('Square.js failed to load properly');
        }

        let payments;
        try {
            payments = sqPaymentScript.payments(applicationId, locationId);
        } catch {
            const statusContainer = document.getElementById(
                'payment-status-container'
            );
            if (statusContainer) {
                statusContainer.className = 'missing-credentials';
                statusContainer.style.visibility = 'visible';
            }
            return;
        }

        let card;
        try {
            card = await this.initializeCard(payments);
            this.setState({
                card: card,
                payments: payments
            });
        } catch (e) {
            console.error('Initializing Card failed', e);
            return;
        }

    }

    initializeCard = async (payments) => {
        const card = await payments.card();
        await card.attach('#card-container');
        return card;
    }

    tokenize = async (paymentMethod) => {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                    tokenResult.errors
                )}`;
            }
            throw new Error(errorMessage);
        }
    }

    handleRemovePaymentMethod = async (event) => {

    }

    handlePaymentMethodSubmission = async (event) => {
        const { sqPaymentConfig, sqPaymentScript } = this.props;
        
        let paymentMethod = this.state.card; 

        try {
            // disable the submit button as we await tokenization and make a payment request.
            this.cardButton.disabled = true;
            const token = await this.tokenize(paymentMethod);
            console.log(token);
            //this.props.sqPaymentInfo.nonce = token;
            //this.props.sqPaymentInfo.sourceId = token;
            this.props.handleSqPaymentInfoChange({
                sourceId: token,
                nonce: token,
                last4: "####"
            });
            this.props.handleSqPaymentErrorsChange({
                squarePaymentInfo: {}
            });
        } catch (e) {
            this.cardButton.disabled = false;
            //displayPaymentResults('FAILURE');
            console.error(e.message);
        }
    }

    renderValidatedCcPlaceholder = () => {
        const { sqPaymentInfo, t } = this.props;
        return (
            <div id="sq-ccbox">
                <div id="cc-field-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className={`credit-card-${sqPaymentInfo.cardBrand.toUpperCase()}`}>{t('cardNumber')} *</label>
                                <div className="form-control cc-box-validated"
                                    id="partial-credit-card-number">
                                    <span className="float-left"> • • • •  • • • •  • • • • {sqPaymentInfo.last4} </span>
                                    <span className="float-right icon-placeholder" onClick={this.handleRemovePaymentMethod}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderSqPaymentForm = () => {
        const { sqPaymentInfo, errors, t } = this.props;
        return (
            <div>
                <div id="sq-ccbox">
                    <div id="card-container"></div>
                    <div id="cc-field-wrapper">
                        <input type="hidden" id="card-nonce" name="nonce" />
                    </div>
                </div>
                <div className="text-right">
                    <button type="button" id="card-button" className="btn btn-secondary" onClick={this.handlePaymentMethodSubmission}>{t('useCreditCard')}</button>
                </div>
            </div>
        )
    }

    render() {
        const { sqPaymentInfo, errors, t } = this.props;
        const isChecked = (sqPaymentInfo.sourceId && sqPaymentInfo.sourceId.length > 0) ? true: false;
        return (
            <div className="container mb-3">
                <div className="row">
                    <div className="col-sm-8 col-md-12 col-lg-5">
                        <div className="row">
                            <div className="col-12">
                                <h6 className="paragraph-title">{t('cardInfo')}</h6>
                            </div>
                        </div>
                        <div id="sq-form-container"
                            className={(isChecked) ? 'success' : ''}>
                            {
                                isChecked ?
                                    this.renderValidatedCcPlaceholder() :
                                    this.renderSqPaymentForm()
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8 col-md-12 col-lg-5">
                        {
                            errors.nonce &&
                            <div className="alert alert-danger">{errors.nonce}</div>
                        }
                    </div>
                </div>
            </div >
        )
    }
}

export default SquareFormSDK; 