import { DistributorActionTypes } from './../actions/distributor';
import {Distributor, PublicPaymentMethod, SeneSiteInfo} from '../models/distributor';
import {PaymentOptionActionTypes} from "../actions/paymentOptionActions";
import {AdminPaymentOption} from "../models/payment";

export type DistributorState = Distributor | null;

export const reducer = (state: DistributorState = null, action) => {
    switch (action.type) {
        case DistributorActionTypes.getDistBySeneSiteSuccess:
        case DistributorActionTypes.getDistByIdSuccess:
            return action.distributor as Distributor;
        case DistributorActionTypes.uploadProfileImage:
        return {
            ...state as Distributor,
            seneSiteInfo: {
                ...state!.seneSiteInfo,
                seneSiteLooks: {
                    ...state!.seneSiteInfo.seneSiteLooks,
                    seneSiteDistrbutorImage: action.res.seneSiteInfo.seneSiteLooks.seneSiteDistrbutorImage
                }
            }
        };
        case DistributorActionTypes.updateDistributorSeneSiteURLState:
            return {
                ...state as Distributor,
                seneSiteInfo: {
                    ...state!.seneSiteInfo,
                    seneSiteUrl: action.seneSiteUrl
                }
            };
        case DistributorActionTypes.updateDistributorSeneSiteInfo:
            return {
                ...state as Distributor,
                seneSiteInfo: action.seneSiteInfo as SeneSiteInfo
            };
        case DistributorActionTypes.updateDistributorMyProfile:
            return {
                ...state as Distributor,
                profile: action.distributorProfile
            };
        case DistributorActionTypes.updateDistributorHandlingInfoSuccess:
            return {
                ...state as Distributor,
                seneSiteInfo: {
                    ...state!.seneSiteInfo,
                    handlingInfo: action.handlingInfo
                }
            };
        case DistributorActionTypes.updateDistributorShippingInfoSuccess:
            return {
                ...state as Distributor,
                seneSiteInfo: {
                    ...state!.seneSiteInfo,
                    shippingInfo: action.shippingInfo
                }
            };
        case DistributorActionTypes.deleteDistributorHandlingInfoSuccess:
            return {
                ...state as Distributor,
                seneSiteInfo: {
                    ...state!.seneSiteInfo,
                    handlingInfo: action.res.data.seneSiteInfo.handlingInfo
                }
            };
        case PaymentOptionActionTypes.updateDistributorPaymentOptionsSuccess:
            const adminPaymentInfo = action.paymentInfo as AdminPaymentOption[];
            const paymentInfo = {
                paymentMethods: adminPaymentInfo.filter(ap => ap.selected).map(ap => <PublicPaymentMethod> {
                    paymentMethodType: ap.paymentMethodType,
                    paymentMethodID: ap.paymentMethodID
                })
            };
            return {
                ...state as Distributor,
                paymentInfo
            };
        default:
            return state;
    }
};
