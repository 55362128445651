import React, { MouseEvent } from "react";

import './ProductCard.scss';
import ProductCardMobile from './ProductCardMobile';
import SeneButton from "../../../common/inputs/SeneButton";
import { ProductVm } from "../../../../models/product";
import { DesktopOrTablet, Mobile } from "../../../common/responsive/ResponsiveHelpers";
import SeneSiteLink from "../../../common/SeneSiteLink";
import i18n from "../../../../i18n";
import Currency from "../../../common/Currency";
import ImageComponent from "../../../common/ImageComponent";
import { debug } from "console";

export interface ProductCardProps {
	product: ProductVm;
	addToCart(productId: number, sku: string);
	currencyForced?: boolean;
	t: i18n.TranslationFunction
}

export interface ProductCardState {
	isHovered: boolean;
}

export class ProductCard extends React.PureComponent<ProductCardProps, ProductCardState> {
	state: ProductCardState = {
		isHovered: false
	}

	addToCart = () => {
		this.props.addToCart(this.props.product.productId, this.props.product.sku);
	}

	onMouseOverOrTouch = (e: MouseEvent<HTMLDivElement>) => {
		this.setState({ isHovered: true });
	}

	onMouseOut = (e: MouseEvent<HTMLDivElement>) => {
		this.setState({ isHovered: false });
	}

	onTouchOut = (e: MouseEvent<HTMLDivElement>) => {
		this.setState({ isHovered: false });
	}

	renderDesktop() {
		const prod = this.props.product;
		const { t } = this.props;
		const productImageUrl = prod.imageUrl ? `products/ProductImages/` + prod.imageUrl : `products/ProductImages/${prod.sku}.tif`;
		return prod &&
			(
				<div className={`card product-card ${this.state.isHovered ? 'product-card--hover' : ''}`}
					onMouseEnter={this.onMouseOverOrTouch}
					onMouseLeave={this.onMouseOut}
				>
					<div className="product-card__top">
						<SeneSiteLink to={prod.link}>
							<ImageComponent
								src={productImageUrl}
								height={200}
								className='product-card__top-img'
								alt={prod.sku}
								fallback="/image_placeholder.png"
							/>
						</SeneSiteLink>
						<SeneButton
							onClick={this.addToCart}
							icon="sene-shopping-bag"
							className="product-card__add-to-bag"
							buttonClass="secondary"
						>
							{t('addToBag')}
						</SeneButton>
					</div>
					<div className="card-body">
						<SeneSiteLink to={prod.link}>
							<h5 className="card-title" style={{
								'overflow': 'hidden',
								'display': '-webkit-box',
								'-webkit-box-orient': 'vertical'
							}}>{prod.name}</h5>
						</SeneSiteLink>
						<p className="card-text"><Currency currencyForced={this.props.currencyForced}>{prod.price}</Currency></p>
					</div>
				</div>
			);

	}

	render() {
		return (
			<>
				<DesktopOrTablet>
					{this.renderDesktop()}
				</DesktopOrTablet>
				<Mobile>
					<ProductCardMobile {...this.props} />
				</Mobile>
			</>
		)
	}
}

export default ProductCard;
