import { LOCATION_CHANGE } from 'connected-react-router';

import { CustomerActionTypes } from './../actions/customerActions';
import { UIActionTypes } from './../actions/ui';
import { CheckoutActionTypes } from '../actions/checkoutActions';
import { ApplicationActionTypes } from '../actions/administration/applicationFormActions';

const initialState = {
    requestsInProgress: 0,
    productSort: {
        sortBy: "name",
        direction: 'asc'
    },
    blockUi: false,
    errors: {
        createAccount: [] as any[],
        checkout: [] as any[],
        applicationForm: [] as any[]
    }
}

export type UIState = typeof initialState;

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UIActionTypes.setLoading:
            const requestsInProgress = action.loading
                ? state.requestsInProgress + 1
                : Math.max(0, state.requestsInProgress - 1); // just to be sure
            return {
                ...state,
                requestsInProgress
            };
        case UIActionTypes.setProductSort:
            return {
                ...state,
                productSort: action.sort
            };
        case UIActionTypes.setBlockUi:
            return {
                ...state,
                blockUi: action.blockUi
            };
        case CustomerActionTypes.createCustomerError: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    createAccount: action.errors as any[]
                }
            }
        }
        case CheckoutActionTypes.removeItemsNotInInventory: {
            let items = [] as string[];
            action.items.map((item, i) => {
                items.push(`${i + 1}) ${item.productName}`);
                return;
            });
            return {
                ...state,
                errors: {
                    ...state.errors,
                    checkout: items
                }
            };
        }
        case ApplicationActionTypes.submitSeneSiteApplicationFormError: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    applicationForm: action.errors
                }
            };
        }
        case CheckoutActionTypes.declinedPayment: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    checkout: action.errors
                }
            };
        }
        // reset errors on navigate
        case LOCATION_CHANGE:
            return {
                ...state,
                errors: initialState.errors
            }
        default:
            return state;
    }
};
