import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { State } from '../../../store';
import { selectUserProfile } from '../../../store/selectors/authSelectors';
import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';

type withDistributorGuardProps = {
    user: any;
    senesiteDistributor: any;
    dispatch: Dispatch;
    redirectToDashboard();
}

export default function withDistributorGuard<OwnProps>(Component: React.ComponentType<OwnProps>) {
    class DistributorGuardedComponent extends React.Component<OwnProps & withDistributorGuardProps> {

        componentDidUpdate(prevProps: withDistributorGuardProps) {
            const {user, senesiteDistributor} = this.props;
            if (senesiteDistributor && user && this.isDistributor(user)) {
                if (user.name != senesiteDistributor.dist_ID) {
                    this.props.dispatch(push('/administration/dashboard/'));
                }
            }
        }

        render() {
            return <Component {...this.props} />
        }

        isDistributor(user) {
            if (Array.isArray(user.role)) {
                return user.role.find(x => x.toLowerCase() == "distributor") != undefined;
            }

            return user.role.toLowerCase() == "distributor";
        }

    }

    const mapStateToProps = (state: State) => ({
        user: selectUserProfile(state),
        senesiteDistributor: selectSenesiteDistributor(state)
    });

    return connect(mapStateToProps)(DistributorGuardedComponent as any);
}