import React from 'react';

import { AssetDirectImage } from '../../common/Images';
import { Distributor } from './../../../models/distributor';
import { SeneSocialMediaGroup } from '../../common/media/SeneSocialMediaGroup'
import DistributorPicture from '../../common/DistributorPicture';
import SeneSiteLink from '../../common/SeneSiteLink';
import './SeneHomeDistributorProfile.scss';
import i18n from '../../../i18n';

export interface SeneHomeDistributorProfileStateProps {
    distributor: Distributor
    hideContactMe?: boolean
    t: i18n.TranslationFunction
}

export class SeneHomeDistributorProfile extends React.PureComponent<SeneHomeDistributorProfileStateProps> {

    renderSignificantSeller = () => {
        if (this.props.distributor.isSignificantSeneSeller) {
            return <div>
                <AssetDirectImage {...{
                    src: 'icons/SignificantSeller_FinalLogo.svg',
                    className: 'home-dist-profile__seal',
                    alt: 'significant seller'
                }} />
            </div>
        }
        else return <span/>
    }

    renderContactMe = () => {
        if (this.props.hideContactMe) {
            return <span/>
        }
        else return <SeneSiteLink to="contact" className="btn btn-lg home-dist-profile__contact-button"> {this.props.t('contactMe')} </SeneSiteLink>
    }

    render() {
        const { t } = this.props;
        return (
            this.props.distributor &&
            <div className="home-dist-profile__info">
                <div className="home-dist-profile__picture mt-5">
                    <DistributorPicture distributor={this.props.distributor}/>
                </div>
                <div className="home-dist-profile__place">
                    {
                        this.props.distributor.profile.addresses &&
                        this.props.distributor.profile.addresses[0] &&
                        `${this.props.distributor.profile.addresses[0].city}, ${this.props.distributor.profile.addresses[0].stateName}`
                    }
                </div>
                <div className="home-dist-profile__id">
                    {t('distributorId')}:&nbsp;
                    <strong>
                        { this.props.distributor.dist_ID}
                    </strong>
                </div>
                <div>
                    <SeneSocialMediaGroup distributor={this.props.distributor}/>
                    <p className="home-dist-profile__connect">{t('connect')}</p>
                    {this.renderContactMe()}
                    {this.renderSignificantSeller()}
                </div>
            </div>
        )
    }
}

export default SeneHomeDistributorProfile;
