import axios from 'axios';

import { Order, OrderStatus } from './../models/order';

export const getAllOrdersForDistributor = (distId: number) => {
    return axios.get<Order[]>(`/api/distributor/${distId}/orders`).then(res => res.data);
}

export const getOrderById = (distId: number, orderId: number) => {
    return axios.get<Order>(`/api/distributor/${distId}/orders/${orderId}`).then(res => res.data);
}

export const updateDistributorOrderStatus = (distId: number, orderId: number, orderStatus: OrderStatus) => {
    return axios.post(`/api/distributor/${distId}/orders/${orderId}/UpdateOrderStatus?newStatus=${orderStatus}`);
}

export const getOrderBadgeCount = (distId: number) => {
    return axios.get<number>(`/api/distributor/${distId}/orders/GetOrderBadgeCount`).then(res => res.data);
}