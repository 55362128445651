import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import produce from 'immer';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router';

import { ActionToastProperties } from '../../../models/ui';
import withAdminPageTitle from '../withAdminPageTitle';
import { DistributorState } from '../../../reducers/distributor';
import Spinner from '../../common/Spinner';
import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';
import * as paymentOptionActions from '../../../actions/paymentOptionActions';
import {
    AdminPaymentOption as PaymentOption,
    AdminPaymentOption,
    CashOrCheckPaymentOption,
    CreditCardPaymentOption,
    PaymentType,
    PayPalApiPaymentOption,
    SquarePaymentOption
} from '../../../models/payment';
import { selectPaymentOptionList } from '../../../store/selectors/paymentOptionSelectors';
import CashOrCheckPanel from './CashOrCheckPanel';
import CreditCardPanel from './CreditCardPanel';
import SquareCredentialPanel from './SquareCredentialPanel';
import PayPalApiPanel from './PayPalApiPanel';
import SeneButton from '../../common/inputs/SeneButton';
import * as toast from './../../../utils/toastHelper';
import withRetryComponent, { RetryComponentProps } from '../../common/withRetryComponent';

export enum Panels {
    cashOrCheck = 1,
    payPalApi = 2,
    square = 3,
    creditCard = 4
}

interface PaymentOptionsPageStateProps {
    distributor: DistributorState;
    distributorPaymentInfo: AdminPaymentOption[] | null;
}

interface PaymentOptionsPageDispatchProps {
    getAvailableAdminPaymentOptions();
    updateDistributorPaymentOptions(paymentOptions: PaymentOption[], toastProps?: ActionToastProperties);
    getSquareURL(distId: number);
}

export type PaymentOptionsPageProps =
    PaymentOptionsPageStateProps &
    PaymentOptionsPageDispatchProps &
    WithNamespaces &
    RouteComponentProps &
    RetryComponentProps;

interface PaymentOptionsPageState {
    openPanel: number;
    mergedPaymentOptions: AdminPaymentOption[] | null;
    squareURL: string;
    squareErrors: string;
    creditCardErrors: string;
    isPayPalApiDirty: boolean;
}

class PaymentOptionsPage extends React.Component<PaymentOptionsPageProps, PaymentOptionsPageState> {

    state: PaymentOptionsPageState = {
        openPanel: 0,
        mergedPaymentOptions: null,
        squareURL: '',
        squareErrors: '',
        creditCardErrors: '',
        isPayPalApiDirty: false
    };

    componentDidMount() {
        if (this.props.distributor && this.props.distributor.dist_ID) {
            this.getPaymentOptions();
        }
        if (this.props.distributorPaymentInfo) {
            this.mergePaymentOptions();
        }

        const qs = queryString.parse(this.props.location.search);
        const openPanel = Panels[(qs["openPanel"] as string)];
        const squareErrors = (qs["error_msg"] as string);
        this.setState({
            openPanel: openPanel ? openPanel : 0,
            squareErrors: squareErrors ? squareErrors : ''
        })
    }

    componentDidUpdate(prevProps: PaymentOptionsPageProps) {
        if (this.shouldReFetchPaymentOptions(prevProps, this.props)) {
            this.getPaymentOptions();
        }

        if (this.havePaymentOptionsChanged(prevProps, this.props)) {
            this.mergePaymentOptions()
        }
    }

    getPaymentOptions() {
        this.props.getAvailableAdminPaymentOptions();
        this.setSquareUrl();
    }

    setSquareUrl() {
        if (this.props.distributor) {
            this.props.getSquareURL(this.props.distributor.dist_ID)
                .then(t => this.setState({ squareURL: t }))
                .catch(err => this.props.initFailed());
        }
    }

    shouldReFetchPaymentOptions(prevProps: PaymentOptionsPageProps | null, newProps: PaymentOptionsPageProps) {
        const prevDistId = prevProps && prevProps.distributor && prevProps.distributor.dist_ID;
        const newDistId = newProps.distributor && newProps.distributor.dist_ID;

        return prevDistId !== newDistId;
    }

    havePaymentOptionsChanged(prevProps: PaymentOptionsPageProps, newProps: PaymentOptionsPageProps) {
        return prevProps.distributorPaymentInfo !== newProps.distributorPaymentInfo;
    }

    mergePaymentOptions = () => {
        const distributorPaymentInfo = this.props.distributorPaymentInfo;
        this.setState({ mergedPaymentOptions: distributorPaymentInfo });
    };

    isOpen = (value: number): boolean => {
        return this.state.openPanel === value;
    };

    toggle = (value: number) => {
        if (this.state.openPanel === value) {
            this.setState({ openPanel: 0 })
        } else {
            this.setState({ openPanel: value })
        }
    };

    handlePaymentOptionChanged = (changedOption: PaymentOption) => {
        this.setState(
            produce(draft => {
                if (draft.mergedPaymentOptions) {
                    const optionIdx = draft.mergedPaymentOptions.findIndex(po => po.paymentMethodType === changedOption.paymentMethodType);
                    if (optionIdx != -1) {
                        draft.mergedPaymentOptions[optionIdx] = changedOption;
                    }
                }
                return draft;
            })
        );
    };

    validateCreditCardDate = (paymentOptionsToSave: PaymentOption[] | null): boolean => {
        let isValid = true;
        const creditCardPaymentOption = paymentOptionsToSave && paymentOptionsToSave.find(mp => mp.paymentMethodType === PaymentType.CreditCard) as CreditCardPaymentOption;
        if(creditCardPaymentOption) {
            if (creditCardPaymentOption.selectedMerchant) {
                //checks whenever any of the fields are left empty or only contains spaces
                const fields = creditCardPaymentOption.selectedMerchant.fields;
                isValid = !Object.keys(fields).find(t => !(fields[t].value && fields[t].value.trim()));
                this.setState({creditCardErrors: "vendorInfoRequired"})
            } else {
                isValid = false;
                this.setState({creditCardErrors: "vendorRequired"})
            }
        }
        if(isValid){
            this.setState({creditCardErrors: ""});
        }
        return isValid;
    }

    savePaymentOptions = () => {
        const paymentOptionsToSave = this.state.mergedPaymentOptions && this.state.mergedPaymentOptions.filter(po => po.selected);

        const isValid = this.validateCreditCardDate(paymentOptionsToSave);

        if(isValid) {
            toast.success({ success: this.props.t('userPaymentUpdateInProgress') });
            if (paymentOptionsToSave) {
                this.props.updateDistributorPaymentOptions(paymentOptionsToSave,
                    { success: this.props.t('userPaymentUpdateSuccess'), error: this.props.t('userPaymentUpdateError') });
            }
        }
    };

    unauthorizeFromSquare = () => {
        const paymentOptionsToSave = this.state.mergedPaymentOptions
            && this.state.mergedPaymentOptions.filter(t => t.paymentMethodType !== PaymentType.Square && t.selected);
        toast.success({ success: this.props.t('userPaymentUpdateInProgress') })
        if (paymentOptionsToSave) {
            this.props.updateDistributorPaymentOptions(paymentOptionsToSave,
                { success: this.props.t('userPaymentUpdateSuccess'), error: this.props.t('userPaymentUpdateError') });
        }
    }

    render() {
        const { t, distributor } = this.props;
        const { mergedPaymentOptions } = this.state;

        if (!distributor || !Array.isArray(mergedPaymentOptions)) {
            return <Spinner />;
        } else {
            const cashOrCheckPaymentOption = mergedPaymentOptions.find(mp => mp.paymentMethodType === PaymentType.CashOrCheck) as CashOrCheckPaymentOption;
            const creditCardPaymentOption = mergedPaymentOptions.find(mp => mp.paymentMethodType === PaymentType.CreditCard) as CreditCardPaymentOption;
            const payPalApiPaymentOption = mergedPaymentOptions.find(mp => mp.paymentMethodType === PaymentType.PayPalApi) as PayPalApiPaymentOption;
            const squarePaymentOption = mergedPaymentOptions.find(mp => mp.paymentMethodType === PaymentType.Square) as SquarePaymentOption;
            return (
                <div className="paymentOptions row">
                    <div className="col-12">
                        {
                            cashOrCheckPaymentOption &&
                            <div className="row mt-4">
                                <div className="col-12">
                                    <CashOrCheckPanel
                                        t={t}
                                        distributorPaymentOption={cashOrCheckPaymentOption}
                                        toggle={this.toggle}
                                        isOpen={this.isOpen(Panels.cashOrCheck)}
                                        panel={Panels.cashOrCheck}
                                        handlePaymentOptionChanged={this.handlePaymentOptionChanged}
                                    />
                                </div>
                            </div>
                        }
                        {
                            payPalApiPaymentOption &&
                            <div className="row mt-4">
                                <div className="col-12">
                                    <PayPalApiPanel
                                        t={t}
                                        distributorPaymentOption={payPalApiPaymentOption}
                                        toggle={this.toggle}
                                        isOpen={this.isOpen(Panels.payPalApi)}
                                        panel={Panels.payPalApi}
                                        handlePaymentOptionChanged={this.handlePaymentOptionChanged}
                                        setIsDirty={(isPayPalApiDirty) => this.setState({isPayPalApiDirty})}
                                    />
                                </div>
                            </div>
                        }
                        {
                            squarePaymentOption &&
                            <div className="row mt-4">
                                <div className="col-12">
                                    <SquareCredentialPanel
                                        t={t}
                                        distributorPaymentOption={squarePaymentOption}
                                        toggle={this.toggle}
                                        isOpen={this.isOpen(Panels.square)}
                                        panel={Panels.square}
                                        url={this.state.squareURL}
                                        error={this.state.squareErrors}
                                        unauthorizeFromSquare={this.unauthorizeFromSquare}
                                    />
                                </div>
                            </div>
                        }
                        {
                            creditCardPaymentOption &&
                            <div className="row mt-4">
                                <div className="col-12">
                                    <CreditCardPanel
                                        t={t}
                                        distributorPaymentOption={creditCardPaymentOption}
                                        toggle={this.toggle}
                                        isOpen={this.isOpen(Panels.creditCard)}
                                        panel={Panels.creditCard}
                                        handlePaymentOptionChanged={this.handlePaymentOptionChanged}
                                        creditCardErrors={this.state.creditCardErrors}
                                    />
                                </div>
                            </div>
                        }
                        {
                            <div className="row mt-4">
                                <div className="col-12" style={{ color: 'red' }}>{t(this.state.creditCardErrors)}</div>
                            </div>
                        }
                        {
                            mergedPaymentOptions &&
                            <div className="row mt-4">
                                <div className="col-12">
                                    <SeneButton
                                        onClick={this.savePaymentOptions}
                                        icon={'fas fa-save'}
                                        buttonClass={'secondary'}
                                        disabled={this.state.isPayPalApiDirty}
                                    >
                                        {t('save')}
                                    </SeneButton>
                                </div>
                            </div>
                        }
                    </div >
                </div >
            )
        }
    }

}

const mapStateToProps = state => ({
    distributor: selectSenesiteDistributor(state),
    distributorPaymentInfo: selectPaymentOptionList(state)
});

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        getAvailableAdminPaymentOptions: paymentOptionActions.getAvailablePaymentOptions,
        updateDistributorPaymentOptions: paymentOptionActions.updateDistributorPaymentOptions,
        getSquareURL: paymentOptionActions.getSquareURL
    }, dispatch)
);

export default withRetryComponent()(withAdminPageTitle('paymentoptions')(withNamespaces('BusinessGeneral')((connect(mapStateToProps, mapDispatchToProps)(PaymentOptionsPage)))));
