import { UserManager } from './UserManager';
import { Writeable, storage } from './../helpers';

const callbackKey = 'senesiteCallbackPath';
const impersonateDistKey = 'impersonateDist';

export const setCallbackUrl = (url: string) => {
    if (storage) {
        storage.setItem(callbackKey, url);
    } else {
        // HACK: In case of a disabled sessionStorage, overwrite the readonly redirect_uri of the userManager with the correct senesite url
        (UserManager.settings as Writeable<typeof UserManager.settings>).redirect_uri += `?url=${url}`;
    }
};

export const getCallbackUrl = () => storage ? storage.getItem(callbackKey) : null;

export const setImpersonatedDistId = (distId: string) => {
    if (storage) {
        storage.setItem(impersonateDistKey, distId);
    }
};

export const getImpersonatedDistId = () => 
    (storage && storage.getItem(impersonateDistKey)) ? +(storage.getItem(impersonateDistKey) as string) : null;