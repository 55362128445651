import React, { ChangeEvent, ReactNode } from "react";

import './RadioButton.scss';

interface RadioButtonProps {
    value: string;
    groupName: string;
    label: ReactNode;
    onChange(e: ChangeEvent<HTMLInputElement>): void;
    identifier: string;
    checked?: boolean;
    className?: string;
}

export const RadioButton = ({
    value,
    groupName,
    label,
    onChange,
    identifier,
    checked,
    className = ''
}: RadioButtonProps) => (
        <div className="field">
            <div className={`radio special ${className}`}>
                <label htmlFor={identifier} className="radio-container">
                    <div className="label-container">
                        {label}
                    </div>
                    <input name={groupName} type="radio" onChange={onChange} id={identifier} value={value} checked={checked} />
                    <span className="checkmark"></span>
                </label>
            </div>
            <div style={{ height: "5px" }} />
        </div>
    );
