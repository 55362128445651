import i18next from 'i18next';

import { CustomerInformation, ShippingAddress, ShippingInfo } from './../../../models/checkout';
import { Validator } from './../../../utils/validation/validator';
import { DistributorContact, DistributorProfile, DistributorAddress } from '../../../models/distributor';

export class DistributorProfileValidator extends Validator<DistributorProfile> {
    constructor(t: i18next.TranslationFunction) {
        super();

        this.ruleFor("firstName").notEmpty(t('firstNameError'));
        this.ruleFor("lastName").notEmpty(t('lastNameError'));
    }
}

export class DistributorContactValidator extends Validator<DistributorContact> {
    constructor(t: i18next.TranslationFunction) {
        super();

        this.ruleFor("email").notEmpty(t('emailError')).email(t('emailError'));
        this.ruleFor("phoneNumber1").notEmpty(t('phoneError')).phone(t('phoneError'));
    }
}

export class DistributorAddressValidator extends Validator<DistributorAddress> {
    constructor(t: i18next.TranslationFunction | ((key: string) => string), countryCode: string) {
        super();

        const cityKey = countryCode !== 'HK' ? 'cityError' : 'cityErrorHk';
        const stateKey = countryCode !== 'HK' ? 'stateError' : 'stateErrorHk';

        this.ruleFor('address1').notEmpty(t('address1Error'));
        this.ruleFor('city').notEmpty(t(cityKey));
        if (countryCode.toUpperCase() !== "NZ") {
            this.ruleFor('stateName').notEmpty(t(stateKey));
            this.ruleFor('stateId').notEmpty(t(stateKey));
        }

        if (countryCode.toUpperCase() !== 'HK') {
            this.ruleFor('zip').notEmpty(t('zipError'));
        }
    }
}