import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { cloneDeep } from 'lodash';
import produce from 'immer';

import withAdminPageTitle from '../withAdminPageTitle';
import * as adminApi from '../../../api/adminApi';
import * as distributorActions from '../../../actions/distributor';
import * as uiActions from '../../../actions/ui';
import { DistributorState } from '../../../reducers/distributor';
import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';
import { InventoryItem } from '../../../models/inventory';
import Spinner from '../../common/Spinner';
import { DesktopOrTablet, Mobile } from '../../common/responsive/ResponsiveHelpers';
import SeneInventoryDesktop from './SeneInventoryDesktop';
import SeneInventoryMobile from './SeneInventoryMobile';
import { SeneSiteInfo } from '../../../models/distributor';
import { ActionToastProperties } from '../../../models/ui';
import * as toast from '../../../utils/toastHelper';
import withRetryComponent, { RetryComponentProps } from '../../common/withRetryComponent';
import { shouldSwithToMaintenance } from "../../../utils/helpers";

interface SeneInventoryPageStateProps {
    distributor: DistributorState;
}

interface SeneInventoryDispatchProps {
    updateSeneSiteInfo(distId: number, seneSiteInfo: SeneSiteInfo, toastProps?: ActionToastProperties);
    setBlockUi(blockUi: boolean);
}

type SeneInventoryPageProps = SeneInventoryDispatchProps & SeneInventoryPageStateProps & RetryComponentProps & WithNamespaces;

interface SeneInventoryPageState {
    origInventory: InventoryItem[] | null;
    file: any | null;
    copyInventory: InventoryItem[] | null;
    searchString: string;
}

class SeneInventoryPage extends React.Component<SeneInventoryPageProps, SeneInventoryPageState> {

    updatedItemSkus = new Set<string>();

    state: SeneInventoryPageState = {
        origInventory: null,
        file: null,
        copyInventory: null,
        searchString: ''
    }

    componentDidMount() {
        this.getInventory();
    }

    componentDidUpdate(prevProps: SeneInventoryPageProps) {
        this.getInventory(prevProps.distributor && prevProps.distributor.dist_ID || undefined);
    }

    getInventory = (prevDistId?: number) => {
        const { distributor } = this.props;
        if (!distributor || !distributor.dist_ID || (prevDistId && distributor.dist_ID === prevDistId)) {
            return;
        }
        this.setState({
            copyInventory: null
        });
        adminApi.loadInventory(distributor.dist_ID)
        .then(resp => {
            this.setState({
                origInventory: resp, copyInventory: cloneDeep(resp)
            });
        })
            .catch(exp => {
                shouldSwithToMaintenance(exp);
                this.props.initFailed()
            });
    }

    handleSaleRegardless = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.distributor || !this.props.distributor.dist_ID || !this.props.distributor.seneSiteInfo) {
            return;
        }

        const seneSiteInfo = {
            ...this.props.distributor.seneSiteInfo,
            saleRegardless: event.target.checked
        };
        const distId = this.props.distributor.dist_ID;

        toast.success({ success: this.props.t('waitForProcessing')});
        this.props.setBlockUi(true);
        this.props.updateSeneSiteInfo(distId, seneSiteInfo, { success: this.props.t('updateSuccess'), error: this.props.t('updateError') })
            .then(response => {
                this.props.setBlockUi(false)
            })
            .catch((exp) => {
                shouldSwithToMaintenance(exp);
                this.props.setBlockUi(false);
            });
    }

    handleFileSelected = (event: any) => {
        this.setState({
            file: event.target.files[0]
        });
    }

    handleFileImport = () => {
        if (!this.props.distributor || !this.props.distributor.dist_ID || !this.state.file) {
            return;
        }
        const formData = new FormData();
        formData.append('file', this.state.file);
        this.props.setBlockUi(true);
        adminApi.uploadInventory(this.props.distributor.dist_ID, formData)
            .then(response => {
                this.props.setBlockUi(false);
                this.getInventory();
                this.updatedItemSkus.clear();
                toast.success({success: this.props.t('excelImportSuccess')});
            })
            .catch((exp) => {
                shouldSwithToMaintenance(exp);
                this.props.setBlockUi(false);
                toast.error({error: this.props.t('excelImportError')});
            });
    }

    updateQty = (value: number, identifier: string) => {
        const origInventoryItem = this.state.origInventory && this.state.origInventory.find(i => i.sku === identifier)
        const index = this.state.copyInventory && this.state.copyInventory.findIndex(item => item.sku === identifier);
        if (!this.state.origInventory || !origInventoryItem || !this.state.copyInventory || index === null || index < 0) {
            return;
        }

        this.setState(
            produce(draft => {
                if (draft.copyInventory && index > -1) {
                    draft.copyInventory[index].qty = value;
                }
                return draft;
            })
        );

        // update sku array to row coloring
        if (origInventoryItem.qty !== value) {
            this.updatedItemSkus.add(origInventoryItem.sku);
        } else {
            this.updatedItemSkus.delete(origInventoryItem.sku);
        }

    }

    handleSave = () => {
        if (!this.props.distributor || !this.props.distributor.dist_ID || !this.state.copyInventory) {
            return;
        }

        this.props.setBlockUi(true);
        const newInventory = this.state.copyInventory.filter(item => item.qty > 0);
        adminApi.updateInventory(this.props.distributor.dist_ID, newInventory)
            .then(response => {
                toast.success({success: this.props.t('updateSuccess')});
                this.props.setBlockUi(false);
                this.getInventory();
                this.updatedItemSkus.clear();
            })
            .catch((exp) => {
                shouldSwithToMaintenance(exp);
                this.props.setBlockUi(false);
                toast.error({error: this.props.t('updateError')});
            });
    }

    handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchString: event.target.value });
    }

    render() {

        if (!this.state.copyInventory) {
            return <Spinner />;
        } else {

            const { t } = this.props;
            const saleRegardless = this.props.distributor && this.props.distributor.seneSiteInfo && this.props.distributor.seneSiteInfo.saleRegardless || false;

            return (
                <div className="inventory">

                    <DesktopOrTablet>
                        <SeneInventoryDesktop
                            t={this.props.t}
                            distributor={this.props.distributor}
                            saleRegardless={saleRegardless}
                            inventory={this.state.copyInventory}
                            itemSkus={this.updatedItemSkus}
                            updateQty={this.updateQty}
                            handleSaleRegardless={this.handleSaleRegardless}
                            handleFileSelected={this.handleFileSelected}
                            handleFileImport={this.handleFileImport}
                            handleSave={this.handleSave}
                        />
                    </DesktopOrTablet>

                    <Mobile>
                        <SeneInventoryMobile
                            t={this.props.t}
                            distributor={this.props.distributor}
                            saleRegardless={saleRegardless}
                            inventory={this.state.copyInventory}
                            itemSkus={this.updatedItemSkus}
                            updateQty={this.updateQty}
                            searchString={this.state.searchString}
                            handleSaleRegardless={this.handleSaleRegardless}
                            handleFileSelected={this.handleFileSelected}
                            handleFileImport={this.handleFileImport}
                            handleSave={this.handleSave}
                            handleSearch={this.handleSearch}
                        />
                    </Mobile>

                </div>

            )

        }

    }

}

const mapStateToProps = state => ({
    distributor: selectSenesiteDistributor(state)
});

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        updateSeneSiteInfo: distributorActions.updateDistributorSeneSiteInfo,
        setBlockUi: uiActions.setBlockUi
    }, dispatch)
);

export default withRetryComponent()(withAdminPageTitle('myinventory')(withNamespaces('SeneSiteInventory')((connect(mapStateToProps, mapDispatchToProps)(SeneInventoryPage)))));
