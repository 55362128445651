import { PublicPaymentMethod as PaymentMethod } from './../models/distributor';
import { CheckoutActionTypes } from './../actions/checkoutActions';
import { CustomerInformation, ShippingAddress, ShippingInfo, CartTotalsResponse, CreditCardInfo, SquarePaymentInfo } from './../models/checkout';

export interface PaymentInfoState {
    paymentMethod: PaymentMethod;
    ccInfo?: CreditCardInfo;
    sqInfo?: SquarePaymentInfo;
    payPalApiPaymentId? : string;
}

export interface CheckoutErrors {
    canContinue?: boolean;
    missingInventoryErrors?: string[];
    declinedPaymentErrors?: string[];
}

export type CheckoutState = {
    customerInfo?: CustomerInformation,
    shippingAddress?: ShippingAddress,
    shippingMethod?: ShippingInfo,
    paymentInfo?: PaymentInfoState;
    totalCharges?: CartTotalsResponse,
    checkoutErrors?: CheckoutErrors,
    jumpToPlaceOrder?: boolean
}
const initialState = {} as CheckoutState;

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CheckoutActionTypes.saveOrderInfo: {
            return {
                ...state,
                customerInfo: action.customerInfo,
                shippingAddress: action.shippingAddress,
                shippingMethod: action.shippingMethod
            };
        }
        case CheckoutActionTypes.savePaymentInfo: {
            return {
                ...state,
                paymentInfo: action.paymentInfo
            };
        }
        case CheckoutActionTypes.calculateChargesSuccess: {
            return {
                ...state,
                totalCharges: action.total
            };
        }
        case CheckoutActionTypes.calculateChargesError: {
            return {
                ...state,
                totalCharges: undefined
            };
        }
        case CheckoutActionTypes.removeItemsNotInInventory: {
            let items = [] as string[];
            action.items.map((item, i) => {
                items.push(`${i + 1}) ${item.productName}`);
                return;
            });
            const canContinueCheckout = state.checkoutErrors ? !!state.checkoutErrors.canContinue : true;
            return {
                ...state,
                checkoutErrors: {
                    ...state.checkoutErrors,
                    canContinue: canContinueCheckout,
                    missingInventoryErrors: items
                }
            };
        }
        case CheckoutActionTypes.declinedPayment: {
            return {
                ...state,
                checkoutErrors: {
                    ...state.checkoutErrors,
                    canContinue: false,
                    declinedPaymentErrors: action.errors
                }
            };
        }
        case CheckoutActionTypes.orderSuccess: {
            return initialState;
        }
        case CheckoutActionTypes.payPalApiPaymentIdReceived: {
            return {
                ...state,
                paymentInfo: {
                    ...state.paymentInfo,
                    payPalApiPaymentId : action.payPalApiPaymentId
                }
            }
        }
        case CheckoutActionTypes.reloadCheckoutState: {
            return {...action.checkoutState, jumpToPlaceOrder: true};
        }
        case CheckoutActionTypes.resetCheckoutErrors: {
            return {
                ...state,
                checkoutErrors: null
            };
        }
        default:
            return state;
    }
}
