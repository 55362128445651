import React from 'react';

interface IRadioButtonItem {
    value: string;
    groupname: string;
    labelDisplayValue?: any;
    onChange(e: React.InputHTMLAttributes<HTMLInputElement>): void;
    identifier: string;
    checked?: boolean;
    itemStyle?: string;
}

export const RadioButton = (params: IRadioButtonItem) => {
    const divStyle = params.itemStyle ? params.itemStyle : "radio special"
    return (<div className="field">
        <div className={divStyle}>
            <label htmlFor={params.identifier} className="radio-container">
                <div className="label-container">
                    {params.labelDisplayValue}
                </div>
                <input name={params.groupname} type="radio" onChange={params.onChange} id={params.identifier} value={params.value} checked={params.checked} />
                <span className="checkmark"></span>
            </label>
        </div>
        <div style={{height : "5px"}} />
    </div>);
}

export default RadioButton;