import React from 'react';

import SeneSiteLink from '../../common/SeneSiteLink';
import './SeneHomeShopNowBanner.scss';
import { CreateBackgroundImageUrl } from '../../common/Images';
import { Configuration } from '../../../models/configuration';
import { State } from '../../../store';
import { selectConfiguration } from '../../../store/selectors/configurationSelectors';
import { connect } from 'react-redux';

export interface SeneHomeShopNowBannerStateProps {
    showNowTranslate: string,
    imageResizerUrl: string,
    config: Configuration | null
}

export class SeneHomeShopNowBanner extends React.PureComponent<SeneHomeShopNowBannerStateProps> {

    render() {
        return (
            <div className="shop-now-banner" style={{ backgroundImage: CreateBackgroundImageUrl({"imageResizerUrl": this.props.imageResizerUrl, "config": this.props.config, "src": "products/ShopProducts/products_right.jpg","width": 400,"height": 300,"crop": "crop"}) }}>
                    <SeneSiteLink to="shopproducts" className="btn btn-lg shop-now-button"> {this.props.showNowTranslate} </SeneSiteLink>
            </div>
        )
    }
}

const mapStateToProps = (state: State) => ({
    config: selectConfiguration(state)
})

export default connect(mapStateToProps)(SeneHomeShopNowBanner);