import { ToastOptions, toast, Flip } from 'react-toastify';
import { ActionToastProperties } from '../models/ui';

const defaultOptions: ToastOptions = {
    hideProgressBar: true,
    transition: Flip
}

const defaultSuccessOptions: ToastOptions = {
    ...defaultOptions,
    autoClose: 5000
}

const defaultErrorOptions: ToastOptions = {
    ...defaultOptions,
    autoClose: false
}

export const success = (toastProperties?: ActionToastProperties, options: ToastOptions = defaultSuccessOptions) => {
    if (toastProperties && toastProperties.success) {
        toast.success(toastProperties.success, options);
    }
}

export const error = (toastProperties?: ActionToastProperties, options: ToastOptions = defaultErrorOptions) => {
    if (toastProperties && toastProperties.error) {
        toast.error(toastProperties.error, options);
    }
}

export const info = (toastProperties?: ActionToastProperties, options: ToastOptions = defaultSuccessOptions) => {
    if (toastProperties && toastProperties.info) {
        toast.info(toastProperties.info, options);
    }
}