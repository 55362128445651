import { CartActionTypes } from './../../actions/cartActions';
import { CheckoutActionTypes } from '../../actions/checkoutActions';
import { cartLocalStorageKey } from '../../utils/constants';
import { State } from '..';

/**
 * Middleware for persisting cart state in localStorage
 */
export const cartPersister = (store) => next => action => {
    const result = next(action);

    if (action.type === CartActionTypes.addProductToCart
        || action.type === CartActionTypes.removeProductFromCart
        || action.type === CartActionTypes.changeProductQty
        || action.type === CheckoutActionTypes.orderSuccess) {
        persistCartInLocalstorage(store.getState());
    }

    return result;
}

const persistCartInLocalstorage = (state: State) => {
    let seneSiteUrl;
    if (state.distributor && state.distributor.seneSiteInfo && state.distributor.seneSiteInfo.seneSiteUrl) {
        seneSiteUrl = state.distributor.seneSiteInfo.seneSiteUrl.toLowerCase();
    }

    if (seneSiteUrl) {
        const cartInStorage = JSON.parse(localStorage.getItem(cartLocalStorageKey) || '{}');
        cartInStorage[seneSiteUrl] = state.cart;

        localStorage.setItem(cartLocalStorageKey, JSON.stringify(cartInStorage));
    }
}
