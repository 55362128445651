import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history';
import { OidcProvider } from 'redux-oidc';
import { loadUser } from 'redux-oidc';
import { I18nextProvider } from 'react-i18next';
import Modal from 'react-modal';

import './styles/index.scss';
import './api/apiConfig';
import i18n from './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import * as configApi from './api/configurationApi';
import { instantiateUserManager } from './utils/auth/UserManager';
import { startSignalR } from './utils/signalR/SignalRHelper';
import { initGoogleAnalytics } from './utils/analytics/googleAnalyticsHelper';
import { getCurrentUserInfo } from './actions/userActions';
import { ConfigurationActionTypes } from './actions/configurationActions';

const history = createBrowserHistory();
const store = configureStore(history, {});

configApi.getConfiguration().then(config => {
    store.dispatch({ type: ConfigurationActionTypes.getConfigurationSuccess, payload: config });
    const rootElement = document.getElementById('root');
    const userManager = instantiateUserManager(config.securityConfiguration, store.dispatch, i18n.language);
    loadUser(store, userManager).then((user) => {
        if (user) {
            store.dispatch(getCurrentUserInfo() as any);
        }
	})
    startSignalR(store.dispatch, config.signalRHub);
    initGoogleAnalytics(config.gTag, history);

    const render = Component => {
        return ReactDOM.render(
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <OidcProvider store={store} userManager={userManager}>
                        <ConnectedRouter history={history}>
                            <Component />
                        </ConnectedRouter>
                    </OidcProvider>
                </Provider>
            </I18nextProvider>,
            rootElement
        );
    };

    render(App);

    Modal.setAppElement(rootElement);

    if (module.hot) {
        module.hot.accept('./App', () => {
            const NextApp = require('./App').default;
            render(NextApp);
        })
    }

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
});
