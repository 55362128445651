import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from "query-string";

import { State } from '../../../store';
import { CheckoutState, CheckoutErrors } from '../../../reducers/checkout';
import { selectCheckoutState, selectCheckoutErrors } from '../../../store/selectors/checkoutSelectors';
import { reloadCheckoutState, submitPayment, resetCheckoutErrors } from "../../../actions/checkoutActions";
import { loadProductsInCart } from '../../../actions/cartActions';
import { ActionToastProperties } from '../../../models/ui';
import { seneSiteNavigateTo } from '../../../actions/router';
import { CartViewModel } from '../../../models/cart';
import { selectCartViewModel } from '../../../store/selectors/cartSelectors';
import { selectDistributorId, selectHandlingRate } from '../../../store/selectors/distributorSelectors';
import Spinner from '../../common/Spinner';
import SectionHeader from '../../common/headers/SectionHeader';
import OrderSummary from './place-order/OrderSummary';
import * as paymentApi from "../../../api/paymentApi";
import { PaymentType } from "../../../models/payment";
import { shouldSwithToMaintenance } from "../../../utils/helpers";

interface PlaceOrderRedirectPageStateProps {
    checkoutState: CheckoutState;
    cart: CartViewModel;
    distributorId: number,
    handlingRate: number;
    reloadCheckoutState(payPalApiPaymentId: string, checkoutState: any);
    resetCheckoutErrors();
    loadProductsInCart();
    submitPayment(customerNotes: string, toastProps?: ActionToastProperties);
    navigate(to: string);
    checkoutErrors?: CheckoutErrors;
}

interface PlaceOrderRedirectState {
    customerNotes: string;
}

type PlaceOrderRedirectPageProps = PlaceOrderRedirectPageStateProps & RouteComponentProps & WithNamespaces;

class PlaceOrderRedirectPage extends React.Component<PlaceOrderRedirectPageProps, PlaceOrderRedirectState> {

    componentDidMount() {
        const qs = queryString.parse(this.props.location.search);
        const customerNotes = qs['customerNotes'];
        this.setState({
            customerNotes: (customerNotes && customerNotes.toString()) || ''
        });
        const paymentId = qs['paymentId'];
        const payPalApiToken = qs['token'];
        if (paymentId && payPalApiToken) {
            paymentApi.getCartInformation(this.props.distributorId, paymentId.toString())
                .then(res => {
                    const paymentInformation = res.paymentInformation;
                    const cartTotals = res.cartTotals;

                    const checkoutState = {
                        paymentInfo: { paymentMethod: { paymentMethodId: 0, paymentMethodType: PaymentType.PayPalApi } },
                        customerInfo: paymentInformation.customerInformation,
                        shippingAddress: paymentInformation.shoppingCart.shippingAddress,
                        shippingMethod: paymentInformation.shoppingCart.shippingInfo,
                        totalCharges: cartTotals
                    };

                    this.props.reloadCheckoutState(paymentId.toString(), checkoutState);
                    this.props.loadProductsInCart();
                })
                .catch(exp => {
                    shouldSwithToMaintenance(exp);
                    this.props.navigate('/shopproducts');
                    this.props.resetCheckoutErrors();
                });
        }
    }

    onSubmitOrder = () => {
        const { t } = this.props;
        this.props.submitPayment(this.state.customerNotes, { success: t('submitOrderSuccess'), error: t('submitOrderError') });
    }

    renderContinue = () => {
        if (this.props.cart.items.length > 0) {
            return <div id='inventoryError' className="alert alert-warning col-12" role="alert">
                <strong> {this.props.t('continueCheckout')}</strong>
            </div>;
        } else {
            return <span></span>
        }

    }

    renderAlerts() {
        const checkoutErrors = this.props.checkoutErrors;
        if (checkoutErrors && (checkoutErrors.declinedPaymentErrors || checkoutErrors.missingInventoryErrors)) {
            return <>
                {<div className="alert alert-danger col-12" role="alert">
                    {checkoutErrors.missingInventoryErrors &&
                        <div key={'missingItems'}>{this.props.t('notEnoughInventory')}</div>}
                    {checkoutErrors.missingInventoryErrors && checkoutErrors.missingInventoryErrors.map((error) => {
                        return <div key={error}>{error}</div>
                    })}
                    {checkoutErrors.declinedPaymentErrors && checkoutErrors.declinedPaymentErrors.map((error) => {
                        return <div key={error}>{error}</div>
                    })}
                </div>}
                {checkoutErrors.canContinue && this.renderContinue()}
            </>

        } else {
            return <span></span>
        }
    }

    render() {
        const { t, checkoutState, cart, handlingRate } = this.props;
        if (!checkoutState || !checkoutState.totalCharges || !cart) {
            return (<Spinner />)
        } else {
            return (
                <>
                    <div>
                        {this.renderAlerts()}
                    </div>
                    <SectionHeader>{t('title')}</SectionHeader>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-6 offset-xl-3">
                                <h3 style={{ color: '#f58220' }}>{t('completeOrderMsg')}</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-6 offset-xl-3">
                                <OrderSummary
                                    t={t}
                                    cart={cart}
                                    handlingRate={handlingRate}
                                    isLoading={false}
                                    key={'placeOrderRedirectPage'}
                                    paymentInfoState={checkoutState.paymentInfo}
                                    shippingMethod={checkoutState.shippingMethod}
                                    totalCharges={checkoutState.totalCharges}
                                    onSubmitOrder={this.onSubmitOrder}
                                    buttonLabel={'placeOrder'}
                                    errors={this.props.checkoutErrors}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }

    }
}

const mapStateToProps = (state: State) => ({
    checkoutState: selectCheckoutState(state),
    cart: selectCartViewModel(state),
    distributorId: selectDistributorId(state),
    handlingRate: selectHandlingRate(state),
    checkoutErrors: selectCheckoutErrors(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    reloadCheckoutState,
    resetCheckoutErrors,
    loadProductsInCart,
    submitPayment,
    navigate: seneSiteNavigateTo
}, dispatch);

export default withNamespaces('CheckoutPage')(connect(mapStateToProps, mapDispatchToProps)(PlaceOrderRedirectPage));
