import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { bindActionCreators } from "redux";

import { CountryLanguage } from "../../models/language";
import { selectAvailableLanguages, selectSelectedLanguageCode } from "../../store/selectors/languageSelectors";
import { changeSelectedLanguage } from "../../actions/languageActions";
import { selectSeneSiteUrl } from '../../store/selectors/distributorSelectors';

interface LanguageSelectorMobileStateProps {
    languages: CountryLanguage[];
    selectedLanguageCode: string;
    renderInAdminHeader: boolean;
    seneSiteUrl: string | null;
    changeSelectedLanguage(selectedLanguage: CountryLanguage, seneSiteUrl: string | null);
    onSelectedLanguageChanged();
}

type LanguageSelectorMobileProps = LanguageSelectorMobileStateProps & WithNamespaces;

class LanguageSelectorMobile extends React.PureComponent<LanguageSelectorMobileProps> {
    

    onLanguageClicked = (e, langCode: string) => {
        e.preventDefault();
        this.changeLanguage(langCode);
        
    };
    
    changeLanguage(langCode: string) {
        const {i18n} = this.props;
        if (langCode) {
            const lang = this.props.languages.filter(lang => lang.code === langCode)[0];
            i18n.changeLanguage(langCode, () => {
                this.props.changeSelectedLanguage(lang, this.props.seneSiteUrl);
                this.props.onSelectedLanguageChanged();
            });
        }
    }
    
    get mobileLanguages() {
        return this.props.languages.filter(l => l.code != this.props.selectedLanguageCode);
    }

    render() {
        let languageList;
        if (this.props.renderInAdminHeader) {
            languageList = (this.mobileLanguages.map(lang => (
                <span key={lang.code} className="list-group-item list-group-item-action" 
                      onClick={(e) => this.onLanguageClicked(e, lang.code)}>{lang.languageDisplayName}</span>
            )));
        } else {
            languageList = (<li className="list-group nav-item nav-item--mobile">
                {
                    this.mobileLanguages.map(lang => (
                        <a key={lang.code} className="nav-link" onClick={(e) => this.onLanguageClicked(e, lang.code)}>
                            {lang.languageDisplayName}
                        </a>
                    ))
                }
            </li>);
        }
        return (
            languageList
        );
    }
}

const mapStateToProps = (state) => ({
    languages: selectAvailableLanguages(state),
    selectedLanguageCode: selectSelectedLanguageCode(state),
    seneSiteUrl: selectSeneSiteUrl(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        changeSelectedLanguage        
    }, dispatch);
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(LanguageSelectorMobile));
