import i18next from 'i18next';

import { HandlingInfo, ShippingInfo } from './../../../models/distributor';
import { Validator } from './../../../utils/validation/validator';

export class HandlingFeeValidator extends Validator<HandlingInfo> {
    constructor(t: i18next.TranslationFunction) {
        super();

        this.ruleFor("rate").greaterThan(0, t('rateNonNegativeMsg')).max(999999, t('rateMaximumMsg')).isFloat(t('rateFloatMsg'));
    }
}

export class ShippingFeeValidator extends Validator<ShippingInfo> {
    constructor(t: i18next.TranslationFunction) {
        super();

        this.ruleFor('carrierName').notEmpty(t('carrierNameNotEmptyMsg'));
        this.ruleFor("rate").min(0, t('rateNonNegativeMsg')).max(999999, t('rateMaximumMsg')).isFloat(t('rateFloatMsg'));
    }
}
