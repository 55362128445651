import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';

import { selectSelectedLanguageCode } from '../../../store/selectors/languageSelectors';
import { SeneSiteApplicationFormValidator } from './SeneSiteApplicationFormValidator';
import { selectSenesiteDistributor } from '../../../store/selectors/distributorSelectors';
import SeneButton from '../../common/inputs/SeneButton';
import { SeneImage } from '../../common/Images';
import { ValidationErrors } from '../../../utils/validation/validator';
import { verifySeneSiteName, submitSeneSiteApplicationForm } from '../../../actions/administration/applicationFormActions';
import SeneSiteApplication from '../../../assets/images/senesite_application.png';
import SenegenceLogo from '../../../assets/images/senegence_logo.png';
import './SeneSiteApplicationForm.scss';
import { DistributorState } from '../../../reducers/distributor';
import { selectLoading, selectApplicationFormErrors } from '../../../store/selectors/uiSelectors';
import {Alert} from "reactstrap";
import { ActionToastProperties } from '../../../models/ui';
import { DisplayApplicationFormData } from '../../../models/applicationForm';
import * as applicationFormApi from '../../../api/applicationFormApi';
import Spinner from '../../common/Spinner';
import { Redirect } from 'react-router';
import SeneSiteUrlValidatorInput from './SeneSiteUrlValidatorInput';

interface SeneSiteApplicationFormInfo {
    seneSiteName: string;
}

export type SeneSiteApplicationFormModel = SeneSiteApplicationFormInfo & { agree: boolean; isValidSeneSiteName: boolean; };

interface SeneSiteApplicationFormStateProps {
    distributor: DistributorState;
    isImpersonation: boolean;
    loading: boolean;
    submitErrors: any[];
    locale: string;

}

interface SeneSiteApplicationFormDispatchProps {
    verifySeneSiteName(seneSiteName: string);
    submitSeneSiteApplicationForm(model: SeneSiteApplicationFormModel, toastProps?: ActionToastProperties);
}

type SeneSiteApplicationFormProps = SeneSiteApplicationFormStateProps & SeneSiteApplicationFormDispatchProps & WithNamespaces;

interface SeneSiteApplicationFormState {
    seneSiteApplicationFormInfo: SeneSiteApplicationFormModel;
    validationMessages: ValidationErrors<SeneSiteApplicationFormModel>;
    errors: any;
    displayApplicationFormData?: DisplayApplicationFormData;
    displayState: 'Form' | 'NotAvailable' | 'LaunchSoon' | ''
}

class SeneSiteApplicationForm extends React.Component<SeneSiteApplicationFormProps, SeneSiteApplicationFormState> {

    state: SeneSiteApplicationFormState = {
        seneSiteApplicationFormInfo: {
            seneSiteName: '',
            agree: false,
            isValidSeneSiteName: false
        },
        validationMessages: {},
        errors: {},
        displayState: ''
    };

    applicationFormValidator?: SeneSiteApplicationFormValidator;

    componentDidMount() {
        if(this.props.distributor) {
            this.getApplicationformConfiguration();
        }
    }

    componentDidUpdate(prevProps: SeneSiteApplicationFormProps) {
        if (this.props.distributor && prevProps.distributor && this.props.distributor.dist_ID != prevProps.distributor.dist_ID) {
            this.getApplicationformConfiguration(prevProps.distributor && prevProps.distributor.dist_ID || undefined);
        }
    }

    setDisplayState() {
        if(!this.state.displayApplicationFormData) {
            return;
        }

        const nowDate = Date.now();
        const config = this.state.displayApplicationFormData.applicationFormConfiguration;
        const startDate = Date.parse(config && config.seneSiteApplicationStartDate || "");
        const endDate = Date.parse(config && config.seneSiteApplicationEndDate || "");
        if((!endDate || (endDate && endDate > nowDate)) &&
            startDate && startDate < nowDate){
            this.setState( {
                displayState: 'Form'
            })
        }
        else if(!startDate || (endDate && endDate < nowDate)) {
            this.setState( {
                displayState: 'NotAvailable'
            })
        }
        else {
            this.setState( {
                displayState: 'LaunchSoon'
            })
        }
    }

    getApplicationformConfiguration(prevDistId?: number) {
        const { distributor } = this.props;
        if (!distributor || (prevDistId && distributor.dist_ID === prevDistId)) {
            return;
        }
        applicationFormApi.getApplicationformConfiguration(distributor.dist_ID)
            .then(displayApplicationFormData => {
                this.setState({ displayApplicationFormData} , () => {this.setDisplayState();});
            })
    }

    isValidated = () => {
        this.applicationFormValidator = new SeneSiteApplicationFormValidator(this.props.t);
        const applicationFormResult = this.applicationFormValidator.validate(this.state.seneSiteApplicationFormInfo);
        if (applicationFormResult.isValid) {
            return true;
        } else {
            const seneSiteNameError = applicationFormResult.errors['isValidSeneSiteName'] || this.state.validationMessages.seneSiteName;
            this.setState({
                errors: applicationFormResult.errors,
                validationMessages: {
                    ...this.state.validationMessages,
                    seneSiteName:  seneSiteNameError
                }
            })

            return false;
        }
    }

    handlePrintClick = () => {
        window.print();
    }

    handleAgreeCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        this.setState({
            seneSiteApplicationFormInfo: {
                ...this.state.seneSiteApplicationFormInfo,
                agree: value
            }
        });
    }

    handleSubmitClick = () => {
        const { t } = this.props;
        if(this.isValidated()) {
            this.props.submitSeneSiteApplicationForm(
                this.state.seneSiteApplicationFormInfo, 
                { success: t('seneSiteApplicationFormSent'), error: t('seneSiteApplicationFormUnexceptedError') }
            )
        }
    }

    renderSeneSiteName = () => {
        const { t } = this.props;
        const { isValidSeneSiteName } = this.state.seneSiteApplicationFormInfo;
        return (
            <div>
                    <SeneSiteUrlValidatorInput 
                        updateIsValidSeneSiteName={this.updateIsValidSeneSiteName}
                        updateSeneSiteName={this.updateSeneSiteName}
                        seneSiteApplicationFormInfo={this.state.seneSiteApplicationFormInfo}
                        t={this.props.t}
                        loading={this.props.loading}
                        locale={this.props.locale}
                        verifySeneSiteName={this.props.verifySeneSiteName}
                    />
            </div>
        )
    }

    updateSeneSiteName = (value: string) => {
        this.setState({
            seneSiteApplicationFormInfo: {
                ...this.state.seneSiteApplicationFormInfo,
                seneSiteName: value
            }
        });
    }
    updateIsValidSeneSiteName = (value: boolean) => {
        this.setState({
            seneSiteApplicationFormInfo: {
                ...this.state.seneSiteApplicationFormInfo,
                isValidSeneSiteName: value
            }
        });
    }

    renderDistributorAgreement = () => {
        const { t } = this.props;
        const TermsAndConditions = this.state.displayApplicationFormData && 
            this.state.displayApplicationFormData.applicationFormConfiguration.termsAndConditions[this.props.locale.toUpperCase()];
        return (
            <div className="form-group">
                <h3 className="paragraph-title">{t('distributorAgreement')}</h3>
                <div className="form-group mt-4">
                    <div>{t('conditions')}</div>
                    <div>{`1. ${t('refundMessage')}`}</div>
                    <div>{`2. ${t('recurringChargesMessage')}`}</div>
                </div>
                <div className="form-group">
                    <div className="h5 mb-3">{t('licenseAgreement')}</div>
                    <FormGroup>
                        <Input {...{
                            id: 'licenseAgreement',
                            type: 'textarea',
                            rows: 10,
                            name: 'licenseAgreementInstructions',
                            value: TermsAndConditions,
                            readOnly: true,
                            onChange: () => { }
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <SeneButton {...{
                            id: 'printTermsAndConditions',
                            buttonClass: 'secondary',
                            onClick: this.handlePrintClick
                        }}>
                            {(t('printTermsAndConditions')).toUpperCase()}
                        </SeneButton>
                    </FormGroup>
                    <FormGroup check className="mt-2">
                        <Label check>
                            <Input {...{
                                id: 'agreeTermsAndConditions',
                                type: 'checkbox',
                                name: 'aggree',
                                checked: this.state.seneSiteApplicationFormInfo.agree,
                                onChange: this.handleAgreeCheckBoxChange
                            }} />
                            {`${t('checkBoxAgreeTermsAndConditionsLabel')} *`}
                            {
                                (this.state.errors.agree) &&
                                <div className="alert alert-danger col-12 mb-1">
                                    {this.state.errors.agree && (
                                        <>
                                            {this.state.errors.agree}
                                            <br />
                                        </>
                                    )}
                                </div>
                            }
                        </Label>
                    </FormGroup>
                </div>
            </div>
        )
    }

    renderSubmit = () => {
        const { t } = this.props;
        return (
            <div className="form-group">
                <SeneButton {...{
                    id: 'submit',
                    buttonClass: 'secondary',
                    className: 'btn-lg text-capitalize',
                    onClick: this.handleSubmitClick
                }}>
                    {(t('submit')).toUpperCase()}
                </SeneButton>
            </div>
        )
    }

    renderFooter = () => {
        const { t } = this.props;
        const year = (new Date()).getFullYear();
        return (
            <footer className="mt-4">
                <p>&copy;{` ${year} ${t('allRightsReserved')}`}</p>
                <p>{t('noPortionCanBeReproduced')}</p>
                <p>
                    <span>{`${t('clickHereFor')} `}</span>
                    <span><a href="https://assets.senegence.com/SenegenceCom/Senesite/Application/TermsOfUse.pdf">{t('termsOfUse')}</a></span>
                    <span>{` ${t('and')} `}</span>
                    <span><a href="https://assets.senegence.com/SenegenceCom/Senesite/Application/PrivacyPolicyEnglish.pdf">{t('privacyStatement')}</a></span>
                </p>
            </footer>
        )
    }

    renderSubmitErrors() {
        const submitErrors = this.props.submitErrors;
        if (submitErrors && submitErrors.length > 0) {
            return <>
                <div className="alert alert-danger col-12" role="alert">
                    {
                        submitErrors.map(error => <div key={error}>{this.props.t(error)}</div>)
                    }
                </div>
            </>

        } else {
            return <span></span>
        }
    }

    renderForm() {
        return (
            <>
                <div className="col-12">
                    <SeneImage {...{
                        src: SeneSiteApplication,
                        className: 'header-image mt-4 mb-4'
                    }} />
                </div>
                <div className="row spacer senesite-name-edit">
                    <div className="col-12">
                        {this.renderSeneSiteName()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {this.renderDistributorAgreement()}
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-12">
                        {this.renderSubmitErrors()}
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-12">
                        {this.renderSubmit()}
                    </div>
                </div>
            </>
        );
    }

    renderNotAvailable() {
        return (
            <h2 id="notavailable" className="my-5">{this.props.t('applicationFormNotAvailable')}</h2>
        )
    }

    renderLaunchSoon() {
        const startDate = (this.state.displayApplicationFormData && 
            this.state.displayApplicationFormData.applicationFormConfiguration.seneSiteApplicationStartDate);
        const displayDate = startDate ? new Date(Date.parse(startDate)) : new Date();
        return (
            <h2 id="launchsoon" className="my-5">{this.props.t('applicationFormLaunchSoon', {'date': displayDate.toLocaleDateString()})}</h2>
        )
    }

    renderPrint() {
        const TermsAndConditions = this.state.displayApplicationFormData && 
            this.state.displayApplicationFormData.applicationFormConfiguration.termsAndConditions[this.props.locale.toUpperCase()];
        return (
            <div className="application_form_section_to_print">
                {TermsAndConditions}
            </div>
        )
    }

    render() {
        if (this.props.isImpersonation) {
            return (
                <div className="container application-form">
                    <Alert className="alert alert-danger">
                        {this.props.t('seneCareImpersonationNotSupported')}
                    </Alert>
                </div>
            )    
        }
        if(this.state.displayApplicationFormData) {

            if(this.state.displayApplicationFormData.isEligibleForApplicationForm) {
    
                return ( <>
                    {this.state.displayState === "Form" && this.renderPrint()}
                    <div className="container application-form application_form_hide_from_print">
                        <div className="row justify-content-center spacer">
                            <div className="col-11">
                                <div className="row text-center spacer">
                                    <div className="col-12">
                                        <SeneImage {...{
                                            src: SenegenceLogo,
                                            className: 'header-image mt-4 mb-2'
                                        }} />
                                        <div className="row text-center spacer"></div>
                                        {this.state.displayState === "Form" && this.renderForm()}
                                        {this.state.displayState === "NotAvailable" && this.renderNotAvailable()}
                                        {this.state.displayState === "LaunchSoon" && this.renderLaunchSoon()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-11 text-center">
                                {this.renderFooter()}
                            </div>
                        </div>
                    </div> 
                    </>)
            }
            return <Redirect to="/" />
        }
        return <Spinner></Spinner>;
    }
}

const mapStateToProps = (state) => ({
    distributor: selectSenesiteDistributor(state),
    loading: selectLoading(state),
    locale: selectSelectedLanguageCode(state),
    submitErrors: selectApplicationFormErrors(state)
});

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        verifySeneSiteName: verifySeneSiteName,
        submitSeneSiteApplicationForm: submitSeneSiteApplicationForm
    }, dispatch)
);

export default withNamespaces('SeneSiteApplicationForm')(connect(mapStateToProps, mapDispatchToProps)(SeneSiteApplicationForm));
