import { selectRegionsForCountry } from './../store/selectors/regionSelectors';
import { selectDistributorCountry } from './../store/selectors/distributorSelectors';
import { Thunk } from './../store/index';
import * as regionApi from '../api/regionsApi';
import { shouldSwithToMaintenance } from "../utils/helpers";

export enum RegionActionTypes {
    getRegionsForCountrySucces = '[Regions] Get regions success',
    getRegionsForCountryError = '[Regions] Get regions error',
}

export const getRegions = (countryCode?: string): Thunk => (dispatch, getState) => {
    const state = getState();

    if (!countryCode) {
        countryCode = selectDistributorCountry(state);
    }

    const regions = selectRegionsForCountry(state, countryCode);

    if (regions && regions.length > 0) {
        return Promise.resolve(regions);
    }

    return regionApi.getRegions(countryCode)
        .then(regions => dispatch({ type: RegionActionTypes.getRegionsForCountrySucces, countryCode, regions }))
        .catch((exp) => {
            shouldSwithToMaintenance(exp);
            dispatch({ type: RegionActionTypes.getRegionsForCountryError })
        });
}
