import { BlogActionTypes } from './../actions/blogActions';
import { BlogFeed } from '../models/blog';


export type BlogFeedState = BlogFeed | null;


export const reducer = (state: BlogFeedState = null, action) => {
    switch (action.type) {
        case BlogActionTypes.getDistributorBlogFeedSuccess:
            return action.payload as BlogFeed;
        default:
            return state;
    }
};
