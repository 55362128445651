import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { CustomContent, Distributor } from '../../../models/distributor';
import SeneHomeCustomContent from './SeneHomeCustomContent';
import SeneHomeDistributorProfile from './SeneHomeDistributorProfile';
import './SeneHomeDistributorInfo.scss';

export interface SeneHomeDistributorInfoProps {
    distributor: Distributor
}

export class SeneHomeDistributorInfo extends React.PureComponent<SeneHomeDistributorInfoProps & WithNamespaces> {

    get distName(): string {
        return (this.props.distributor && this.props.distributor.profile.firstName && this.props.distributor.profile.lastName)
            ? `${this.props.distributor.profile.firstName} ${this.props.distributor.profile.lastName}`
            : '';
    }

    get customContent(): CustomContent[] {
        return (this.props.distributor && this.props.distributor.seneSiteInfo && this.props.distributor.seneSiteInfo.seneSiteLooks)
            ? this.props.distributor.seneSiteInfo.seneSiteLooks.customContents
            : [];
    }

    render() {
        const { t } = this.props;
        const customContent = this.customContent;
        return (
            this.props.distributor &&
            <div className="container home-dist-info__container">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <SeneHomeDistributorProfile distributor={this.props.distributor} t={this.props.t}/>
                    </div>
                    <div className="col-md-8 col-sm-12 home-dist-info__message-container">
                        <div>
                            <h2 className="home-dist-info__dist-name">{this.distName}</h2>
                        </div>
                        <div className="home-dist-info__ind-dist-text">
                            {t('independentDistributor')}
                        </div>
                        <div className="home-dist-info__dist-message">
                            <SeneHomeCustomContent currentLanguage={this.props.i18n.language} contents={customContent} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withNamespaces('DistributorInfo')(SeneHomeDistributorInfo);
