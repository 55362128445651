import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { ToastContainer, Flip } from 'react-toastify';
import { Route } from 'react-router';

import { mainRoutes } from './routing';
import BlockUi from './components/common/BlockUi';

class App extends Component {
  render() {
    return (
      <>
        <BlockUi />
        <ToastContainer className="sene-toast"/>
        <div className="App">
          <Switch>
            {mainRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </Switch>
        </div>
      </>
    );
  }
}

export default App;
