import React, { memo } from 'react';
import i18next from 'i18next';

import './OrderSummary.scss';
import { CartViewModel } from '../../../../models/cart';
import Spinner from '../../../common/Spinner';
import SeneButton from '../../../common/inputs/SeneButton';
import Currency from '../../../common/Currency';
import { CartTotalsResponse, ShippingInfo } from '../../../../models/checkout';
import { PaymentType } from "../../../../models/payment";
import { PaymentInfoState, CheckoutErrors } from "../../../../reducers/checkout";
import SeneSiteLink from '../../../common/SeneSiteLink';
import { connect } from 'react-redux';
import { State } from '../../../../store';
import { selectCurrency } from '../../../../store/selectors/catalogSelectors';

interface OrderSummaryProps {
    isLoading?: boolean;
    t: i18next.TranslationFunction;
    cart: CartViewModel;
    totalCharges?: CartTotalsResponse;
    shippingMethod?: ShippingInfo;
    handlingRate: number;
    paymentInfoState?: PaymentInfoState
    onSubmitOrder?();
    buttonLabel?: string;
    errors?: CheckoutErrors;
    currency: string;
}

const OrderSummary = memo(({
    isLoading = false,
    t,
    cart,
    totalCharges,
    shippingMethod,
    handlingRate,
    onSubmitOrder,
    paymentInfoState,
    buttonLabel,
    errors,
    currency
}: OrderSummaryProps) => {
    const totalItemCount = cart.items.reduce((acc, i) => acc += i.qty, 0);
    const shippingRate = shippingMethod && shippingMethod.rate || 0;
    const getTaxLabel = (totalCharges: CartTotalsResponse)  => {
        return `${t(totalCharges.taxLabel)}(${(totalCharges.taxRate * 100).toFixed(2)}%)`;
    }

    return (
        <div className="card-deck" style={{ margin: '0' }}>
            <div className="card order-summary-totals" style={{ margin: '0' }}>
                <div className="card-body">
                    { //Submit order: There is no error and submit button must be displayed
                        (!errors && buttonLabel && onSubmitOrder) && 
                        <SeneButton {...{
                            className: 'btn btn-primary btn-lg order-summary-totals__place-order',
                            onClick: onSubmitOrder,
                            icon: 'fa-shopping-bag',
                            disabled: isLoading || cart.items.length === 0 || !totalCharges
                        }}>
                            {t(buttonLabel)}
                        </SeneButton>
                    }
                    { //Retry order: There is a declined payment error and not a missing inventory error
                        (errors && !errors.missingInventoryErrors && onSubmitOrder) &&
                            <SeneButton {...{
                                className: 'btn btn-primary btn-lg order-summary-totals__place-order',
                                onClick: onSubmitOrder,
                                icon: 'fa-undo',
                                disabled: isLoading || cart.items.length === 0 || !totalCharges
                            }}>
                                {t("retryPlaceYourOrder")}
                            </SeneButton>
                    }
                    { //Choose another payment method: There is an error
                        (errors) && <SeneSiteLink to="/checkout?jumpToPaymentSelection=true" className="mt-2 btn btn-secondary btn-lg order-summary-totals__place-order">
                            <i className={`fas fa-undo mr-2`}></i> {t('backToPaymentSelect')}
                        </SeneSiteLink>
                    }
                    {
                        !(buttonLabel && onSubmitOrder) && <h3>{t('orderSummary')}</h3>
                    }
                    {
                        isLoading
                            ? <Spinner style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
                            : totalCharges && (
                                <div className="order-summary-totals__details">
                                    <div className="row">
                                        <div className="col-12 font-weight-bold">{t('orderTotal')} </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-7">{t('subTotal')} ({totalItemCount}) {t('items')}:</div>
                                        <div className="col-5 order-summary-totals__item-price">
                                            <Currency>{totalCharges.productTotal}</Currency>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-7">{t('shipping')}</div>
                                        <div className="col-5 order-summary-totals__item-price">
                                            {
                                                <Currency>{shippingRate}</Currency>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-7">{t('handling')}</div>
                                        <div className="col-5 order-summary-totals__item-price">
                                            <Currency>{handlingRate}</Currency>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-7">{getTaxLabel(totalCharges)}</div>
                                        <div className="col-5 order-summary-totals__item-price">
                                            <Currency currencyForced={true}>{totalCharges.totalTax}</Currency>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col">
                                            <div className="h4">{`${t('orderTotal')}`}</div>
                                        </div>
                                        <div className="col order-summary-totals__total-price">
                                            <Currency currencyForced={true}>{totalCharges.cartTotal}</Currency>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </div>
        </div>
    );
});

const mapStateToProps = (state: State) => ({
    currency: selectCurrency(state),
});

export default connect(mapStateToProps)(OrderSummary);
