import axios from 'axios';

import { ShoppingCart, CartTotalsResponse } from '../models/checkout';
import { PaymentRequest, PaymentResponse } from '../models/payment';

export interface CreatePayPalApiPaymentResult {
    redirectUrl: string,
    referenceToken: string
}
export const calculateTotals = (distId: number, cartInfo: ShoppingCart) => {
    return axios.post<CartTotalsResponse>(`/api/Distributor/${distId}/Payment/CalculateTotals`, cartInfo).then(res => res.data);
}

export const submitPayment = (distId: number, paymentRequest: PaymentRequest) => {
    return axios.post<PaymentResponse>(`/api/Distributor/${distId}/Payment/SubmitPayment`, paymentRequest).then(res => res.data);
}

export const createPayPalApiPayment = (distId: number, paymentRequest: PaymentRequest) => {
    return axios.post<CreatePayPalApiPaymentResult>(`/api/Distributor/${distId}/Payment/CreatePayPalApiPayment`, paymentRequest).then(res => res.data);
}

export const getCartInformation = (distId: number, paymentId: string) => {
    return axios.get(`/api/Distributor/${distId}/Payment/GetCachedShoppingCart/${paymentId}`).then(res => res.data);
}