import i18next from 'i18next';

import { Validator } from './../../../utils/validation/validator';
import { CustomerProfileModel } from './CustomerProfile';
import { UserInfoShippingAddress, UserInfoBillingAddress } from '../../../models/userInfo';


export class CustomerProfileValidator extends Validator<CustomerProfileModel> {
    constructor(t: i18next.TranslationFunction) {
        super();

        this.ruleFor("firstName").notEmpty(t('FirstNameError'));
        this.ruleFor("lastName").notEmpty(t('LastNameError'));
        this.ruleFor("phone").phone(t('PhoneError'));
    }
}

export class CustomerShippingAddressValidator extends Validator<UserInfoShippingAddress> {
    constructor(t: i18next.TranslationFunction, countryCode: string) {
        super();

        const cityKey = countryCode !== 'HK' ? 'cityError' : 'cityErrorHk';
        const stateKey = countryCode !== 'HK' ? 'stateError' : 'stateErrorHk';

        this.ruleFor("address1").notEmpty(t('address1Error'))
        if (countryCode.toUpperCase() !== 'NZ') {
            this.ruleFor("state").notEmpty(t(stateKey));
            if (countryCode.toUpperCase() !== 'HK') {
                this.ruleFor("zipCode").notEmpty(t('zipError'));
            }
        } else {
            this.ruleFor("zipCode").notEmpty(t('postalCodeError'));
        }
        this.ruleFor("city").notEmpty(t(cityKey));
        this.ruleFor("name").notEmpty(t('nameError'));
    }
}

export class CustomerBillingAddressValidator extends Validator<UserInfoBillingAddress> {
    constructor(t: i18next.TranslationFunction, countryCode: string) {
        super();

        const cityKey = countryCode !== 'HK' ? 'cityError' : 'cityErrorHk';
        const stateKey = countryCode !== 'HK' ? 'stateError' : 'stateErrorHk';

        this.ruleFor("address1").notEmpty(t('address1Error'))
        if (countryCode.toUpperCase() !== 'NZ') {
            this.ruleFor("state").notEmpty(t(stateKey));
            if (countryCode.toUpperCase() !== 'HK') {
                this.ruleFor("zipCode").notEmpty(t('zipError'));
            }
        } else {
            this.ruleFor("zipCode").notEmpty(t('postalCodeError'));
        }
        this.ruleFor("city").notEmpty(t(cityKey));
        this.ruleFor("firstName").notEmpty(t('firstNameError'));
        this.ruleFor("lastName").notEmpty(t('lastNameError'));
    }
}
