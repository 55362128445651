import React from 'react';
import memoize from 'memoize-one';
import { take, slice } from 'lodash';

import './ProductResults.scss';
import Spinner from '../../../common/Spinner';
import ProductCard from './ProductCard';
import { ProductVm } from '../../../../models/product';
import SectionHeader from '../../../common/headers/SectionHeader';
import SenePaginator from '../../../common/SenePaginator';
import SeneSortDropdown, { SeneNavItem } from '../../../common/SeneSortDropdown';
import { scrollToElement } from '../../../../utils/helpers';
import i18n from '../../../../i18n';
import i18next from 'i18next';

interface ProductResultsProps {
    products: ProductVm[];
    isLoading: boolean;
    currencyForced?: boolean;
    addToCart(productId: number, sku: string);
    setSort(sortBy: string, direction: string);
    sort: { sortBy: string, direction: string };
    t: i18n.TranslationFunction;
}

interface ProductResultsState {
    selectedPage: number;
    sortOptions:  SeneNavItem[];
}

class ProductResults extends React.PureComponent<ProductResultsProps, ProductResultsState> {
    private readonly itemsPerPage = 20;

    state: ProductResultsState = {
        selectedPage: 1,
        sortOptions: [
        ]
    }

    componentDidMount(){
        this.changeSortOptions();

        let languageChangeFunction = (() => this.changeSortOptions()).bind(this);
        i18next.on('languageChanged', function(lng) { languageChangeFunction()})
    }

    componentDidUpdate(prevProps: ProductResultsProps) {
        if (prevProps.products.length !== this.props.products.length) {
            this.setState({selectedPage: 1});
        }
    }

    changeSortOptions = () => {
        const { t } = this.props;
        this.setState({sortOptions: [
            { text: t('nameAscending'), data: 'name;asc', divider: false }, 
            { text: t('nameDescending'), data: 'name;desc', divider: false },
            { divider: true },
            { text: t('priceAscending'), data: 'price;asc', divider: false },
            { text: t('priceDescending'), data: 'price;desc', divider: false }
        ]});
    }

    get pageCount() {
        return Math.ceil(this.props.products.length / this.itemsPerPage);
    }

    get offset() {
        return this.state.selectedPage * this.itemsPerPage;
    }

    pageProducts = memoize(
        (products: ProductVm[], offset: number, itemsPerPage: number) => {
            return take(slice(products, (offset - itemsPerPage)), itemsPerPage);
        }
    )

    handlePageClick = (data) => {
        const selected = data.selected + 1;

        this.setState({ selectedPage: selected }, () => { scrollToElement('products-page__header') });
    };

    handleSort = (e) => {
        const [sortBy, direction] = e.data.split(';');

        this.props.setSort(sortBy, direction);
    }

    renderTopInfoRow() {
        const {t} = this.props;
        return this.props.products && this.props.products.length > 0 && this.state.sortOptions.length > 0 && (
            <div className="row">
                <div className="top-info-row">
                    <span className="products-count">
                        {`${this.props.products.length} ${this.props.products.length > 1 ? t('products') : t('product')}`}
                    </span>
                    <SeneSortDropdown
                        title={t('sortBy')}
                        items={this.state.sortOptions}
                        onClick={this.handleSort}
                        activeData={`${this.props.sort.sortBy};${this.props.sort.direction}`}
                    />
                </div>
            </div>
        )
    }

    renderProducts() {
        const { products } = this.props;
        const {t} = this.props;

        if (!products || products.length === 0) {
            return (
                <div style={{marginTop: '2rem'}}>
                    <SectionHeader>{this.props.t('noRecordsFound')}</SectionHeader>
                </div>
            );
        }

        const pagedProducts = this.pageProducts(products, this.offset, this.itemsPerPage);
        return (
            <div className="row">
                {
                    pagedProducts.map(product => {
                        return (
                            <div key={product.productId} className="col-xl-3 col-lg-4 col-md-6">
                                <ProductCard t={t} product={product} addToCart={this.props.addToCart} currencyForced={this.props.currencyForced} />
                            </div>
                        )
                    })}
            </div>
        );
    }

    renderPaginatorBottom() {
        return this.props.products && this.props.products.length > 0 && this.pageCount > 1
            && <SenePaginator t={this.props.t} pageCount={this.pageCount} selectedPage={this.state.selectedPage} onPageChange={this.handlePageClick} />
    }


    render() {
        return this.props.isLoading
            ? <Spinner className="product-result__spinner" />
            : (
                <>
                    {this.renderTopInfoRow()}
                    {this.renderProducts()}
                    {this.renderPaginatorBottom()}
                </>
            );
    }
}

export default ProductResults;
