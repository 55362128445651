import React from 'react';
import { Collapse, CardBody, Card, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Spinner from "../../common/Spinner";
import { CashOrCheckPaymentOption, AdminPaymentOption as PaymentOption } from "../../../models/payment";
import i18next from "i18next";

interface CashOrCheckPanelProps {
    distributorPaymentOption: CashOrCheckPaymentOption;
    toggle(value: number);
    isOpen: boolean;
    panel: number;
    t: i18next.TranslationFunction;
    handlePaymentOptionChanged(changedOption: PaymentOption);
}

class CashOrCheckPanel extends React.PureComponent<CashOrCheckPanelProps> {

    handleInstructionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const changedOption = { ...this.props.distributorPaymentOption, cashOrCheckInstructions: e.target.value };
        this.props.handlePaymentOptionChanged(changedOption);
    };

    handleCashOrCheckCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const changedOption = { ...this.props.distributorPaymentOption, selected: e.target.checked };
        this.props.handlePaymentOptionChanged(changedOption);
    };

    render() {
        const { t, isOpen, panel, distributorPaymentOption } = this.props;
        if (!distributorPaymentOption) {
            return (<Spinner />);
        }

        const { cashOrCheckInstructions, selected } = distributorPaymentOption;
        return (
            <>
                <Card>
                    <Row className="align-items-center">
                        <Col xs="8" sm="8" md="8" className="text-left">
                            <a className="btn text-primary" onClick={() => this.props.toggle(panel)}>
                                {
                                    isOpen ?
                                        <i className="fas fa-chevron-down btn text-primary"></i> :
                                        <i className="fas fa-chevron-right btn text-primary"></i>
                                }
                                {t('checkCashTitle')}
                            </a>
                        </Col>
                        <Col xs="4" sm="4" md="4" className="text-right">
                            {
                                selected &&
                                <div className="text-primary pr-3" style={{ cursor: "default" }}> {t('activated')} </div>
                            }
                        </Col>
                    </Row>
                </Card>
                <Collapse isOpen={isOpen}>
                    <Card>
                        <CardBody>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <Form>
                                            <FormGroup check>
                                                <Label check className="font-weight-bold">
                                                    <Input type="checkbox" onChange={this.handleCashOrCheckCheckBoxChange} checked={selected} />
                                                    {t('checkBoxAcceptCashorCheck')}
                                                </Label>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="cashCheckInstructions">{t('cashCheckInstructions')}</Label>
                                                <Input disabled={!selected} type="textarea" name="paymentInstructions" id="paymentInstructions" value={cashOrCheckInstructions || ''} onChange={this.handleInstructionsChange} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>{t('cashCheckTextBottom')}</Label>
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>
            </>
        )
    }

}

export default CashOrCheckPanel;
