import { produce } from 'immer';

import { Marketing } from './../models/marketing';
import { MarketingActionTypes } from './../actions/marketingActions';

export type MarketingState = Marketing | null;

export const reducer = (state: MarketingState = null, action) =>
    produce(state, draft => {
        switch (action.type) {
            case MarketingActionTypes.getMarketingError:
            case MarketingActionTypes.getMarketingSuccess:
                return action.payload as Marketing
            default:
                return draft;
        }
    });
