import React from 'react';
import memoize from 'memoize-one';

import { ProductVm } from '../../../../models/product';
import SeneGradientHeader from '../../../common/headers/SeneGradientHeader';
import ProductResults from './ProductResults';
import { filterMatched } from '../../../../utils/helpers';
import i18n from '../../../../i18n';

interface ShopSearchResultsProps {
    isLoading: boolean;
    query: string;
    getAllProducts(availableOnly: boolean);
    products: ProductVm[];
    addToCart(productId: number, sku: string);
    sort: { sortBy: string, direction: string };
    setSort(sortBy: string, direction: string);
    t: i18n.TranslationFunction
}

class ShopSearchResults extends React.Component<ShopSearchResultsProps> {
    componentDidMount() {
        this.props.getAllProducts(true);
    }

    filterProducts = memoize((products: ProductVm[], query: string) => {
        return products.filter(p => {
            let names = "";
            if(p.productDetails) {
                for (let key in p.productDetails.productAttributes) {
                    const translatedName = p.productDetails.productAttributes[key].name || "";
                    names += translatedName + '_';
                }
            }
            names += p.placeholderName;
            return filterMatched(`${names}${p.sku}`, query);
        }
        );
    })

    render() {
        const products = this.filterProducts(this.props.products, this.props.query);
        return (
        <>
            <SeneGradientHeader id="products-page__header">
                {`“${this.props.query}”`}
            </SeneGradientHeader>
            <ProductResults
                products={products}
                addToCart={this.props.addToCart}
                isLoading={this.props.isLoading}
                currencyForced={true}
                sort={this.props.sort}
                setSort={this.props.setSort}
                t={this.props.t}
            />
        </>
        )
    }
}

export default ShopSearchResults;
