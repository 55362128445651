import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import './ProductDetailsPage.scss';
import {
    selectProductsForCategory,
    selectProductVmById,
    selectCategories,
    selectCategory,
    selectCrossSellingProducts
} from '../../../store/selectors/catalogSelectors';
import { State } from '../../../store';
import { ProductVm } from '../../../models/product';
import { getProductById, getProductsForCategory, getProductsByIds } from '../../../actions/catalogActions';
import { Category, SubCategory } from '../../../models/category';
import ProductImageViewer from './ProductImageViewer';
import ProductAttributes from './ProductAttributes';
import CategoryBreadcrumbs from './CategoryBreadcrumbs';
import ProductColorAndQtySelector from './ProductColorAndQtySelector';
import CrossSellingProducts from './CrossSellingProducts';
import { addProductToCart } from '../../../actions/cartActions';
import SeneButton from '../../common/inputs/SeneButton';
import ShareButton from '../../common/media/ShareButton';
import { CountryLanguage } from '../../../models/language';
import { selectSelectedLanguage, selectSelectedLanguageCode } from '../../../store/selectors/languageSelectors';
import { seneSiteNavigateTo } from '../../../actions/router';
import Spinner from '../../common/Spinner';
import SectionHeader from '../../common/headers/SectionHeader';
import { selectLoading } from '../../../store/selectors/uiSelectors';
import { ActionToastProperties } from '../../../models/ui';
import { getProductName } from '../../../utils/helpers';
import { selectImageResizerUrl } from '../../../store/selectors/configurationSelectors';

interface ProductDetailsPageStateProps {
    categories: Category[];
    product: ProductVm;
    productsInCategory: ProductVm[];
    selectedLanguage: CountryLanguage | null;
    category: Category | null;
    subCategory?: SubCategory;
    crossSellingProducts?: ProductVm[];
    isLoading: boolean;
    locale: string;
    imageResizerUrl: string;
}
interface ProductDetailsPageDispatchProps {
    getProductById(productId: number);
    getProductsByIds(productIds: number[]);
    getProductsForCategory(categoryId: number, subCategoryId?: number);
    addProductToCart(productId: number, sku: string, quantity: number, toastProps?: ActionToastProperties);
    seneSiteNavigateTo(to: string);
}

interface ProductDetailsPageState {
    quantity: number;
}

type ProductDetailsPageProps = ProductDetailsPageStateProps & ProductDetailsPageDispatchProps & RouteComponentProps & WithNamespaces;

class ProductDetailsPage extends React.Component<ProductDetailsPageProps, ProductDetailsPageState> {
    state: ProductDetailsPageState = {
        quantity: 1
    }

    get locale() {
        return this.props.selectedLanguage && this.props.selectedLanguage.code || 'en-us';
    }

    private getProduct() {
        const productId = this.props.match.params['productId'];
        productId && this.props.getProductById(productId);
    }

    private getProductsInCategory() {
        this.props.getProductsForCategory(this.props.product.categoryId, this.props.product.subCategoryId);
    }

    private getCrossSellingProducts() {
        const hasCrossSellingProducts = this.props.product.productDetails
            && this.props.product.productDetails.crossSellingProductIds
            && this.props.product.productDetails.crossSellingProductIds.length;

        if (hasCrossSellingProducts) {
            this.props.getProductsByIds(this.props.product.productDetails!.crossSellingProductIds);
        }
    }

    componentDidMount() {
        this.getProduct();
    }

    componentDidUpdate(prevProps: ProductDetailsPageProps) {
        if (prevProps.match.params['productId'] !== this.props.match.params['productId']
            || prevProps.categories.length !== this.props.categories.length) {
            this.getProduct();
        }

        const productOrProductDetailsChanged = this.props.product
            && (!prevProps.product || prevProps.product.productId !== this.props.product.productId)
            || (prevProps.product && !prevProps.product.productDetails && this.props.product.productDetails);

        if (productOrProductDetailsChanged) {
            this.getProductsInCategory();
            this.getCrossSellingProducts();
            this.setState({ quantity: 1 });
        }
    }

    handleQuantityChange = (qty: number) => {
        this.setState({ quantity: qty });
    }

    addToCart = () => {
        this.props.addProductToCart(this.props.product.productId, this.props.product.sku, this.state.quantity, { success: this.props.t('addToCartSuccess') });
    }

    render() {
        const { t, product, isLoading, locale } = this.props;

        if (isLoading) {
            return <Spinner className="centered" />
        }

        return product
            ? <>
                {
                    this.props.category &&
                    <CategoryBreadcrumbs locale={locale} t={t} category={this.props.category} subCategory={this.props.subCategory} />
                }
                <div className="row">
                    <div className="col-md-5 offset-md-1">
                        <ProductImageViewer
                            imageResizerUrl={this.props.imageResizerUrl}
                            product={product}
                        />
                    </div>
                    <div className="col-md-5 product-details">
                        <div className="product-details__base">
                            <h2 className="product-details__title">{getProductName(product, locale)}</h2>
                            <p className="product-details__info">{product.displayWeight} </p>
                            <p className="product-details__info">
                                {product.baseShade && `${t(product.baseShade)} ${t('based')} -`} {t('item')} #{product.sku}
                            </p>
                            <p className="product-details__price">
                                {product.displayPrice}
                            </p>
                        </div>

                        <ProductColorAndQtySelector
                            product={product}
                            productsInCategory={this.props.productsInCategory}
                            navigate={this.props.seneSiteNavigateTo}
                            quantity={this.state.quantity}
                            onQuantityChange={this.handleQuantityChange}
                            t={t}
                        />

                        <div className="row">
                            <div className="col-lg-6 mt-2 mt-lg-0">
                                <SeneButton
                                    onClick={this.addToCart}
                                    icon="sene-shopping-bag"
                                    buttonClass="secondary"
                                    className="col"
                                    disabled={!product.isForSale}
                                >
                                    {t('addToBag')}
                                </SeneButton>
                            </div>
                            <div className="col-lg-4">
                                <ShareButton
                                    className="col"
                                    id="share-product"
                                    t={t}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ProductAttributes
                    product={product}
                    locale={this.locale}
                    t={t}
                />
                <CrossSellingProducts
                    t={t}
                    products={this.props.crossSellingProducts}
                    addToCart={this.props.addProductToCart}
                />
            </>
            : (
                <div style={{ marginTop: '2rem' }}>
                    {/* TODO: What should happen there */}
                    <SectionHeader>{t('productNotFound')}</SectionHeader>
                </div>
            );
    }
}
const mapStateToProps = (state: State, ownProps: ProductDetailsPageProps) => {
    const product: ProductVm = selectProductVmById(state, +ownProps.match.params['productId'])
    const { categoryId = 0, subCategoryId = 0 } = product || {};
    const category = selectCategory(state, categoryId) as Category | null;
    const subCategory = subCategoryId
        ? selectCategory(state, categoryId, subCategoryId) as SubCategory
        : undefined;
    const crossSellingProducts = selectCrossSellingProducts(state, product);

    return {
        isLoading: selectLoading(state),
        categories: selectCategories(state),
        productsInCategory: selectProductsForCategory(state, categoryId, subCategoryId),
        selectedLanguage: selectSelectedLanguage(state),
        product,
        category,
        subCategory,
        crossSellingProducts,
        locale: selectSelectedLanguageCode(state),
        imageResizerUrl: selectImageResizerUrl(state)
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    getProductById,
    getProductsByIds,
    getProductsForCategory,
    addProductToCart,
    seneSiteNavigateTo
}, dispatch);

export default withNamespaces(['ProductDetail', 'Colors'])(connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPage));
