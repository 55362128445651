
import React from 'react';
import { ImageUpload } from '../../common/inputs/ImageUpload';

import './SeneProfilePage.scss';
import { Distributor } from '../../../models/distributor';
import placeholder from '../../../assets/images/image_placeholder-01.png';
import i18n from '../../../i18n';
import ImageComponent from '../../common/ImageComponent';
import { Configuration } from '../../../models/configuration';
import { selectConfiguration } from '../../../store/selectors/configurationSelectors';
import { connect } from 'react-redux';
import { State } from '../../../store';

interface SeneProfileImageProps {
    t: i18n.TranslationFunction;
    distributor: Distributor;
    updateDistributorPicture(fileStream: any);
    config: Configuration | null;
}

export class SeneProfileImage extends React.Component<SeneProfileImageProps> {

    uploadFileHandler = (fileStream: any) => {
        this.props.updateDistributorPicture(fileStream);
    }

    render () {
        const { t, config } = this.props;

        const pic = this.props.distributor.seneSiteInfo.seneSiteLooks.seneSiteDistrbutorImage;
        const imgSrc = pic && config
            ? `${config.apiConstants.IMAGESCUSTOMERS}${pic}`
            : placeholder;

        return <div className='picture-panel'>
                    <div className='row'>
                        <div className='picture-panel-img' >
                            {
                                pic
                            ? <ImageComponent src={imgSrc} alt="distributor image" width={300} height={300} crop="stretch"/>
                                    : <img src={imgSrc} width={300} height={300} alt="distributor image" />
                            }
                        </div>
                    </div>
                    <div>
                        <ImageUpload t={this.props.t} uploadFileHandler={this.uploadFileHandler} />
                    </div>
                </div>
    }
}

const mapStateToProps = (state: State) => ({
    config: selectConfiguration(state)
})

export default connect(mapStateToProps)(SeneProfileImage);