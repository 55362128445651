import React from 'react';
import * as dompurify from 'dompurify';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import "./SeneDisclaimer.scss";

export class SeneTermsOfUsePage extends React.Component<WithNamespaces> {

    render() {
        return <div className="disclaimer" dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(this.props.t('termsOfUse')) 
           }} /> 
    }
}

export default withNamespaces("TermsOfUse")(SeneTermsOfUsePage);
