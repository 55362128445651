import React from 'react';
import { FormGroup, CustomInput, Label } from 'reactstrap';
import i18next from 'i18next';

import './ShippingForm.scss';
import Reaptcha from 'reaptcha';
import { CustomerInformation, ShippingAddress, ShippingCaptchaState } from '../../../../models/checkout';
import TextInput from '../../../common/inputs/SeneTextInput';
import { OrderInfoParams } from '../../../../actions/checkoutActions';
import { ShippingInfo } from '../../../../models/distributor';
import { CustomerInfoValidator, ShippingAddressValidator, ShippingMethodValidator, ShippingCaptchaValidator } from './ShippingFormValidators';
import { ValidationErrors, Validator } from '../../../../utils/validation/validator';
import { CheckoutState } from '../../../../reducers/checkout';
import Currency from '../../../common/Currency';
import SelectInput from '../../../common/inputs/SeneSelectInput';
import { CountryState } from '../../../../models/region';
import * as captchaApi from '../../../../api/recaptchaApi';
import { DistributorState } from '../../../../reducers/distributor';
import DistributorInfo from '../../../administration/dashboard/DistributorInfo';
import { CountryLanguage } from '../../../../models/language';
import { TranslationRemapper, shouldSwithToMaintenance } from "../../../../../src/utils/helpers";

interface ShippingFormProps {
    currentUser: any;
    t: i18next.TranslationFunction;
    shippingMethods: ShippingInfo[];
    saveOrderInfo(info: OrderInfoParams);
    checkoutState: CheckoutState;
    regions: CountryState[];
    recaptchaKey: string;
    distributor: DistributorState;
    selectedLanguageAlpha2Code: string;
    captchasDisabled: boolean;
}

interface ShippingFormState {
    customerInfo: CustomerInformation,
    shippingAddress: ShippingAddress;
    shippingMethod: ShippingInfo;
    shippingCaptchaState: ShippingCaptchaState;

    errors: {
        customerInfo: ValidationErrors<CustomerInformation>,
        shippingAddress: ValidationErrors<ShippingAddress>,
        shippingMethod: ValidationErrors<ShippingInfo>
        shippingcaptchaState: ValidationErrors<ShippingCaptchaState>
    }
}

class ShippingForm extends React.Component<ShippingFormProps, ShippingFormState> {
    state: Readonly<ShippingFormState> = {
        customerInfo: {
            email: '',
            firstName: '',
            lastName: '',
            phone: ''
        },
        shippingAddress: {
            address: '',
            address2: '',
            address3: '',
            city: '',
            country: '',
            state: '',
            zipCode: '',
        },
        shippingCaptchaState: {
            isCaptchaVerified: false,
            captchaToken: '',
        },
        shippingMethod: this.getDefaultSelectedShippingMethod(),
        errors: {
            customerInfo: {},
            shippingAddress: {},
            shippingMethod: {},
            shippingcaptchaState: {}
        }
    }

    customerInfoValidator?: Validator<CustomerInformation>;
    shippingAddressValidator?: Validator<ShippingAddress>;
    shippingMethodValidator?: Validator<ShippingInfo>;
    captchaMethodValidator?: Validator<ShippingCaptchaState>;

    getDefaultSelectedShippingMethod(): ShippingInfo {
        if (this.props.shippingMethods && this.props.shippingMethods.length == 1) {
            return this.props.shippingMethods[0];
        } else {
            return {} as ShippingInfo;
        }
    }

    get regions() {
        return this.props.regions.map(country => ({ value: country.abbreviation, text: country.name }));
    }

    componentDidMount() {
        this.loadLoggedInUser();
        this.loadSavedCheckoutState();
    }

    componentDidUpdate(prevProps: ShippingFormProps) {
        if (this.props.currentUser && (!prevProps.currentUser || this.props.currentUser !== prevProps.currentUser)) {
            this.loadLoggedInUser();
        }
        if (this.props.selectedLanguageAlpha2Code != prevProps.selectedLanguageAlpha2Code) {
            this.onExpire();
        }
    }

    loadSavedCheckoutState() {
        const { customerInfo, shippingAddress, shippingMethod } = this.props.checkoutState;

        // React batches state updates, so we totally can do this
        customerInfo && this.setState({ customerInfo });
        shippingAddress && this.setState({ shippingAddress });
        shippingMethod && this.setState({ shippingMethod });
    }

    loadLoggedInUser() {
        if (this.isLoggedIn) {
            const { shippingAddress } = this.props.currentUser;

            this.setState({
                customerInfo: {
                    ...this.state.customerInfo,
                    firstName: this.props.currentUser.firstName,
                    lastName: this.props.currentUser.lastName,
                    email: this.props.currentUser.email,
                    phone: this.props.currentUser.phone
                }
            })

            const isUserFromDistributorCountry = this.props.currentUser.country === (this.props.distributor && this.props.distributor.countryName)

            if (isUserFromDistributorCountry) {
                shippingAddress && this.setState({
                    shippingAddress: {
                        ...this.state.shippingAddress,
                        address: shippingAddress.address1,
                        address2: shippingAddress.address2,
                        address3: shippingAddress.address3,
                        city: shippingAddress.city,
                        state: shippingAddress.state,
                        zipCode: shippingAddress.zipCode
                    }
                });
            }

        }
    }

    get isLoggedIn() {
        return !!this.props.currentUser;
    }

    getTranslator() {
        const dic = {
            'NZ': {
                'zipCode': 'postalCode',
                'zipCodeError': 'msgPostal'
            },
            'HK': {
                "address": "addressHk",
                "address2": "address2Hk",
                "address3": "address3Hk",
                "city": "cityHk",
                "state": "stateHk",
                "msgCity": "msgCityHk",
                "msgState": "msgStateHk"
            }
        };
        const countryName = this.props && this.props.distributor ? this.props.distributor.countryName : '';
        return new TranslationRemapper(dic, this.props.t).getTranslatorFor(countryName);
    }

    isValidated = () => {
        const distributorCountry = (this.props.distributor && this.props.distributor.countryName) || '';
        this.customerInfoValidator = new CustomerInfoValidator(this.props.t);
        const customerInfoResult = this.customerInfoValidator.validate(this.state.customerInfo);

        this.shippingAddressValidator = new ShippingAddressValidator(this.props.t, distributorCountry);
        const shippingAddressResult = this.shippingAddressValidator.validate(this.state.shippingAddress);

        this.shippingMethodValidator = new ShippingMethodValidator(this.props.t, this.props.shippingMethods);
        const shippingMethodResult = this.shippingMethodValidator.validate(this.state.shippingMethod);

        this.captchaMethodValidator = new ShippingCaptchaValidator(this.props.t, this.isLoggedIn, this.props.captchasDisabled);
        const captchaResult = this.captchaMethodValidator.validate(this.state.shippingCaptchaState)

        if (customerInfoResult.isValid && shippingAddressResult.isValid && shippingMethodResult.isValid && captchaResult.isValid) {
            this.props.saveOrderInfo({
                customerInfo: this.state.customerInfo,
                shippingAddress: this.state.shippingAddress,
                shippingMethod: this.state.shippingMethod,
            });
            return true;
        } else {
            this.setState({
                errors: {
                    customerInfo: customerInfoResult.errors,
                    shippingAddress: shippingAddressResult.errors,
                    shippingMethod: shippingMethodResult.errors,
                    shippingcaptchaState: captchaResult.errors
                }
            })
            return false;
        }
    }

    handleCustomerInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.handleChange('customerInfo', e);
    }

    handleShippingAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        this.handleChange('shippingAddress', e);
    }

    handleShippingMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = e.target.value;

        this.setState({ shippingMethod: this.props.shippingMethods[index] });

        if (this.state.errors.shippingMethod) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    shippingMethod: {}
                }
            });
        }
    }

    handleChange = (targetGroup: 'customerInfo' | 'shippingAddress', e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            ...this.state,
            [targetGroup]: {
                ...this.state[targetGroup],
                [name]: value
            }
        })
    }

    onVerify = (recaptchaToken: string) => {
        this.setState({
            shippingCaptchaState: {
                ...this.state.shippingCaptchaState,
                captchaToken: recaptchaToken
            },
        });
        captchaApi.validateRecaptchaTokenApi(recaptchaToken).then(res => {
            this.setState({
                shippingCaptchaState: {
                    ...this.state.shippingCaptchaState,
                    isCaptchaVerified: true
                }
            })
        }).catch(exp => {
            shouldSwithToMaintenance(exp);
            this.setState({
                shippingCaptchaState: {
                    ...this.state.shippingCaptchaState,
                    isCaptchaVerified: false
                }
            })
        })
    }

    onExpire = () => {
        this.setState({
            shippingCaptchaState: {
                isCaptchaVerified: false,
                captchaToken: ''
            }
        });
    }

    renderGuestCustomerInfo() {
        const { t } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-sm-4">
                        <TextInput {...{
                            name: 'firstName',
                            label: `${t('firstName')} *`,
                            id: 'firstName',
                            onChange: this.handleCustomerInfoChange,
                            value: this.state.customerInfo.firstName,
                            error: this.state.errors.customerInfo.firstName,
                            autocomplete: 'given-name'
                        }} />
                    </div>
                    <div className="col-sm-4">
                        <TextInput {...{
                            name: 'lastName',
                            label: `${t('lastName')} *`,
                            id: 'lastName',
                            onChange: this.handleCustomerInfoChange,
                            value: this.state.customerInfo.lastName,
                            error: this.state.errors.customerInfo.lastName,
                            autocomplete: 'family-name'
                        }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <TextInput {...{
                            name: 'email',
                            label: `${t('email')} *`,
                            id: 'email',
                            type: 'email',
                            onChange: this.handleCustomerInfoChange,
                            value: this.state.customerInfo.email,
                            error: this.state.errors.customerInfo.email,
                            autocomplete: 'email'
                        }} />
                    </div>
                    <div className="col-sm-4">
                        <TextInput {...{
                            name: 'phone',
                            label: `${t('phone')} *`,
                            id: 'phone',
                            onChange: this.handleCustomerInfoChange,
                            value: this.state.customerInfo.phone,
                            error: this.state.errors.customerInfo.phone,
                            autocomplete: 'tel-national'
                        }} />
                    </div>
                </div>
            </>
        )
    }

    renderLoggedInCustomerInfo() {
        const { t } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-sm-4">
                        <label className="font-weight-bold">{t('fullName')}</label>
                        <div>{this.state.customerInfo.firstName} {this.state.customerInfo.lastName}</div>
                    </div>
                    <div className="col-sm-4 mt-sm-2">
                        <label className="font-weight-bold">{t('email')}</label>
                        <div>{this.state.customerInfo.email}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <TextInput {...{
                            name: 'phone',
                            label: `${t('phone')} *`,
                            id: 'phone',
                            onChange: this.handleCustomerInfoChange,
                            value: this.state.customerInfo.phone,
                            error: this.state.errors.customerInfo.phone,
                            autocomplete: 'tel-national'
                        }} />
                    </div>
                </div>
            </>
        )
    }

    renderShippingOptions() {
        const { t } = this.props;
        return (
            <div className="col-12">
                <h3 className="paragraph-title">{t('shippingOptions')}</h3>
                <br />
                <div className="container-fluid">
                    <p className="address-text">{t('shippingNote')}</p>
                    <br />
                    <FormGroup>
                        {this.props.shippingMethods.length > 0 &&
                            this.props.shippingMethods.map((info, i) => {
                                return (
                                    <div className="shipping-option" key={i}>
                                        <CustomInput
                                            type="radio"
                                            name="shippingInfo"
                                            id={`option${i}`}
                                            value={i}
                                            checked={info === this.state.shippingMethod}
                                            onChange={this.handleShippingMethodChange}
                                        />
                                        <Label for={`option${i}`} className="col-10 col-md-6 col-xl-5 shipping-option__label">
                                            <span>{info.carrierName}</span>
                                            <span><Currency>{info.rate}</Currency></span>
                                        </Label>
                                    </div>
                                )
                            })
                        }
                        {this.props.shippingMethods.length === 0 &&
                            <div className="shipping-option">
                                <CustomInput
                                    type="radio"
                                    name="shippingInfo"
                                    id={`option0`}
                                    value={0}
                                    checked={true}
                                />
                                <Label for={`option0`} className="col-10 col-md-6 col-xl-5 shipping-option__label">
                                    <span>{this.props.t('defaultShipping')}</span>
                                    <span><Currency>{0}</Currency></span>
                                </Label>
                            </div>
                        }
                    </FormGroup>
                    {
                        this.state.errors.shippingMethod.rate &&
                        <div className="alert alert-danger">{this.state.errors.shippingMethod.rate}</div>
                    }
                </div>
            </div>
        );
    }

    render() {
        const t = this.getTranslator();

        return (
            <>
                <form id="shipping_form">
                    <div className="form-group col-12">
                        <h3 className="paragraph-title">{t('personalInfo')}</h3>
                        <div className="container-fluid">
                            {
                                this.isLoggedIn
                                    ? this.renderLoggedInCustomerInfo()
                                    : this.renderGuestCustomerInfo()
                            }
                        </div>
                    </div>
                    <div className="form-group col-md-12 content form-block-holder">
                        <h3 className="paragraph-title">{t('shippingAddress')}</h3>
                        <br />

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <TextInput {...{
                                        name: 'address',
                                        label: `${t('address')} *`,
                                        id: 'address',
                                        onChange: this.handleShippingAddressChange,
                                        value: this.state.shippingAddress.address,
                                        error: this.state.errors.shippingAddress.address,
                                        autocomplete: 'address-line1'
                                    }} />
                                </div>
                                <div className="col-sm-4">
                                    <TextInput {...{
                                        name: 'address2',
                                        label: t('address2'),
                                        id: 'address2',
                                        onChange: this.handleShippingAddressChange,
                                        value: this.state.shippingAddress.address2,
                                        autocomplete: 'address-line2'
                                    }} />
                                </div>
                            </div>

                            {this.props.distributor && (this.props.distributor.countryName == "MX" || this.props.distributor.countryName == "HK") &&
                                <div className="row">
                                    <div className="col-sm-4">
                                        <TextInput {...{
                                            name: 'address3',
                                            label: t('address3'),
                                            id: 'address3',
                                            onChange: this.handleShippingAddressChange,
                                            value: this.state.shippingAddress.address3,
                                            autocomplete: 'address-line3'
                                        }} />
                                    </div>
                                </div>
                            }

                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <TextInput {...{
                                            name: 'city',
                                            label: `${t('city')} *`,
                                            id: 'city',
                                            onChange: this.handleShippingAddressChange,
                                            value: this.state.shippingAddress.city,
                                            error: this.state.errors.shippingAddress.city,
                                            autocomplete: 'address-level2'
                                        }} />
                                    </div>
                                </div>
                                {this.props.distributor && this.props.distributor.countryName !== "NZ" &&
                                    <div className="col-sm-4">
                                        <SelectInput {...{
                                            name: 'state',
                                            label: `${t('state')} *`,
                                            id: 'state',
                                            onChange: this.handleShippingAddressChange,
                                            options: this.regions,
                                            value: this.state.shippingAddress.state,
                                            error: this.state.errors.shippingAddress.state,
                                            autocomplete: 'address-level1'
                                        }} />
                                    </div>
                                }
                                {this.props.distributor && this.props.distributor.countryName == "NZ" &&
                                    <div className="col-sm-4">
                                        <TextInput {...{
                                            name: 'address3',
                                            label: t('suburb'),
                                            id: 'address3',
                                            onChange: this.handleShippingAddressChange,
                                            value: this.state.shippingAddress.address3,
                                            autocomplete: 'address-line3'
                                        }} />
                                    </div>
                                }
                            </div>
                            <div className="row">
                                {this.props.distributor && this.props.distributor.countryName !== "HK" &&
                                    <div className="col-sm-4">
                                        <TextInput {...{
                                            name: 'zipCode',
                                            label: `${t('zipCode')} *`,
                                            id: 'zipCode',
                                            onChange: this.handleShippingAddressChange,
                                            value: this.state.shippingAddress.zipCode,
                                            error: this.state.errors.shippingAddress.zipCode,
                                            autocomplete: 'postal-code'
                                        }} />
                                    </div>
                                }
                            </div>
                            <div className="row">
                                {
                                    this.isLoggedIn ?
                                        <div></div> :

                                        <div className="col-sm-4" key={this.props.selectedLanguageAlpha2Code + "_captcha"}>
                                            {!this.props.captchasDisabled && <Reaptcha
                                                hl={this.props.selectedLanguageAlpha2Code}
                                                sitekey={this.props.recaptchaKey}
                                                onVerify={this.onVerify}
                                                onExpire={this.onExpire}
                                            />}
                                        </div>
                                }
                            </div>
                            {this.state.errors.shippingcaptchaState['captchaToken'] && <div className="alert alert-danger">{this.state.errors.shippingcaptchaState['captchaToken']}</div>}
                        </div >
                    </div>
                    {this.renderShippingOptions()}
                </form>
            </>
        )
    }
}

export default ShippingForm;
