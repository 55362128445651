import React from 'react';
import memoize from 'memoize-one';
import { uniq, chunk, sortBy } from 'lodash';
import { FormGroup, Input, Label, CarouselItem, Row, Col } from 'reactstrap';

import './ProductColorAndQtySelector.scss';
import { ProductVm } from '../../../models/product';
import SeneCarouselBase from '../../common/SeneCarouselBase';
import SeneSiteLink from '../../common/SeneSiteLink';
import SeneNumberSelect from '../../common/inputs/SeneNumberSelect';
import i18n from '../../../i18n';
import ImageComponent from '../../common/ImageComponent';
interface ProductColorAndQtySelectorProps {
    productsInCategory: ProductVm[];
    product: ProductVm;
    navigate(to: string);
    quantity: number;
    onQuantityChange(qty: number);
    t: i18n.TranslationFunction
}

interface ProductColorAndQtySelectorState {
    selectedBaseName: string;
}

class ProductColorAndQtySelector extends React.PureComponent<ProductColorAndQtySelectorProps, ProductColorAndQtySelectorState> {
    private readonly swatchesDisplayed = 12;
    private readonly allBaseShadesKey = 'All';

    state: ProductColorAndQtySelectorState = {
        selectedBaseName: this.allBaseShadesKey
    }

    get shouldShowColorSelector() {
        return this.props.product.showColorSwatches && this.props.productsInCategory && this.props.productsInCategory.length;

    }

    getBasesInCategory = memoize((products: ProductVm[]) => {
        return uniq(products.map(p => p.baseShade))
            .filter(b => !!b)
            .sort();
    })

    getProductsInSelectedBase = memoize((products: ProductVm[], selectedBase: string) => {
        return selectedBase === this.allBaseShadesKey
            ? sortBy(products, (p: ProductVm) => p.name)
            : sortBy(products.filter(p => p.baseShade === selectedBase), (p: ProductVm) => p.name);
    })

    getCarouselItems = memoize((productsInBase: ProductVm[]) => {
        return chunk(productsInBase, this.swatchesDisplayed);
    })

    handleBaseChange = (e: React.ChangeEvent<HTMLSelectElement> & React.ChangeEvent<HTMLInputElement>) => {
        const selectedBaseName = e.target.value;
        this.setState({ selectedBaseName }, () => {
            // If the current products has a different base color, navigate to the first product in the products in selected base list
            if (selectedBaseName !== this.allBaseShadesKey && this.props.product.baseShade !== selectedBaseName) {
                const productsInBase = this.getProductsInSelectedBase(this.props.productsInCategory, selectedBaseName);

                this.props.navigate(productsInBase[0].link);
            }
        });
    }

    handleColorChange = (e: React.ChangeEvent<HTMLSelectElement> & React.ChangeEvent<HTMLInputElement>) => {
        const productId = e.target.value;
        const product = this.props.productsInCategory.find(p => p.productId === +productId);

        if (product) {
            this.props.navigate(product.link);
        }
    }

    itemRenderer = ({ items, onExited, onExiting }) => {
        return items.map((page: any[], i) => {
            return (
                <CarouselItem
                    key={i}
                    onExiting={onExiting}
                    onExited={onExited}
                >
                    <div className="swatch-carousel__item">
                        {page.map((p: ProductVm) => {
                            const swatchImageUrl = p.colorSwatchImage ? `products/ProductImages/` + p.colorSwatchImage : `products/ProductImages/${p.sku}_swatch.tif`;
                            return (
                                <SeneSiteLink key={p.sku} to={p.link}>
                                    <ImageComponent
                                        height={58}
                                        width={58}
                                        crop={"pad"}
                                        src={swatchImageUrl}
                                        extension="png"
                                        fallback="/image_placeholder.png"
                                    />
                                </SeneSiteLink>
                            )
                        })
                        }
                    </div>
                </CarouselItem>
            )
        });
    }

    renderColorSelector() {
        const uniqBases = [this.allBaseShadesKey, ...this.getBasesInCategory(this.props.productsInCategory)];
        const productsInBase = this.getProductsInSelectedBase(this.props.productsInCategory, this.state.selectedBaseName);
        const carouselItems = this.getCarouselItems(productsInBase);
        const { t } = this.props;
        return (
            <>
                <FormGroup>
                    <Label for="baseSelect">{t('baseColor')}</Label>
                    <Input type="select" name="baseSelect" id="baseSelect" onChange={this.handleBaseChange}>
                        {
                            uniqBases.map((b) => (
                                <option key={b} value={b}>{this.props.t(b)}</option>
                            ))
                        }
                    </Input>
                </FormGroup>
                <SeneCarouselBase
                    className="swatch-carousel"
                    items={carouselItems}
                    indicators={false}
                    renderItems={this.itemRenderer}
                    t={t}
                />
                <div className="row">
                    <div className="col-md-9">
                        <FormGroup>
                            <Label for="colorSelect">{t('selectColor')}</Label>
                            <Input type="select" name="colorSelect" id="colorSelect" value={this.props.product.productId} onChange={this.handleColorChange}>
                                {
                                    productsInBase.map((p) => (
                                        <option key={p.productId} value={p.productId}>{p.name}</option>
                                    ))
                                }
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-md-3">
                        {this.renderQtySelector()}
                    </div>
                </div>
            </>
        );
    }

    renderQtySelector() {
        return (
            <FormGroup row>
                <Label xs={4} sm={4} md={4} lg={2}>{this.props.t('qty')}</Label>
                <Col xs={8} sm={8} md={8} lg={4}>
                    <SeneNumberSelect
                        value={this.props.quantity}
                        onChange={this.props.onQuantityChange}
                    />
                </Col>
            </FormGroup>
        )
    }

    render() {
        if (!this.props.product) {
            return null;
        }

        return this.shouldShowColorSelector
            ? this.renderColorSelector()
            : this.renderQtySelector();
    }
}

export default ProductColorAndQtySelector;
