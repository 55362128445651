import { SignalRActionTypes } from './../actions/signalR';

export type SignalRState = string | null;

export const reducer = (state: SignalRState = null, action) => {
    switch (action.type) {
        case SignalRActionTypes.connectionStarted:
            return action.connectionId;
        default:
            return state;
    }
}
